<ng-container>
	<form @grow [formGroup]="customerForm" (ngSubmit)="onSubmit()" class="needs-validation product-add">
		<div class="card-header">
			<div class="col-12 text-right">
				<!-- [disabled]="!customerForm.valid" -->
				<button class="ml-auto mr-0" mat-button mat-raised-button  [color]="customerForm.valid ? 'warn': 'accent' "> <i class="material-icons">save</i> Save</button>

			</div>
			
		</div>

		<div class="row">
			<ng-container *ngIf="config && config.env.package === 'beauty'">
				<div class="col-md-12 text-center mb-3">
					<mat-slide-toggle formControlName="signed_mor">Signed Contract</mat-slide-toggle>
				</div>
				<div class="col-md-6">
					<mat-form-field appearance="outline">
						<mat-label>License</mat-label>
						<input matInput formControlName="cosm_license">
					</mat-form-field>
				</div>
				<div class="col-md-6" >
					<mat-form-field appearance="outline">
					  <mat-label>Expiration</mat-label>
					  <input matInput formControlName="licExpDate" [matDatepicker]="licExpDatedatepicker">
					  <mat-datepicker-toggle matSuffix [for]="licExpDatedatepicker"></mat-datepicker-toggle>
					  <mat-datepicker #licExpDatedatepicker></mat-datepicker>
					
					  <!-- Hint message for invalid date -->
					  <mat-hint *ngIf="customerForm.get('licExpDate')?.invalid && customerForm.get('licExpDate')?.touched" class="text-danger">
						Invalid date
					  </mat-hint>
					</mat-form-field>

				</div>
			</ng-container>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Name</mat-label>
					<input matInput required value="" formControlName="name">
				</mat-form-field>
			</div>
		</div>
		<div class="row" *ngIf="config && config.env.package === 'beauty'">
		<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Parent Customer No</mat-label>
					<input matInput value="" formControlName="parentdebtorno">
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Address 1</mat-label>
					<input matInput required value="" formControlName="address1">
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Address 2</mat-label>
					<input matInput value="" formControlName="address2">
				</mat-form-field>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>City</mat-label>
					<input matInput required value="" formControlName="address3">
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline" class="">
					<mat-label>Region</mat-label>
					<mat-select formControlName="address4">
						<mat-option *ngFor="let zone of zones.zones" [value]="zone.code">
							{{zone.code }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="col-md-4">
				<mat-form-field appearance="outline">
					<mat-label>Postal</mat-label>
					<input matInput required value="" formControlName="address5">
				</mat-form-field>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline" class="">
					<mat-label>Country</mat-label>
					<mat-select formControlName="address6">
						<mat-option *ngFor="let zone of zones.countries" [value]="zone.name">
							{{ zone.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>

		<ng-container *ngIf="user.user.customeradmin == '1'">
			<div class="col-12 text-center mb-2">
				<button mat-raised-button color="accent" (click)="removeCustomer()">
					Delete Customer
				</button>
			</div>
			<br />
		</ng-container>
	</form>
</ng-container>
