<div class="main-content">
  <div class="container-fluid">
	<div class="row">
	  <div class="col-md-12">
		<div class="card">
		  <div class="card-header card-header-{{color}}">
			<h4 class="card-title" id="headof">
			  <i class="material-icons clickable-icon" (click)="back()">arrow_left</i>
			  Trial Balance
			</h4>
		  </div>
		  <div class="card-body">
			<!-- Form Section -->
			<form [formGroup]="formGroup" *ngIf="formGroup">
			  <div class="row">
				<div class="col-md-6">
				  <mat-form-field appearance="outline" class="ml-2 mr-2 full-width">
					<mat-label>From Period</mat-label>
					<mat-select formControlName="from_period" (selectionChange)="updateToOptions($event.value)">
					  <mat-option *ngFor="let period of from_period_options" [value]="period.periodno">
						{{ period.lastdate_in_period }}
					  </mat-option>
					</mat-select>
				  </mat-form-field>
				</div>
				<div class="col-md-6">
				  <mat-form-field appearance="outline" class="ml-2 mr-2 full-width">
					<mat-label>To Period</mat-label>
					<mat-select formControlName="to_period">
					  <mat-option *ngFor="let period of to_period_options" [value]="period.periodno">
						{{ period.lastdate_in_period }}
					  </mat-option>
					</mat-select>
				  </mat-form-field>
				</div>
				<div class="col-md-6 mt-3">
				  <button mat-raised-button color="accent" class="action-button" (click)="getTrialBalance()">Run</button>
				  &nbsp;
				  <ng-container *ngIf="trial_balance && trial_balance.length">
					&nbsp;&nbsp;
					<a href="{{pdflink}}" class="btn btn-danger" title="Download PDF">
					  <i class="fa fa-file-pdf-o"></i>
					</a>
					&nbsp;&nbsp;
					<a href="{{excellink}}" class="btn btn-success" title="Download Excel">
					  <i class="fa fa-file-excel-o"></i>
					</a>
				  </ng-container>
				</div>
			  </div>
			</form>

			<!-- Loading Spinner -->
			<div class="row">
			  <div class="col-md-12 text-center" *ngIf="loading">
				<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			  </div>
			</div>

			<!-- Trial Balance Table -->
			<ng-container *ngIf="trial_balance">
			  <div class="trial-balance-container">
				<h2 class="title">{{ title }}</h2>

				<div class="group" *ngFor="let group of trial_balance; last as isLast">
				  <h3 class="group-title">{{ group.title }}</h3>
				  <div class="table-container">
					<table class="table table-striped table-bordered">
					  <thead>
						<tr>
						  <th>Account</th>
						  <th>Account Name</th>
						  <th class="text-right">Month Actual</th>
						  <th class="text-right">Month Budget</th>
						  <th class="text-right">Period Actual</th>
						  <th class="text-right">Period Budget</th>
						</tr>
					  </thead>
					  <tbody>
						<tr *ngFor="let account of group.accounts">
						  <td>{{ account.accountcode }}</td>
						  <td>{{ account.accountname }}</td>
						  <td class="text-right">{{ account.monthactual | currency }}</td>
						  <td class="text-right">{{ account.monthbudget | currency }}</td>
						  <td class="text-right">{{ account.periodactual | currency }}</td>
						  <td class="text-right">{{ account.periodbudget | currency }}</td>
						</tr>
					  </tbody>
					  <tfoot>
						<tr>
						  <td colspan="2"><strong>{{ group.totals.groupname }} Totals</strong></td>
						  <td class="text-right">{{ group.totals.monthactual | currency }}</td>
						  <td class="text-right">{{ group.totals.monthbudget | currency }}</td>
						  <td class="text-right">{{ group.totals.periodactual | currency }}</td>
						  <td class="text-right">{{ group.totals.periodbudget | currency }}</td>
						</tr>
						<tr *ngIf="isLast">
						  <td colspan="2"><strong>Check Totals</strong></td>
						  <td class="text-right">{{ trial_balance_totals.monthactual | currency }}</td>
						  <td class="text-right">{{ trial_balance_totals.monthbudget | currency }}</td>
						  <td class="text-right">{{ trial_balance_totals.periodactual | currency }}</td>
						  <td class="text-right">{{ trial_balance_totals.periodbudget | currency }}</td>
						</tr>
					  </tfoot>
					</table>
				  </div>
				</div>
			  </div>
			</ng-container>

			<!-- No Results Section -->
			<ng-container *ngIf="!trial_balance">
			  <div class="no-results-container">
				<h4>No Results Found</h4>
			  </div>
			</ng-container>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</div>
