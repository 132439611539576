import { Injectable } from '@angular/core';
import { Message } from '../classes/message';
import { Observable } from 'rxjs';
import { UsersService } from './users.service';
import { catchError , retry } from 'rxjs/internal/operators';
import { HttpHeaders,HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { GlobalSearchService } from './globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';


@Injectable({
	providedIn: 'root'
})
export class PaymentsService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	//todo make connection reusabel
	socket: any;
	details: any;
	user : any = [];
	package: any = '';

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type':	'application/json',
		})
	};

	constructor(private http: HttpClient,private _configSvc: RuntimeConfigLoaderService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.globalSearchService.user.subscribe( (result) => {
			this.user = result;
		});

	}

	public openJournal(data: any) {
		const method = 'payments/journal/open';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public closeJournal(data: any) {
		const method = 'payments/journal/close';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getJournals(data: any) {
		const method = 'payments/journal/getopenjournals';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getAllUserOpenJounrals(data: any) {
		const method = 'payments/journal/getalluseropenjournals';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}



	public getJournal(data: any) {
		const method = 'payments/journal/getopenjournal';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getAllJournals(data: any) {
		const method = 'payments/journal/getalljournals';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getClosedJournals(data: any) {
		const method = 'payments/journal/getclosedjournals';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}
	//first mile links -- sorry
	public getLinks(data: any) {
		const method = 'payments/postback/links';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}
	public removeCreditCard(data: any) {
		const method = 'payments/creditcards/remove';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public voidPayment(data: any) {
		const method = 'payments/void';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public voidInvoice(data: any) {
		const method = 'payments/voidinvoice';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public editCreditCard(data: any) {
		const method = 'payments/creditcards/edit';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public addCreditCard(data: any) {
		const method = 'payments/creditcards/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public loadProfile(debtorno: any, branchcode: any) {
		const method = 'payments/customerar/loadprofile&debtorno='+debtorno+'&branchcode='+branchcode;
		return this.http.get(this.setEndPoint(method));
	}

	public addAllocation(data: any) {
		const method = 'payments/allocations/addallocation';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public addAllocations(data: any) {
		const method = 'payments/addallocations';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getDayEndExcel(data: any) {
		const method = 'globals/dayend/excel';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getAmountSearch(data: any) {
		const method = 'payments/search/amount&amount='+data;
		return this.http.get(this.setEndPoint(method));
	}

	public getInvoiceSearch(data: any) {
		const method = 'payments/search/invoice&invoice='+data;
		return this.http.get(this.setEndPoint(method));
	}


	public getVendorAccountPayable(supplierid: any) {
		const method = 'payments/vendorap&supplierid='+supplierid;
		return this.http.get(this.setEndPoint(method));
	}

	public getCustomerAccountReceivables(debtorno: any, branchcode: any) {
		const method = 'payments/customerar&debtorno='+debtorno+'&branchcode='+branchcode;
		return this.http.get(this.setEndPoint(method));
	}

	public getCustomerAccountReceivablesByTerm(shipvia: any) {
		const method = 'payments/customerar/term&shipvia='+shipvia;
		return this.http.get(this.setEndPoint(method));
	}
	public getCustomerAccountReceivablesByShipVia(shipvia: any) {
		const method = 'payments/customerar/shipvia&shipvia='+shipvia;
		return this.http.get(this.setEndPoint(method));
	}

	public autoAllocDocument(data:any) {
		const method = 'payments/allocations/autoalloc';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}
	public autoAllocMatchingDocument(data:any) {
		const method = 'payments/allocations/autoallocmatching';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}



	public dealllocateDocument(data:any) {
		const method = 'payments/allocations/deallocate';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public dealllocateTo(data:any) {
		const method = 'payments/allocations/deallocateto';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public dealllocateFrom(data:any) {
		const method = 'payments/allocations/deallocatefrom';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}



	public getCustomerAllPaymentCredits(debtorno: any, branchcode: any) {
		const method = 'payments/customerar&debtorno='+debtorno+'&branchcode='+branchcode+'&show=allrec';
		return this.http.get(this.setEndPoint(method));
	}

	public moveJournal(data: any) {
		const method = 'payments/movejournal';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public updateBankTrans(data: any) {
		const method = 'payments/updatetype';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public createRefund(data: any) {
		const method = 'payments/reversal';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}
	public getPaymentById(id: any) {
		const method = 'payments/gettransaction&id='+id;
		return this.http.post(this.setEndPoint(method), id, this.httpOptions).pipe(
			catchError(this.handleError('payments', id))
		);
	}

	public getPaymentRange(data: any) {
		const method = 'payments/getpaymentrange';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}



	public updateTransaction(data: any) {
		const method = 'payments/updatebankrec';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getBankRec(data: any) {
		const method = 'payments/getbankrec';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getDailyTransactions(data: any) {
		const method = 'payments/gettransactions';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getDRI(data: any) {
		const method = 'payments/getdri';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}


	public getCheckSetup(payments: any) {
		const method = 'apchecksma';
		const data = payments
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public searchChecks(payments: any) {
		const method = 'apchecksma/getchecks';
		const data = payments
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public postChecks(payments: any) {
		const method = 'apchecksma/checkpost';
		const data = payments
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public postChecksFinalize(payments: any) {
		const method = 'apchecksma/checkpostfinalize';
		const data = payments
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getPayments(date: any) {
		const method = 'payments/getpayments&date='+date;
		return this.http.get(this.setEndPoint(method));
	}

	public addVendorPayment(payments: any) {
		const method = 'vendor/payments/add';
		const data = payments
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public removePayment(payment_id: any) {
		const method = 'orders/payments/remove';
		const data = { payment_id: payment_id }
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public removeAllPayments(payload: any) {
		const method = 'orders/payments/removeall';
		const data = payload
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}



	public getOrderPayments(data: any) {
		const method = 'orders/payments/get';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getPosOrderPayments(data: any) {
		const method = 'orders/payments/get&pos=true';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public addCashRecPayment(data: any) {
		const method = 'payments/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public setDefaultCard(data: any) {
		const method = 'payments/creditcards/setdefault';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public addShipViaPayment(data: any) {
		const method = 'payments/shipvia';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public addPayment(data: any) {
		const method = 'orders/payments/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public updatePayment(data: any) {
		const method = 'orders/payments/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getRefundables(debtorno: any) {
		const method = 'payments/refundable/getRefundables&debtorno='+debtorno;
		return this.http.get(this.setEndPoint(method), this.httpOptions);
	}

	private getApi() {
		  return this.config.apiServer.crossPoint;
	}

	private getApiKey() {
		 return '&key='+this.config.apiCredentials.clientId+'&userid='+this.user.user.userid + this.getPackage();;
	}

	public getKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if(p) {
			return '&package='+p;
		} else {
			return '';
		}
	}

	private setEndPoint(method: any) {
		  return this.getApi()+method+this.getApiKey()
	}

	private handleError<T>(operation = 'operation', result?: T) {
		return (error: any): Observable<T> => {
			return error;
		};
	}
}
