<div class="text-end">
	<button mat-raised-button color="accent" class="mt-3 mb-3" (click)="isFormVisible = !isFormVisible; resetForm()">
		{{ isFormVisible ? 'Hide Form' : 'Add New Asset' }}
	</button>
</div>

<!-- Form for Adding/Editing Fixed Assets -->
<div *ngIf="isFormVisible" [@expandCollapse] class="form-container p-4 border rounded shadow-sm bg-light">
	<form [formGroup]="fixedAssetForm" (ngSubmit)="saveFixedAsset()">
		<div class="row g-4">
			<!-- Section Title -->
			<div class="col-12">
				<h4 class="text-primary mb-3">{{ editingAsset ? 'Edit Asset' : 'Add New Asset' }}</h4>
			</div>

			<!-- Description -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Description</mat-label>
					<input matInput formControlName="description" placeholder="Enter asset description" />
					<mat-error *ngIf="fixedAssetForm.get('description')?.invalid">
						Description is required.
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Long Description -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Long Description</mat-label>
					<textarea matInput formControlName="longdescription" rows="3" placeholder="Enter long description"></textarea>
				</mat-form-field>
			</div>

			<!-- Asset Category -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Asset Category</mat-label>
					<mat-select formControlName="assetcategoryid">
						<mat-option *ngFor="let category of assetCategories" [value]="category.categoryid">
							{{ category.categoryid }} {{ category.categorydescription }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="fixedAssetForm.get('assetcategoryid')?.invalid">
						Asset category is required.
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Asset Location -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Asset Location</mat-label>
					<mat-select formControlName="assetlocation">
						<mat-option *ngFor="let location of assetLocations" [value]="location.locationid">
							{{ location.locationdescription }}
						</mat-option>
					</mat-select>
					<mat-error *ngIf="fixedAssetForm.get('assetlocation')?.invalid">
						Asset location is required.
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Depreciation Type -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Depreciation Type</mat-label>
					<mat-select formControlName="depntype">
						<mat-option *ngFor="let type of depreciationTypes" [value]="type.id">
							{{ type.name }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>

			<!-- Depreciation Rate -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Depreciation Rate</mat-label>
					<input matInput formControlName="depnrate" type="number" placeholder="Enter depreciation rate" />
				</mat-form-field>
			</div>

			<!-- Barcode -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Barcode</mat-label>
					<input matInput formControlName="barcode" placeholder="Enter barcode" />
				</mat-form-field>
			</div>

			<!-- Serial Number -->
			<div class="col-md-6">
				<mat-form-field appearance="outline" class="w-100">
					<mat-label>Serial Number</mat-label>
					<input matInput formControlName="serialno" placeholder="Enter serial number" />
				</mat-form-field>
			</div>

			<!-- Submit Button -->
			<div class="col-12 text-end">
				<button mat-raised-button color="accent" type="submit" [disabled]="fixedAssetForm.invalid">
					{{ editingAsset ? 'Update Asset' : 'Add Asset' }}
				</button>
			</div>
		</div>
	</form>
</div>

<!-- Fixed Assets Table -->
<div class="table-responsive mt-4">
	<table class="table table-striped table-hover table-bordered">
		<thead class="thead-dark">
			<tr>
				<th>Description</th>
				<th>Category</th>
				<th>Location</th>
				<th>Depreciation Type</th>
				<th>Depreciation Rate</th>
				<th>Actions</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let asset of fixedAssets" [class.selected]="editingAsset?.assetid === asset.assetid">
				<td>{{ asset.description }}</td>
				<td>{{ asset.categorydescription }}</td>
				<td>{{ asset.locationdescription }}</td>
				<td>{{ asset.depntype }}</td>
				<td>{{ asset.depnrate }}</td>
				<td>
					<button mat-icon-button color="primary" (click)="editAsset(asset)" matTooltip="Edit">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="warn" (click)="deleteFixedAsset(asset.assetid)" matTooltip="Delete">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>