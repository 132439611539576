import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';
import { GlobalSearchService } from './globalsearchservice.service';

@Injectable({
	providedIn: 'root',
})
export class FixedAssetsService {
	private config: any;
	private user: any;

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		}),
	};

	constructor(
		private http: HttpClient,
		private configService: RuntimeConfigLoaderService,
		private globalSearchService: GlobalSearchService
	) {
		this.globalSearchService.configsubscription.subscribe((config) => {
			this.config = config;
		});

		this.globalSearchService.user.subscribe((user) => {
			this.user = user;
		});
	}


	public addAssetCategory(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/categories/add');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('addAssetCategory', data))
	  );
	}

	public updateAssetCategory(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/categories/update');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('updateAssetCategory', data))
	  );
	}

	public deleteAssetCategory(categoryId: string): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/categories/delete');
	  return this.http.post(endpoint, { categoryId }, this.httpOptions).pipe(
		catchError(this.handleError('deleteAssetCategory', { categoryId }))
	  );
	}

	public addAssetLocation(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/locations/add');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('addAssetLocation', data))
	  );
	}

	public updateAssetLocation(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/locations/update');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('updateAssetLocation', data))
	  );
	}

	public deleteAssetLocation(locationId: string): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/locations/delete');
	  return this.http.post(endpoint, { locationId }, this.httpOptions).pipe(
		catchError(this.handleError('deleteAssetLocation', { locationId }))
	  );
	}


	// Fetch a specific asset by ID
	public fetchFilters(): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/register/filters`);
		return this.http.get(endpoint, { params: {  } }).pipe(
			catchError(this.handleError('fetchAsset'))
		);
	}

	public fetchFixedAssetsRegister(locationId: string): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/register/fetch');
	  return this.http.post(endpoint, { locationId }, this.httpOptions).pipe(
		catchError(this.handleError('deleteAssetLocation', { locationId }))
	  );
	}

	public exportFixedAssetsCsv(locationId: string): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/locations/delete');
	  return this.http.post(endpoint, { locationId }, this.httpOptions).pipe(
		catchError(this.handleError('deleteAssetLocation', { locationId }))
	  );
	}


	// Fetch a specific asset by ID
	public fetchAsset(assetId: string): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/items/fetch`);
		return this.http.get(endpoint, { params: { AssetID: assetId } }).pipe(
			catchError(this.handleError('fetchAsset'))
		);
	}

	public calculateDepreciation(payload: { processDate: string }): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/depreciation/calculate');
	  return this.http.post(endpoint, payload, this.httpOptions).pipe(
		catchError(this.handleError('calculateDepreciation', payload))
	  );
	}


	public commitDepreciation(data: any[]): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/depreciation/commit');
	  return this.http.post(endpoint, { depreciationData: data }, this.httpOptions).pipe(
		catchError(this.handleError('commitDepreciation', data))
	  );
	}


	// Fetch all assets
	public fetchAllAssets(): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/items/fetchAll`);
		return this.http.get(endpoint).pipe(
			catchError(this.handleError('fetchAllAssets'))
		);
	}

	public fetchAccounts(type: any): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/categories/fetchAccounts&type=`+type);
		return this.http.get(endpoint).pipe(
			catchError(this.handleError('fetchAssetfetchAccounts'))
		);
	}
	// Fetch all asset categories
	public fetchAssetCategories(): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/categories/fetchAll`);
		return this.http.get(endpoint).pipe(
			catchError(this.handleError('fetchAssetCategories'))
		);
	}

	// Fetch all asset locations
	public fetchAssetLocations(): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/locations/fetchAll`);
		return this.http.get(endpoint).pipe(
			catchError(this.handleError('fetchAssetLocations'))
		);
	}

	// Add a new asset
	public addAsset(data: any): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/items/add`);
		return this.http.post(endpoint, data, this.httpOptions).pipe(
			catchError(this.handleError('addAsset', data))
		);
	}

	// Update an existing asset
	public updateAsset(data: any): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/items/update`);
		return this.http.post(endpoint, data, this.httpOptions).pipe(
			catchError(this.handleError('updateAsset', data))
		);
	}

	// Delete an asset
	public deleteAsset(assetId: string): Observable<any> {
		const endpoint = this.setEndpoint(`fixedassets/items/delete`);
		const data = { assetId: assetId };
		return this.http.post(endpoint, data, this.httpOptions).pipe(
			catchError(this.handleError('deleteAsset', data))
		);
	}

	// Add a new Asset Category
	public addCategory(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/categories/add');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('addCategory', data))
	  );
	}

	// Update an existing Asset Category
	public updateCategory(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/categories/update');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('updateCategory', data))
	  );
	}

	// Delete an Asset Category
	public deleteCategory(categoryId: string): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/categories/delete');
	  const payload = { categoryId };
	  return this.http.post(endpoint, payload, this.httpOptions).pipe(
		catchError(this.handleError('deleteCategory', payload))
	  );
	}

	// Add a new Asset Location
	public addLocation(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/locations/add');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('addLocation', data))
	  );
	}

	// Update an existing Asset Location
	public updateLocation(data: any): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/locations/update');
	  return this.http.post(endpoint, data, this.httpOptions).pipe(
		catchError(this.handleError('updateLocation', data))
	  );
	}

	// Delete an Asset Location
	public deleteLocation(locationId: string): Observable<any> {
	  const endpoint = this.setEndpoint('fixedassets/locations/delete');
	  const payload = { locationId };
	  return this.http.post(endpoint, payload, this.httpOptions).pipe(
		catchError(this.handleError('deleteLocation', payload))
	  );
	}


	// Helper methods
	private getApi(): string {
		return this.config.apiServer.crossPoint;
	}

	private getApiKey(): string {
		const packageId = this.globalSearchService.getSessionItem('package');
		return `&key=${this.config.apiCredentials.clientId}&userid=${this.user.user.userid}${packageId ? `&package=${packageId}` : ''}`;
	}

	private setEndpoint(method: string): string {
		return `${this.getApi()}${method}${this.getApiKey()}`;
	}

	private handleError < T > (operation = 'operation', result ? : T) {
		return (error: any): Observable < T > => {
			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}
			console.error(`${operation} failed:`, error);
			return throwError(() => error);
		};
	}
}