import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-fixed-assets-management',
	templateUrl: './fixed-assets-management.component.html',
	styleUrls: ['./fixed-assets-management.component.scss'],
})
export class FixedAssetsManagementComponent implements OnInit {
	color: string = 'blue'; // Default color for the card header

	constructor(private location: Location, private globalSearchService: GlobalSearchService) {}

	ngOnInit(): void {
		// Optionally fetch color or other configurations
		this.color = this.globalSearchService.getColor();
	}

	back(): void {
		// Navigate back to the previous page
		this.location.back();
	}
}