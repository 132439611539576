import { Component, OnDestroy, OnInit, ViewChild, OnChanges, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Directive, HostListener } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, DatePipe } from '@angular/common'
import { Subject, Observable, Subscription, forkJoin } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';
import { trigger, state, keyframes, style, transition, animate, animation } from '@angular/animations';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { InventoryService } from '../../services/inventory.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';
import { PaymentsService } from '../../services/payments.service';
import { OmsService } from '../../services/oms.service';
import { CreditStatusComponent } from 'app/system/credit-status/credit-status.component';
import { MatSelect } from '@angular/material/select';

export enum KEY_CODE {
	PLUS = 107,
		MINUS = 109,
		TAB = 9,
		PLUSTWO = 187,
}

@Component({
	selector: 'app-counter-pos',
	templateUrl: './counter-pos.component.html',
	styleUrls: ['./counter-pos.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
		trigger('cinfo', [
			transition(':enter', [
				animate('50ms', style({ opacity: 1, height: 100 })),
			]),
			transition(':leave', [
				animate('100ms', style({ opacity: 1, height: 0 }))
			]),
		]),
		trigger('itemSearch', [
			transition(':enter', animation([style({ transform: 'translate(-800px,0)', }),
				animate('0.2s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(1200px,0)', })
				),
			])),
		]),
		trigger('addFilters', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.15s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('navIn', [
			transition(':enter', animation([style({ transform: 'translate(200px,0)', }),
				animate('0.10s cubic-bezier(0.59, 0.32, 0.38, 0.33)',
					style({ transform: 'translate(0)', })
				),
			])),
			transition(':leave', animation([style({ transform: 'translate(0)' }),
				animate('0.0s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
					style({ transform: 'translate(-200px,0)', })
				),
			])),
		]),
		trigger('shakeit', [
			state('shakestart', style({
				transform: 'scale(1)',
			})),
			state('shakeend', style({
				transform: 'scale(1)',
			})),
			transition('shakestart => shakeend', animate('1000ms ease-in',
				keyframes([
					style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.1 }),
					style({ transform: 'translate3d(2px, 0, 0)', offset: 0.2 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.3 }),
					style({ transform: 'translate3d(4px, 0, 0)', offset: 0.4 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.5 }),
					style({ transform: 'translate3d(4px, 0, 0)', offset: 0.6 }),
					style({ transform: 'translate3d(-4px, 0, 0)', offset: 0.7 }),
					style({ transform: 'translate3d(2px, 0, 0)', offset: 0.8 }),
					style({ transform: 'translate3d(-1px, 0, 0)', offset: 0.9 }),
				]))),
		])
	],
})

export class CounterPosComponent implements OnInit {
	@ViewChild('inactiveModal') inactiveModalRef: ElementRef;
	@ViewChild('itemdetails') itemDetails: ElementRef;
	@ViewChild('taxdetails') taxdetailsRef: ElementRef;
	@ViewChild('purchasedetails') purchasedetails: ElementRef;
	@ViewChild('creditover') creditover: ElementRef;
	@ViewChild('configproductele') configproductele: ElementRef;
	@ViewChild('itemHistoryRef') itemHistoryRef: ElementRef;
	@ViewChild('nonstock') nonstockref: ElementRef;
	@ViewChild('itemQty') itemQtyRef: ElementRef;
	@ViewChild('editRow') editRowRef: ElementRef;
	@ViewChild('quoteModal') quoteModalRef: ElementRef;
	@ViewChild('orderModal') orderModalRef: ElementRef;
	@ViewChild("preorderdetails") preorderRef: ElementRef;
	@ViewChild('editCustomer') editCustomerRef: ElementRef;
	@ViewChild('monthDetails') monthDetailsRef: ElementRef;
	@ViewChild('empCode') empCodeRef: ElementRef;
	@ViewChild('editNotes') editNotesRef: ElementRef;
	@ViewChild('creditModal') creditModalRef: ElementRef;
	@ViewChild('branchselect') branchSelectRef: MatSelect;

	flip: any = 'inactive';
	states = {};

	pay_title;
	any = 'PAYMENT/RECEIPT';
	CHAT_ROOM = 'OrderBoard';
	duration: any = 100;
	currentstep = 0;
	receipt_data: any = false;
	emperror: any = false;
	globalon = true;
	expand_details = true;
	orderResults: any = false;
	customer_data: any = [];
	customer_details: any = false;
	filters: any = [];
	debtorno: any = '';
	branchcode: any = '';
	today = new Date();
	lastsearch: any = '';
	order_result: any = false;
	//cart
	dspcost = true;
	showGuide = false;
	total_cart_qty = 0;
	cart_items: any = [];
	cart_totals: any = [];
	selectedindex = 0;
	payment_total: any = 0.00;
	activeLink: any = 'order_entry';
	sendingpreorder: any = false;
	//subscription to search
	inventorysearching: any = false;
	preOrderOpenState = false;
	preorderDate: any = {
		status: true,
		value: new Date(),
	};
	search_items: any = [];
	filtered_items: any = [];
	searching = false;

	taxgroups: any = [];
	salespeople: any = [];
	shipvia: any = [];
	terms: any = [];
	zones: any = [];
	locations: any = [];
	holdreasons: any = [];
	itemhistory: any = [];
	placingOrder: boolean = false;
	datewanted = new UntypedFormControl(new Date());
	empcode = new UntypedFormControl();
	branch = new UntypedFormControl();
	forceTax = new UntypedFormControl(false);
	zeroTax = new UntypedFormControl(false);
	email = new UntypedFormControl();
	globalDiscount = new UntypedFormControl('0');
	limits = [
		{ name: '25', value: '25' },
		{ name: '50', value: '50' },
		{ name: '100', value: '100' },
		{ name: '150', value: '150' },
		{ name: '250', value: '200' },
		{ name: '500', value: '500' },
		{ name: 'No Limit', value: false },
	];

	sorts = [
		{ name: 'All Availability, Sell Low To High', value: 'avail.sell|ASC' },
		{ name: 'All Availability, Sell High To Low', value: 'avail.sell|DESC' },
		{ name: 'Local Availability, Sell Low To High', value: 'local.sell|ASC' },
		{ name: 'Local Availability, Sell High To Low', value: 'local.sell|DESC' },
		{ name: 'Sell Low To High', value: 'price|ASC' },
		{ name: 'Sell High To Low', value: 'price|DESC' },
		{ name: 'Name ASC', value: 'description|ASC' },
		{ name: 'Name DESC', value: 'description|DESC' },
	];
	navigating: boolean = false;
	itemSearchForm: UntypedFormGroup;
	customerForm: UntypedFormGroup
	sendingorder: boolean = false;
	customerEditAllowed = [
		'debtorno',
		'branchcode',
		'name',
		'address1',
		'address2',
		'address3',
		'address4',
		'address5',
		'address6',
		'contactname',
		'brname',
		'braddress1',
		'braddress2',
		'braddress3',
		'braddress4',
		'braddress5',
		'braddress6',
		'email',
		'salesman',
		'salestype',
		'defaultshipvia',
		'phoneno',
		'faxno',
		'specialinstructions',
		'defaultlocation',
		'paymentterms',
		'tax_ref',
		'taxgroupid',
		'area',
		'datewanted',
		'enteredby',
		'takenby',
	]

	addonfields: [
		'datewanted',
		'enteredby',
		'takenby',
		'freightcost',
	];

	addingitems = false;
	customerinfo = true;
	//viewing item
	item_details: any = false;
	editing_order = false;
	order_details: any = [];
	purchase_items: any = [];
	user: any = false;

	showfilters = false;
	overcredit = false;
	allowed_credit = false;
	proceed = false;
	overcredit_override = '';
	selecteditem: any = false;
	config: any = [];

	nonStockForm: UntypedFormGroup;
	brands: any = [];
	librarysearch = new UntypedFormControl('');
	librarySearchResults: any = [];
	librarySearchSelected: any = false;
	librarySearching: any = false;
	collapseMini: any = false;
	fetchingCart: any = false;
	shipping: any = 0.00;
	cart_item: any = false;
	refusedemail: any = false;
	color: any = 'blue';

	dealsmissed: any = [];
	coupons: any = [];
	change_due: any = 0;
	coupon: any = '';
	payments: any = [{ 'paymentname': 'Cash', 'value': 0, 'fa_icon': 'fa-money' }, { 'paymentname': 'Check', 'value': 0, 'fa_icon': 'fa-newspaper-o' }];

	sending: any = false;
	brand_details: any = false;
	ref_location: any = false;
	payments_added: any = [];
	sendingdiscount: any = false;
	itemdetails: any = false;
	loggedemployee: any = false;
	itemsearching = false;
	acceptedNumbers: string[] = ['.', '', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
	payment_methods: any[] = [];
	items_per_page = [25, 50, 100, 200, 500];
	pageSize = 25;
	pagesizedefault = 25;
	loadedorderno: any = false;
	loadingOrder: any = false;

	hasNegative: boolean = false;
	private totalsSubscription: Subscription | null = null;
	@ViewChild('itemDetailContainer') itemDetailContainerRef: ElementRef;
	appliedCredits: any = [];
	creditAmt: any = 0;
	openCredits: any = false;
	branchlistener: any = false;

	@HostListener('window:keyup', ['$event'])
	keyEvent(event: KeyboardEvent) {
		// Check if the active element is the element with id="itemQty", and skip logic if so
		const activeElement = document.activeElement;
		if (activeElement.id === 'itemQty') {
			return;
		}
		// Check for both regular and numpad "+" keys
		if ((event.keyCode === KEY_CODE.PLUS || event.keyCode === 107) && this.currentstep == 2) {
			this.increment(this.cart_item);
			return false;
		}

		// Check for both regular and numpad "-" keys
		if ((event.keyCode === KEY_CODE.MINUS || event.keyCode === 109) && this.currentstep == 2) {
			this.decrement(this.cart_item);
			return false;
		}
	}



	constructor(private paymentsService: PaymentsService, private inventoryService: InventoryService, private route: ActivatedRoute, private customerService: CustomerService, private orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, public omsService: OmsService, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal, public usersService: UsersService) {
		this.color = this.globalSearchService.getColor();
		this.config = this.globalsService.getApiConfig();

		// Run the observables in parallel where possible


		this.globalSearchService.salespeople.subscribe(r => {
			this.salespeople = r;
		})


		this.globalSearchService.empCode.subscribe(r => {
			if (r) {
				this.empcode.setValue(r);
				this.loggedemployee = r;
			}
		});

		this.globalSearchService.user.subscribe(results => {

			//only run if user is defined
			if (this.user) {

				if (results.user.defaultlocation.loccode !== this.ref_location) {
					if (this.customer_data) {
						this.customer_data.defaultlocation = this.user.user.defaultlocation.loccode;
					}
					this.ref_location = false;
				}
			}

			this.user = results

			if (!this.ref_location) {
				this.ref_location = this.user.user.defaultlocation.loccode;
			}

			this.globalSearchService.locations.subscribe(async (r: any) => {
				this.locations = r;

				if (results) {
					const val = [
						this.user.user.defaultlocation.loccode
					];

					if (this.customer_data) {
						this.customer_data.defaultlocation = this.user.user.defaultlocation.loccode;
					}
				}
			});

		});

		//shake screen ... misbehaving a bit shakes on load
		this.states['state1'] = 'shakestart';
		this.states['state2'] = 'shakestart';
		this.omsService.setPrintedOrder();

		this.forceTax.valueChanges.subscribe(newValue => {
			const data = {
				debtorno: this.customer_data.debtorno,
				branchcode: this.customer_data.branchcode,
				defaultlocation: this.customer_data.defaultlocation,
				forcetax: this.forceTax.value
			}

			this.orderService.reloadItemsWIthForcedTax(data).subscribe((results: any) => {
				this.updateCart();
				this.updateTotals();
			});
		});



	}

	updatePageSize(event: any) {
		this.pageSize = event.value;
	}

	shakeMe(stateVar: string) {
		if (this.loggedemployee) {
			this.states[stateVar] = (this.states[stateVar] === 'shakestart' ? 'shakeend' : 'shakestart');
		}
	}

	shakeEnd(stateVar: string, event) {
		this.states[stateVar] = 'shakeend';
	}

	changeSalesman(et: any) {
		this.customer_details.customer.salesman = et.value
	}

	ngOnInit(): void {

		this.omsService.setPrintedOrder();
		this.globalSearchService.mesagesallowed.next(false);
		this.currentstep = 0;
		this.route.params.subscribe(params => {

			if (params['cid'] && params['cid'] !== '' && this.debtorno !== params['cid'] && this.branchcode != params['branch'] && params['cid'] !== undefined) {

				this.debtorno = params['cid'];
				this.branchcode = params['branch'];
				this.branch.setValue(this.branchcode);
				this.currentstep = 2;
				//load customer. cart. and totals.
				this.orderService.getCustomer(this.debtorno, this.branchcode).subscribe((results: any) => {
					this.globalSearchService.empCode.subscribe(r => {
						if (r) {
							this.empcode.setValue(r);
							this.loggedemployee = r;
						}
					});

					this.customer_data = results;
					this.loadCustomerBranch();
					this.setCustomerForm();
					const details = results
					this.updateCart();
				});
			} else {
				setTimeout(() => {
					this.empCodeRef.nativeElement.focus();
				}, 0);
			}
		});



		this.globalSearchService.payment_methods.subscribe((results: any) => {
			this.payment_methods = results
			if (results) {
				this.payments.forEach((payment) => {
					payment.value = 0;
				});
			}
		});

		this.globalDiscount.valueChanges.subscribe(newvalue => {

			this.updateDiscounts(newvalue);
		})

		this.zeroTax.valueChanges.subscribe(newValue => {
			//	this.forceTax.setValue(true);
			const data = {
				debtorno: this.customer_data.debtorno,
				branchcode: this.customer_data.branchcode,
				defaultlocation: this.customer_data.defaultlocation,
				forcetax: this.zeroTax.value
			}

			this.orderService.reloadItemsWIthForcedTaxZero(data).subscribe((results: any) => {
				this.updateCart();
				this.updateTotals();
			});
		});

	}

	public checkCode() {
		const data = { employee: this.empcode.value }
		if (this.empcode.value && this.empcode.value != '') {
			this.orderService.getEmployee(data).subscribe((result: any) => {
				if (result.success) {
					this.currentstep = 1;
					this.emperror = false;
					this.globalSearchService.empCode.next(this.empcode.value);
					this.loggedemployee = this.empcode.value;
				} else {
					this.empcode.setValue('');
					this.globalSearchService.empCode.next(false);
					this.loggedemployee = false;
					this.shakeMe('state1');
					this.emperror = true;
				}
			});
		}

	}

	setCustomerForm() {
		const startingBranch = this.customerForm?.get('branchcode').value ? this.customerForm?.get('branchcode').value : false ;
		if (this.customer_data) {
			let dnote = '';
			let inote = '';
			let ref = '';
			let card = false;
			//note already set re apply it
			if (this.customerForm) {
				if (this.customerForm.get('comments').value != '') {
					dnote = this.customerForm.get('comments').value;
				} else {
					dnote = this.customer_data.dispatchnote;
				}
				if (this.customerForm.get('notes').value != '') {
					inote = this.customerForm.get('notes').value;
				}

				if (this.customerForm.get('payment_method').value) {
					card = this.customerForm.get('payment_method').value;
				}
			}

			//reset form based on existing order details

			if (this.customer_data?.editingorder) {
				dnote = this.customer_data.editingorder.notes;
				inote = this.customer_data.editingorder.comments;
				ref = this.customer_data.editingorder.customerref;

				//preselected payment method
				if (this.customer_data.preselected) {
					card = this.customer_data.preselected;
				}
			}

			if (this.user && this.user.user.defaultlocation.loccode != this.customer_data.defaultlocation) {
				this.customer_data.defaultlocation = this.user.user.defaultlocation.loccode;
			}




			const shipvia =
				this.customer_data.defaultshipvia ||
				this.customer_data.customer?.defaultshipvia ||
				this.shipvia

			this.customerForm = this.fb.group({
				debtorno: [this.customer_data.debtorno, Validators.required],
				branchcode: [this.customer_data.branchcode, Validators.required],
				name: [this.customer_data.name, Validators.required],
				address1: [this.customer_data.address1, Validators.required],
				address2: [this.customer_data.address2],
				address3: [this.customer_data.address3, Validators.required],
				address4: [this.customer_data.address4, Validators.required],
				address5: [this.customer_data.address5, Validators.required],
				address6: [this.customer_data.address6],
				brname: [this.customer_data.brname, Validators.required],
				braddress1: [this.customer_data.braddress1, Validators.required],
				braddress2: [this.customer_data.braddress2],
				braddress3: [this.customer_data.braddress3, Validators.required],
				braddress4: [this.customer_data.braddress4, Validators.required],
				braddress5: [this.customer_data.braddress5, Validators.required],
				braddress6: [this.customer_data.braddress6],
				reference: [ref],
				paymentterms: [this.customer_data.paymentterms, Validators.required],
				datewanted: [this.today, Validators.required],
				invoiceDate: [this.today, Validators.required],
				email: [this.customer_data.email],
				defaultlocation: [this.customer_data.defaultlocation, Validators.required],
				salesman: [this.customer_data.salesman, Validators.required],
				phoneno: [this.customer_data.phoneno],
				enteredby: [this.user.userid, Validators.required],
				takenby: [this.user.userid, Validators.required],
				defaultshipvia: [shipvia, Validators.required],
				salestype: [this.customer_data.salestype, Validators.required],
				freightcost: [0.00],
				comments: [dnote],
				notes: [inote],
				payment_method: [card]
				//quote: ['0', Validators.required]
			});

			if (this.customer_data.customerpoline === true || this.customer_data.customerpoline === '1') {
				this.customerForm.get('reference').setValidators([Validators.required])
				this.customerForm.get('reference').updateValueAndValidity();
				if (ref != '') {
					this.customerForm.get('reference').setValue(ref);
				}
			} else {

			}

			this.globalSearchService.shipvia.subscribe((results: any) => {

				if (results) {
					this.handleShipViaResults(results);
				} else {
					this.globalsService.getShipVia().subscribe((fetchedResults: any) => {
						this.globalSearchService.shipvia.next(fetchedResults);
						this.handleShipViaResults(fetchedResults);
					});
				}
			});
		}

		if(this.customerForm?.get('branchcode').value !== startingBranch && startingBranch){
			this.globalSearchService.showNotification(`Changed to branch ${this.customerForm.get('branchcode').value}`, 'success', 'bottom', 'right');
		}

		this.omsService.setPrintedOrder();
	}

	private handleShipViaResults(results: any[]): void {
		this.shipvia = results;

		const shipvia = results.find(v => v.shipper_id === this.customer_data.defaultshipvia);
		if (shipvia) {

			//POS
			this.customerForm.get('freightcost')?.setValue(0.00);
		}
	}


	getTotalItems() {

		let total = 0;
		if (this.cart_items && this.cart_items.length) {
			total = this.cart_items.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.quantity);
			}, 0);
		}

		return total;
	}

	loadPreOrder(order: any) {
		if(order.header.branchcode != this.customer_data.branchcode){
			this.changeDebtorBranch(order.header.branchcode);
		}
		this.editing_order = true;
		this.customer_data.editingorder = order.header;
		this.customer_data.preselected = order.preselected;
		this.order_details = order;
		this.cart_items = order.cart;
		this.updateTotals();
		this.modalService.dismissAll();
		this.cart_item = this.cart_items[0];
		this.loadedorderno = order.header.orderno;
	}

	loadOrder(order: any) {

		if (this.loadingOrder) {
			this.loadingOrder.unsubscribe();
		}


		if(order.header.branchcode != this.customer_data.branchcode){
			this.changeDebtorBranch(order.header.branchcode);
		}
		
		this.loadingOrder = this.orderService.loadSalesOrderPos(order.header.orderno, true).subscribe((results: any) => {

			this.editing_order = true;
			this.customer_data.editingorder = results.header;
			this.customer_data.preselected = results.preselected;
			this.order_details = results;
			this.cart_items = results.cart;
			this.updateTotals();
			this.modalService.dismissAll();
			this.cart_item = this.cart_items[0];
			this.loadedorderno = results.header.orderno;
		})
	}

	back(): void {
		this.location.back()
	}

	randomString(length: any, chars: any) {
		let result = '';
		for (let i = length; i > 0; --i) { result += chars[Math.floor(Math.random() * chars.length)]; }
		return result;
	}

	updateTax(item: any) {
		this.openModal(this.taxdetailsRef, item);
	}

	getItemDetails(item: any) {
		this.inventoryService.loadItem(item).subscribe((results: any) => {
			this.item_details = results;
			this.modalService.open(this.itemDetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
				//item

			}, (reason) => {
				this.item_details = false;
			});
		});
	}

	openModal(content, item) {

		this.item_details = item;
		this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {
			this.item_details = false;
		});
	}

	editCustomerModal() {
		this.modalService.open(this.editCustomerRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	populateItem(item: any) {
		this.cart_item = item
	}
	invoice() {
		this.router.navigate(['/counter-pos/invoice/' + this.orderResults.orderno]);
	}

	ngAfterViewInit() {

	}

	// CART FUNCTIONS
	setItemSelectedPoOption(item: any, data: any) {
		item.selected_po_option = data;
	}

	viewitemOrderHistory(item: any) {
		this.selecteditem = item;
		this.customerService.getCustomerHistoryItem(this.debtorno, item.stockid).subscribe((result: any) => {
			this.itemhistory = result
			this.modalService.open(this.itemHistoryRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result: any) => {

			}, (reason) => {
				this.itemhistory = [];
				this.selecteditem = false;
			});
		});
	}

	setPurchOrderOptions(cart_id: any, selected: any) {
		const data = { cart_id: cart_id, options: selected }
		this.orderService.setPoOptions(data).subscribe((results: any) => {
			this.modalService.dismissAll();
			this.purchase_items = [];
			this.orderService.getPosCart(this.debtorno, this.branchcode, this.customer_data.defaultlocation).subscribe(async (items: any) => {
				this.cart_items = items;
				this.updateTotals();
			});
		});

		this.sendCartUpdate();
	}

	viewPurchOrderOptions(line: any) {
		line.allownegative = true;
		const data = { cart_id: line.cart_id, stockid: line.stockid }
		this.orderService.getPurchDataFromLine(data).subscribe((results: any) => {
			this.purchase_items.push(results);
			this.modalService.open(this.purchasedetails, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {

			}, (reason) => {
				this.purchase_items = [];
			});
		});
	}

	toggleItemDsp(item: any) {
		if (!item.visible) {
			item.visible = true;
		} else {
			item.visible = false;
		}
	}

	removeFromOrder(cart_id) {
		this.orderService.removeFromOrderPos(cart_id, this.customer_data.debtorno, this.customer_data.branchcode, this.customer_data.defaultlocation, this.forceTax.value).subscribe((results: any) => {
			this.cart_items = results;
			this.globalSearchService.showNotification('Item Removed', 'danger', 'top', 'left');
			this.updateTotals();
			this.sendCartUpdate();
			if (this.cart_items.length) {
				this.cart_item = this.cart_items[0];
			} else {
				this.cart_item = false;
			}
		});
	}

	sendCartUpdate() {

		if (this.user) {
			const data = [{ customer: this.customer_data.debtorno, user: this.user }]
			this.omsService.sendCartUpdate({ data, roomName: 'CartRoom' }, cb => {});
		}
	}

	updateTotals() {
		if (!this.customer_data || !this.customer_data.debtorno || !this.customer_data.branchcode) {
			return;
		}

		// Unsubscribe from any previous subscription to avoid duplicates
		if (this.totalsSubscription) {
			this.totalsSubscription.unsubscribe();
		}

		// Call the order service to get the totals
		this.totalsSubscription = this.orderService.getPosTotals(
			this.customer_data.debtorno,
			this.customer_data.branchcode,
			this.shipping,
			this.customer_data.defaultlocation,
			this.forceTax.value
		).subscribe(
			(results: any) => {
				// Ensure results exist
				if (!results) {
					console.error('No results received.');
					return;
				}

				this.cart_totals = results;

				// Calculate total cart quantity
				if (this.cart_items && this.cart_items.length) {
					this.total_cart_qty = this.cart_items.reduce(
						(accumulator: number, item: any) => accumulator + parseFloat(item.quantity || 0),
						0
					);
					this.hasNegative = this.cart_items.some(
						(item: any) => parseFloat(item.price || 0) < 0 || parseFloat(item.quantity || 0) < 0
					);
				}
				if (this.appliedCredits.length) {
					this.creditAmt = this.financial(this.appliedCredits.reduce(function(accumulator, cr) {
						return accumulator + cr.applied;
					}, 0));
					this.appliedCredits = [...this.appliedCredits];
				} else {
					this.appliedCredits = [];
					this.creditAmt = 0;
				}

				this.getBalance();
				// Check customer credit limit
				this.checkCreditLimit();
			},
			error => {
				console.error('Error fetching totals:', error);
				// You can add some UI feedback here for the user
			}
		);
	}



	getTotal() {

		const total = this.cart_totals.filter(t => {
			return t.code == 'total';
		})[0]

		let value = 0.00;
		if (total) {
			value = this.financial(total.text)
		}

		return value;

	}

	reloadData() {

	}

	selectCustomer(row: any) {
		this.customerChanged(row);
		this.currentstep = 2;
	}

	customerChanged(customer: any) {
		this.customerinfo = true;
		this.selectedindex = 0;
		// this.cart_items = [];
		// this.total_cart_qty = 0;
		// this.preOrderOpenState = false;
		// this.globalDiscount.setValue(0);
		// this.placingOrder = false;
		// this.customer_data = false;
		//
		const data = {
			debtorno: customer.debtorno,
			branchcode: customer.branchcode,
			salestype: 10,
		}
		//not sure why we were loading the customer here and in the route change?
		this.orderService.repriceCart(data).subscribe((result: any) => {
			//this.orderService.getCustomer(customer.debtorno, customer.branchcode).subscribe((results: any) => {
			this.router.navigate(['/counter-pos/' + customer.debtorno + '/' + customer.branchcode]);
			//	this.customer_data = results;
			//this.setCustomerForm();
			//});
		});

	}

	nothing() {
		return false;
	}
	getFormValidationErrors() {
		Object.keys(this.customerForm.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = this.customerForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					this.customerForm.markAllAsTouched();
					this.itemSearchForm.markAsUntouched()
					this.customerinfo = true;
					if (key == 'reference') {
						key = 'PO Number';
					}
					this.globalSearchService.showNotification(key + ' ' + keyError, 'danger', 'top', 'right')
				});
			}
		});
	}


	checkCredit() {

		if (!this.allowed_credit && this.cart_totals.length) {

			this.proceed = true;

			const subtotal = this.cart_totals.filter(t => {
				return t.code == 'sub_total'
			})[0]

			if (subtotal && parseFloat(subtotal.text) > this.customer_data.credit_avail) {
				//alert("This order will Breech the customers credit limit ($"+this.customer_data.credit_avail+'/$'+this.customer_data.creditlimit+")")
				this.proceed = false;
			}
		}

		this.proceed = true;

	}

	checkCreditLimit() {

		//disabling credit check for now.
		// 		if (!this.allowed_credit && this.cart_totals.length) {
		// 			this.proceed = true;
		// 			this.overcredit = false;
		// 			let subtotal = this.cart_totals.filter(t => {
		// 				return t.code == 'sub_total'
		// 			})[0]
		//
		// 			if (subtotal && parseFloat(subtotal.text) > this.customer_data.credit_avail) {
		// 				this.proceed = false;
		// 				this.overcredit = true;
		// 				if (this.overcredit_override == '') {
		// 					this.orderService.flagCart(this.customer_data.debtorno, this.customer_data.branchcode).subscribe((result: any) => {
		// 						this.overcredit_override = result
		// 					})
		// 				}
		// 			}
		// 		}
	}

	unlock() {
		if (this.proceed) {
			this.orderService.unflagCart(this.customer_data.debtorno, this.customer_data.branchcode).subscribe((result: any) => {
				this.overcredit_override = result
				this.overcredit = false;
				this.allowed_credit = true;
				this.modalService.dismissAll();
			})
		}
	}

	verifyUnlock(value: any) {
		this.proceed = false;
		value = value.toUpperCase()
		if (this.overcredit_override.toUpperCase() == value.toUpperCase()) {
			this.proceed = true;
		}

		return this.proceed;
	}

	updateItem(pdata: any) {
		this.purchase_items[0].allownegative = true;
		this.purchase_items[0].purchdata = pdata.purchdata
		this.updateTotals();
	}

	clearCartCancel() {

		this.orderService.clearOrder(this.customer_data.debtorno, this.customer_data.branchcode).subscribe((results: any) => {
			this.loadedorderno = false;
			this.updateCart();
			this.customer_details = false;
			this.editing_order = false;
			this.order_details = false;
			this.cart_item = false;
			this.currentstep = 0;
			this.customer_data = [];
			this.router.navigate(['/counter-pos/']);

		});
	}

	clearOrder() {

		this.orderService.clearOrder(this.customer_data.debtorno, this.customer_data.branchcode).subscribe((results: any) => {

			this.updateCart();
			this.editing_order = false;
			this.order_details = false;
			this.cart_item = false;
			this.currentstep = 2;
		});
	}

	placeOpenOrder() {

		if (!this.placingOrder) {
			this.placingOrder = true;
		} else {
			return;
		}

		this.checkCredit();
		this.allowed_credit = true;
		if (!this.proceed && !this.allowed_credit) {
			this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
				this.item_details = false;
			});
		}

		let customer = this.customer_data;

		if (this.customerForm) {
			customer = this.customerForm.value
		}


		if (this.customer_data?.editingorder?.orderno) {

			this.sending = true;
			this.orderService.updatePosSalesOrder(customer, this.cart_items, this.cart_totals, this.order_details, this.user, this.loggedemployee).subscribe((results: any) => {
					this.sending = false;

					if (!results.success) {
						this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'right');
						return false;
					}

					this.orderResults = results;
					this.orderService.clearOrder(this.debtorno, this.branchcode).subscribe((cleared: any) => {

						this.cart_items = [];
						this.total_cart_qty = 0;
						this.preOrderOpenState = false;
						this.globalDiscount.setValue(0);
						this.placingOrder = false;
						this.router.navigate(['counter-pos/invoice/' + this.orderResults.orderno + '/dispatch']);

					}, (reason) => {});
					this.currentstep = 1;

				},
				(error: any) => {
					this.sending = false;
					console.error('Error updating order:', error);
				});

		} else {
			this.orderService.createPosInvoice(customer, this.cart_items, this.cart_totals, this.user, this.loggedemployee).subscribe((results: any) => {

				this.orderResults = results;
				this.orderService.clearOrder(this.debtorno, this.branchcode).subscribe((cleared: any) => {

					this.cart_items = [];
					this.pay_title = 'RECEIPT';
					this.orderService.getOrderReceipt(results.order).subscribe((rec: any) => {
						this.receipt_data = rec
					})

					this.total_cart_qty = 0;
					this.preOrderOpenState = false;

					this.globalDiscount.setValue(0);
					this.placingOrder = false;
					this.router.navigate(['counter-pos/invoice/' + this.orderResults.orderno + '/dispatch']);
					const data = { user: this.user };
					this.omsService.sendOrderCheckUpdate({ data, roomName: this.CHAT_ROOM }, cb => {});
				}, (reason) => {});
				this.currentstep = 1;
			});

		}

	}

	placeOrder() {
		if (this.sendingorder) {
			// Prevent double submission if already sending
			return;
		}

		this.checkCredit();

		this.allowed_credit = true;
		if (!this.proceed && !this.allowed_credit) {
			this.modalService.open(this.creditover, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {
				this.item_details = false;
			});
		}

		let customer = this.customer_data;
		if (this.customerForm) {
			customer = this.customerForm.value;
		}

		customer.empcode = this.empcode.value;
		this.user.forcetax = this.forceTax.value;

		if (this.editing_order) {
			customer['editing'] = this.customer_data?.editingorder?.orderno || null;
		}


		this.loadedorderno = false;

		this.sendingorder = true;
		this.navigating = true;
		this.orderService.createOrderAndInvoicePos(customer, this.cart_items, this.cart_totals, this.user, this.loggedemployee, this.appliedCredits).subscribe((orderresults: any) => {

			if (orderresults.success) {
				this.orderResults = orderresults;
				this.orderService.clearOrder(this.debtorno, this.branchcode).subscribe((cleared: any) => {
					delete this.user.forcetax;

					this.cart_item = false;
					this.total_cart_qty = 0;
					const data = {};
					this.globalDiscount.setValue(0);
					this.globalSearchService.empCode.next(false);
					this.loggedemployee = false;
					this.cart_items = [];
					const url = '/pos/success/' + orderresults.transno;
					//short second burst when clearing cart that users can click
					this.navigating = false;
					this.router.navigate([url]);
					this.sendingorder = false;

				});
			} else {
				this.globalSearchService.showNotification(orderresults.message, 'danger', 'bottom', 'right');
				this.sendingorder = false;
			}
		});
	}


	addItems(items: any) {

		this.orderService.addMultipleToOrderPos(items, this.customer_data.debtorno, this.customer_data.branchcode, this.customer_data.defaultlocation, this.forceTax.value).subscribe((results: any) => {

			if (results.success) {
				this.cart_items = results.cart;
				//this.updateTotals();
				const components = [];
				items.forEach((item, index) => {
					//reset qtys and show notification
					if (parseFloat(item.quantity) != 0) {
						const color = (item.isnegative) ? 'warn' : 'success';
						this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Added', color, 'top', 'right');
						this.cart_item = item;
					}
				});

				if (this.cart_items.length) {
					this.cart_item = this.cart_items[0];
				}

				this.updateTotals();

				if (this.globalon) {
					if (this.globalDiscount.value != '' && this.globalDiscount.value != 0) {
						this.updateDiscounts(this.globalDiscount.value);
					}
				}

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'top', 'right');
			}
		});
	}

	searchClosed(closed: any) {
		if (this.itemDetailContainerRef && this.itemDetailContainerRef.nativeElement) {

			if (this.config.posfocus && this.config.posfocus === 'qty') {
				setTimeout(() => {
					this.itemDetailContainerRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
					this.itemQtyRef.nativeElement.focus();

				}, 100);
			}
		}
	}


	updateCart() {

		if (this.fetchingCart) {
			this.fetchingCart.unsubscribe();
		}

		if (this.customer_data.debtorno) {

			this.fetchingCart = this.orderService.getPosCart(this.customer_data.debtorno, this.customer_data.branchcode, this.customer_data.defaultlocation, this.forceTax.value).subscribe((items: any) => {
				// alert('fetching cart');
				const cart_items = items;
				if (items.length) {
					//this.flip ='active';
					this.cart_item = items[0];
				}

				//current cart tied to a sales order - user loaded an order and left
				const hasorderno = items && items.length ?
					items.find(item => item.orderno != 0)?.orderno || false :
					false;

				//todo rework this - doubles up on the calls
				if (hasorderno && !this.order_details.header) {

					const waspreorder = this.globalSearchService.getPreOrder();
					if (waspreorder) {
						this.globalSearchService.setPreOrder(false);

						this.editing_order = true;
						this.customer_data.editingorder = waspreorder.header;
						this.customer_data.preselected = waspreorder.preselected;
						this.order_details = waspreorder;
						this.cart_items = waspreorder.cart;
						if (waspreorder.cart.length) {
							this.cart_item = this.cart_items[0];
						}
					} else {

						this.orderService.loadSalesOrder(hasorderno, true).subscribe((results: any) => {
							this.editing_order = true;
							this.customer_data.editingorder = results.header;
							this.customer_data.preselected = results.preselected;
							this.order_details = results;
							this.cart_items = results.cart;
							if (results.cart.length) {
								this.cart_item = this.cart_items[0];
								//this.flip ='active';
							}
						});
					}
				} else {
					this.cart_items = items;
				}
				this.updateTotals();
			});
		}
	}

	getDiscountTotal(item: any) {
		const discounted = this.financial(item.price * (item.discount / 100));
		const discountprice = item.price - discounted;
		const total = item.quantity * discountprice;

		return total;
	}

	updatePrice(event: any, item: any) {
		if (event.target.value != '') {
			const index = this.cart_items.indexOf(item);
			this.cart_items[index].price = event.target.value;
			//this.globalSearchService.showNotification(item.description + ' x ' + item.quantity + ' Updated', 'warning', 'bottom', 'left');
			this.updateCartItem(this.cart_items[index]);
		}
	}

	updateQuantity(event: any, item: any) {
		let inputValue = event.target.value;

		// Check if the input contains a trailing minus sign and move it to the beginning
		if (inputValue.endsWith('-')) {
			inputValue = '-' + inputValue.slice(0, -1); // Move the minus sign to the beginning
			event.target.value = inputValue; // Update the input field
		}

		// Parse the input value as a float and check if it's valid
		const parsedValue = parseFloat(inputValue);
		if (!isNaN(parsedValue)) {
			const index = this.cart_items.indexOf(item);
			this.cart_items[index].quantity = parsedValue;
			this.updateCartItem(this.cart_items[index]);
		}
	}

	updateDiscount(event: any, item: any) {
		let procced = false;

		if (parseFloat(event.target.value) < 100 && !isNaN(parseFloat(event.target.value))) {
			procced = true;
		} else {
			if (!isNaN(parseFloat(event.target.value))) {
				event.target.value = 100;
				procced = true;
			}

			if (event.target.value > 100) {
				event.target.value = 100;
			}

			if (event.target.value < 0) {
				event.target.value = 0;
			}
		}

		if (procced) {
			const index = this.cart_items.indexOf(item);

			this.cart_items[index].discount = event.target.value;
			this.updateCartItem(this.cart_items[index]);
		}
	}

	updateNote(event: any, item: any) {
		const index = this.cart_items.indexOf(item);
		this.cart_items[index].notes = event.target.value;
		this.updateCartItem(this.cart_items[index]);
	}

	updateCartItem(item: any) {

		const index = this.cart_items.indexOf(item);

		const data = {
			customer: this.customer_data.debtorno,
			branch: this.customer_data.branchcode,
			cartid: item.cart_id,
			cart_id: item.cart_id,
			price: item.price,
			startprice: item.startprice,
			option: item.option,
			quantity: item.quantity,
			discount: item.discount,
			notes: item.notes,
			stockid: item.stockid,
			coupon_used: item.coupon_used,
			editing_order: this.editing_order,
			order_details: this.order_details,
			location: this.customer_data.defaultlocation,
			forcetax: this.forceTax.value,
			taxoveride: item.taxdetails
		};

		this.orderService.updatePosOrder(data).subscribe((results: any) => {

			const existed = results.filter(i => {
				return i.cart_id == item.cart_id;
			})[0];

			if (existed) {
				this.cart_item.taxtotal = existed.taxtotal
				this.cart_item.taxdetails = existed.taxdetails
			}

			this.updateTotals();
			if (results[0].forceupdate) {
				this.updateCart();
			}
			//item.cart_id
			//this.updateChildren();

		});
	}

	decrement(item: any) {
		const index = this.cart_items.indexOf(item);
		if (index >= 0) {

			//allowing negative through
			//if (this.cart_items[index].quantity > 0) {
			this.cart_items[index].quantity -= 1;
			this.updateCartItem(this.cart_items[index]);
			//}

			if (this.cart_items[index].quantity === 0) {
				//this.removeFromOrder(this.cart_items[index].cart_id);
			}
		}


	}

	increment(item: any) {
		const index = this.cart_items.indexOf(item);
		//if (index >= 0) {
		this.cart_items[index].quantity += 1;
		this.updateCartItem(this.cart_items[index]);
		//}
	}

	itemSearching(event: any) {
		this.itemsearching = event;
	}

	getDiscountPrice(item: any) {
		const total = (item.price - (item.price * (item.discount / 100)));
		// if(total < 0) {
		// 	total = 0;
		// }
		return this.financial(total);
	}

	getDiscount(item: any) {
		const total = ((item.price * (item.discount / 100)));
		// if(total < 0) {
		// 	total = 0;
		// }
		return this.financial(total);
	}

	decimalVal(x) {
		return parseFloat(Number.parseFloat(x).toFixed(5));
	}

	financial(num: number): number {

		if (!num) {
			num = 0.00;
		}
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100;
		return parseFloat(rounded.toFixed(2));
	}

	focusToTop() {
		setTimeout(() => {
			this.editRowRef.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
			this.itemQtyRef.nativeElement.focus();
		}, 0);
	}

	loadCustomerBranch() {
		this.loadedorderno = false;
		if (!this.customer_data.branchcode) {
			this.customerService.getDebtor(this.customer_data.debtorno).subscribe(async (results: any) => {
				this.customer_details = results;
				this.refusedemail = this.customer_details.customer.refusedemail
			});
		} else {
			this.customerService.getDebtorWithBranch(this.customer_data.debtorno, this.customer_data.branchcode).subscribe(async (results: any) => {
				this.customer_details = results;
				this.refusedemail = this.customer_details.customer.refusedemail
			});
		}

		//load debtor open credits
		this.paymentsService.getCustomerAccountReceivables(this.customer_data.debtorno, this.customer_data.branchcode).subscribe((results: any) => {
			this.openCredits = results.payments;
		});

		this.email.setValue(this.customer_details.invoiceemailaddress)
		// this.customer_data.notes = this.customer_details.notes
		this.updateCart();
	}

	salemanName(input: any) {
		const name = this.customer_details.salespeople.filter((p) => {
			return p.code == input
		})[0];
		if (name) {
			return name.name
		}
		return 'N/A';
	}

	placePreOrder(cont: boolean) {
		if (this.sendingorder) {
			// Prevent double submission if already sending
			return;
		}

		this.modalService.dismissAll();
		if (cont) {
			let preorder = {
				status: true,
				date: this.preorderDate.value,
				existing: this.loadedorderno
			};
			this.sendingorder = true;
			this.orderService.createPreOrderPos(this.customerForm.value, this.cart_items, this.cart_totals, this.user, preorder)
				.subscribe((results: any) => {
					this.sendingorder = false;
					this.orderResults = results;
					this.orderService.clearOrder(this.debtorno, this.branchcode).subscribe((cleared: any) => {

						this.cart_items = [];
						this.pay_title = 'RECEIPT';
						this.orderService.getOrderReceipt(results.order).subscribe((rec: any) => {
							this.receipt_data = rec;
						});

						this.total_cart_qty = 0;
						this.preOrderOpenState = false;
						this.globalDiscount.setValue(0);
						this.globalSearchService.showNotification('Pre Order Created', 'success', 'bottom', 'right');
						this.router.navigate(['/counter-pos/']);
					});
				});
		}
	}


	saveQuote() {

		if (this.editing_order) {
			this.editing_order = true;
			const continueconfirm = confirm('This is an open order, save as a quote and cancel order?');
			if (continueconfirm) {
				this.orderService.cancelOrder(this.order_details.header.orderno).subscribe((results: any) => {});
			} else {
				return false;
			}
		}
		const posQuoteData = {
			'defaultshipvia': 0,
			'notes': 'POS quote',
			'comments': ''
		}

		this.orderService.saveQuote(this.customer_data, this.cart_items, this.cart_totals, this.user, posQuoteData).subscribe((result: any) => {
			this.globalSearchService.showNotification('Quote ' + result.orderno + ' Saved', 'success', 'top', 'left');
			this.clearOrder();
			this.loadCustomerBranch();
			this.globalSearchService.empCode.next(false);
			this.router.navigate(['/counter-pos/']);

		});
	}
	//todo
	loadQuoteModal() {
		this.modalService.open(this.quoteModalRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	loadOrderModal() {
		this.modalService.open(this.orderModalRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	changeDebtorBranch(branchcode = false) {
		if(!this.branchlistener){
			this.branchlistener = true;
			this.branch.valueChanges.subscribe((branchcode)=>{
				const shipto = this.customer_details['branches'].find((br)=>br.branchcode === branchcode);
				var temp = this.customer_data;
				const keys = Object.keys(temp);
				
				Object.keys(shipto).forEach((k)=>{
					keys.includes(k) ? temp[k] = shipto[k] : '';
				})

				this.cart_item = false;
				this.cart_items = false;
				this.customer_data = temp;
				this.branchcode = this.customer_data.branchcode;
				this.customerChanged(this.customer_data);
				this.loadCustomerBranch();
				this.setCustomerForm();
			})
		}


		if(!branchcode){
			this.branchSelectRef.open()
		}else{
			this.branch.setValue(branchcode)
		}
	}

	changeBranch(branchcode: any) {
		this.customerService.getDebtorWithBranch(this.customer_data.debtorno, branchcode).subscribe(async (results: any) => {
			this.customer_details = results;
			this.refusedemail = this.customer_details.customer.refusedemail
			this.updateCart();
		});
	}

	updateCustomer(input: any) {

		this.customer_details = input;
		if (input && input.user && input.user.defaultlocation.loccode != this.ref_location) {
			if (this.customer_data) {
				this.customer_data.defaultlocation = this.user.user.defaultlocation.loccode;
			}
			this.ref_location = false;
		}

		this.updateCart();

	}

	loadQuote(quote: any) {

		this.orderService.loadQuote(quote.orderno).subscribe((r: any) => {
			this.changeBranch(r.header.branchcode);
			this.customer_data.debtorno = r.header.debtorno
			this.customer_data.branchcode = r.header.branchcode
			this.modalService.dismissAll();
			this.updateCart();
		})
	}

	loadSalesOrder(quote: any) {

		this.orderService.loadSalesOrder(quote.orderno, true).subscribe((r: any) => {
			this.changeBranch(r.header.branchcode);
			this.customer_data.debtorno = r.header.debtorno
			this.customer_data.branchcode = r.header.branchcode
			this.modalService.dismissAll();
			this.updateCart();
			this.cart_item = this.cart_items[0];
		})
	}

	checkStep(data: any) {
		this.currentstep = data.selectedIndex;

		switch (this.currentstep) {
			case 0:
				setTimeout(() => {
					this.empCodeRef.nativeElement.focus();
				}, 10);

				break;
			case 1:
				this.customer_data = [];
				this.customer_details = false;
				this.cart_items = [];
				this.cart_item = false;
				this.router.navigate(['/counter-pos/']);
				break;
			case 2:
				break;
			case 3:
				this.checkDeals();
				break;
		}
	}

	getCartItemIndex(item: any) {
		const index = this.cart_items.indexOf(item);
		return index;
	}

	loadOrders() {
		this.flip = 'active';
	}

	loadCredits() {
		this.modalService.open(this.creditModalRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
	}

	creditSelected(credit) {
		var credit_total;
		var balance;

		credit_total = Math.abs(credit.leftto);
		balance = this.getBalance() ? this.getBalance() : this.getTotal();

		if (credit_total > balance) {
			credit_total = balance;
		}

		credit['applied'] = credit_total;

		this.appliedCredits = [credit, ...this.appliedCredits];

		this.updateTotals();


	}

	creditRemove(credit) {
		//remove applied field from cr in open credits
		var openCrIndex = this.openCredits.find((cr) => cr.orderno == credit.orderno);
		delete this.openCredits[this.openCredits.indexOf(openCrIndex)]['applied'];

		//remove from applied
		const match = this.appliedCredits.filter((cr) => { cr.orderno == credit.orderno })[0];
		this.appliedCredits.splice(this.appliedCredits.indexOf(match), 1);

		this.updateTotals();

	}

	updateTotalAmount(amt) {
		this.cart_totals.forEach(tot => {
			if (tot.code == 'total') {
				tot.text = amt + '';
			}
		});
	}

	applyCoupon(input: any) {

	}
	addCoupon(input: any, item: any) {

	}

	showCoupons() {
		// 	this.modalService.open(this.couponModalRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result: any) => {
		//
		// 	}, (reason) => {
		// 		this.purchase_items = [];
		// 	});
	}


	getCustomerAllowedInvoice() {
		if (this.customer_details && this.customer_details.customer.onaccountallowed) {
			return true;
		}
		return false;
	}

	getBalance() {
		let value = 0;

		if (this.cart_totals.length) {
			const total = this.cart_totals.filter(t => {
				return t.code == 'total';
			})[0];

			let total_amount = 0.00;
			if (total) {
				total_amount = parseFloat(total.text)
			}

			value = this.financial(total_amount) - (this.financial(this.payment_total + this.creditAmt));
			if (value < 0) {
				this.change_due = 0;
				this.payments.forEach((pay) => {
					//Is there cash involved, then we can give change.
					if (pay.opencashdrawer == '1' && pay.value > 0 && value < 0) {
						//It's cash!
						if (pay.value > Math.abs(value)) {
							this.change_due = Math.abs(value);
							value = 0;
						} else {
							this.change_due = pay.value;
							value = value + pay.value;
						}
					} else {

					}

				});
			} else {
				this.change_due = 0;
			}
		} else {
			let value = 0.00;
		}

		return value;
	}

	// updatePayments(event: any, payment: any) {
	// 	const parent = this
	// 	payment.value = this.financial(event.target.value);
	// 	this.payment_total = this.payments.reduce(function(accumulator, item) {
	// 		return parent.financial(accumulator) + parent.financial(item.value);
	// 	}, 0);
	// }

	updatePayments(event: any) {

		if (event) {
			this.payments_added = event;
			this.payment_total = this.payments_added.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0);
		}

		const data = {
			orderno: (this.order_details && this.order_details.header) ? this.order_details.header.orderno : '1',
			debtorno: this.customer_data.debtorno,
			branchcode: this.customer_data.branchcode,
		}

		this.paymentsService.getPosOrderPayments(data).subscribe((results: any) => {
			this.payments = results;
			this.payments.forEach((payment) => {
				//reduce cash payment by change due
				if (this.change_due > 0 && payment.opencashdrawer == '1' && payment.value > 0) {
					payment.value = this.financial(payment.value) - this.financial(this.change_due);
				}

				if (this.financial(payment.amount) > 0) {

					const data = {
						payment: {
							amount: this.financial(payment.amount),
							reference: payment.reference,
							type: payment.payment_type,
							bankaccount: payment.bankaccount
						},
						order: {
							orderno: false,
							debtorno: this.customer_data.debtorno,
							branchcode: this.customer_data.branchcode
						}
					};
				}
			});

			this.updateTotals()
			this.payment_total = this.financial(results.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0));

		});

	}

	canProcess(): boolean {
		const hasOpenCashDrawer = this.hasOpenCashDrawer();
		const balance = this.getBalance();

		return !(balance < 0 && !hasOpenCashDrawer);
	}

	hasOpenCashDrawer(): boolean {
		if (!this.payments || !this.payments.length) {
			return false;
		}

		return this.payments.some(m => {
			const payment = this.getPaymentMethodByName(m.payment_type);
			return payment && payment.opencashdrawer === '1';
		});
	}

	getPaymentMethodByName(paymentname: string) {
		return this.payment_methods.find(method => method.paymentname === paymentname);
	}



	applyDeal(dealcode: any) {
		const data = {
			debtorno: this.customer_data.debtorno,
			branchcode: this.customer_data.branchcode,
			dealid: dealcode,
		}
		this.orderService.applyDeal(data).subscribe((result: any) => {
			if (result.success) {
				this.sendCartUpdate();
				this.checkDeals();
			}
		});
	}

	checkDeals() {
		const data = {
			debtorno: this.customer_data.debtorno,
			branchcode: this.customer_data.branchcode
		}
		this.orderService.getDealCheck(data).subscribe((result: any) => {
			if (result.promofound) {
				this.dealsmissed = result.promolist;
			} else {
				this.dealsmissed = [];
			}
		});
	}

	updateDiscounts(value) {

		if (this.globalon) {
			if (!isNaN(value) && value != '') {

				if (value > 100) {
					value = 100;
				}

				if (value < 0) {
					value = 0;
				}

				const data = {
					debtorno: this.customer_data.debtorno,
					branchcode: this.customer_data.branchcode,
					discount: parseFloat(value),
				}

				if (this.sendingdiscount) {
					this.sendingdiscount.unsubscribe();
				}
				this.sendingdiscount = this.orderService.updateCartItemsDiscountPos(data).subscribe(r => {
					this.updateCart();
				});
			} else {
				value = 0;
			}
		}
	}

	completeOrder() {

		const cango = this.canProcess();
		if (!cango) {
			alert('Correct Over Payment Amount');
			return false;
		}

		this.payments.forEach((payment) => {
			//reduce cash payment by change due
			if (this.change_due > 0 && payment.opencashdrawer == '1' && payment.value > 0) {
				payment.value = this.financial(payment.value) - this.financial(this.change_due);
			}


			if (this.financial(payment.amount) > 0) {
				const data = {
					payment: {
						amount: this.financial(payment.amount),
						reference: payment.reference,
						type: payment.payment_type,
						bankaccount: payment.bankaccount
					},
					order: {
						orderno: false,
						debtorno: this.customer_data.debtorno,
						branchcode: this.customer_data.branchcode
					}
				};
			}
		});

		this.placeOrder();

	}

	addPromo(promo: any) {
		this.orderService.addPromoPos(promo, this.customer_data.debtorno, this.customer_data.branchcode, this.ref_location).subscribe((results: any) => {
			if (results.success) {

				this.cart_items = results.cart;
				this.updateTotals();
				this.globalSearchService.showNotification(results.message, 'success', 'top', 'right')

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'top', 'left');
			}
		});

	}

	getBrandSales(debtorno: any, brand: any) {

		const today = new Date();
		//var priorDate = new Date(new Date().setDate(today.getDate() - this.daysmax));

		const data = {
			debtorno: debtorno,
			brands: [brand],
			from: 'all',
			//to: today,
		}

		this.customerService.searchCustomerHistory(data).subscribe(async (results: any) => {
			this.sending = false;
			this.brand_details = results;
			this.modalService.open(this.monthDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
		});
	}

	togglePreOrder() {

		this.preOrderOpenState = (this.preOrderOpenState) ? false : true;

		if (this.preOrderOpenState) {
			this.setCustomerForm();
		}

	}

	printReceipt() {
		const data = {
			id: this.receipt_data.id
		}
		this.orderService.printOrderReciept(data).subscribe(r => {

		});
	}

	emailReceipt() {
		const data = {
			id: this.receipt_data.id
		}
		this.orderService.emailOrderReciept(data).subscribe(r => {

		});
	}

	ngOnChanges(changes: any) {


	}

	isActiveText() {

		if (this.customer_details) {
			return (this.customer_details.customer.isactive) ? 'ACTIVE' : 'INACTIVE';
		}
		return '';
	}

	updateActive(event: any) {
		//event.preventDefault();
		if (this.customer_details.customer.isactive) {
			const confirmed = confirm('Mark Customer as Inactive?');
			if (confirmed) {
				const data = {
					debtorno: this.customer_details.debtorno,
					branchcode: this.customer_details.branchcode,
					isactive: false
				}
				this.customerService.updateDebtorActive(data).subscribe((results) => {
					this.customer_details = results;
					this.updateCart();
				});
			} else {
				return false;
			}
		} else {
			this.modalService.open(this.inactiveModalRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {

			}, (reason) => {
				this.reactivateCustomer();
			});
		}
	}

	updateBranch(input: any) {
		this.customerService.getDebtor(this.debtorno).subscribe((results: any) => {
			this.customer_details = results;
			this.refusedemail = this.customer_details.customer.refusedemail
			this.modalService.dismissAll('close');
			this.updateCart();
		});
	}

	reactivateCustomer() {

		const data = {
			debtorno: this.customer_details.debtorno,
			branchcode: this.customer_details.branchcode,
			isactive: true
		}

		this.customerService.updateDebtorActive(data).subscribe((results) => {
			this.customer_details = results;
			this.updateCart();
		});
	}

	addNonStockItem(event: any) {

		const item = event;
		const data = { item: item, debtorno: this.customer_data.debtorno, branchcode: this.customer_data.branchcode, location: this.customer_data.defaultlocation, forcetax: this.forceTax.value }

		this.orderService.addNonStock(data).subscribe((results: any) => {
			this.globalSearchService.showNotification(item.description + ' x ' + item.qty + ' Added', 'success', 'top', 'right');
			this.updateCart();
		});
	}

	getTaxDisplay(amount: any) {

		if (amount && !isNaN(amount)) {
			return this.decimalVal(amount * 100);
		}

		return 0.00
	}

	updateTaxRate(newvalue: any, tax: any) {
		//rounding on the other side
		const newtaxvalue = newvalue / 100;
		const index = this.cart_item.taxdetails.indexOf(tax);

		this.cart_item.taxdetails[index].taxrate = this.santizeNumberValue(newtaxvalue)
		this.cart_item.taxdetails[index].taxratepercent = this.santizeNumberValue(newvalue)

		this.updateCartItem(this.cart_item);

	}



	santizeNumberValue(input: any) {
		return (isNaN(input) || input == '') ? 0 : parseFloat(input);
	}

	focusSearch() {
		const ref = document.getElementById('searchkeywords');
		setTimeout(() => {
			ref.focus();
		}, 0);
	}

	updateRefused(event: any) {

		this.refusedemail = event.target.checked;

		const data = {
			debtorno: this.customer_details.debtorno,
			branchcode: this.customer_details.branchcode,
			refusedemail: event.target.checked
		}

		this.customerService.updateDebtorRefused(data).subscribe((results) => {
			this.customer_details = results;
			if (!event.target.checked) {
				this.editCustomerModal()
			}
		});
	}

	openNoteModal() {
		this.modalService.open(this.editNotesRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {
			//item

		}, (reason) => {});
	}

	updateNotes(event: any) {
		this.customer_data.notes = event.notes;
	}

	isSelectedItem(item: any): boolean {
		return this.cart_item === item;
	}

	selectCartItem(item: any): void {
		this.cart_item = item;
	}

	validateDate(event: any) {
		let newDate = new Date(event);
		let now = new Date();

		// Create new dates with only the year, month, and day components
		let formattedNow = new Date(now.getFullYear(), now.getMonth(), now.getDate());
		let formattedNew = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate());

		if (formattedNow.getTime() <= formattedNew.getTime()) {
			this.preorderDate.value = newDate;
			this.preorderDate.status = true;
		} else {
			this.preorderDate.value = "Pre-Order date has to be a future date.";
			this.preorderDate.status = false;
		}
	}

	addCreditItems(items: any) {

		this.flip = 'inactive';

		const payload = items.map(item => ({
			orderno: item.orderno,
			stockid: item.stockid,
			description: item.description,
			discount: parseFloat(item.discountpercent) * 100,
			quantity: -item.qty,
			price: parseFloat(item.price)
		}));

		this.addItems(payload);
	}
	openPreOrder() {
		this.modalService.open(this.preorderRef, { ariaLabelledBy: 'modal-title', size: 'l' }).result.then((result: any) => {}, (reason) => {

		});
	}

	ngOnDestroy(): void {
		if (this.totalsSubscription) {
			this.totalsSubscription.unsubscribe();
		}
	}

}