import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ValidationErrors, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Location } from '@angular/common'

import { merge, fromEvent,Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, tap, delay } from 'rxjs/operators';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GeneralLedgerService  } from '../../services/general-ledger.service';
import { GlobalsService } from '../../services/globals.service';
import { PrintService } from '../../services/print.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

declare let $: any;

@Component({
	selector: 'app-journal-entry',
	templateUrl: './journal-entry.component.html',
	styleUrls: ['./journal-entry.component.scss']
})
export class JournalEntryComponent implements OnInit {

	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('locationsearch') customersearch: ElementRef;
	@ViewChild('editAddAccount') editAddRef: ElementRef;
	@ViewChild('print_table') printtable: ElementRef;
	loccode: any = '';
	addedgl: any = false;
	locselected = false;
	sending = false;
	ld: any = [];
	term: any = '';

	taxgroup: any = '';
	taxes: any = [];
	currencies: any = [];
	bankaccounts: any = [];
	types: any = [];
	zones: any = [];
	accountForm: any = false;
	groups: any = [];
	pandl: any = [];
	accounts: any = [];
	results: any = [];
	glaccounts: any = [];
	allglaccounts: any = [];
	color = 'blue'
	filteredOptions: Observable<any[]>;
	debits_credits: any = [];
	now = new Date();
	resetjournal: boolean = false;
	journal_types = [
		'normal', 'reversing'
	]

	constructor(private route: ActivatedRoute, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, public globalsService: GlobalsService, private modalService: NgbModal, public generalLedgerService: GeneralLedgerService, public printService: PrintService) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.resetjournal = false;
		this.setForm();
		this.loadData();
	}


	addGl(event: any) {
		const selection = event.option.value
		this.accountForm.get('account').setValue(selection)

	}


	setForm() {


		this.accountForm = this.fb.group({
			trandate: [ this.now.toISOString(), [Validators.required]],
			type: ['normal',[Validators.required]],
			account: ['', [Validators.required]],
			description: ['', [Validators.required]],
			debitcredit: [0, [Validators.required]],
		});
	}

	loadData() {

		this.globalsService.getGLAccounts().subscribe((results: any) => {
			this.glaccounts = results
			this.allglaccounts = results
			this.filteredOptions = results
		});

		this.globalsService.getCurrencies().subscribe((results: any) => {
			this.currencies = results
		});


	}

	getFormValidationErrors() {
		Object.keys(this.accountForm.controls).forEach((key, index) => {
			const controlErrors: ValidationErrors = this.accountForm.get(key).errors;
			if (controlErrors != null) {
				Object.keys(controlErrors).forEach(keyError => {
					//this.customerForm.controls[index].markAsTouched()
					this.accountForm.markAllAsTouched();
					this.accountForm.markAsUntouched()

					if (key == 'reference') {
						key = 'PO Number';
					}

					this.globalSearchService.showNotification(key + ' ' + keyError, 'danger', 'bottom', 'right')
				});
			}
		});
	}

	getCreditTotal() : number {
		let value = 0.00;
		if(this.debits_credits.length) {
			value = this.debits_credits.reduce(function(accumulator: number, items: any) {
				if(parseFloat(items.debitcredit) < 0) {
					 accumulator = accumulator + parseFloat(items.debitcredit);
				}
				return accumulator
			}, 0) ;
		}
		return value;
	}

	getDebitTotal() : number {
		let value = 0.00;
		if(this.debits_credits.length) {
			value = this.debits_credits.reduce(function(accumulator: number, items: any) {
				if(parseFloat(items.debitcredit) >= 0) {
					 accumulator = accumulator + parseFloat(items.debitcredit);
				}
				return accumulator
			}, 0) ;
		}
		return value;

	}

	requiredToBalance(): number {
		let value = 0.00;
		if(this.debits_credits.length) {
			value = this.debits_credits.reduce(function(accumulator: number, items: any) {
				return accumulator - parseFloat(items.debitcredit);
			}, 0) ;
		}
		return value;
	}

	addValue() {
		if(this.accountForm.valid) {
			if(this.accountForm.get('debitcredit').value) {
				this.debits_credits.push(this.accountForm.value);
				this.accountForm.get('debitcredit').setValue(this.requiredToBalance());
				this.resetjournal = true;
				setTimeout(() => {
				  this.resetjournal = false;
				}, 100);
			}
		} else {
			this.getFormValidationErrors();
		}

	}

	removeDC(debitcredit: any) {
		const index = this.debits_credits.indexOf(debitcredit);
		if(index < 0) {

		} else {
			this.debits_credits.splice(index,1);
		}
	}

	postJournal() {
		if(this.debits_credits.length) {
			this.generalLedgerService.postJournalEntry(this.debits_credits).subscribe( (result: any) => {

				let color = 'danger'
				if(result.success) {
					color = 'success'
					this.setForm();
					this.resetjournal = true;
					setTimeout(() => {
					  this.resetjournal = false;
					}, 100);
					this.debits_credits = [];
				}

				this.globalSearchService.showNotification( result.message, color, 'bottom', 'center')
				//this.resetjournal = false;

			});
		} else {
			//this.globalSearchService.showNotification()
		}

	}


	exportPdf() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'jounral_entry',
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
			this.sending = false;
		});
	}

	exportXls() {
		this.sending = true;

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: 'jounral_entry',
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
			this.sending = false;
		});
	}
	announceSortChange(sortState: Sort) {

	}

	displayFn(account: any): string {
		return account.accountname
	}

	back(): void {
		this.location.back()
	}


}
