import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SearchComponent } from './search.component';
import { GlobalSearchService } from '../services/globalsearchservice.service';
import { ChatService } from '../services/chat.service';
import { ReactiveFormsModule } from '@angular/forms';
import { ChatboxComponent } from './chatbox/chatbox.component';
import { ChatSidebarComponent } from './chat-sidebar/chat-sidebar.component';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from '../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatBottomSheetModule ,  MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MatSelectModule } from '@angular/material/select';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppConfig } from '../app.config';
import { ConfigSwitcherComponent } from './config-switcher/config-switcher.component';
import { FormsModule } from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatIconModule, MatBadgeModule,MatSelectModule, MatButtonModule, MatInputModule,MatBottomSheetModule,
		SharedModule,
		MatTooltipModule,
		MatTableModule,
		MatPaginatorModule,
		MatSortModule,
		MatSnackBarModule,
		MatTabsModule,
		NgIdleKeepaliveModule.forRoot(),
	],
	declarations: [
		FooterComponent,
		NavbarComponent,
		SidebarComponent,
		SearchComponent,
		ChatboxComponent,
		ChatSidebarComponent,
  ConfigSwitcherComponent,
	],
	exports: [
		FooterComponent,
		NavbarComponent,
		SidebarComponent,
	],
	providers: [GlobalSearchService, ChatService,AppConfig,
		{
			provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
			useValue: { panelClass: 'mybottomsheet', hasBackdrop: false }
		}
	],
})
export class ComponentsModule {}
