import { Injectable } from '@angular/core';
import { Message } from '../classes/message';
import { Observable } from 'rxjs';
import { UsersService } from './users.service';
import { catchError, retry } from 'rxjs/internal/operators';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { GlobalSearchService } from './globalsearchservice.service';
import { RuntimeConfigLoaderService } from 'runtime-config-loader';



@Injectable({
	providedIn: 'root'
})
export class GeneralLedgerService {
	key = 'UET6DFMPAXW7BKCB';
	config: any;
	//todo make connection reusabel
	socket: any;
	details: any;
	user: any = [];
	package: any = '';

	public httpOptions = {
		headers: new HttpHeaders({
			'Content-Type': 'application/json',
		})
	};

	constructor(private http: HttpClient, private _configSvc: RuntimeConfigLoaderService, private globalSearchService: GlobalSearchService) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
		this.globalSearchService.user.subscribe((result) => {
			this.user = result;
		});

	}


	public getMaxJournalNumber() {
		const method = 'generalledger/getmaxjournal';
		return this.http.get(this.setEndPoint(method));
	}


	public getJournals(data) {
		const method = 'generalledger/journalinquiry';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}


	public repostGl() {
		const method = 'generalledger/repost';
		return this.http.get(this.setEndPoint(method));
	}

	public getPeriods() {
		const method = 'generalledger/getperiods';
		return this.http.get(this.setEndPoint(method));
	}

	public getTags() {
		const method = 'generalledger/fetchtags';
		return this.http.get(this.setEndPoint(method));
	}

	// Fetch Tags
	public fetchTags(data: any = {}): Observable < any > {
		const method = 'generalledger/fetchtags';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('fetchTags', data))
		);
	}

	// Add Tag
	public addTag(data: { description: string }): Observable < any > {
		const method = 'generalledger/addtag';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('addTag', data))
		);
	}

	// Update Tag
	public updateTag(data: { tagId: string;description: string }): Observable < any > {
		const method = 'generalledger/updatetag';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('updateTag', data))
		);
	}

	// Delete Tag
	public deleteTag(data: { tagId: string }): Observable < any > {
		const method = 'generalledger/deletetag';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('deleteTag', data))
		);
	}

	public getAccounts(data) {
		const method = 'generalledger/handleglbudgets';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getBudgets(data) {
		const method = 'generalledger/fetchbudgets';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	// Add a new Inventory GL Posting
	public addInventoryGLPosting(data: any) {
		const method = 'generalledger/inventoryglpostings/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('addInventoryGLPosting', data))
		);
	}

	// Update an existing Inventory GL Posting
	public updateInventoryGLPosting(data: any) {
		const method = 'generalledger/inventoryglpostings/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('updateInventoryGLPosting', data))
		);
	}

	// Delete an Inventory GL Posting
	public deleteInventoryGLPosting(data: any) {
		const method = 'generalledger/inventoryglpostings/delete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('deleteInventoryGLPosting', data))
		);
	}

	// Fetch Inventory GL Postings
	public fetchInventoryGLPostings(params: any) {
		const method = 'generalledger/inventoryglpostings/fetch';
		return this.http.get(this.setEndPoint(method), { params, ...this.httpOptions }).pipe(
			catchError(this.handleError('fetchInventoryGLPostings', null))
		);
	}

	// Add a new COGS GL Posting
	public addCogsGLPosting(data: any) {
		const method = 'generalledger/cogsglpostings/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('addCogsGLPosting', data))
		);
	}

	// Update an existing COGS GL Posting
	public updateCogsGLPosting(data: any) {
		const method = 'generalledger/cogsglpostings/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('updateCogsGLPosting', data))
		);
	}

	// Delete a COGS GL Posting
	public deleteCogsGLPosting(data: any) {
		const method = 'generalledger/cogsglpostings/delete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('deleteCogsGLPosting', data))
		);
	}

	// Fetch Areas for dropdown
	public fetchCogsAreas() {
		const method = 'generalledger/cogsglpostings/areas';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchAreas', null))
		);
	}

	// Fetch Stock Categories for dropdown
	public fetchCogsStockCategories() {
		const method = 'generalledger/cogsglpostings/stockcategories';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchStockCategories', null))
		);
	}

	// Fetch Sales Types for dropdown
	public fetchCogsSalesTypes() {
		const method = 'generalledger/cogsglpostings/salestypes';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchSalesTypes', null))
		);
	}

	// Fetch GL Codes for dropdown
	public fetchCogsGLCodes() {
		const method = 'generalledger/cogsglpostings/glcodes';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchGLCodes', null))
		);
	}

	public fetchCogsGLPostings(data: any) {
		const method = 'generalledger/cogsglpostings/fetch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('fetchCogsGLPostings', data))
		);
	}


	// Fetch all Sales GL Postings
	public fetchSalesGLPostings(data: any) {
		const method = 'generalledger/salesglpostings/fetch';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('fetchSalesGLPostings', data))
		);
	}

	// Add a new Sales GL Posting
	public addSalesGLPosting(data: any) {
		const method = 'generalledger/salesglpostings/add';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('addSalesGLPosting', data))
		);
	}

	// Update an existing Sales GL Posting
	public updateSalesGLPosting(data: any) {
		const method = 'generalledger/salesglpostings/update';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('updateSalesGLPosting', data))
		);
	}

	// Delete a Sales GL Posting
	public deleteSalesGLPosting(data: any) {
		const method = 'generalledger/salesglpostings/delete';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('deleteSalesGLPosting', data))
		);
	}

	// Fetch Areas for dropdown
	public fetchAreas() {
		const method = 'generalledger/salesglpostings/areas';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchAreas', null))
		);
	}

	// Fetch Stock Categories for dropdown
	public fetchStockCategories() {
		const method = 'generalledger/salesglpostings/stockcategories';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchStockCategories', null))
		);
	}

	// Fetch Sales Types for dropdown
	public fetchSalesTypes() {
		const method = 'generalledger/salesglpostings/salestypes';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchSalesTypes', null))
		);
	}

	// Fetch GL Codes for dropdown
	public fetchGLCodes() {
		const method = 'generalledger/salesglpostings/glcodes';
		return this.http.get(this.setEndPoint(method), this.httpOptions).pipe(
			catchError(this.handleError('fetchGLCodes', null))
		);
	}

	public updateBudgets(data) {
		const method = 'generalledger/updatebudgets';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getAcctListing() {
		const method = 'generalledger/listing';
		return this.http.get(this.setEndPoint(method));
	}

	public getAccountInquiry(data) {
		const method = 'generalledger/accountinquiry';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getTrialBalance(data: any) {
		const method = 'generalledger/trialbalance';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getTaxReport(data: any) {
		const method = 'generalledger/taxreport';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('taxreport', data))
		);
	};

	public getCompressedTaxReport(data: any) {
		const method = 'generalledger/taxcompressed';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('taxreport', data))
		);
	};

	public getProfitAndLoss(data: any) {
		const method = 'generalledger/profitandloss';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public postJournalEntry(data: any) {
		const method = 'generalledger/journalentry';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	public getBalanceSheet(data: any) {
		const method = 'generalledger/balancesheet';
		return this.http.post(this.setEndPoint(method), data, this.httpOptions).pipe(
			catchError(this.handleError('payments', data))
		);
	}

	private getApi() {
		return this.config.apiServer.crossPoint;
	}

	private getApiKey() {
		return '&key=' + this.config.apiCredentials.clientId + '&userid=' + this.user.user.userid + this.getPackage();
	}

	private getPackage() {
		const p = this.globalSearchService.getSessionItem('package');
		if (p) {
			return '&package=' + p;
		} else {
			return '';
		}
	}

	public getKey() {
		return '&apikey=' + this.key + '&userid=' + this.user.user.userid;
	}

	private setEndPoint(method: any) {
		return this.getApi() + method + this.getApiKey()
	}

	private handleError < T > (operation = 'operation', result ? : T) {

		return (error: any): Observable < T > => {

			if (error instanceof HttpErrorResponse && error.status === 401) {
				window.location.href = '/#/auth/logout';
			}

			return error;
		};
	}
}