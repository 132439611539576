import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-account-groups',
  templateUrl: './account-groups.component.html',
  styleUrls: ['./account-groups.component.scss'],
  animations: [
	trigger('expandCollapse', [
	  state('void', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
	  state('*', style({ height: '*', opacity: 1 })),
	  transition('void <=> *', [animate('300ms ease-in-out')]),
	]),
  ],
})
export class AccountGroupsComponent implements OnInit {
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  groupForm: FormGroup;
  groupsTable : any = [];;
  results: any = [];
  sections: any = [];
  groups: any = [];
  headerColumns: string[] = [
	'actions',
	'groupname',
	'sectioninaccounts',
	'pandl',
	'sequenceintb',
	'parentgroupname',
  ];
  selectedGroup: any = null;
  editingGroup: any = null;
  color: string;
  isFormVisible = false;
  searchTerm: string = '';
  filteredGroups: any = [];

  selectedSection: string = '';

  constructor(
	private fb: FormBuilder,
	private globalsService: GlobalsService,
	private globalSearchService: GlobalSearchService,
	private route: ActivatedRoute,
	private router: Router,
	private location: Location,
	private snackBar: MatSnackBar
  ) {
	this.color = this.globalSearchService.getColor();
  }

  ngOnInit(): void {
	this.initializeForm();
	this.loadSections();
	this.loadGroups();
  }

  // Initialize the group form
  initializeForm(): void {
	this.groupForm = this.fb.group({
	  groupname: ['', Validators.required],
	  sectioninaccounts: ['', Validators.required],
	  pandl: [''],
	  sequenceintb: ['', Validators.required],
	  parentgroupname: [''],
	  startingname: ['']
	});
  }

  // Load account sections for dropdown
  loadSections(): void {
	this.globalsService.getAccountSections().subscribe((sections) => {
	  this.sections = sections;
	});
  }

  // Load all groups
  loadGroups(): void {
	this.globalsService.getAccountGroups().subscribe(
	  (groups) => {
		this.results = groups;
		this.groups = groups;
		// this.groupsTable = new MatTableDataSource(groups);
		// this.groupsTable.sort = this.sort;
		// this.groupsTable.paginator = this.paginator;
		this.filteredGroups = groups;
	  },
	  () => {
		this.snackBar.open('Error loading groups.', 'Close', { duration: 3000 });
	  }
	);
  }

  sectionName(sectionId: string): string {
	const section = this.sections.find((s: any) => s.sectionid === sectionId);
	return section ? section.sectionname : 'Unknown Section';
  }

  // Filter groups based on search term
  applyFilter(event: any): void {
	this.searchTerm = event.target.value.toLowerCase();
	this.filterGroups();
  }

  // Filter groups based on search term and dropdown filters
  filterGroups(): void {
	this.filteredGroups = this.results.filter((group: any) => {
	  return (
		(!this.searchTerm ||
		  group.groupname.toLowerCase().includes(this.searchTerm) ||
		  group.sectioninaccounts.toLowerCase().includes(this.searchTerm)) &&
		(!this.editingGroup || this.editingGroup.groupname !== group.groupname)
	  );
	});
  }

  // Save or update group
  saveGroup(): void {
	if (this.groupForm.valid) {
	  const groupData = this.groupForm.value;

	  if (this.editingGroup) {
		// Update existing group
		this.globalsService.updateGroup(groupData).subscribe(() => {
		  this.snackBar.open('Group updated successfully.', 'Close', { duration: 3000 });
		  this.loadGroups();
		  this.cancelEdit();
		});
	  } else {
		// Add new group
		this.globalsService.updateGroup(groupData).subscribe(() => {
		  this.snackBar.open('Group added successfully.', 'Close', { duration: 3000 });
		  this.loadGroups();
		  this.cancelEdit();
		});
	  }
	}
  }

 	 // Edit group
	editGroup(group: any): void {
	// Reverse the text to numeric equivalent for `pandl`
	if (group.pandl === 'Profit / Loss') {
		group.pandl = '1';
	} else if (group.pandl === 'Balance Sheet') {
		group.pandl = '0';
	}

	this.editingGroup = group;
	this.groupForm.patchValue(group); // Patch the updated group into the form
	this.isFormVisible = true; // Show the form
	}


  // Cancel editing
  cancelEdit(): void {
	this.editingGroup = null;
	this.isFormVisible = false;
	this.groupForm.reset();
  }

  // Delete group
  deleteGroup(groupName: string): void {
	if (!confirm('Are you sure you want to delete this group?')) return;

	this.globalsService.deleteGroup(groupName).subscribe(
	  () => {
		this.snackBar.open('Group deleted successfully.', 'Close', { duration: 3000 });
		this.loadGroups();
	  },
	  () => {
		this.snackBar.open('Error deleting group.', 'Close', { duration: 3000 });
	  }
	);
  }

  // Toggle form visibility
  toggleForm(): void {
	if (this.editingGroup) {
	  this.cancelEdit();
	}
	this.isFormVisible = !this.isFormVisible;
	if (!this.isFormVisible) {
	  this.groupForm.reset();
	}
  }

  // Filter by search term
  applySearch(event: any): void {
	  this.searchTerm = event.target.value.toLowerCase();
	  this.filterGroups();
  }

  // Filter by Account Section
  applySectionFilter(sectionId: string): void {
	  this.selectedSection = sectionId;
	  this.filterGroups();
  }

  // Navigate back
  back(): void {
	this.location.back();
  }
}
