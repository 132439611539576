import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';

import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';
import { PrintService } from '../../services/print.service';

@Component({
	selector: 'app-account-inquiry',
	templateUrl: './account-inquiry.component.html',
	styleUrls: ['./account-inquiry.component.scss']
})
export class AccountInquiryComponent implements OnInit {
	periods: any = [];
	default_period: any = [];
	default_toperiod: any = '';
	default_fromperiod: any = '';
	formGroup: UntypedFormGroup;
	loading = false;
	trial_balance: any = [];
	title = '';
	from_period_options: any = [];
	to_period_options: any = [];
	tags: any = [];
	glaccounts: any = [];
	transresults: any = false;
	exportList: any = [];
	color: any = '';
	selectedaccount: any = false;
	periods_transactions: any = false;

	postendonly = [{ label:'No', value: '0' },{ label:'Yes', value: '1' }]

	@ViewChild('print_table') printtable: ElementRef;
	subed: any = false;
	isrunning: any = false;
	filename = 'AccountInquiry';
	constructor(private location: Location, private generalLedgerService: GeneralLedgerService, private printService: PrintService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

		this.formGroup = this.fb.group({
			glaccount: ['', [Validators.required]],
			from_period: ['' , [Validators.required]],
			to_period: ['', [Validators.required]],
			gl_tag: [''],
			posted: ['0', [Validators.required]]
		});

		this.generalLedgerService.getPeriods().subscribe((results: any) => {
			this.periods = results.periods;
			this.from_period_options = results.periods;
			this.to_period_options = results.periods;
			this.default_fromperiod = results.default_fromperiod;
			this.default_toperiod = results.default_toperiod;
			this.formGroup.get('from_period').setValue(results.default_fromperiod)
			this.formGroup.get('to_period').setValue(results.default_toperiod)
		});
	}

	updateToOptions(value: any) {
		this.to_period_options = this.periods.filter((period) => {
			return parseInt(period.periodno) >= parseInt(value)
		});
	}

	back() {
		this.location.back();
	}



	getAccount() {
		if(this.formGroup.valid) {
			this.isrunning = true;
			if(this.subed) {
				this.subed.unsubscribe();
			}
			this.subed = this.generalLedgerService.getAccountInquiry(this.formGroup.value).subscribe((results: any) => {
				this.isrunning = false;
				this.transresults = results;
				this.periods_transactions = results.periods;
			});
		}
	}

	selectGl(value: any) {
		this.selectedaccount = value.option.value;
		this.formGroup.get('glaccount').setValue(value.option.value.accountcode);
	}

	exportExcel() {
		this.exportList = [];
		this.transresults.periods.forEach(x => this.addToList(x));
		this.globalSearchService.exportJsonToExcel(this.exportList, 'AccountInquiry' + new Date() + '.xlsx')
	}

	addToList(data: any) {
		data.transactions.forEach(x => this.addRows(x));
	}

	addRows(transactions: any) {
		this.exportList.push(transactions);
	}
	exportPDF(element: any) {
		const encoded: string = btoa(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename,
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}
}
