<nav class="navbar bg-transparent m-0 p-0 noshadow">
	<ul class="navbar-nav bg-transparent ">
		<ng-container *ngIf="config.env.package === 'beauty'">
			<ng-container *ngIf="!isPosUser">
				<ng-container [ngSwitch]="user.user.department">
					<a *ngSwitchCase="'3'" class="nav-item professional-button forced" mat-raised-button [routerLink]="'/orders/openorders'">
						<mat-icon class="professional-icon forced">note_add</mat-icon>
					</a>
					<a *ngSwitchDefault class="nav-item professional-button forced" mat-raised-button [routerLink]="'/orders/entry'">
						<mat-icon class="professional-icon forced">note_add</mat-icon>
					</a>
				</ng-container>
			</ng-container>
			<a class="nav-item professional-button forced" mat-raised-button [routerLink]="'/counter-pos'" *ngIf="isPosUser">
				<mat-icon class="professional-icon forced">note_add</mat-icon>
			</a>
		</ng-container>
		<ng-container *ngIf="!issalesman">
			<li class="nav-item no-border no-shadow" *ngIf="user && locations && locations.length > 1">
				<form class="form-inline my-2 my-lg-0">
					&nbsp;&nbsp;&nbsp;
					<select [(value)]="selected_location" (change)="changeLocation($event.target)" class="select-dropdown professional-select bg-transparent forced">
						<option [value]="loc.loccode" [selected]="selected_location == loc.loccode" *ngFor="let loc of locations">{{ loc.locationname }}</option>
					</select>
				</form>
			</li>
		</ng-container>
		<ng-container *ngIf="user && user.user && user.user.fullaccess === '9'">
			&nbsp;&nbsp;
			<li class="nav-item no-border no-shadow">
				<form class="form-inline my-2 my-lg-0">
					<app-config-switcher (reload)="initLoad($event)"></app-config-switcher>
				</form>
			</li>
		</ng-container>
	</ul>
</nav>