import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-gl-accounts',
	templateUrl: './gl-accounts.component.html',
	styleUrls: ['./gl-accounts.component.scss'],
	animations: [
		trigger('expandCollapse', [
			state('void', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
			state('*', style({ height: '*', opacity: 1 })),
			transition('void <=> *', [animate('300ms ease-in-out')]),
		]),
	],
})
export class GlAccountsComponent implements OnInit {
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	accountForm: FormGroup;
	accounts = new MatTableDataSource<any>([]);
	results: any = [];
	groups: any = [];
	selectedAccount: any = null;
	headerColumns: string[] = ['actions', 'accountcode', 'accountname', 'group'];
	color: string;
	isFormVisible = false;
	isLoading = false;
	editingAccount: any = false;

	filteredAccounts: any[] = [];
	searchTerm: string = '';
	selectedGroup: string = '';
	selectedGroups: string[] = [];

	constructor(
		private fb: FormBuilder,
		private globalsService: GlobalsService,
		private globalSearchService: GlobalSearchService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private snackBar: MatSnackBar
	) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.initializeForm();
		this.loadAccountGroups();
		this.loadAccounts();
		this.checkRouteParams();
	}

	// Initialize the account form
	initializeForm(): void {
		this.accountForm = this.fb.group({
			accountcode: ['', Validators.required],
			accountname: ['', Validators.required],
			group: ['', Validators.required],
		});
	}

	// Load account groups for dropdown
	loadAccountGroups(): void {
		this.globalsService.getAccountGroups().subscribe((details) => {
			this.groups = details;
		});
	}

	cancelEdit(): void {
		this.resetForm();
		this.editingAccount = null;
	}


	// Load all accounts
	loadAccounts(): void {
		this.isLoading = true;
		this.globalsService.getGLAccounts().subscribe(
			(results: any) => {
				this.accounts = results;
				this.results = results;
				this.filteredAccounts = results;
				this.accounts.sort = this.sort;
				this.accounts.paginator = this.paginator;
				this.isLoading = false;
			},
			() => {
				this.snackBar.open('Error loading accounts.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	applyFilter(event: any): void {
		this.searchTerm = event.target.value.toLowerCase();
		this.filterAccounts();
	}

	applyGroupFilter(groups: string[]): void {
		this.selectedGroups = groups;
		this.filterAccounts();
	}

	filterAccounts(): void {
		this.filteredAccounts = this.results.filter((account: any) => {
			const matchesSearch =
				!this.searchTerm ||
				account.accountcode.toLowerCase().includes(this.searchTerm) ||
				account.accountname.toLowerCase().includes(this.searchTerm);
			const matchesGroup =
				this.selectedGroups.length === 0 || this.selectedGroups.includes(account.group);
			return matchesSearch && matchesGroup;
		});
	}

	// Check route parameters for specific account
	checkRouteParams(): void {
		this.route.params.subscribe((params) => {
			const accountCode = params['id'];
			if (accountCode) {
				this.loadAccountDetails(accountCode);
			}
		});
	}

	// Load details of a specific account
	loadAccountDetails(accountCode: string): void {
		this.globalsService.getGLAccount(accountCode).subscribe((results: any) => {
			this.selectedAccount = results;
			this.accountForm.patchValue({
				accountcode: results.accountcode,
				accountname: results.accountname,
				group: results.group_,
			});
			this.isFormVisible = true;
		});
	}

	// Save or update account
	saveGLAccount(): void {
		if (this.accountForm.valid) {
			this.editingAccount = false;
			const accountData = this.accountForm.value;
			if (this.selectedAccount) {
				// Update account
				this.globalsService.updateGLAccount(accountData).subscribe(() => {
					this.snackBar.open('Account updated successfully.', 'Close', { duration: 3000 });
					this.loadAccounts();
					this.toggleForm();
				});
			} else {
				// Add new account
				this.globalsService.addGLAccount(accountData).subscribe(() => {
					this.snackBar.open('Account added successfully.', 'Close', { duration: 3000 });
					this.loadAccounts();
					this.toggleForm();
				});
			}
		}
	}

	// Delete account
	deleteAccount(accountCode: string): void {
		if (!confirm('Are you sure you want to delete this account?')) return;

		this.globalsService.deleteGLAccount(accountCode).subscribe(
			(result) => {
				if(result.success) {
					this.snackBar.open('Account deleted successfully.', 'Close', { duration: 3000 });
					this.loadAccounts();
				} else {
					this.snackBar.open(result.error, 'Close', { duration: 3000 });
				}
			},
			() => {
				this.snackBar.open('Error deleting account.', 'Close', { duration: 3000 });
			}
		);
	}

	// Select account for editing
	editAccount(account: any): void {
		this.selectedAccount = account;
		this.editingAccount = account;
		this.accountForm.patchValue(account);
		this.isFormVisible = true;
	}

	// Toggle form visibility
	toggleForm(): void {
		if (this.editingAccount) {
			// Cancel the editing if an account is being edited
			this.cancelEdit();
		}
		this.isFormVisible = !this.isFormVisible;
		if (this.isFormVisible) {
			this.resetForm(); // Reset the form for adding a new account
		}
	}


	// Reset form fields
	resetForm(): void {
		this.accountForm.reset();
		this.selectedAccount = null;
	}

	// Export to Excel
	exportExcel(): void {
		this.globalSearchService.exportJsonToExcel(this.results, `GLAccounts_${new Date().toISOString()}.xlsx`);
	}

	// Navigate back
	back(): void {
		this.location.back();
	}
}
