<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title " *ngIf="!transid"> <i class="material-icons" (click)="back()">arrow_left</i> Payables Awaiting Allocation</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">
						<div class="row">
							<div class="col-2">
								<div class="table table-responsive text-center" *ngIf="searching">
									<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
										</circle>
									</svg>
								</div>
							</div>
							<div class="col-10">
								<mat-form-field appearance="outline" >
									<mat-label>Search</mat-label>
									<input tabindex="1" matInput value="" (input)="onInput()" [formControl]="vendorSearch" class="text-right" autofocus (keydown.enter)="$event.preventDefault();onInput()">
									<span matSuffix>
										<button tabindex="-1" mat-icon-button color="white">
											<mat-icon (click)="onInput()">search</mat-icon>
										</button>
									</span>
									<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
								</mat-form-field>
							</div>
						</div>
						<div class="table-container">
							<table mat-table class="table table-sm" [dataSource]="transactions" matSort (matSortChange)="announceSortChange($event)" multiTemplateDataRows [trackBy]="identify">
								<ng-container matColumnDef="transno">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Document#</th>
									<td mat-cell *matCellDef="let tran">
										<!--
										<ng-container *ngIf="tran.type != '12'">
									<i class="material-icons smaller" (click)="$event.stopPropagation();openDocument(tran,details,'html')">visibility</i>&nbsp;&nbsp;{{ tran.transno }}
									</ng-container>
								-->
										{{ tran.transno }}
									</td>
								</ng-container>
								<ng-container matColumnDef="trandate">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
									<td mat-cell *matCellDef="let tran">
										{{ tran.trandate | date:"MM/dd/yy"}}
									</td>
								</ng-container>
								<ng-container matColumnDef="typename">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
									<td mat-cell *matCellDef="let tran">
										{{ tran.typename }}
									</td>
								</ng-container>
								<ng-container matColumnDef="supplierno">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor#</th>
									<td mat-cell *matCellDef="let tran">
										<a [routerLink]="'/vendors/'+tran.supplierno"><b>{{ tran.supplierno }}</b></a>
									</td>
								</ng-container>
								<ng-container matColumnDef="suppname">
									<th mat-header-cell *matHeaderCellDef mat-sort-header class="min-20">Name</th>
									<td mat-cell *matCellDef="let tran" class="min-20">
										{{ tran.suppname }}
									</td>
								</ng-container>
								<ng-container matColumnDef="invtext">
									<th mat-header-cell *matHeaderCellDef mat-sort-header class="min-30">Ref</th>
									<td mat-cell *matCellDef="let tran" [innerHTML]="tran.suppreference" class="min-30">
									</td>
								</ng-container>
								<ng-container matColumnDef="totalamount">
									<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right mat-column-right" arrowPosition="before"> Total</th>
									<td mat-cell class="text-right" *matCellDef="let tran">
										{{ tran.total | currency }}
									</td>
								</ng-container>
								<ng-container matColumnDef="allocated">
									<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right mat-column-right" arrowPosition="before">Allocated</th>
									<td mat-cell class="text-right" *matCellDef="let tran">
										{{ tran.alloc | currency }}
									</td>
								</ng-container>
								<ng-container matColumnDef="balance" class="text-right">
									<th mat-header-cell class="text-right " *matHeaderCellDef mat-sort-header arrowPosition="before">Balance</th>
									<td mat-cell class="text-right" *matCellDef="let tran;let i = dataIndex;">
										{{ tran.balance | currency }} <i class="material-icons smaller" (click)="$event.stopPropagation();expandRow(tran,i)">edit</i>
									</td>
								</ng-container>
								<ng-container matColumnDef="expandedDetail">
									<td mat-cell *matCellDef="let tran" [attr.colspan]="headercolumns.length">
										<div class="alloc-element-detail" [@detailExpand]="tran == expandedElement ? 'expanded' : 'collapsed'">
											<div class="alloc-element-description">
												<app-allocations [transid]="tran.id" [type]="tran.type" *ngIf="tran == expandedElement"></app-allocations>
											</div>
										</div>
									</td>
								</ng-container>
								<tr mat-header-row *matHeaderRowDef="headercolumns;sticky:true;"></tr>
								<tr mat-row *matRowDef="let row; columns: headercolumns;let i = dataIndex;" class="alloc-element-row" (click)="expandRow(row, i );" [class.alloc-expanded-row]="expandedElement === row"></tr>
								<tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="w-100 alloc-detail-row" [class.bg-danger]="row.selected == true"></tr>
							</table>
							<mat-paginator [pageSizeOptions]="[100, 500, 1000]" showFirstLastButtons aria-label="select page">
							</mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
