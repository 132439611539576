<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer">post_add</mat-icon>
							Sales GL Postings
						</h4>

					</div>
					<div class="card-body">
						<div class="text-end">
							<button mat-raised-button class="mt-2 mb-2" color="accent" (click)="toggleForm()">
								{{ isFormVisible ? 'Hide Form' : 'Add New Posting' }}
							</button>
						</div>
						<div *ngIf="isFormVisible" [@expandCollapse] class="form-container p-4 border rounded shadow-sm bg-light">
							<form [formGroup]="salesGLForm" (ngSubmit)="saveSalesGLPosting()">
								<div class="row g-4">
									<!-- Section Title -->
									<div class="col-12">
										<h4 class="text-primary mb-3">{{ editingPost ? 'Edit Posting' : 'Add New Posting' }}</h4>
									</div>

									<!-- Account Information -->
									<div class="col-md-4">
										<mat-form-field appearance="outline" class="w-100">
											<mat-label>Area</mat-label>
											<mat-select formControlName="area" required>
												<mat-option *ngFor="let area of areas" [value]="area.code">
													{{ area.code }} {{ area.name }}
												</mat-option>
											</mat-select>
											<mat-hint>Select the geographic area for the posting.</mat-hint>
										</mat-form-field>
									</div>

									<div class="col-md-4">
										<mat-form-field appearance="outline" class="w-100">
											<mat-label>Stock Category</mat-label>
											<mat-select formControlName="stkcat" required>
												<mat-option *ngFor="let category of stockCategories" [value]="category.code">
													{{ category.code }} {{ category.name }}
												</mat-option>
											</mat-select>
											<mat-hint>Select the stock category for the posting.</mat-hint>
										</mat-form-field>
									</div>

									<div class="col-md-4">
										<mat-form-field appearance="outline" class="w-100">
											<mat-label>Sales Type</mat-label>
											<mat-select formControlName="salestype" required>
												<mat-option *ngFor="let type of salesTypes" [value]="type.code">
													{{ type.code }} {{ type.name }}
												</mat-option>
											</mat-select>
											<mat-hint>Select the sales type for the posting.</mat-hint>
										</mat-form-field>
									</div>

									<!-- GL Codes -->
									<div class="col-md-6">
										<mat-form-field appearance="outline" class="w-100">
											<mat-label>Sales GL Code</mat-label>
											<mat-select formControlName="salesglcode" required>
												<mat-option *ngFor="let code of glCodes" [value]="code.code">
													{{ code.code }} - {{ code.name }}
												</mat-option>
											</mat-select>
											<mat-hint>Select the sales GL code.</mat-hint>
										</mat-form-field>
									</div>

									<div class="col-md-6">
										<mat-form-field appearance="outline" class="w-100">
											<mat-label>Discount GL Code</mat-label>
											<mat-select formControlName="discountglcode" required>
												<mat-option *ngFor="let code of glCodes" [value]="code.code">
													{{ code.code }} - {{ code.name }}
												</mat-option>
											</mat-select>
											<mat-hint>Select the discount GL code.</mat-hint>
										</mat-form-field>
									</div>

									<!-- Submit Button -->
									<div class="col-12 text-end mt-3">
										<button mat-raised-button color="accent" type="submit" [disabled]="salesGLForm.invalid" class="w-25">
											{{ editingPost ? 'Update Posting' : 'Add Posting' }}
										</button>
									</div>
								</div>
							</form>
						</div>

						<!-- Sales GL Postings Table -->
						<div class="table-responsive mt-4">
							<table class="table table-striped table-hover table-bordered">
								<thead class="thead-dark">
									<tr>
										<th class="text-center">Area</th>
										<th class="text-center">Stock Category</th>
										<th class="text-center">Sales Type</th>
										<th class="text-center">Sales GL Code</th>
										<th class="text-center">Discount GL Code</th>
										<th class="text-center"></th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let post of salesGLPostings" [class.selected]="editingPost?.id === post.id">
										<td class="text-center">{{ post.area }}</td>
										<td class="text-center">{{ post.stkcat }}</td>
										<td class="text-center">{{ post.salestype }}</td>
										<td class="text-center">{{ post.salesglcode }}</td>
										<td class="text-center">{{ post.discountglcode }}</td>
										<td class="text-center">
											<button mat-icon-button color="primary" (click)="editPosting(post)" matTooltip="Edit">
												<mat-icon>edit</mat-icon>
											</button>
											<button mat-icon-button color="warn" (click)="deletePosting(post.id)" matTooltip="Delete">
												<mat-icon>delete</mat-icon>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
