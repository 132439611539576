<ng-template #monthView let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<ng-container *ngIf="datepicked">
			<app-day-sales [inputdate]="datepicked"></app-day-sales>
		</ng-container>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="col-md-12">

		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="row tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
				<div class="card">
					<div class="card-header card-header-{{ color }}-3 pointer">
						<h4 class="card-title "> <i class="material-icons pointer" (click)="toggleFlip('')">arrow_left</i> {{ viewing }} </h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body mt-0 mb-0 pt-0 pb-0">
						<ng-container [ngSwitch]="viewing">
							<ng-container *ngSwitchCase="'Needs Bin'">
								<app-need-bin-location></app-need-bin-location>
							</ng-container>
							<ng-container *ngSwitchCase="'Negative Inventory'">
								<app-negative-items></app-negative-items>
							</ng-container>
							<ng-container *ngSwitchCase="'Open Orders'">

								<app-open-orders (preorderloaded)="selectItemLookup($event);" (orderloaded)="selectItemLookup($event)" [customerdata]="''" (orderinvoice)="invoiceOrder($event)"></app-open-orders>
							</ng-container>
							<ng-container *ngIf="config.env.package == 'beauty'">
								<ng-container *ngSwitchCase="'Gap Report'">
									<app-gapp-report></app-gapp-report>
								</ng-container>
								<ng-container *ngSwitchCase="'Open Quotes'">
									<!-- <app-open-quotes [quoteData]="openquotes"></app-open-quotes> -->
									<app-quote-list (orderloaded)="selectItemLookup($event)"></app-quote-list>
								</ng-container>
							</ng-container>
						</ng-container>
					</div>
				</div>
			</div>


			<div class="card card-stats" *ngIf="config.arpreauth" [ngClass]="flip != 'inactive' ? 'd-none' : ''">
				<div class="card-header card-header-primary card-header-icon">
					<div class="card-icon ">
						<i class="material-icons">pie_chart</i>
					</div>
					<p class="card-category">This Over Last</p>
				</div>
				<div class="card-body">
					<h5 *ngIf="thisoverlast">

						UNITS MTD/MTDL: {{ thisoverlast.mtd_units }} / {{ thisoverlast.mtdlast_units }}

						<span [ngClass]="thisoverlast.percent_updown_units > 0 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="thisoverlast.percent_updown_units > 0 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i></span>

						<span [ngClass]="thisoverlast.percent_updown_units < 0 ? 'text-danger': 'text-success'"> {{ thisoverlast.percent_updown_units | percent}}
						</span>


						<br /> SALES MTD/MTDL: {{ thisoverlast.mtd_dollars | currency }}  / {{ thisoverlast.mtdlast_dollars | currency}}

						<span [ngClass]="thisoverlast.percent_updown_dollars > 0 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="thisoverlast.percent_updown_dollars > 0 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i></span>

						<span [ngClass]="thisoverlast.percent_updown_dollars < 0 ? 'text-danger': 'text-success'"> {{ thisoverlast.percent_updown_dollars | percent}}
						</span>
					</h5>
					<p class="card-category"></p>
					<div class="ct-chart" *ngIf="!thisoverlast">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</div>
					<div class="table-responsive mt-3" *ngIf="thisoverlast">
						<table class="table table-bordered" *ngFor="let year of thisoverlast.years; let i = index;">
							<thead class="thead-light">
								<tr>
									<th class="text-center total-header">
										<span *ngIf="year.qty">{{ year.qty[0].year }}</span>
									</th>
									<th class="text-center total-header">TTL</th>
									<ng-container *ngFor="let month of year.sales; let bf = index;">

										<th class="text-center month-header">
											{{ month.label }}
											<div class="month-stats">
												<div class="stat-group">
													<span class="stat-label" [ngClass]="i != (thisoverlast.years.length - 1) ? getMonthYearQty(month.label, year).class : 'text-muted'">
														<i class="fa" [ngClass]="i != (thisoverlast.years.length - 1) ? getMonthYearQty(month.label, year).icon : ''"></i>
														Y: {{ i != (thisoverlast.years.length - 1) ? (getMonthYearQty(month.label, year).percent | percent) : 'N/A' }}
													</span>
													<span class="stat-value" [ngClass]="i != (thisoverlast.years.length - 1) ? getMonthYearQty(month.label, year).percent_month_color : 'text-muted'">
														<i class="fa" [ngClass]="i != (thisoverlast.years.length - 1) ? getMonthYearQty(month.label, year).percent_month_icon : ''"></i>
														M: {{ i != (thisoverlast.years.length - 1) ? (getMonthYearQty(month.label, year).percent_month | percent) : 'N/A' }}
													</span>
												</div>
											</div>
										</th>
									</ng-container>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td class="text-center align-middle">Qty </td>
									<td class="text-center align-middle">{{ getQtyTotalYear( year ) | number:'1.0-0'  }}</td>
									<td class="text-center" *ngFor="let month of year.qty">
										{{ month.value | number:'1.0-0' }}
									</td>
									<!-- <td class="text-center">{{ getQtyTotalYear(year) | number:'1.0-0' }}</td> -->
								</tr>
								<tr>
									<td class="text-center align-middle">Sale</td>
									<td class="text-center align-middle">{{ getSaleTotalYear( year ) | currency }}</td>
									<td class="text-center" *ngFor="let month of year.sales">
										{{ month.value | currency }}
									</td>
									<!-- <td class="text-center">{{ getSaleTotalYear(year) | currency }}</td> -->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
				<div class="col-12" *ngIf="userData.user.issalesman">
					<div class="card card-stats">
						<div class="card-header card-header-primary card-header-icon">
							<div class="card-icon pointer ripple">
								<i class="material-icons">view_quilt</i>
							</div>
							<p class="card-category">Recent Transactions</p>
						</div>
						<div class="card-body m-0 mt-2 p-0">
							<app-openorders-and-invoices></app-openorders-and-invoices>
						</div>
					</div>
				</div>
				<div [ngClass]="config.env.package == 'tires' ? 'col-lg-4 col-md-4 col-sm-4' : config.env.package == 'beauty' && !userData.user.issalesman && !isPosUser ? 'col-lg-3 col-md-3 col-sm-3' : 'col-lg-6 col-md-6 col-sm-6'">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon pointer ripple" (click)="toggleFlip('Open Orders')">
								<i class="material-icons">info_outline</i>
							</div>
							<p class="card-category">Open Orders</p>

							<h3 class="card-title" *ngIf="openorders!= 'notready'">{{ this.openorders}}</h3>
							<span *ngIf="openorders == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg></span>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a class="pointer" (click)="toggleFlip('Open Orders')" matTooltip="View" [matTooltipPosition]="'below'"><i class="material-icons">visibility</i> View Now</a>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-md-6 col-sm-6" *ngIf="config.env.package == 'beauty' && (userData.user.issalesman || isPosUser)">
					<div class="card card-stats">
						<div class="card-header card-header-primary card-header-icon">
							<div class="card-icon pointer ripple" (click)="toggleFlip('Open Quotes')">
								<i class="material-icons">view_quilt</i>
							</div>
							<p class="card-category">Open Quotes</p>
							<h3 class="card-title" *ngIf="openquotes != 'notready'">{{openquotes}}</h3>
							<span *ngIf="openquotes == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg></span>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a class="pointer" (click)="toggleFlip('Open Quotes')" matTooltip="View" [matTooltipPosition]="'below'"><i class="material-icons">visibility</i> View Now</a>
							</div>
						</div>
					</div>
				</div>

				<div [ngClass]="config.env.package == 'tires' ? 'col-lg-4 col-md-4 col-sm-4' : 'col-lg-3 col-md-3 col-sm-3'" *ngIf="(config.env.package == 'beauty' && !userData.user.issalesman && !isPosUser)">
					<div class="card card-stats">
						<div class="card-header card-header-primary card-header-icon">
							<div class="card-icon pointer ripple" (click)="toggleFlip('Gap Report')">
								<i class="material-icons">view_quilt</i>
							</div>
							<p class="card-category">Gap Report</p>

							<!-- <h3 class="card-title" *ngIf="openorders!= 'notready'">Gap Report</h3> -->
							<span *ngIf="openorders == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg></span>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a class="pointer" (click)="toggleFlip('Gap Report')" matTooltip="View" [matTooltipPosition]="'below'"><i class="material-icons">visibility</i> View Now</a>
							</div>
						</div>
					</div>
				</div>

				<div [ngClass]="config.env.package == 'tires' ? 'col-lg-4 col-md-4 col-sm-4' : 'col-lg-3 col-md-3 col-sm-3'" *ngIf="(config.env.package == 'beauty' && !userData.user.issalesman && !isPosUser) || config.env.package == 'tires'">
					<div class="card card-stats">
						<div class="card-header card-header-warning card-header-icon">
							<div class="card-icon pointer" (click)="toggleFlip('Needs Bin')">
								<i class="material-icons">store</i>
							</div>
							<p class="card-category">Need Bin Location</p>
							<h3 class="card-title">
								<span *ngIf="needlocation != 'notready'">{{ needlocation.length }}</span>
								<span *ngIf="needlocation == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg></span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a class="pointer" (click)="toggleFlip('Needs Bin')" matTooltip="View" [matTooltipPosition]="'below'"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
						</div>
					</div>
				</div>
				<div [ngClass]="config.env.package == 'tires' || this.userData.user.issalesman ? 'col-lg-4 col-md-4 col-sm-4' : 'col-lg-3 col-md-3 col-sm-3'" *ngIf="(config.env.package == 'beauty' && !userData.user.issalesman && !isPosUser) || config.env.package == 'tires'">
					<div class="card card-stats">
						<div class="card-header card-header-success card-header-icon">
							<div class="card-icon pointer" (click)="toggleFlip('Negative Inventory')">
								<i class="material-icons">content_copy</i>
							</div>
							<p class="card-category">Negative Items</p>
							<h3 class="card-title" *ngIf="negativestock != 'notready'">{{ this.negativestock }}</h3>
							<span *ngIf="negativestock == 'notready'"><svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg></span>
						</div>
						<div class="card-footer">
							<div class="stats">
								<a class="pointer" (click)="toggleFlip('Negative Inventory')" matTooltip="View" [matTooltipPosition]="'below'"><i class="material-icons">visibility</i> View Now</a>
							</div>
						</div>
					</div>
				</div>

				<!-- NO PURCHASE -->
				<div class="col-md-12">

				</div>
				<div class="col-md-12">
					<app-customer-no-purchase></app-customer-no-purchase>
				</div>


				<div class="col-md-6" *ngIf="config.env.package == 'tires'">
					<div class="card card-chart">
						<div class="card-header card-header-success" data-header-animation="true">
							<div class="ct-chart" id="dailySalesChart" style="max-height:300px"></div>
							<div class="ct-chart" *ngIf="!ordersbyday">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>
						</div>
						<div class="card-body">
							<div class="card-actions">

								<button mat-raised-button type="button" class="btn btn-info btn-link" matTooltip="Refresh" [matTooltipPosition]="'below'" (click)="loadData()">
									<i class="material-icons">refresh</i>
								</button>
								<!--<button mat-raised-button type="button" class="btn btn-default btn-link" matTooltip="Change Date" [matTooltipPosition]="'below'">
								  <i class="material-icons">edit</i>
								</button>-->
							</div>

							<h4 class="card-title">Items Sold</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-footer">

							<div class="stats">
								<p class="card-category">
									<span [ngClass]="percentup > 1 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="percentup > 1 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i> {{ percentup - 1 | percent }} </span> {{ orderpweek }} Last week / {{ orderweek }} this week
								</p>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="config.env.package == 'tires'">
					<div class="card card-chart">
						<div class="card-header card-header-warning" data-header-animation="true">
							<div class="ct-chart" id="websiteViewsChart" style="max-height:300px"></div>
							<div class="ct-chart" *ngIf="!posbyday">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>

						</div>
						<div class="card-body">
							<div class="card-actions">

								<button mat-raised-button type="button" class="btn btn-info btn-link" matTooltip="Refresh" [matTooltipPosition]="'below'" (click)="loadData()">
									<i class="material-icons">refresh</i>
								</button>
								<!--
									<button mat-raised-button type="button" class="btn btn-default btn-link" matTooltip="Change Date" [matTooltipPosition]="'below'">
								  <i class="material-icons">edit</i>
								</button>
								-->
							</div>

							<h4 class="card-title">Items Received</h4>
							<p class="card-category"></p>
						</div>
						<div class="card-footer">
							<div class="stats">
								<p class="card-category">
									<span [ngClass]="popercentup > 1 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="popercentup > 1 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i> {{ popercentup - 1 | percent }} </span> {{ popweek }} Last week / {{ poweek }} this week
								</p>
							</div>
						</div>
					</div>
				</div>



				<!-- BEAUTY TELEGRAM REPORTING -->
				<!-- <div class="col-md-12">
					<app-telegram-reports></app-telegram-reports>
				</div> -->
				<!-- BEAUTY TELEGRAM REPORTING -->
				<div class="col-md-12" *ngIf="telegramAllowed">
					<app-telegram-reports [menuapp]="false"></app-telegram-reports>
				</div>
				<div class="col-md-12" *ngIf="brandAllowed">
					<app-brand-report [customer]="false"></app-brand-report>
				</div>
				<div class="col-md-6" *ngIf="linePie">
					<div class="card card-chart">
						<div class="card-header card-header-icon card-header-danger">
							<div class="card-icon">
								<i class="material-icons">pie_chart</i>
							</div>
							<h4 class="card-title text-dark">Product Line Sales Last 7 Days</h4>
						</div>
						<div class="card-body">
							<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" *ngIf="linePieLoading">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
							<div class="chart-container bg-white">
								<canvas id="plineChart" class="ml-auto mr-auto w-100 h-100">{{linechart}}</canvas>
							</div>
							<div class="boxed" *ngIf="linePie">
								<table class="table table-fixed ">
									<tr *ngFor="let t of catandlines.line_sales">
										<td>
											<span class="labeldata {{t.className}}"> &nbsp;&nbsp;</span>&nbsp;
											<span *ngIf="t.label != ''">{{ t.label }}</span>
											<span *ngIf="t.label == ''">**UNKNOWN**</span>
										</td>
										<td class="text-right">{{ t.value | currency }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="categoryPie">
					<div class="card card-chart">
						<div class="card-header card-header-icon card-header-danger">
							<div class="card-icon">
								<i class="material-icons">pie_chart</i>
							</div>
							<h4 class="card-title">
								<ng-container *ngIf="config.env.package == 'tires'">Tire Type Sales</ng-container>
								<ng-container *ngIf="config.env.package == 'beauty'">Category Sales</ng-container>
							</h4>
						</div>
						<div class="card-body">
							<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" *ngIf="categoryPieLoading">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
							<div class="chart-container bg-white">
								<canvas id="catChartRef">{{catchart}}</canvas>
							</div>
							<div class="boxed">
								<table class="table table-fixed ">
									<tr *ngFor="let t of catandlines.category_sales">
										<td>
											<span class="labeldata {{t?.className}}">&nbsp;&nbsp;</span>&nbsp;
											<span *ngIf="t.label != ''">{{ t.label }}</span>
											<span *ngIf="t.label == ''">**UNKNOWN**</span>
										</td>
										<td class="text-right">{{ t.value | currency }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>

				<div class="col-md-6" *ngIf="customerTypePie">
					<div class="card card-chart">
						<div class="card-header card-header-icon card-header-danger">
							<div class="card-icon">
								<i class="material-icons">pie_chart</i>
							</div>
							<h4 class="card-title">Customer Type Sales</h4>
						</div>
						<div class="card-body">
							<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" *ngIf="customerTypePieLoading">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
							<div class="chart-container bg-white">
								<canvas id="customerTypePie">{{customerTypePie}}</canvas>
							</div>
							<div class="boxed">
								<table class="table table-fixed ">
									<tr *ngFor="let t of catandlines.customer_type_sales">
										<td>
											<span class="labeldata {{t?.className}}">&nbsp;&nbsp;</span>&nbsp;
											<span *ngIf="t.label != ''">{{ t.label }}</span>
											<span *ngIf="t.label == ''">**UNKNOWN**</span>
										</td>
										<td class="text-right">{{ t.value | currency }}</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</div>

				<!-- NO PURCHASE
				<div class="col-md-6">
					<app-customer-no-purchase></app-customer-no-purchase>
				</div>
				-->
				<div class="col-md-12" *ngIf="config.env.package == 'tires'">
					<div class="card">
						<div class="card-body">
							<button mat-raised-button class="ml-auto btn-success btn-height" (click)="$event.preventDefault();exportXls()">
								<i class="fa fa-file-excel-o"></i> XLS
							</button>
							&nbsp;
							<button mat-raised-button class="btn-danger btn-height" (click)="$event.preventDefault();exportPdf()">
								<i class="fa fa-file-pdf-o"></i> PDF
							</button>

							<div class="card-actions">
								<!--
									<button mat-raised-button type="button" class="btn btn-info btn-link" matTooltip="Refresh" [matTooltipPosition]="'below'" (click)="loadData()">
									<i class="material-icons">refresh</i>
								</button>

									<button mat-raised-button type="button" class="btn btn-default btn-link" matTooltip="Change Date" [matTooltipPosition]="'below'">
								  <i class="material-icons">edit</i>
								</button>
								-->
							</div>

							<div class="form-group mb-3">
								<label class="mr-2">Years to Display:</label>
								<input type="number" 
									   class="form-control d-inline-block"
									   style="width: 100px;" 
									   [(ngModel)]="yearCount" 
									   (ngModelChange)="loadData()"
									   min="1" 
									   value="3">
							</div>

							<h4 class="card-title">Units This/Last</h4>
							<h5 *ngIf="thisoverlast">
								UNITS MTD/MTDL: {{ thisoverlast.mtd_units }} / {{ thisoverlast.mtdlast_units }}

								<span [ngClass]="thisoverlast.percent_updown_units > 0 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="thisoverlast.percent_updown_units > 0 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i></span>

								<span [ngClass]="thisoverlast.percent_updown_units < 0 ? 'text-danger': 'text-success'"> {{ thisoverlast.percent_updown_units | percent}}
								</span>

								<br /> SALES MTD/MTDL: {{ thisoverlast.mtd_dollars | currency }} / {{ thisoverlast.mtdlast_dollars | currency}}

								<span [ngClass]="thisoverlast.percent_updown_dollars > 0 ? 'text-success' : 'text-danger'"><i class="fa " [ngClass]="thisoverlast.percent_updown_dollars > 0 ? 'fa-long-arrow-up' : 'fa-long-arrow-down'"></i></span>

								<span [ngClass]="thisoverlast.percent_updown_dollars < 0 ? 'text-danger': 'text-success'"> {{ thisoverlast.percent_updown_dollars | percent}}
								</span>
							</h5>
							<p class="card-category"></p>
							<div class="ct-chart" *ngIf="!thisoverlast">
								<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
								</svg>
							</div>

							<div #print_table class="table mt-3 table-responsive" *ngIf="thisoverlast">
								<table class="w-100 table table-bordered " *ngFor="let year of thisoverlast.years;let i = index;">
									<tr>
										<ng-container *ngFor="let month of year.sales;let bf = index;">
											<th *ngIf="bf == 0" class="text-center yearpercent">
												{{ month.year }}
											</th>
											<th class="text-center yearpercent">
												{{ month.label }}
												<br>

												<ng-container *ngIf="(i != ( thisoverlast.years.length - 1 ))">
													<div class="row p-0 m-0">
														<div class="col-6 m-0 p-0 smaller text-left" [ngClass]="getMonthYearQty(month.label, year).class">

															<i class="smaller fa " [ngClass]="getMonthYearQty(month.label, year).icon"></i>
															Y:{{ getMonthYearQty(month.label, year).percent | percent }}
														</div>
														<div class="col-6 m-0 p-0 smaller text-right" [ngClass]="getMonthYearQty(month.label, year).percent_month_color">

															<i class="smaller fa " [ngClass]="getMonthYearQty(month.label, year).percent_month_icon"></i>
															M:{{ getMonthYearQty(month.label, year).percent_month | percent }}

														</div>
													</div>
												</ng-container>
											</th>
										</ng-container>
										<th class="text-center">Total</th>
									</tr>
									<tr>
										<td class="text-center">Qty</td>
										<td class="text-center yearpercent" *ngFor="let month of year.qty">
											<button mat-flat-button class="noprint" (click)="viewHistory( month.monthnumber, month.year)">{{ month.value | number:'1.0-0' }}</button>
											<span class="noshow">{{ month.value | number:'1.0-0' }}</span>
										</td>
										<td class="text-center">{{ getQtyTotalYear( year ) | number:'1.0-0'  }}
										</td>
									</tr>
									<tr>
										<td class="text-center">Sales </td>
										<td class="text-center yearpercent" *ngFor="let month of year.sales">
											{{ month.value | currency }}
										</td>
										<td class="text-center">{{ getSaleTotalYear( year ) | currency }}</td>
									</tr>
									<!-- <tr>
										<td class="text-center">Cost </td>
										<td class="text-center" *ngFor="let month of year.cost">
											{{ month.value | currency }}
										</td>
										<td class="text-center">{{ getCostTotalYear( year ) | currency }}</td>
									</tr> -->
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>