import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-bank-accounts',
	templateUrl: './bank-accounts.component.html',
	styleUrls: ['./bank-accounts.component.scss']
})
export class BankAccountsComponent implements OnInit {
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	accountForm: FormGroup;
	accounts: MatTableDataSource < any > = new MatTableDataSource([]);
	currencies: any = [];
	filteredAccounts: any = [];
	headerColumns: string[] = [
		'actions',
		'accountcode',
		'currcode',
		'bankaccountcode',
		'bankaccountname',
	];
	isFormVisible = false;
	editingAccount: any = null;
	color: string;
	searchTerm: string = '';

	constructor(
		private fb: FormBuilder,
		private globalsService: GlobalsService,
		private globalSearchService: GlobalSearchService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private snackBar: MatSnackBar
	) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.initializeForm();
		this.loadAccounts();
		this.loadCurrencies();
	}

	// Initialize the account form
	initializeForm(): void {
		this.accountForm = this.fb.group({
			accountcode: ['', Validators.required],
			bankaccountcode: ['', Validators.required],
			bankaccountname: ['', Validators.required],
			currcode: ['USD', Validators.required],
			invoice: [1],
			bankaccountnumber: ['', Validators.required],
			bankaddress: [''],
			importformat: [''],
			nextchecknum: [''],
		});
	}

	// Load accounts data
	loadAccounts(): void {
		this.editingAccount = null;
		this.globalsService.getBankAccounts().subscribe((accounts) => {
			this.accounts = new MatTableDataSource(accounts);
			this.accounts.sort = this.sort;
			this.accounts.paginator = this.paginator;
			this.filteredAccounts = accounts;
		});
	}

	// Load currencies data
	loadCurrencies(): void {
		this.globalsService.getCurrencies().subscribe((currencies) => {
			this.currencies = currencies;
		});
	}

	// Save or update an account
	saveAccount(): void {
		if (this.accountForm.valid) {
			const accountData = this.accountForm.value;
			if (this.editingAccount) {
				// Update existing account
				this.globalsService.saveBankAccount(accountData).subscribe(() => {
					this.snackBar.open('Account updated successfully.', 'Close', { duration: 3000 });
					this.loadAccounts();
					this.toggleForm();
				});
			} else {
				// Add new account
				this.globalsService.saveBankAccount(accountData).subscribe(() => {
					this.snackBar.open('Account added successfully.', 'Close', { duration: 3000 });
					this.loadAccounts();
					this.toggleForm();
				});
			}
		} else {
			this.snackBar.open('Please fill out all required fields.', 'Close', { duration: 3000 });
		}
	}

	// Edit an account
	editAccount(account: any): void {
		this.editingAccount = account;
		this.accountForm.patchValue(account);
		this.isFormVisible = true;
	}

	// Delete an account
	deleteAccount(account: any): void {
		if (!confirm('Are you sure you want to delete this account?')) return;
		this.globalsService.deleteBankAccount(account).subscribe(
			(result: any) => {
				if (result.success) {
					this.snackBar.open('Account deleted successfully.', 'Close', { duration: 3000 });
					this.loadAccounts();
				} else {
					this.snackBar.open('Error deleting account.', 'Close', { duration: 3000 });
				}
			},
			() => {
				this.snackBar.open('Error deleting account.', 'Close', { duration: 3000 });
			}
		);
	}

	// Filter accounts by search term
	applyFilter(event: any): void {
		this.searchTerm = event.target.value.toLowerCase();
		this.filteredAccounts = this.accounts.data.filter((account: any) =>
			account.bankaccountname.toLowerCase().includes(this.searchTerm)
		);
	}

	// Toggle form visibility
	toggleForm(): void {
		if (this.editingAccount) {
			this.cancelEdit();
		}
		this.isFormVisible = !this.isFormVisible;
		if (!this.isFormVisible) {
			this.accountForm.reset();
		}
	}

	// Cancel editing
	cancelEdit(): void {
		this.editingAccount = null;
		this.isFormVisible = false;
		this.accountForm.reset();
	}

	// Navigate back
	back(): void {
		this.location.back();
	}
}