import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { OrdersService } from 'app/services/orders.service';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { table } from 'console';
import { PurchasingService } from 'app/services/purchasing.service';
import { GlobalsService } from 'app/services/globals.service';

@Component({
	selector: 'app-warehouse-item-lookup',
	templateUrl: './warehouse-item-lookup.component.html',
	styleUrls: ['./warehouse-item-lookup.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(360deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		])
	]
})
export class WarehouseItemLookupComponent implements OnInit {
	itemOrders: any;
	trackingorder: any;
	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataSource = new MatTableDataSource < any > ;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;
	movementsLoading: any = false;
	itemMovements: any = false;

	bins: any = [{'bin' : 'RM1A1B1'}];
	@ViewChild('detailsModal') detailsModalEle: ElementRef;
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('searchRef') searchRefRef: ElementRef;
	@ViewChild('trackingModal') trackingModalRef: ElementRef;

	itemForm: UntypedFormGroup;
	itemlist: any = false;
	lookup = new UntypedFormControl('');
	item: any = false;
	ordertitle = '';
	orderdetails: any = [];
	searching: boolean;
	showlist: boolean;
	historyloading: boolean;
	showorder: boolean;
	lastsearch = '';
	noresults = false;
	searchsub: any = false;
	color: any = '';
	viewingitem: any = false;
	binDisplay: any = false;
	itemio: any = false;
	config: any = false;
	user: any = false;
	history: any = false;
	
	constructor(private _changeDetectorRef: ChangeDetectorRef,private location: Location,public inventoryService: InventoryService, private globalSearchService: GlobalSearchService, public ordersService: OrdersService, private modalService: NgbModal) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});
		this.color = this.globalSearchService.getColor();
	}

	focusSearchInput(): void {
		setTimeout(() => {
		  this.searchRefRef.nativeElement.focus();
		}, 100);
	  }

	ngOnInit(): void {
		this.focusSearchInput();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	openTrackingAssign(orderno: any){
		this.trackingorder = orderno;
		this.modalService.open(this.trackingModalRef, { ariaLabelledBy: 'modal-title', size: 'trackingsel' }).result.then((result) => { }, (reason) => {
			this.trackingorder = false;
		});
	}

	dismissTracking(){
		this.reloadData();
		document.getElementById('trackingexit').click();
	}

	lookupVersionEight() {
		this.searching = true;
		this.lastsearch = this.lookup.value;

		if (this.searchsub) {
			this.searchsub.unsubscribe();
		}

		if (this.lookup.value != '') {
			const data = { stockid: this.lookup.value }
			this.searchsub = this.inventoryService.itemSearch(data).subscribe((results: any) => {
				this.itemlist = results
				if (results.length == 1) {
					this.itemio = results;
					this.loadItem(results[0]);
				} else {
					this.viewingitem = false;
				}
				this.searching = false;
				this.lookup.setValue('');
			});
		} else {
			this.lastsearch = '';
			this.searching = false;
			this.showlist = false;
			this.noresults = false;
			this.itemlist = null;
		}
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	addNegativeBins() {
		const binadd = [];
		const total_negative = 0;
		let total_onhand = 0;
		for (const key in this.itemio.bins) {
			for (const vals in this.itemio.bins[key]) {

				const thisbinval = this.financial(this.itemio.bins[key][vals].quantity);
				total_onhand += thisbinval;
				const bindata = this.itemio.bins[key][vals]
				binadd.push(bindata)
				if (thisbinval < 0) {

				}
			}
		}

		const sorted = binadd.sort((n1, n2) => {
			if (n1.quantity > n2.quantity) {
				return -1;
			}

			if (n1.quantity < n2.quantity) {
				return 1;
			}
			return 0;
		});

		this.binDisplay = sorted;
	}

	loadItem(item: any) {
		this.itemOrders = [];
		this.itemio = false;
		this.inventoryService.loadItem(item.stockid).subscribe((result: any) => {

			this.viewingitem = true;
			this.item = result;
			this.itemOrders =  [...new Set(result['salesordersdetails'].map((i)=>{return i.orderno}))];
			this.itemlist = false;
			this.itemio = result;
			this.addNegativeBins();
			this.getMovements(item.stockid, this.user.user.defaultlocation.loccode);
			// this.focusSearchInput();
		});
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		})
	}

	getMovements(stockid, loccode){
		const afterdate = new Date();
		const todate = new Date(new Date().setDate(afterdate.getDate() - 365));

		const get_movement = {
			stockid : stockid,
			loccode: loccode,
			fromdate: todate.toLocaleDateString(),
			todate: afterdate.toLocaleDateString()
		};
		this.movementsLoading = this.inventoryService.getMovements(get_movement).subscribe(r => {
			this.setPagination(r);
			// Filter objects with type === '25' and assign to this.history
			this.history = r.filter((item: any) => item.type === '25');
		});
	}

	setPagination(tableData) {
		this.movementsLoading = false;

		if(tableData.length > 0){
			this.dataSource = new MatTableDataSource <any> (tableData);
			this._changeDetectorRef.detectChanges();
			this.dataSource.paginator = this.paginator;
			this.dataObs = this.dataSource.connect();
			this.itemMovements = true;
		}
	}

	reloadData() {
		console.log('reloading');
		if(this.itemio){
			this.loadItem(this.itemio.item)
		}
	}


	orderReservedPicks(orderno: any, stockid: any){
		this.ordersService.getOrderReservedItems(orderno, stockid).subscribe((results: any) => {
			return results;
		});

	}

	openSearch(){

	}


	checkOverflow (element: any) {
		return element.offsetHeight < element.scrollHeight || element.offsetWidth < element.scrollWidth;
	}


	closeSearch(){
		this.itemlist = false;
	}


	loadOrder(order) {
		this.ordersService.getOrder(order).subscribe((results: any) => {
			this.showorder = true;
			this.orderdetails = results;

			this.modalService.open(this.detailsModalEle, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {

			});

		});
	}

	financial(x) {
		if (Number.isNaN(x)) {
			x = 0
		}
		return parseFloat(Number.parseFloat(x).toFixed(2));
	}

	back(): void {
		this.location.back()
	}


}
