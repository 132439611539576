<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							GL Budgets Management
						</h4>
						<p class="card-category text-right">View and manage GL budgets</p>
					</div>
					<div class="card-body">
						<!-- Form for Selecting GL Account -->
						<form [formGroup]="glBudgetForm" (ngSubmit)="saveBudgets()" class="p-4 border rounded shadow-sm bg-light">
							<div class="row g-3 align-items-end">
								<!-- GL Account Selection -->
								<div class="col-md-8">
									<mat-form-field appearance="outline" class="w-100 mt-0 pt-0">
										<mat-label>Select General Ledger Account</mat-label>
										<mat-select formControlName="selectedAccount" (selectionChange)="onAccountChange($event)" required>
											<mat-option *ngFor="let account of accounts" [value]="account.accountcode">
												{{ account.accountcode }} - {{ account.accountname }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<!-- Save Button -->
								<div class="col-md-4 mt-0 pt-0 text-end">
									<button mat-raised-button color="accent" type="submit" class="w-100 mt-0 pt-0 " [disabled]="!glBudgetForm.get('selectedAccount')?.value">
										Save Budgets
									</button>
								</div>
							</div>

							<!-- Account Summary Display -->
							<div class="row mt-4" *ngIf="selectedAccountName">
								<div class="col-md-12">
									<h4><b>Selected Account: {{ selectedAccountName }}</b></h4>
								</div>
							</div>
						</form>


						<!-- Spinner -->
						<div class="col-md-12 text-center" *ngIf="isLoading">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>

						<!-- Budget Tables -->
						<div class="row mt-4" *ngIf="budgetData">
							<!-- Budget Table Section -->
							<div class="col-md-4" *ngFor="let section of ['lastYear', 'thisYear', 'nextYear']; let i = index">
								<div class="card shadow-sm">
									<div class="card-body p-0">
										<div class="card-header text-center">
											<h5 class="mb-0">
												{{ section === 'lastYear' ? 'Last Year' : section === 'thisYear' ? 'This Year' : 'Next Year' }}
											</h5>
											<small class="text-muted">
												{{ section === 'lastYear' ? 'Review past budgets' : section === 'thisYear' ? 'Manage current budgets' : 'Plan for the future' }}
											</small>
										</div>
										<div class="table-responsive">
											<table class="table table-striped table-hover table-bordered m-0">
												<thead class="thead-dark">
													<tr>
														<th class="text-center">Period</th>
														<th class="text-center">Actual</th>
														<th class="text-center">Budget</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let item of budgetData[section]">
														<td class="text-center">{{ item.period }}</td>
														<td class="text-center" [class.text-danger]="item.actual < 0" [class.text-success]="item.actual >= 0">
															{{ item.actual | currency }}
														</td>
														<td>
															<input type="number" class="form-control form-control-sm text-right" [(ngModel)]="item.budget" (ngModelChange)="onBudgetChange(section, item)" placeholder="Enter budget" />
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>