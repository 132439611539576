<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<!-- Card Header -->
					<div class="card-header card-header-{{ color }}">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							Bank Accounts
						</h4>
					</div>

					<!-- Card Body -->
					<div class="card-body">
						<!-- Add New Account Button -->
						<div class="text-end mb-3">
							<button mat-raised-button color="accent" (click)="toggleForm()">
								{{ isFormVisible ? 'Hide Form' : 'Add New Account' }}
							</button>
						</div>
						<div class="row mb-3">
							<div class="col-md-12">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Search Accounts</mat-label>
									<input matInput (keyup)="applyFilter($event)" placeholder="Search by Account Name" />
									<mat-icon matSuffix>search</mat-icon>
								</mat-form-field>
							</div>
						</div>
						<!-- Table -->
						<div class="table-responsive mt-4">
							<table class="table table-striped table-hover table-bordered">
								<thead class="thead-dark">
									<tr>
										<th class="text-center">Account Code</th>
										<th class="text-center">Currency</th>
										<th class="text-center">Bank Code</th>
										<th class="text-center">Account Name</th>
										<th class="text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									<!-- Inline Add Form -->
									<tr *ngIf="isFormVisible && !editingAccount">
										<td colspan="5">
											<form [formGroup]="accountForm" (ngSubmit)="saveAccount()" class="p-4 border rounded bg-light">
												<div class="row g-4">
													<div class="col-md-4">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Account Code</mat-label>
															<input matInput formControlName="accountcode" placeholder="Account Code" required />
															<mat-error *ngIf="accountForm.get('accountcode')?.hasError('required')">
																Account Code is required.
															</mat-error>
														</mat-form-field>
													</div>
													<div class="col-md-4">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Bank Code</mat-label>
															<input matInput formControlName="bankaccountcode" placeholder="Bank Code" required />
															<mat-error *ngIf="accountForm.get('bankaccountcode')?.hasError('required')">
																Bank Code is required.
															</mat-error>
														</mat-form-field>
													</div>
													<div class="col-md-4">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Currency</mat-label>
															<mat-select formControlName="currcode">
																<mat-option *ngFor="let currency of currencies" [value]="currency.currabrev">
																	{{ currency.currency }}
																</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
													<div class="col-md-6">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Account Name</mat-label>
															<input matInput formControlName="bankaccountname" placeholder="Account Name" required />
															<mat-error *ngIf="accountForm.get('bankaccountname')?.hasError('required')">
																Account Name is required.
															</mat-error>
														</mat-form-field>
													</div>
													<div class="col-md-6">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Account Number</mat-label>
															<input matInput formControlName="bankaccountnumber" placeholder="Account Number" required />
															<mat-error *ngIf="accountForm.get('bankaccountnumber')?.hasError('required')">
																Account Number is required.
															</mat-error>
														</mat-form-field>
													</div>
												</div>
												<div class="text-end mt-3">
													<button mat-raised-button color="accent" type="submit" [disabled]="accountForm.invalid">
														Add Account
													</button>
													<button mat-raised-button color="warn" type="button" (click)="cancelEdit()">
														Cancel
													</button>
												</div>
											</form>
										</td>
									</tr>

									<!-- Bank Accounts Data -->
									<ng-container *ngFor="let account of filteredAccounts">
										<tr *ngIf="editingAccount?.accountcode !== account.accountcode">
											<td class="text-center">{{ account.accountcode }}</td>
											<td class="text-center">{{ account.currcode }}</td>
											<td class="text-center">{{ account.bankaccountcode }}</td>
											<td class="text-center">{{ account.bankaccountname }}</td>
											<td class="text-center">
												<button mat-icon-button color="primary" (click)="editAccount(account)" matTooltip="Edit">
													<mat-icon>edit</mat-icon>
												</button>
												<button mat-icon-button color="warn" (click)="deleteAccount(account)" matTooltip="Delete">
													<mat-icon>delete</mat-icon>
												</button>
											</td>
										</tr>

										<!-- Inline Edit Form -->
										<tr *ngIf="editingAccount?.accountcode === account.accountcode">
											<td colspan="5">
												<form [formGroup]="accountForm" (ngSubmit)="saveAccount()" class="p-4 border rounded bg-light">
													<div class="row g-4">
														<div class="col-md-4">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Account Code</mat-label>
																<input matInput formControlName="accountcode" placeholder="Account Code" required />
																<mat-error *ngIf="accountForm.get('accountcode')?.hasError('required')">
																	Account Code is required.
																</mat-error>
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Bank Code</mat-label>
																<input matInput formControlName="bankaccountcode" placeholder="Bank Code" required />
																<mat-error *ngIf="accountForm.get('bankaccountcode')?.hasError('required')">
																	Bank Code is required.
																</mat-error>
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Currency</mat-label>
																<mat-select formControlName="currcode">
																	<mat-option *ngFor="let currency of currencies" [value]="currency.currabrev">
																		{{ currency.currency }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Account Name</mat-label>
																<input matInput formControlName="bankaccountname" placeholder="Account Name" required />
																<mat-error *ngIf="accountForm.get('bankaccountname')?.hasError('required')">
																	Account Name is required.
																</mat-error>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Account Number</mat-label>
																<input matInput formControlName="bankaccountnumber" placeholder="Account Number" required />
																<mat-error *ngIf="accountForm.get('bankaccountnumber')?.hasError('required')">
																	Account Number is required.
																</mat-error>
															</mat-form-field>
														</div>
													</div>
													<div class="text-end mt-3">
														<button mat-raised-button color="accent" type="submit" [disabled]="accountForm.invalid">
															Update Account
														</button>
														<button mat-raised-button color="warn" type="button" (click)="cancelEdit()">
															Cancel
														</button>
													</div>
												</form>
											</td>
										</tr>
									</ng-container>

									<!-- No Data Message -->
									<tr *ngIf="filteredAccounts.length === 0">
										<td colspan="5" class="text-center">No accounts found.</td>
									</tr>
								</tbody>
							</table>
						</div>

						<!-- Paginator -->
						<mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons aria-label="Select page">
						</mat-paginator>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
