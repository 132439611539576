<div class="text-end">
  <button mat-raised-button color="accent" class="mt-3 mb-3" (click)="isFiltersVisible = !isFiltersVisible">
	{{ isFiltersVisible ? 'Hide Filters' : 'Show Filters' }}
  </button>

  <button mat-raised-button color="primary" (click)="calculateDepreciation()" [disabled]="isCalculating">
	  {{ isCalculating ? 'Calculating...' : 'Calculate Depreciation' }}
	</button>
	<button mat-raised-button color="accent" (click)="commitDepreciation()" [disabled]="isCommitting || depreciationData.length === 0">
	  {{ isCommitting ? 'Committing...' : 'Commit Depreciation' }}
	</button>

</div>



<!-- Table with Depreciation Details -->
<div class="table-responsive">
  <table class="table table-striped table-hover table-bordered">
	<thead class="thead-dark">
	  <tr>
		<th>Asset ID</th>
		<th>Description</th>
		<th>Category</th>
		<th>Location</th>
		<th>Book Value B/Fwd</th>
		<th>Depreciation</th>
		<th>Book Value C/Fwd</th>
		<th>Actions</th>
	  </tr>
	</thead>
	<tbody>
	  <tr *ngFor="let asset of depreciationData">
		<td>{{ asset.assetId }}</td>
		<td>{{ asset.description }}</td>
		<td>{{ asset.category }}</td>
		<td>{{ asset.location }}</td>
		<td>{{ asset.bookValueBfwd | currency }}</td>
		<td>{{ asset.depreciation | currency }}</td>
		<td>{{ asset.bookValueCfwd | currency }}</td>
		<td>
		  <button mat-icon-button color="warn" (click)="excludeAsset(asset.assetId)" matTooltip="Exclude from Depreciation">
			<mat-icon>remove_circle</mat-icon>
		  </button>
		</td>
	  </tr>
	</tbody>
  </table>
</div>

<!-- Filters Section -->
<div *ngIf="isFiltersVisible" [@expandCollapse] class="form-container p-4 border rounded shadow-sm bg-light">
  <form [formGroup]="registerFiltersForm" (ngSubmit)="loadFixedAssets()">
	<div class="row g-4">
	  <!-- Section Title -->
	  <div class="col-12">
		<h4 class="text-primary mb-3">Filter Fixed Assets</h4>
	  </div>

	  <!-- From Date -->
	  <div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
		  <mat-label>From Date</mat-label>
		  <input matInput [matDatepicker]="fromDate" formControlName="fromDate" />
		  <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
		  <mat-datepicker #fromDate></mat-datepicker>
		</mat-form-field>
	  </div>

	  <!-- To Date -->
	  <div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
		  <mat-label>To Date</mat-label>
		  <input matInput [matDatepicker]="toDate" formControlName="toDate" />
		  <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
		  <mat-datepicker #toDate></mat-datepicker>
		</mat-form-field>
	  </div>

	  <!-- Depreciation Status -->
	  <div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
		  <mat-label>Depreciation Status</mat-label>
		  <mat-select formControlName="depreciationStatus">
			<mat-option value="all">All</mat-option>
			<mat-option value="active">Active</mat-option>
			<mat-option value="fully-depreciated">Fully Depreciated</mat-option>
		  </mat-select>
		</mat-form-field>
	  </div>

	  <!-- Maintenance Status -->
	  <div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
		  <mat-label>Maintenance Status</mat-label>
		  <mat-select formControlName="maintenanceStatus">
			<mat-option value="all">All</mat-option>
			<mat-option value="pending">Pending</mat-option>
			<mat-option value="completed">Completed</mat-option>
		  </mat-select>
		</mat-form-field>
	  </div>

	  <!-- Submit Button -->
	  <div class="col-12 text-end">
		<button mat-raised-button color="primary" type="submit">
		  Apply Filters
		</button>
	  </div>
	</div>
  </form>
</div>

<!-- Fixed Assets Table -->
<div class="table-responsive mt-4">
  <table class="table table-striped table-hover table-bordered">
	<thead class="thead-dark">
	  <tr>
		<th>Asset ID</th>
		<th>Description</th>
		<th>Category</th>
		<th>Location</th>
		<th>Cost B/Fwd</th>
		<th>Additions</th>
		<th>Depreciation B/Fwd</th>
		<th>Depreciation</th>
		<th>Net Book Value</th>
		<th>Maintenance Status</th>
		<th>Actions</th>
	  </tr>
	</thead>
	<tbody>
	  <tr *ngFor="let asset of fixedAssets" [class.selected]="editingAsset?.assetid === asset.assetid">
		<td>{{ asset.assetId }}</td>
		<td>{{ asset.description }}</td>
		<td>{{ asset.categorydescription }}</td>
		<td>{{ asset.location }}</td>
		<td>{{ asset.costBfwd | currency }}</td>
		<td>{{ asset.additions | currency }}</td>
		<td>{{ asset.depnBfwd | currency }}</td>
		<td>{{ asset.depreciation | currency }}</td>
		<td>{{ asset.nbv | currency }}</td>
		<td>
		  <span *ngIf="asset.maintenanceStatus === 'completed'" class="text-success">Completed</span>
		  <span *ngIf="asset.maintenanceStatus === 'pending'" class="text-warning">Pending</span>
		</td>
		<td>
		  <button mat-icon-button color="primary" (click)="editAsset(asset)" matTooltip="Edit">
			<mat-icon>edit</mat-icon>
		  </button>
		  <button mat-icon-button color="warn" (click)="deleteFixedAsset(asset.assetId)" matTooltip="Delete">
			<mat-icon>delete</mat-icon>
		  </button>
		</td>
	  </tr>
	</tbody>
	<tfoot>
	  <tr>
		<td colspan="4" class="text-end font-weight-bold">Totals:</td>
		<td>{{ getTotalCostBfwd() | currency }}</td>
		<td></td>
		<td>{{ getTotalDepnBfwd() | currency }}</td>
		<td></td>
		<td>{{ getTotalNbv() | currency }}</td>
		<td colspan="2"></td>
	  </tr>
	</tfoot>
  </table>
</div>
