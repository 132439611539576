import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';


@Component({
	selector: 'app-gl-budgets',
	templateUrl: './gl-budgets.component.html',
	styleUrls: ['./gl-budgets.component.scss']
})
export class GlBudgetsComponent implements OnInit {
	glBudgetForm: FormGroup;
	accounts: any = [];
	budgetData: any = [];
	isLoading = false;
	color = 'blue';
	selectedAccountName: any = null;

	constructor(
		private fb: FormBuilder,
		private glService: GeneralLedgerService,
		private snackBar: MatSnackBar,
		private globalSearchService: GlobalSearchService,
	) {
		this.color = this.globalSearchService.getColor();
		this.glBudgetForm = this.fb.group({
			selectedAccount: ['']
		});
	}

	ngOnInit(): void {
		this.glBudgetForm.get('selectedAccount')?.valueChanges.subscribe((selectedAccountCode) => {
			const selectedAccount = this.accounts.find(acc => acc.accountcode === selectedAccountCode);
			this.selectedAccountName = selectedAccount ? `${selectedAccount.accountname} (${selectedAccount.accountcode})` : null;
		});

		this.fetchAccounts();
	}

	// Fetch GL Accounts using GeneralLedgerService
	fetchAccounts(): void {
		this.isLoading = true;

		let payload = {
			action: 'fetchAccounts'
		}

		this.glService.getAccounts(payload).subscribe(
			(response: any) => {
				this.accounts = response.accounts || [];
				this.isLoading = false;
			},
			(error) => {
				console.error('Error fetching accounts', error);
				this.snackBar.open('Failed to load accounts.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	// Handle account change and fetch budgets for the selected account
	onAccountChange(event: any): void {
		const selectedAccount = event.value;
		if (selectedAccount) {
			this.fetchBudgets(selectedAccount);
		}
	}

	// Fetch budgets for the selected account using GeneralLedgerService
	fetchBudgets(accountCode: string): void {
		this.isLoading = true;
		const data = { selectedAccount: accountCode, action: 'fetchBudgets' };
		this.glService.getBudgets(data).subscribe(
			(response: any) => {
				this.budgetData = response || [];
				this.isLoading = false;
			},
			(error) => {
				console.error('Error fetching budgets', error);
				this.snackBar.open('Failed to load budgets.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}
	updateBudget() {

	}
	// Save updated budgets using GeneralLedgerService
	saveBudgets(): void {
		const selectedAccount = this.glBudgetForm.get('selectedAccount')?.value;
		if (!selectedAccount) {
			this.snackBar.open('Please select an account first.', 'Close', { duration: 3000 });
			return;
		}

		this.isLoading = true;

		// Combine data from lastYear, thisYear, and nextYear for saving
		const budgets = [
			...this.budgetData.lastYear.map((item) => ({
				period: item.periodNumber, // Use period number for saving
				budget: item.budget,
			})),
			...this.budgetData.thisYear.map((item) => ({
				period: item.periodNumber, // Use period number for saving
				budget: item.budget,
			})),
			...this.budgetData.nextYear.map((item) => ({
				period: item.periodNumber, // Use period number for saving
				budget: item.budget,
			})),
		];

		const data = {
			selectedAccount,
			budgets,
		};

		this.glService.updateBudgets(data).subscribe(
			() => {
				this.snackBar.open('Budgets updated successfully.', 'Close', { duration: 3000 });
				this.isLoading = false;
			},
			(error) => {
				console.error('Error updating budgets', error);
				this.snackBar.open('Failed to update budgets.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	// Go back to the previous screen
	back(): void {
		window.history.back();
	}
}