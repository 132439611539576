<ng-template #mmmodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ promo.itemdesc.stockid }} <span>{{promo.itemdesc.description }}</span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-md-9">
				<div class="promo-item-description">
					<p class="stock-id">{{ promo.itemdesc.stockid }}</p>
					<p class="description">{{ promo.itemdesc.description }}</p>
				</div>
				<ul class="list-group">
					<ng-container *ngFor="let intro of promo.intros">
						<ng-container *ngIf="intro.type === '1' || intro.type === 1">
							<li class="list-group-item item-details-above">
								<div class="item-description">
									<p>{{ intro.stockid }} - {{ intro.description }} ({{ intro.qty }} @ {{ intro.price | currency }})</p>
								</div>
							</li>
						</ng-container>
					</ng-container>
				</ul>
			</div>
			<div class="col-md-3 text-right">
				<mat-form-field appearance="outline">
					<mat-label>Qty</mat-label>
					<span matPrefix>#</span>
					<input matInput type="number" [(ngModel)]="promo.qty" (input)="setParent($event, promo)" class="text-right">
				</mat-form-field>
			</div>
		</div>

		<!-- {{ promo.descriptions | json }} <h4>PROMO {{ promo.description }} {{ ispromo.promo.price | currency }} </h4> -->
		<div class="promo-container" *ngIf="promo">
			<h5>Promo Items</h5>
			<div class="row m-0 p-0">
				<div class="col-12 sticky-col">
					<button class="btn btn-primary float-right" [disabled] = "isCompleted(promo)"  (click)="addMM(promo)">Add To Order </button>
				</div>
				<div class="col-12">
					<mat-form-field appearance="outline">
						<mat-label>Scan to select</mat-label>
						<input matInput placeholder="Scan to select item..." value="" (keyup.enter)="searchPromos($event)">
					  </mat-form-field>
				</div>
				<ng-container *ngFor="let intro of promo.intros">
					<div class="col-md-{{ 12  / getNumberOfNonType1Intros(promo.intros) }}" *ngIf="intro.type != '1'">
						<div class="promo-intro">
							<h4>{{ intro.name }} {{ intro.description }}</h4>
							<ng-container *ngIf="intro.type === '1'; else typeDefault">
								<div class="intro-details">
									<div class="quantity-info">
										{{ intro.item }} - {{ intro.description }} ({{ intro.qty }} @ {{ intro.price | currency }})
									</div>
								</div>
							</ng-container>
							<ng-template #typeDefault>
								<div class="intro-details" [ngClass]="{'error': intro.error}">
									<div class="quantity-info">
										{{ intro.price }} <span [ngClass]="{'zero-quantity': (updateMMQty(intro) == 0) }">{{ intro.dsptype }} {{ updateMMQty(intro) }} / {{ intro.floor }}</span> {{ intro.price }}
									</div>
								</div>
							</ng-template>
						</div>

						<div class="mt-2 mb-2">
							<mat-form-field appearance="outline">
								<mat-label>Search Mix & Match...</mat-label>
								<input tabindex="1" matInput value="" [(ngModel)]="intro.searchQuery" (input)="filterItems(intro)" appCollapseKeyboard>
								<span matSuffix>
									<button tabindex="-1" mat-icon-button color="white">
										<mat-icon (click)="filterItems(intro)">search</mat-icon>
									</button>
								</span>
							</mat-form-field>
						</div>


						<ng-container *ngFor="let item of (intro.filteredItems ? intro.filteredItems : intro.items)">
							<li class="list-group-item item-details">
								<div class="item-details-row">
									<div class="item-description">
										<p>{{ item.stockid }}</p>
										<p>{{ item.description }}</p>
									</div>
									<div class="item-quantity">
										<button mat-icon-button (click)="decrementmm(item, intro)">
											<mat-icon>remove</mat-icon>
										</button>
										<input matInput type="text" class="quantity-input" value="{{ item.quantity | number }}" (input)="qtyMM($event, item, intro)">
										<button mat-icon-button (click)="incrementmm(item, intro)">
											<mat-icon>add</mat-icon>
										</button>
									</div>
								</div>
							</li>
						</ng-container>
					</div>
				</ng-container>
			</div>
		</div>
	
	</div>
</ng-template>
<ng-template #edumodal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">{{ event.name }} Registration - {{ event.location_name }} - {{event.date}}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="col-12">
				{{ event.short_description }}
			</div>
		</div>
		<br>
		<div class="row">
			<div class="col-3">
				<b>Host</b>
			</div>
			<div class="col-3">
				{{ event.host }}
			</div>
			<div class="col-3">
				<b>Presenter</b>
			</div>
			<div class="col-3">
				{{ event.artist_name }}
			</div>
		</div>
		<hr>
		<div class="row">
			<div class="col-3">
				<b>Cost Per Seat</b>
			</div>
			<div class="col-3">
				{{ event.discount | currency }}
			</div>
			<div class="col-3">
				<b>Capacity Remaining</b>
			</div>
			<div class="col-3">
				{{ event.remaining }} / {{ event.uses_total }}
			</div>
		</div>
		<hr>
		<form [formGroup]="regForm">
			<div class="row">

				<div class="col-2">
					<mat-form-field>
						<mat-label>Name</mat-label>
						<input matInput type="text" formControlName="eduname">
					</mat-form-field>
				</div>
				<div class="col-2">
					<mat-form-field>
						<mat-label>Cell Phone</mat-label>
						<input matInput type="text" formControlName="educell">
					</mat-form-field>
				</div>
				<div class="col-2">
					<mat-form-field>
						<mat-label>Email</mat-label>
						<input matInput type="text" formControlName="eduemail">
					</mat-form-field>
				</div>
				<div class="col-2">
					<mat-form-field>
						<mat-label>Instagram</mat-label>
						<input matInput type="text" formControlName="eduinsta">
					</mat-form-field>
				</div>
				<div class="col-2">
					<button type="button" mat-stroked-button aria-label="Add" (click)="addSeat(event)">
						<mat-icon>add</mat-icon> Add Seat
					</button>
				</div>

			</div>
		</form>
		<hr>
		<div class="row" *ngFor="let seat of event.seats">
			<div class="col-2">
				{{ seat.name }}
			</div>
			<div class="col-2">
				{{ seat.cell }}
			</div>
			<div class="col-2">
				{{ seat.email }}
			</div>
			<div class="col-2">
				{{ seat.insta }}
			</div>
			<div class="col-2">
				<button type="button" mat-stroked-button aria-label="Add" (click)="remSeat(seat)">
					<mat-icon>remove</mat-icon>
				</button>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<h4 class="modal-title">Total: {{ event.total | currency}}</h4>
		<button type="button" mat-stroked-button aria-label="Add" (click)="addedureg(event)">
			<mat-icon>add</mat-icon> Add To Order
		</button>
	</div>
</ng-template>
<ng-template #itemHistoryRef let-modal>
	<div class="modal-header  m-0 p-0">
		<h4 class="modal-title">{{ selecteditem.stockid }} <span [innerHTML]="selecteditem.description"></span></h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body m-0 p-0 text-dark">
		<mat-tab-group>
			<mat-tab label="{{ customer.name }} Sales">
				<div class="row mt-3">
					<div class="col-12">

						<div class="row" *ngIf="!itemhistory.length">
							<div class="col-12 text-center">
								<h5>No Sales History</h5>
							</div>
						</div>
						<table class="table table-sm smaller-font" *ngIf="itemhistory.length">
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Order#</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Total</th>
							</tr>
							<tr *ngFor="let i of itemhistory">
								<td>{{ i.trandate }}</td>
								<td>{{ i.typename }}</td>
								<td>{{ i.order_ }}</td>
								<td>{{ i.quantity }}</td>
								<td>{{ i.price  | currency}}</td>
								<td>{{ i.price * i.quantity | currency }}</td>
							</tr>
						</table>
					</div>
				</div>
			</mat-tab>
			<mat-tab label="Purchases">
				<!-- (orderloaded)="selectItemLookup($event)" (orderinvoice)="invoiceOrder($event)" -->
				<h4>Open Purchase Orders</h4>
				<app-open-purchase-orders [item]="selecteditem.stockid"></app-open-purchase-orders>
				<mat-divider></mat-divider>
				<h4>Purchase History</h4>
				<table class="table table-sm" *ngIf="itemhistory.purchase">
					<thead>
						<tr>
							<th class="text-left w-20">SUPP</th>
							<th class="text-left w-20">PO</th>
							<th class="text-left w-20">DATE</th>
							<th class="text-center w-20">QTY</th>
							<th class="text-right w-20">COST</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let h of itemhistory.purchase">
							<td class="text-left"> {{h.suppname}}</td>
							<td class="text-left"> {{h.orderno}}</td>
							<td class="text-left"> {{h.orddate}}</td>
							<td class="text-center"> {{h.quantityord}}</td>
							<td class="text-right"> {{h.unitprice | currency}}</td>
						</tr>
					</tbody>
				</table>
			</mat-tab>
			<mat-tab label="Purchase Data">
				<div class="row m-0 p-0 mt-3">
					<ng-container *ngFor="let pdata of selecteditem.purchdata">
						<mat-card class="ml-auto mr-auto col-md-4 cpdata" [ngClass]="selecteditem.selected_po_option == pdata ? 'cpdata-selected': '' " (click)="setItemSelectedPoOption(selecteditem,pdata)">
							<mat-card-header>
								<mat-card-subtitle><span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="small" matBadgeColor="accent"></span></mat-card-subtitle>
								<mat-card-title>{{ pdata.supplierid }} - {{ pdata.suppname }} </mat-card-title>
								<mat-card-title> {{ pdata.price | currency }}</mat-card-title>
							</mat-card-header>
							<mat-card-content>
								<h4>Inventory</h4>
								<ng-container *ngIf="pdata.remote">
									<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
										<li>
											<div class="spec-label">{{ remote.feedlabel }}:</div>
											<div class="spec-value">{{ remote.qty }}</div>
										</li>
									</ul>
								</ng-container>
								<ng-container *ngIf="!pdata.remote">
									<ul class="entry-list p-0 m-0">
										<li>
											<div class="spec-label">N/A:</div>
											<div class="spec-value">N/A</div>
										</li>
									</ul>
								</ng-container>
							</mat-card-content>

						</mat-card>
					</ng-container>
				</div>
				<app-purchase-data [item]="selecteditem" (newitemdata)="updateItem($event)"></app-purchase-data>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #purchasedetails let-modal (click)="stopProp($event)">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Purchase Information</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row" *ngFor="let item of purchase_items">
			<div class="col-12">
				<app-purchase-data [item]="item" (newitemdata)="updateItem($event)"></app-purchase-data>
			</div>
			<div [ngClass]="item.purchdata.length == 1 ? 'col-md-12': 'col-md-6'" *ngFor="let pdata of item.purchdata">
				<div class="card highlight" (click)="setPurchOrderOptions(item.cart_id, pdata)">
					<span matBadge="{{ pdata.preferred_sort }}" matBadgeSize="large" matBadgeColor="accent"></span>
					<div class="card-header text-center">
						<h4 class="card-title text-center">{{ pdata.supplierid }} - {{ pdata.suppname }} {{ pdata.price | currency }}</h4>
					</div>
					<div class="card-body text-center">
						<div class="col-12">
							<h5>{{ pdata.stockid }} {{ pdata.supplierdescription }}</h5>
						</div>
						<div class="col-12 p-0 m-0">
							<ul class="entry-list p-0 m-0" *ngFor="let remote of pdata.remote">
								<li>
									<div class="spec-label">{{ remote.feedlabel }}:</div>
									<div class="spec-value">{{ remote.qty }}</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #itemdetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title"><b>{{ item_details.stockid }}</b><span [innerHTML]="item_details.name"></span> &nbsp;&nbsp;&nbsp;
			<a [routerLink]="'/inventory/view/'+item_details.stockid"><i class="pointer fa fa-edit"></i></a>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row">
			<mat-card class="tire-card " *ngIf="item_details">
				<mat-card-title-group>
					<mat-card-title>
						<h3 [innerHTML]="item_details.descriptions.name"></h3>
						<section class="section-small" [innerHTML]="item_details.descriptions.description"></section>
					</mat-card-title>
					<div class="tire-image">
						<div class="tire-image-container" *ngIf="item_details.image_large">
							<img mat-card-lg-image src="{{ item_details.image_large }}" class="img img-responsive large-thumb" lazy>
						</div>
					</div>
				</mat-card-title-group>
				<mat-card-content class="padding-0">
					<mat-tab-group #tabPanel mat-align-tabs="start" color="accent">
						<mat-tab label="Overview">
							<div class="row">
								<div class="col-md-6">
									Features:<br>
									<span [innerHTML]="item_details.descriptions.features"></span>
								</div>
								<div class="col-md-6">
									Benefits:<br>
									<span [innerHTML]="item_details.descriptions.benefits"></span>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Attributes">
							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.additional; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
						<mat-tab label="Specs">
							<!-- <mat-form-field appearance="outline" *ngIf="!addingattribute" class="text-right">
								<mat-label>Search Attributes</mat-label>
								<input type="text" class="text-right m-0 p-0" matInput (input)="searchAttributes($event.target.value)" />
							</mat-form-field> -->

							<div class="row">
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="!(i % 2)">
											<div class="spec-label"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
								<div class="col-6">
									<ul class="spec-list" *ngFor="let a of item_details.specs; let i = index">
										<li *ngIf="i % 2">
											<div class="spec-label ml-1"> {{ a.label }}</div>
											<div class="spec-value"> {{ a.value }}</div>
										</li>
									</ul>
								</div>
							</div>
						</mat-tab>
					</mat-tab-group>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<ng-container *ngIf="itemSearchForm">
	<form [formGroup]="itemSearchForm" autocomplete="off"  class="no-overflow">
		<div class="row form-inputs m-0 ml-auto mr-0">

			<div class="col-md-2 mt-3" *ngIf="type == '11'">
				<mat-slide-toggle [formControl]="search_customer_items">Customer Purchases Only </mat-slide-toggle>
			</div>
			<div class="col-md-4" *ngIf="type == '21'">
				<mat-slide-toggle [formControl]="search_vendor_items">Vendor Items Only </mat-slide-toggle>
			</div>
			<div class="col-md-12">
				<mat-form-field appearance="outline">
					<mat-label>Search For Items</mat-label>
					<input tabindex="1" matInput value="" id="searchkeywords" #searchkeywords formControlName="keywords" class="text-right" autofocus (keydown.enter)="$event.preventDefault();itemSearch()" (keydown.tab)="focusQty()" (keydown.plus)="$event.preventDefault();" (keydown.minus)="$event.preventDefault();" appSelectOnFocus appCollapseKeyboard>
					<span matSuffix>
						<button tabindex="-1" mat-icon-button color="white">
							<mat-icon (click)="itemSearch()">search</mat-icon>
						</button>
					</span>
					<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
				</mat-form-field>
			</div>
		</div>
	</form>
</ng-container>
<div class="col-12 text-center" *ngIf="showGuide">
	<app-tire-guide [input]="filtered_items" (output)="runFunction($event)"></app-tire-guide>
</div>
<div class="text-right" *ngIf="filtered_items.length">
	RESULTS: ({{ filtered_items.length }})
</div>
<ng-template #itemPosResults class="m-0 p-0">
	<div class="container-fluid m-0 p-0 w-10" *ngIf="filtered_items && filtered_items.length">
		<div class="row m-0 p-0">
			<div class="col-md-10 ml-0 pl-0 ">
				<h4 class="ml-0"><b>{{ itemSearchForm.get('keywords').value | uppercase }}</b> SEARCH RESULTS </h4>
			</div>
			<div class="col-md-2 text-right mr-0 pr-0">
				<button mat-raised-button [color]="'accent'" (click)="closeBottom()" class="mt-3 mb-2">Close</button>
			</div>
		</div>
		<div class="table table-fixed w-100">
			<table class="table table-striped table-sm table-fixed">
				<thead class="zi-1 flush-header">
					<tr>
						<th class="text-left item-th">Item# | Brand</th>
						<th class="text-left item-th">Description</th>
						<th class="text-right item-th">Price</th>
						<th class="text-right item-th">Qty</th>
						<th class="text-right item-th">Total</th>
						<th class="text-right item-th"></th>
					</tr>
				</thead>
				<tbody *ngIf="filtered_items.length">
					<tr *ngFor="let item of filtered_items; let i = index;">
						<td class="text-left"><span class="boldid">{{ item.stockid }}</span> | <span class="lightgreyrand">{{item.brand}}</span>
							<hr class="m0">
							<span class="lightgreyrand"><b>{{ item.mfginfo?.name }}</b></span>
						</td>
						<td class="text-left"> <span>{{ item.description }}</span></td>
						<td class="text-right"><input (input)="updateSearchPrice($event, item)" value="{{ item.price }}" tabindex="-1" class="header-input-discount text-right" placeholder="0" appNumericField> </td>
						<td>
							<div class="row">
								<div class="col-2 mt-3 ml-auto mr-0 p-0 shrink-2">
									<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="decrement(item)">
										<small><i class="material-icons">remove</i></small>
									</button>
								</div>
								<div class="col-6 m-0 mw100 p-0">
									<mat-form-field appearance="fill" color="white" #inputElement>
										<mat-label>Qty</mat-label>
										<span matPrefix>#</span>
										<ng-container *ngIf="i === 0">
											<input matInput #firstItem tabindex="{{ i + 2 }}" type="text" (keydown.tab)="handleTabKey($event, i)"  value="{{ item.quantity | number }}"  (input)="updateSearchQuantity($event, item )" (keydown.enter)="addToOrder(item); handleEnterKey($event, i);"
											(focus)="selectText($event)" class="text-right">
										</ng-container>
										<ng-container *ngIf="i !== 0">
											<input matInput (keydown.tab)="handleTabKey($event, i)"  tabindex="{{ i + 2 }}" type="text" value="{{ item.quantity | number }}" (focus)="selectText($event)" (keydown.enter)="addToOrder(item); handleEnterKey($event, i);"
											(input)="updateSearchQuantity($event, item )" class="text-right">
										</ng-container>

									</mat-form-field>
								</div>
								<div class="col-2 mt-3 ml-0 mr-0 p-0 mr-auto shrink-2">
									<ng-container *ngIf="!item.ispromo">
										<button tabindex="-1" mat-stroked-button class="btn btn-just-icon btn-white btn-fab btn-round btn-sm mr-md-2" (click)="increment(item)">
											<small>
												<i class="material-icons">add</i></small>
										</button>

									</ng-container>
								</div>
							</div>
						</td>
						<td class="text-right">{{ getLineTotal(item) | currency }} </td>
						<td class="text-right">

							<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab mr-md-2" (click)="addToOrder(item)" *ngIf="!item.ispromo && !item.isevent">
								<small><i class="material-icons">add_box</i></small>
							</button>
							<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button" (click)="triggerEdu(item)" *ngIf="item.isevent">
									<small><i class="material-icons">settings</i></small>
							</button>
							<ng-container *ngIf="item.ispromo">
								<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="triggerMm(item)" *ngIf="item.ispromo.requiresconfig">
									<small><i class="material-icons">settings</i></small>
								</button>
								<button tabindex="-1" mat-stroked-button (color)="'accent'" class="btn btn-just-icon btn-success btn-fab btn-round btn-sm mr-md-2 linetotal_button " (click)="addPromo(item)" *ngIf="!item.ispromo.requiresconfig">
									<small><i class="material-icons">add_box</i></small>
								</button>
							</ng-container>

						</td>
					</tr>
				</tbody>
				<tbody *ngIf="!filtered_items.length">
					<td class="text-center" colspan="5"> NO RESULTS</td>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>
