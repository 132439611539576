<div class="text-end">
	<button mat-raised-button class="mt-2 mb-2" color="accent" (click)="isFormVisible = !isFormVisible; resetForm()">
		{{ isFormVisible ? 'Hide Form' : 'Add New Category' }}
	</button>
</div>

<!-- Form for Adding/Editing Categories -->
<div *ngIf="isFormVisible" [@expandCollapse] class="form-container p-4 border rounded shadow-sm bg-light">
	<form [formGroup]="assetCategoryForm" (ngSubmit)="saveCategory()" class="p-4 border rounded shadow bg-white">
		<div class="row gy-3">
			<!-- Section Title -->
			<div class="col-12 mb-3">
				<h2 class="text-primary text-center">
					<mat-icon class="align-middle">category</mat-icon>
					{{ editingCategory ? 'Edit Category' : 'Add New Category' }}
				</h2>
			</div>

			<!-- Category ID -->
			<div class="col-md-6">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Category ID</mat-label>
					<input matInput formControlName="categoryid" placeholder="Enter category ID" />
					<mat-hint>Max. 6 characters</mat-hint>
					<mat-error *ngIf="assetCategoryForm.get('categoryid')?.invalid">
						Category ID is required and must be 6 characters or less.
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Category Description -->
			<div class="col-md-6">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Category Description</mat-label>
					<input matInput formControlName="categorydescription" placeholder="Enter category description" />
					<mat-hint>Max. 20 characters</mat-hint>
					<mat-error *ngIf="assetCategoryForm.get('categorydescription')?.invalid">
						Category Description is required and must be 20 characters or less.
					</mat-error>
				</mat-form-field>
			</div>

			<!-- Cost Account -->
			<div class="col-md-6">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Cost Account</mat-label>
					<mat-select formControlName="costact">
						<mat-option *ngFor="let account of bsAccounts" [value]="account.accountcode">
							{{ account.accountname }} ({{ account.accountcode }})
						</mat-option>
					</mat-select>
					<mat-hint>Select a balance sheet account</mat-hint>
				</mat-form-field>
			</div>

			<!-- Depreciation Account -->
			<div class="col-md-6">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Depreciation Account</mat-label>
					<mat-select formControlName="depnact">
						<mat-option *ngFor="let account of plAccounts" [value]="account.accountcode">
							{{ account.accountname }} ({{ account.accountcode }})
						</mat-option>
					</mat-select>
					<mat-hint>Select a profit & loss account</mat-hint>
				</mat-form-field>
			</div>

			<!-- Disposal Account -->
			<div class="col-md-6">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Disposal Account</mat-label>
					<mat-select formControlName="disposalact">
						<mat-option *ngFor="let account of plAccounts" [value]="account.accountcode">
							{{ account.accountname }} ({{ account.accountcode }})
						</mat-option>
					</mat-select>
					<mat-hint>Select a profit & loss account</mat-hint>
				</mat-form-field>
			</div>

			<!-- Accumulated Depreciation Account -->
			<div class="col-md-6">
				<mat-form-field appearance="fill" class="w-100">
					<mat-label>Accumulated Depreciation Account</mat-label>
					<mat-select formControlName="accumdepnact">
						<mat-option *ngFor="let account of bsAccounts" [value]="account.accountcode">
							{{ account.accountname }} ({{ account.accountcode }})
						</mat-option>
					</mat-select>
					<mat-hint>Select a balance sheet account</mat-hint>
				</mat-form-field>
			</div>

			<!-- Submit Button -->
			<div class="col-12 text-center mt-4">
				<button mat-raised-button color="accent" type="submit" [disabled]="assetCategoryForm.invalid" class="w-50">
					{{ editingCategory ? 'Update Category' : 'Add Category' }}
				</button>
			</div>
		</div>
	</form>
</div>

<!-- Asset Categories Table -->
<div class="table-responsive mt-4">
	<table class="table table-striped table-hover table-bordered">
		<thead class="thead-dark">
			<tr>
				<th class="text-center">Category ID</th>
				<th class="text-center">Description</th>
				<th class="text-center">Cost GL</th>
				<th class="text-center">Depreciation GL</th>
				<th class="text-center">Disposal GL</th>
				<th class="text-center">Accum Depn GL</th>
				<th class="text-center">Actions</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let category of assetCategories" [class.selected]="editingCategory?.categoryid === category.categoryid">
				<td class="text-center">{{ category.categoryid }}</td>
				<td class="text-center">{{ category.categorydescription }}</td>
				<td class="text-center">{{ category.costact }}</td>
				<td class="text-center">{{ category.depnact }}</td>
				<td class="text-center">{{ category.disposalact }}</td>
				<td class="text-center">{{ category.accumdepnact }}</td>
				<td class="text-center">
					<button mat-icon-button color="primary" (click)="editCategory(category)" matTooltip="Edit">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button color="warn" (click)="deleteCategory(category.categoryid)" matTooltip="Delete">
						<mat-icon>delete</mat-icon>
					</button>
				</td>
			</tr>
		</tbody>
	</table>
</div>