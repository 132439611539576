<ng-template #monthDetails let-modal>
	<div class="modal-header">
		<h4> {{ monthtitle }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="table mt-3" *ngIf="results">
			<div class="table">
				<table class="table table-striped table-hover" *ngIf="monthdetails">
					<tr>
						<th>DOCUMENT#</th>
						<th>SOURCE</th>
						<th>TYPE</th>
						<th>DATE</th>
						<th>ITEM#</th>
						<th>DESC</th>
						<th class="text-center">DISCOUNT</th>
						<th class="text-center">QTY</th>
						<th class="text-right">PRICE</th>
						<th class="text-right">TOTAL</th>
					</tr>
					<tr *ngFor="let item of monthdetails">
						<td>
							<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
								<mat-icon>more_vert</mat-icon>
							</button>
							<mat-menu #menu="matMenu">
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'html')">
									<mat-icon>visibility</mat-icon>
									<span>View HTML</span>
								</button>
								<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'pdf')">
									<mat-icon>visibility</mat-icon>
									<span>View PDF</span>
								</button>
								<button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10' && !issalesman">
									<mat-icon>history</mat-icon>
									<span>Credit</span>
								</button>
							</mat-menu>
							{{ item.transno }}
						</td>
						<td>
							<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'beauty'">
								<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
								<ng-container *ngSwitchCase="2">POS</ng-container>
								<ng-container *ngSwitchCase="3">SALES PERSON</ng-container>
								<ng-container *ngSwitchCase="4">WEB</ng-container>
								<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
							</ng-container>
							<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'tires'">
								<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
								<ng-container *ngSwitchCase="2">AMI</ng-container>
								<ng-container *ngSwitchCase="4">WEB</ng-container>
								<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
							</ng-container>
						</td>
						<td>{{ item.typename }}</td>
						<td>{{ item.trandate }}</td>
						<td><a [routerLink]="'/inventory/view/'+item.stockid">{{ item.stockid }}</a></td>
						<td>{{item.description}}</td>
						<td class="text-center">{{item.discountpercent | percent }}</td>
						<td class="text-center">{{ item.qty }}</td>
						<td class="text-right">{{item.price | currency }}</td>
						<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
					</tr>
				</table>
				<h4 class="text-center" *ngIf="!results.items">
					No Results
				</h4>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
	</div>
	<div class="modal-body">
		<div *ngIf="showRawSource">
			<pre>{{ displaydetails | json }}</pre>
		</div>
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-container *ngIf="menuapp">
	<div class="main-content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}-6">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Customer Item History</h4>
							<p class="card-category text-right"></p>
						</div>
						<div class="card-body">
							<div class="m-auto main-width">
								<div class="row d-flex justify-content-between dotted-divider" *ngIf="!customerdata">
									<h4 class="padding-t-25">Select a customer</h4>
								</div>
								<ng-container *ngIf="!customerdata || displayLookup">
									<app-customer-lookup (customer_selected)="selectCustomer($event)" [lookupOnly]="true" [ngClass]="displayLookup && !customerdata ? 'lookup' : 'lookupmt-60'"></app-customer-lookup>
								</ng-container>

								<div [ngClass]="!displayLookup && customerdata ? 'reportFilters' : 'reportFiltersNoTop'">
									<div class="row m-0 p-0" *ngIf="customerdata">
										<div class="col-md-3">
											<mat-form-field appearance="outline">
												<mat-label>Warehouse</mat-label>
												<mat-select [formControl]="defaultlocation" multiple>
													<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
														{{ loc.locationname }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-3">
											<mat-form-field appearance="outline" class="">
												<mat-label>Brand</mat-label>
												<mat-select [formControl]="brands" multiple>
													<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">
														{{ k.name }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-3">
											<mat-form-field appearance="outline" class="">
												<mat-label>Category</mat-label>
												<mat-select [formControl]="categoryid" multiple>
													<mat-option *ngFor="let k of properties.categories" [value]="k.value">
														{{ k.name }}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-3">
											<div class="row">
												<mat-form-field appearance="outline" class="w-75">
													<mat-label>Product Line</mat-label>
													<mat-select [formControl]="lineid" multiple>
														<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
															{{ k.name }}
														</mat-option>
													</mat-select>
												</mat-form-field>
												<button mat-raised-button color="primary" class="export mt-auto mb-auto ml-auto" (click)="showCustomerLookup()">{{customerdata.debtorno}}<mat-icon>person</mat-icon></button>
											</div>
										</div>
										<div class="col-sm-3">
											<mat-form-field appearance="outline">
												<mat-label>Order Source</mat-label>
												<mat-select [formControl]="ordersource" multiple appSelectAll>
													<mat-option *ngFor="let k of sources" [value]="k.value">
														{{k.viewValue}}
													</mat-option>
												</mat-select>
											</mat-form-field>
										</div>
										<div class="col-md-auto">
											<mat-form-field appearance="outline" class="text-right">
												<mat-label>Transaction Dates</mat-label>
												<mat-date-range-input [rangePicker]="picker">
													<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
													<input matEndDate [formControl]="todateCtrl" placeholder="End date">
												</mat-date-range-input>
												<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
												<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
												<mat-date-range-picker #picker></mat-date-range-picker>
											</mat-form-field>
										</div>
										<div class="col-md-3 mr-0">
											<mat-form-field appearance="outline">
												<input type="text" class="text-right p-0 m-0" placeholder="Search Items " aria-label="Notes" #itemsearch [formControl]="search" (keyup.enter)="loadData()" matInput />
												<span matSuffix>
													<button tabindex="-1" mat-icon-button color="white">
														<mat-icon (click)="loadData()">search</mat-icon>
													</button>
												</span>
											</mat-form-field>
										</div>
										<div class="col-md-3 ml-auto ">
											<div class="row justify-content-end mt-10">
												<ng-container *ngIf="!sending && customerdata">
													<button mat-raised-button class="btn btn-success export" (click)="exportXls();"><i class="fa fa-file-excel-o"></i></button>
													&nbsp;&nbsp;
													<button mat-raised-button class="btn btn-danger export" (click)="exportPdf();"><i class="fa fa-file-pdf-o"></i></button>
													&nbsp;
													<button mat-raised-button color="accent" (click)="loadData()" *ngIf="!sending" class="margin-t-5 export">
														<mat-icon>refresh</mat-icon>
													</button>
												</ng-container>
												<span *ngIf="sending">
													<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
														<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
													</svg>
												</span>

											</div>
										</div>
									</div>
									<div *ngIf="customerdata">
										<div class="row customerDataRow">
											<h3><b>Customer Item History</b>: <span class="custno">(<a href="#/customers/view/{{customerdata.debtorno}}">{{customerdata.debtorno}}</a>) {{customerdata.customer.name}}</span></h3>
										</div>
									</div>
								</div>
								<ng-container *ngIf="results.items">

									<div [ngClass]="results.items && results.items?.length > 0 ? 'contentTable' : ''" *ngIf="results">
										<div class="table mt-2" #print_items>
											<table class="table table-striped table-fixed table-hover" *ngIf="results.items && results.items?.length > 0">
												<thead>
													<tr>
														<th>DOCUMENT#</th>
														<th>TYPE</th>
														<th>LOCATION#</th>
														<th>ORDER SOURCE</th>
														<th>DATE</th>
														<th>ITEM#</th>
														<th>DESC</th>
														<th class="text-center">DISCOUNT</th>
														<th class="text-center">QTY</th>
														<th class="text-right">PRICE</th>
														<th class="text-right">TOTAL</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let item of results.items">
														<td>
															<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="donotprint">
																<mat-icon>more_vert</mat-icon>
															</button>
															<mat-menu #menu="matMenu">
																<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'html')">
																	<mat-icon>visibility</mat-icon>
																	<span>View HTML</span>
																</button>
																<button mat-menu-item *ngIf="item.type == '10' || item.type == '11'" (click)="openDocument(item,details,'pdf')">
																	<mat-icon>visibility</mat-icon>
																	<span>View PDF</span>
																</button>
																<button mat-menu-item (click)="creditInvoice(item)" *ngIf="item.type == '10' && !issalesman">
																	<mat-icon>history</mat-icon>
																	<span>RMA/Credit</span>
																</button>
															</mat-menu>
															{{ item.transno }}
														</td>
														<td>{{ item.typename }}</td>
														<td>{{ item.loccode }}</td>
														<td>
															<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'beauty'">
																<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
																<ng-container *ngSwitchCase="2">POS</ng-container>
																<ng-container *ngSwitchCase="3">SALES PERSON</ng-container>
																<ng-container *ngSwitchCase="4">WEB</ng-container>
																<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
															</ng-container>
															<ng-container [ngSwitch]="item.placedfrom" *ngIf="config.env.package == 'tires'">
																<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
																<ng-container *ngSwitchCase="2">AMI</ng-container>
																<ng-container *ngSwitchCase="4">WEB</ng-container>
																<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
															</ng-container>
														</td>
														<td>{{ item.trandate }}</td>
														<td>{{ item.stockid }}</td>
														<td>{{item.description}}</td>
														<td class="text-center">{{item.discountpercent | percent }}</td>
														<td class="text-center">{{ item.qty }}</td>
														<td class="text-right">{{item.price | currency }}</td>
														<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
													</tr>
												</tbody>
											</table>
											<h4 class="text-center" *ngIf="results.items && results.items.length == 0">
												No Results
											</h4>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-container>

<!-- Floating Action Button -->

<mat-tab-group (selectedTabChange)="loadData()" *ngIf="!menuapp">
	<mat-tab label="Item History">
		<ng-template matTabContent>
			<div class="container-fluid ">
				<h3 class="text-left my-4">Item History</h3>
				<div class="row m-0 p-0 ">
					<!-- Filters Row -->
					<div class="col-3 d-flex align-items-center">
						<mat-form-field appearance="outline" class="text-right flex-grow-1">
							<mat-label>Transaction Dates</mat-label>
							<mat-date-range-input [rangePicker]="picker">
								<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
								<input matEndDate [formControl]="todateCtrl" placeholder="End date" (dateChange)="loadData()">
							</mat-date-range-input>
							<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
							<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
							<mat-date-range-picker #picker></mat-date-range-picker>
						</mat-form-field>

					</div>

					<div class="col-6" *ngIf="results && results.items">
						<mat-form-field appearance="outline" class="w-100">
							<mat-label>Search Results</mat-label>
							<input type="text" class="text-left" matInput [formControl]="searcher" />
						</mat-form-field>


					</div>
					<div class="col-2">
					<button mat-icon-button class="ml-2 mt-2 load-data-btn" (click)="loadData()" aria-label="Reload data">
						<mat-icon>refresh</mat-icon>
					</button>
					</div>


				</div>

				<!-- Results and Paginator -->
				<div class="row mt-3">



					<div class="col-12 text-right">
						<mat-paginator [pageSizeOptions]="items_per_page" [pageSize]="100"></mat-paginator>

						<button *ngIf="selectedItems.length > 0" class="cool-button" (click)="creditSelectedItems()">
							Credit Items
						</button>


					</div>
				</div>

				<!-- Table with Checkboxes -->
				<div class="table mt-2" #print_items>
					<table class="table table-striped table-fixed table-hover" *ngIf="results && results.items">
						<thead class="bg-primary text-white">
							<tr>
								<th>DOCUMENT#</th>
								<th>TYPE</th>
								<th>LOCATION#</th>
								<th>ORDER SOURCE</th>
								<th>DATE</th>
								<th>ITEM#</th>
								<th>DESC</th>
								<th class="text-center">DISCOUNT</th>
								<th class="text-center">QTY</th>
								<th class="text-right">PRICE</th>
								<th class="text-right">TOTAL</th>
								<th class="text-center">SELECT</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let item of dataObs | async">
								<td>{{ item.transno }}</td>
								<td>{{ item.typename }}</td>
								<td>{{ item.loccode }}</td>
								<td>
									<ng-container [ngSwitch]="item.placedfrom">
										<ng-container *ngSwitchCase="1">BACK OFFICE</ng-container>
										<ng-container *ngSwitchCase="2">POS</ng-container>
										<ng-container *ngSwitchCase="3">SALES PERSON</ng-container>
										<ng-container *ngSwitchCase="4">WEB</ng-container>
										<ng-container *ngSwitchCase="undefined">UNKNOWN</ng-container>
									</ng-container>
								</td>
								<td>{{ item.trandate }}</td>
								<td>{{ item.stockid }}</td>
								<td>{{ item.description }}</td>
								<td class="text-center">{{ item.discountpercent | percent }}</td>
								<td class="text-center">{{ item.qty }}</td>
								<td class="text-right">{{ item.price | currency }}</td>
								<td class="text-right">{{ item.discountedlinetotal | currency }}</td>
								<td class="text-center">
									<label class="custom-checkbox">
										<input type="checkbox" [checked]="isChecked(item)" (change)="onCheckboxChange($event, item)">
										<span class="checkmark"></span>
									</label>
								</td>
							</tr>
							<tr>
								<td>TOTAL</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td class="text-center">{{ getItemTotals() }}</td>
								<td></td>
								<td class="text-right">{{ getDollarTotals() | currency }}</td>
								<td></td>
							</tr>
						</tbody>
					</table>
					<h4 class="text-center" *ngIf="!results.items">No Results</h4>
				</div>
			</div>


		</ng-template>
	</mat-tab>


	<mat-tab label="Monthly History" (click)="loadData()"><ng-template matTabContent>

			<h3>Monthly Qty / Sales / Cost</h3>

			<div class="row m-0 p-0">
				<div class="col-md-auto">
					<mat-form-field appearance="outline">
						<mat-label>Warehouse</mat-label>
						<mat-select [formControl]="defaultlocation" multiple appSelectAll>
							<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
								{{ loc.locationname }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-auto">
					<mat-form-field appearance="outline" class="">
						<mat-label>Brand</mat-label>
						<mat-select [formControl]="brands" multiple appSelectAll>
							<mat-option *ngFor="let k of properties.brands" [value]="k.manufacturer_id">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-auto">
					<mat-form-field appearance="outline" class="">
						<mat-label>Category</mat-label>
						<mat-select [formControl]="categoryid" multiple appSelectAll>
							<mat-option *ngFor="let k of properties.categories" [value]="k.value">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-auto">
					<mat-form-field appearance="outline" class="">
						<mat-label>Product Line</mat-label>
						<mat-select [formControl]="lineid" multiple appSelectAll>
							<mat-option *ngFor="let k of properties.productlines" [value]="k.value">
								{{ k.name }}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-auto">
					<mat-form-field appearance="outline">
						<mat-label>Order Source</mat-label>
						<mat-select [formControl]="ordersource" multiple appSelectAll>
							<mat-option *ngFor="let k of sources" [value]="k.value">
								{{k.viewValue}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-auto">
					<mat-form-field appearance="outline">
						<mat-label>Source Breakout</mat-label>
						<mat-select [formControl]="subsToView" multiple appSelectAll>
							<mat-option *ngFor="let opt of viewableSubs" [value]="opt.value">
								{{opt.viewValue}}
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-md-auto text-right mt-2">
					<mat-slide-toggle class="nav-link" [formControl]="consolidated">
						<mat-label>Consolidated Ship To's</mat-label>
					</mat-slide-toggle>
					<mat-checkbox [formControl]="hideshowunits">Units&nbsp;</mat-checkbox>
					<mat-checkbox [formControl]="hideshowsales">Sales&nbsp;</mat-checkbox>
					<mat-checkbox [formControl]="hideshowcost" *ngIf="user && user.user.viewcost">Cost&nbsp;</mat-checkbox>
				</div>
				<div class="col-md-auto ml-auto mr-0">
					<ng-container>
						<button class="btn btn-success btn-sm" [disabled]="sending && results.items?.length" (click)="exportMXls();"><i class="fa fa-file-excel-o"></i></button>
						&nbsp;
						<button class="btn btn-danger btn-sm" [disabled]="sending && results.items?.length" (click)="exportMPdf();"><i class="fa fa-file-pdf-o"></i></button>
						&nbsp;
					</ng-container>
					<button mat-raised-button color="accent" class="ml-2 mt-2" (click)="loadData()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
				</div>
			</div>
			<div class="table mt-3" *ngIf="results" #print_history>

				<!-- <div class="ct-chart" id="monthOverMonth" style="min-height:300px;max-height:500px"></div> -->

				<table class="table table-bordered w-100" *ngFor="let year of results.years | keyvalue">
					<tr>
						<th class="monthly-head"></th>
						<th *ngFor="let month of year.value.sales" class="text-center monthly-head">
							{{ month.label }} {{ month.year }}
						</th>
						<th class="text-center monthly-head">Total</th>
					</tr>
					<tr *ngIf="hideshowunits.value">
						<td class="text-center">Qty</td>
						<td class="text-center" *ngFor="let month of year.value.qty">
							<button mat-flat-button (click)="viewHistory( month.monthnumber, month.year)" class="noprint">
								{{ month.value | number:'1.0-0' }}</button>
							<br>
							<div class="source-container noprint" *ngIf="subsToView.value.includes(2)">
								<ng-container *ngIf="config.env.package == 'beauty'">
									<div [ngClass]="(month.subvals.bof > 0) ? 'source-pos' : (month.subvals.bof < 0) ? 'source-neg' : 'source-row'">BOF {{month.subvals.bof | number: '1.0-0'}}<br></div>
									<div [ngClass]="(month.subvals.pos > 0) ? 'source-pos' : (month.subvals.pos < 0) ? 'source-neg' : 'source-row'">POS {{month.subvals.pos | number: '1.0-0'}}<br></div>
									<div [ngClass]="(month.subvals.sls > 0) ? 'source-pos' : (month.subvals.sls < 0) ? 'source-neg' : 'source-row'">SLS {{month.subvals.sls | number: '1.0-0'}}<br></div>
									<div [ngClass]="(month.subvals.web > 0) ? 'source-pos' : (month.subvals.web < 0) ? 'source-neg' : 'source-row'">WEB {{month.subvals.web | number: '1.0-0'}}<br></div>
									<div [ngClass]="(month.subvals.unk > 0) ? 'source-pos' : (month.subvals.unk < 0) ? 'source-neg' : 'source-row'">UNK {{month.subvals.unk | number: '1.0-0'}}<br></div>
								</ng-container>
								<ng-container *ngIf="config.env.package == 'tires'">
									<div [ngClass]="(month.subvals.bof > 0) ? 'source-pos' : (month.subvals.bof < 0) ? 'source-neg' : 'source-row'">BOF {{month.subvals.bof | number: '1.0-0'}}<br></div>
									<div [ngClass]="(month.subvals.ami > 0) ? 'source-pos' : (month.subvals.pos < 0) ? 'source-neg' : 'source-row'">AMI {{month.subvals.ami | number: '1.0-0'}}<br></div>
									<div [ngClass]="(month.subvals.web > 0) ? 'source-pos' : (month.subvals.web < 0) ? 'source-neg' : 'source-row'">WEB {{month.subvals.web | number: '1.0-0'}}<br></div>
									<div [ngClass]="(month.subvals.unk > 0) ? 'source-pos' : (month.subvals.unk < 0) ? 'source-neg' : 'source-row'">UNK {{month.subvals.unk | number: '1.0-0'}}<br></div>
								</ng-container>
							</div>

							<span class="donotshow">{{ month.value }}</span>
						</td>
						<td class="text-center">{{ getQtyTotalYear( year ) }}</td>
					</tr>
					<tr *ngIf="hideshowsales.value">
						<td class="text-center">Sales </td>
						<td class="text-center" *ngFor="let month of year.value.sales">
							{{ month.value | currency }}
							<br>
							<div class="source-container noprint" *ngIf="subsToView.value.includes(1)">
								<ng-container *ngIf="config.env.package == 'beauty'">
									<div [ngClass]="(month.subvals.bof > 0) ? 'source-pos' : (month.subvals.bof < 0) ? 'source-neg' : 'source-row'">BOF {{month.subvals.bof | currency}}<br></div>
									<div [ngClass]="(month.subvals.pos > 0) ? 'source-pos' : (month.subvals.pos < 0) ? 'source-neg' : 'source-row'">POS {{month.subvals.pos | currency}}<br></div>
									<div [ngClass]="(month.subvals.sls > 0) ? 'source-pos' : (month.subvals.sls < 0) ? 'source-neg' : 'source-row'">SLS {{month.subvals.sls | currency}}<br></div>
									<div [ngClass]="(month.subvals.web > 0) ? 'source-pos' : (month.subvals.web < 0) ? 'source-neg' : 'source-row'">WEB {{month.subvals.web | currency}}<br></div>
									<div [ngClass]="(month.subvals.unk > 0) ? 'source-pos' : (month.subvals.unk < 0) ? 'source-neg' : 'source-row'">UNK {{month.subvals.unk | currency}}<br></div>
								</ng-container>
								<ng-container *ngIf="config.env.package == 'tires'">
									<div [ngClass]="(month.subvals.bof > 0) ? 'source-pos' : (month.subvals.bof < 0) ? 'source-neg' : 'source-row'">BOF {{month.subvals.bof | currency}}<br></div>
									<div [ngClass]="(month.subvals.ami > 0) ? 'source-pos' : (month.subvals.pos < 0) ? 'source-neg' : 'source-row'">AMI {{month.subvals.ami | currency}}<br></div>
									<div [ngClass]="(month.subvals.web > 0) ? 'source-pos' : (month.subvals.web < 0) ? 'source-neg' : 'source-row'">WEB {{month.subvals.web | currency}}<br></div>
									<div [ngClass]="(month.subvals.unk > 0) ? 'source-pos' : (month.subvals.unk < 0) ? 'source-neg' : 'source-row'">UNK {{month.subvals.unk | currency}}<br></div>
								</ng-container>
							</div>
						</td>
						<td class="text-center">{{ getSaleTotalYear( year ) | currency }}</td>
					</tr>
					<tr *ngIf="hideshowcost.value">
						<td class="text-center">Cost </td>
						<td class="text-center" *ngFor="let month of year.value.cost">
							{{ month.value | currency }}
						</td>
						<td class="text-center">{{ getCostTotalYear( year ) | currency }}</td>
					</tr>
				</table>
			</div>
		</ng-template></mat-tab>
	<mat-tab label="Brands">
		<ng-template matTabContent>
			<app-brand-report [customer]="customerdata" [menuapp]="false"></app-brand-report>
		</ng-template>
	</mat-tab>
	<mat-tab label="CDET">
		<ng-template matTabContent>
			<app-customer-cdet [customer]="customerdata" [menuapp]="false"></app-customer-cdet>
		</ng-template>
	</mat-tab>
	<mat-tab label="CPSLS">
		<ng-template matTabContent>
			<app-cpsls [customer]="customerdata" [menuapp]="false"></app-cpsls>
		</ng-template>
	</mat-tab>
	<mat-tab label="Top Items">
		<ng-template matTabContent>
			<app-top-hundred [customer]="customerdata" [menuapp]="false"></app-top-hundred>
		</ng-template>
	</mat-tab>

</mat-tab-group>