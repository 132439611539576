import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { GlobalSearchService } from '../services/globalsearchservice.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragDropModule,CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { NgSelectModule } from '@ng-select/ng-select';

import * as _ from 'underscore';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { GoogleMapsModule } from '@angular/google-maps';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';

import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';

import { DispatchesRouteBuilderComponent } from '../dispatches/dispatches-route-builder/dispatches-route-builder.component';
import { DispatchesActiveDispatchComponent } from '../dispatches/dispatches-active-dispatch/dispatches-active-dispatch.component';
import { DispatchesDriverRunComponent } from './dispatches-driver-run/dispatches-driver-run.component';
import { DispatchesDriversComponent } from './dispatches-drivers/dispatches-drivers.component';
import { DispatchesTrucksComponent } from './dispatches-trucks/dispatches-trucks.component';
import { DispatchesDeliveredNotinvoicedComponent } from './dispatches-delivered-notinvoiced/dispatches-delivered-notinvoiced.component';
import { DispatchesAdjustDispatchComponent } from './dispatches-adjust-dispatch/dispatches-adjust-dispatch.component';
import { DispatchesMaintanceComponent } from './dispatches-maintance/dispatches-maintance.component';
import { DispatchAddCardComponent } from './dispatch-add-card/dispatch-add-card.component';

import { DispatchesDashboardComponent } from './dispatches-dashboard/dispatches-dashboard.component';
import { DispatchesLoadSheetComponent } from './dispatches-load-sheet/dispatches-load-sheet.component';
import { SharedModule } from  '../shared/shared.module';
import { DispatchesLoadSheetOnlyComponent } from './dispatches-load-sheet-only/dispatches-load-sheet-only.component';
import { DispatchesOrderConfirmationComponent } from './dispatches-order-confirmation/dispatches-order-confirmation.component';
import { DispatchesReorgAfterCommitComponent } from './dispatches-reorg-after-commit/dispatches-reorg-after-commit.component';
import { DisaptchesCheckingComponent } from './disaptches-checking/disaptches-checking.component';
import { DispatchesLoadPriorityComponent } from './dispatches-load-priority/dispatches-load-priority.component';
import { DispatchDriverUnloadComponent } from './dispatch-driver-unload/dispatch-driver-unload.component';
import { DispatchesRouteMaintanceComponent } from './dispatches-route-maintance/dispatches-route-maintance.component';

import { OrdersModule } from 'app/orders/orders.module';

const DragConfig = {
	dragStartThreshold: 0,
	pointerDirectionChangeThreshold: 5,
	//zIndex: 9000
};



@NgModule({
	declarations: [
  	DispatchesRouteBuilderComponent,
  	DispatchesActiveDispatchComponent,
		DispatchesDriverRunComponent,
		DispatchesDriversComponent,
		DispatchesTrucksComponent,
		DispatchesDeliveredNotinvoicedComponent,
		DispatchesAdjustDispatchComponent,
		DispatchesMaintanceComponent,
		DispatchAddCardComponent,
		DispatchesDashboardComponent,
		DispatchesLoadSheetComponent,
		DispatchesLoadSheetOnlyComponent,
		DispatchesOrderConfirmationComponent,
		DispatchesReorgAfterCommitComponent,
		DisaptchesCheckingComponent,
		DispatchesLoadPriorityComponent,
  DispatchDriverUnloadComponent,
  DispatchesRouteMaintanceComponent,
	],
	imports: [
		OrdersModule,
		CommonModule,
		BrowserModule,
		RouterModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		MatCheckboxModule,
		ScrollingModule,
		DragDropModule,
		NgSelectModule,

		MatToolbarModule,
		MatBadgeModule,
		MatButtonModule,
		MatDialogModule,
		MatDividerModule,
		MatInputModule,
		MatTableModule,
		MatSortModule,
		MatPaginatorModule,
		MatFormFieldModule,
		MatAutocompleteModule,
		MatSlideToggleModule,
		MatExpansionModule,
		MatTabsModule,
		MatStepperModule,
		MatListModule,
		PopoverModule,
		MatDatepickerModule,
		MatNativeDateModule,
		MatCardModule,
		MatMenuModule,
		MatIconModule,
		MatSelectModule,
		MatProgressSpinnerModule,
		MatRadioModule,
		MatGridListModule,
		CreditCardDirectivesModule,
		MatTooltipModule,
		SharedModule,
		GoogleMapsModule
	],
	providers: [{ provide: CDK_DRAG_CONFIG, useValue: DragConfig }]
})
export class DispatchesModule { }
