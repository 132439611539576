import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-pacman',
  templateUrl: './pacman.component.html',
  styleUrls: ['./pacman.component.scss'],
})
export class PacmanComponent implements OnInit {
  gridSize = 25; // Size of each grid cell
  pacmanPosition = { top: 0, left: 0 }; // Pac-Man's position
  pacmanDirection = 'rotate(0deg)'; // Pac-Man's facing direction
  pellets = []; // Pellet positions
  ghosts = []; // Ghost positions
  score = 0; // Player's score
  lives = 3; // Lives remaining
  level = 1; // Current level
  timer: number = 0; // Game timer in seconds
  isPaused = false; // Pause status
  isGameOver = false; // Game over status
  isWin = false; // Winning status
  ghostSpeed = 500; // Ghost movement speed (ms)
  timerInterval: any; // Timer interval reference
  ghostInterval: any; // Ghost movement interval reference
 maze: number[][] = [
   [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
   [1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 1],
   [1, 0, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0, 1, 1, 0, 1],
   [1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1],
   [1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1],
   [1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1],
   [1, 0, 1, 0, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1],
   [1, 0, 1, 0, 1, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 1],
   [1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0, 1, 0, 1],
   [1, 0, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 1, 0, 1],
   [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
 ];
  ngOnInit() {
	  
	this.startLevel();
  }
  
  drawMaze(): void {
	const gameContainer = document.querySelector('.game-container') as HTMLElement; // Cast to HTMLElement
  
	if (!gameContainer) {
	  console.error('Game container not found!');
	  return;
	}
  
	// Clear any existing maze elements
	gameContainer.innerHTML = '';
  
	// Apply grid styles directly
	gameContainer.style.display = 'grid';
	gameContainer.style.gridTemplateRows = `repeat(${this.maze.length}, ${this.gridSize}px)`;
	gameContainer.style.gridTemplateColumns = `repeat(${this.maze[0].length}, ${this.gridSize}px)`;
  
	// Loop through the maze array
	this.maze.forEach((row, rowIndex) => {
		console.log(row, rowIndex)
	  row.forEach((cell, colIndex) => {
		const cellElement = document.createElement('div');
		cellElement.style.width = `${this.gridSize}px`;
		cellElement.style.height = `${this.gridSize}px`;
  
		if (cell === 1) {
		  // Wall
		  cellElement.classList.add('wall');
		} else if (cell === 0) {
		  // Path
		  cellElement.classList.add('path');
		}
  
		gameContainer.appendChild(cellElement);
	  });
	});
  }
  
  startLevel(): void {
	  this.drawMaze();
	this.resetGameObjects();
	this.generatePellets();
	this.spawnGhosts();
	this.startTimer();
	this.moveGhosts();
  }

  resetGameObjects(): void {
	this.pacmanPosition = { top: 0, left: 0 };
	this.pacmanDirection = 'rotate(0deg)';
	this.pellets = [];
	this.ghosts = [];
	this.isGameOver = false;
	this.isWin = false;
	this.isPaused = false;
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
	if (this.isPaused || this.isGameOver || this.isWin) return;
	switch (event.key) {
	  case 'ArrowUp':
		this.movePacman('up');
		break;
	  case 'ArrowDown':
		this.movePacman('down');
		break;
	  case 'ArrowLeft':
		this.movePacman('left');
		break;
	  case 'ArrowRight':
		this.movePacman('right');
		break;
	}
  }

  movePacman(direction: string): void {
	this.removeIdleState(); // Remove idle animation on movement
  
	switch (direction) {
	  case 'up':
		this.pacmanDirection = 'rotate(-90deg)';
		if (this.pacmanPosition.top > 0) this.pacmanPosition.top -= this.gridSize;
		break;
	  case 'down':
		this.pacmanDirection = 'rotate(90deg)';
		if (this.pacmanPosition.top < 380) this.pacmanPosition.top += this.gridSize;
		break;
	  case 'left':
		this.pacmanDirection = 'rotate(180deg)';
		if (this.pacmanPosition.left > 0) this.pacmanPosition.left -= this.gridSize;
		break;
	  case 'right':
		this.pacmanDirection = 'rotate(0deg)';
		if (this.pacmanPosition.left < 380) this.pacmanPosition.left += this.gridSize;
		break;
	}
  
	this.checkCollision();
	this.addIdleStateAfterDelay(); // Add idle animation after delay if no further movement
  }
  
  removeIdleState(): void {
	const pacmanElement = document.querySelector('.pacman');
	pacmanElement?.classList.remove('idle');
  }
  
  addIdleStateAfterDelay(): void {
	setTimeout(() => {
	  const pacmanElement = document.querySelector('.pacman');
	  pacmanElement?.classList.add('idle');
	}, 1000); // Add idle state after 1 second of no movement
  }

  generatePellets(): void {
	for (let i = 0; i < 50 + this.level * 10; i++) {
	  const top = Math.floor(Math.random() * 20) * this.gridSize;
	  const left = Math.floor(Math.random() * 20) * this.gridSize;
	  this.pellets.push({ top, left });
	}
  }

  spawnGhosts(): void {
	for (let i = 0; i < this.level; i++) {
	  const top = Math.floor(Math.random() * 20) * this.gridSize;
	  const left = Math.floor(Math.random() * 20) * this.gridSize;
	  this.ghosts.push({ top, left });
	}
  }

  moveGhosts(): void {
	this.ghostInterval = setInterval(() => {
	  if (this.isPaused || this.isGameOver || this.isWin) return;
	  this.ghosts.forEach((ghost) => {
		this.moveGhostTowardsPacman(ghost);
	  });
	  this.checkGameOver();
	}, this.ghostSpeed - this.level * 50); // Increase speed as level increases
  }

  moveGhostTowardsPacman(ghost: any): void {
	if (ghost.top < this.pacmanPosition.top) ghost.top += this.gridSize;
	else if (ghost.top > this.pacmanPosition.top) ghost.top -= this.gridSize;

	if (ghost.left < this.pacmanPosition.left) ghost.left += this.gridSize;
	else if (ghost.left > this.pacmanPosition.left) ghost.left -= this.gridSize;
  }

  checkCollision(): void {
	this.pellets = this.pellets.filter((pellet) => {
	  const isCollision =
		pellet.top === this.pacmanPosition.top &&
		pellet.left === this.pacmanPosition.left;
	  if (isCollision) this.score++;
	  return !isCollision;
	});

	if (this.pellets.length === 0) {
	  this.levelUp();
	}
  }

  checkGameOver(): void {
	this.ghosts.forEach((ghost) => {
	  if (
		ghost.top === this.pacmanPosition.top &&
		ghost.left === this.pacmanPosition.left
	  ) {
		this.lives--;
		if (this.lives === 0) {
		  this.isGameOver = true;
		  this.stopGame();
		} else {
		  this.resetGameObjects();
		}
	  }
	});
  }

  levelUp(): void {
	this.isWin = true;
	clearInterval(this.timerInterval);
	clearInterval(this.ghostInterval);
	setTimeout(() => {
	  this.level++;
	  this.startLevel();
	}, 2000);
  }

  startTimer(): void {
	this.timer = 0;
	this.timerInterval = setInterval(() => {
	  if (this.isPaused || this.isGameOver || this.isWin) return;
	  this.timer++;
	}, 1000);
  }

  togglePause(): void {
	this.isPaused = !this.isPaused;
  }

  restartGame(): void {
	this.lives = 3;
	this.level = 1;
	this.score = 0;
	this.startLevel();
  }

  stopGame(): void {
	clearInterval(this.timerInterval);
	clearInterval(this.ghostInterval);
  }
}