import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalsService } from '../../../services/globals.service';
import { GlobalSearchService } from '../../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-account-sections',
	templateUrl: './account-sections.component.html',
	styleUrls: ['./account-sections.component.scss'],
	animations: [
		trigger('expandCollapse', [
			state('void', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
			state('*', style({ height: '*', opacity: 1 })),
			transition('void <=> *', [animate('300ms ease-in-out')]),
		]),
	],
})
export class AccountSectionsComponent implements OnInit {
	@ViewChild(MatSort) sort!: MatSort;
	@ViewChild(MatPaginator) paginator!: MatPaginator;

	sectionForm: FormGroup;
	sections: any = [];
	filteredSections: any = [];
	results: any = [];
	editingSection: any = null;
	isFormVisible = false;
	searchTerm: string = '';
	color: string = 'blue'
	constructor(
		private fb: FormBuilder,
		private globalsService: GlobalsService,
		private globalSearchService: GlobalSearchService,
		private route: ActivatedRoute,
		private router: Router,
		private location: Location,
		private snackBar: MatSnackBar
	) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.initializeForm();
		this.loadSections();
	}

	// Initialize the form
	initializeForm(): void {
		this.sectionForm = this.fb.group({
			sectionid: ['', Validators.required],
			sectionname: ['', Validators.required],
		});
	}

	// Load sections
	loadSections(): void {
		this.globalsService.getAccountSections().subscribe((results) => {
			this.sections = results;
			this.filteredSections = results;
		});
	}

	// Apply search filter
	applySearch(event: any): void {
		this.searchTerm = event.target.value.toLowerCase();
		this.filterSections();
	}

	// Filter sections
	filterSections(): void {
		this.filteredSections = this.sections.filter((section: any) =>
			section.sectionname.toLowerCase().includes(this.searchTerm)
		);
	}

	// Edit section
	editSection(section: any): void {
		this.editingSection = section;
		this.sectionForm.patchValue(section);
		this.isFormVisible = true;
	}

	// Save or update section
	saveSection(): void {
		if (this.sectionForm.valid) {
			const sectionData = this.sectionForm.value;

			if (this.editingSection) {
				this.globalsService.updateSection(sectionData).subscribe(() => {
					this.snackBar.open('Section updated successfully.', 'Close', { duration: 3000 });
					this.loadSections();
					this.cancelEdit();
				});
			} else {
				this.globalsService.updateSection(sectionData).subscribe(() => {
					this.snackBar.open('Section added successfully.', 'Close', { duration: 3000 });
					this.loadSections();
					this.cancelEdit();
				});
			}
		}
	}

	// Cancel editing
	cancelEdit(): void {
		this.editingSection = null;
		this.isFormVisible = false;
		this.sectionForm.reset();
	}

	// Delete section
	deleteSection(sectionId: string): void {
		if (!confirm('Are you sure you want to delete this section?')) return;

		this.globalsService.deleteSection(sectionId).subscribe(() => {
			this.snackBar.open('Section deleted successfully.', 'Close', { duration: 3000 });
			this.loadSections();
		});
	}

	// Toggle form visibility
	toggleForm(): void {
		this.isFormVisible = !this.isFormVisible;
		if (!this.isFormVisible) {
			this.cancelEdit();
		}
	}

	// Navigate back
	back(): void {
		this.location.back();
	}
}