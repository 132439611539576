<ng-template #orderDetails let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title" *ngIf="podetails">
			<a [routerLink]="'/vendors/'+podetails.poheader.supplierno">{{ podetails.poheader.supplierno }}</a> Purchase Order
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body">
		<mat-tab-group #tabPanel mat-align-tabs="start" color="accent" class="no-overflow">
			<!-- Purchase Order Tab -->
			<mat-tab label="Purchase Order">
				<div class="po-details" *ngIf="podetails">
					<!-- PO Header -->
					<h4 class="mt-4 mb-0"><strong>Purchase Order #{{ podetails.poheader.orderno }}</strong></h4>

					<!-- Contact and Delivery Information Cards -->
					<div class="row m-0 p-0">
						<!-- Contact Information -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-primary text-white">
									<strong>Contact Information</strong>
								</div>
								<div class="card-body">
									<p>
										<strong>Contact:</strong> {{ podetails.poheader.contact }}<br>
										<strong>Address:</strong>
										<ng-container *ngIf="podetails.address">
											<div [innerHTML]="podetails.address"></div>
										</ng-container>
									</p>
								</div>
							</div>
						</div>

						<!-- Delivery Information -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-primary text-white">
									<strong>Delivery Information</strong>
								</div>
								<div class="card-body">
									<p>
										<strong>Delivery Address:</strong><br>
										<ng-container *ngFor="let field of ['deladd1', 'deladd2', 'deladd3', 'deladd4', 'deladd5']">
											<span *ngIf="podetails.poheader[field]">{{ podetails.poheader[field] }}<br></span>
										</ng-container>
									</p>
								</div>
							</div>
						</div>
					</div>

					<!-- Notes and Totals -->
					<div class="row m-0 p-0">
						<!-- Notes Section -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-info text-white">
									<strong>Notes</strong>
								</div>
								<div class="card-body">
									<div [innerHTML]="podetails.poheader.stat_comment"></div>
								</div>
							</div>
						</div>

						<!-- Totals Section -->
						<div class="col-md-6">
							<div class="card shadow-sm mb-4">
								<div class="card-header bg-info text-white">
									<strong>Totals</strong>
								</div>
								<div class="card-body">
									<p>
										<strong>Total Quantity:</strong> {{ getTotalQuantity() }}<br>
										<strong>Total Value:</strong> {{ getTotalValue() | currency }}
									</p>
								</div>
							</div>
						</div>
					</div>

					<!-- Purchase Order Lines Table -->
					<div class="table-responsive mt-3">
						<table class="table table-sm table-striped table-hover table-bordered">
							<thead class="thead-dark">
								<tr>
									<th>ITEM</th>
									<th>DESCRIPTION</th>
									<th class="text-center">QTY</th>
									<th class="text-center">RCVD</th>
									<th class="text-center">PENDING</th>
									<th class="text-right">PRICE</th>
									<th class="text-right">TOTAL</th>
								</tr>
							</thead>
							<tbody>
								<tr *ngFor="let item of podetails.polines">
									<td>
										<button mat-icon-button (click)="copyToClipboard(item.itemcode)" title="Copy to clipboard" class="mr-2">
											<mat-icon>content_copy</mat-icon>
										</button>
										<a [routerLink]="'/inventory/view/' + item.itemcode" (click)="modal.dismiss('route change')" class="font-weight-bold">
											{{ item.itemcode }}
										</a>
									</td>
									<td>{{ item.itemdescription }}</td>
									<td class="text-center">{{ item.quantityord }}</td>
									<td class="text-center">{{ item.quantityrecd }}</td>
									<td class="text-center">{{ item.quantityord - item.quantityrecd }}</td>
									<td class="text-right">{{ item.unitprice | currency }}</td>
									<td class="text-right">{{ item.unitprice * item.quantityord | currency }}</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="2"><strong>Total</strong></td>
									<td class="text-center"><strong>{{ getTotalQuantity() }}</strong></td>
									<td colspan="3"></td>
									<td class="text-right"><strong>{{ getTotalValue() | currency }}</strong></td>
								</tr>
							</tfoot>
						</table>
					</div>
				</div>
			</mat-tab>

			<!-- Receiving Details Tab -->
			<mat-tab label="Receiving Details">
				<ng-container *ngIf="podetails.receiving.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>GRN</th>
								<th>Order#</th>
								<th>Item#</th>
								<th>Description</th>
								<th>Received</th>
								<th>Invoiced</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.receiving">
								<td>{{ r.grnno }}</td>
								<td>{{ r.poorderno }}</td>
								<td>{{ r.itemcode }}</td>
								<td>{{ r.itemdescription }}</td>
								<td>{{ r.qtyrecd }}</td>
								<td>{{ r.quantityinv }}</td>
								<td>{{ r.update_date }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.receiving.length">
					<p>No Receiving Details</p>
				</ng-container>
			</mat-tab>
			<!-- Receiving Movements Tab -->
			<mat-tab label="Receiving Movements">
				<ng-container *ngIf="podetails.movements.length">
					<table class="table table-fixed table-sm">
						<thead>
							<tr>
								<th>Item#</th>
								<th>Location</th>
								<th>User</th>
								<th>Quantity</th>
								<th>QOH</th>
								<th>Date</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let r of podetails.movements">
								<td>{{ r.stockid }}</td>
								<td>{{ r.loccode }}</td>
								<td>{{ r.userid }}</td>
								<td>{{ r.qty }}</td>
								<td>{{ r.newqoh }}</td>
								<td>{{ r.trandate }}</td>
							</tr>
						</tbody>
					</table>
				</ng-container>
				<ng-container *ngIf="!podetails.movements.length">
					<p>No Movements</p>
				</ng-container>
			</mat-tab>
		</mat-tab-group>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.dismiss('cancel')">Close</button>
	</div>
</ng-template>



<div class="row m-0 p-0 mt-3">
	<div class="col-2 text-left mt-3">
		<div class="d-flex justify-content-around">
			<button mat-raised-button type="submit" color="accent" (click)="exportXls()" *ngIf="purchaseorders">
				<i class="material-icons">text_snippet</i> XLS
			</button>
			<button mat-raised-button type="submit" color="primary" (click)="exportPdf()" *ngIf="purchaseorders">
				<i class="material-icons">text_snippet</i> PDF
			</button>
		</div>
	</div>
	<div class="col-md-3">
		<mat-form-field appearance="outline" *ngIf="statuses">
			<mat-label>Status</mat-label>
			<mat-select [formControl]="status">
				<mat-option [value]="''" (click)="onStatusSelection()">All</mat-option>
				<mat-option *ngFor="let v of statuses" [value]="v.status_id" (click)="onStatusSelection()">
					{{v.name}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div class="col-md-2">
		<mat-form-field appearance="outline">
			<mat-label>Item Search</mat-label>
			<input matInput [formControl]="searchInputItems" (input)="searchItems()" placeholder="Search Items..." class="text-right">
			<span matSuffix>
				<button mat-icon-button (click)="searchItems()">
					<mat-icon>search</mat-icon>
				</button>
			</span>
		</mat-form-field>
	</div>
	<div class="col-5">
		<mat-form-field appearance="outline" class="text-right">
			<mat-label>Search</mat-label>
			<input tabindex="1" matInput value="" (input)="onInput()" [formControl]="vendorSearch" class="text-right" autofocus (keydown.enter)="$event.preventDefault();onInput()">
			<span matSuffix>
				<button tabindex="-1" mat-icon-button color="white">
					<mat-icon (click)="searchOrders()">search</mat-icon>
				</button>
			</span>
			<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
		</mat-form-field>
	</div>
	<div class="table table-responsive text-center" *ngIf="!allpurchaseorders">
		<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
			</circle>
		</svg>
	</div>
</div>
<div class="table-container">
	<table mat-table [dataSource]="purchaseorders" matSort (matSortChange)="announceSortChange($event)">
		<ng-container matColumnDef="actions">
			<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
			<td mat-cell *matCellDef="let tran">
				<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
					<mat-icon>more_vert</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<button mat-menu-item (click)="viewOrder(tran.orderno, orderDetails)">
						<mat-icon>visibility</mat-icon>
						<span>View</span>
					</button>
					<a href="{{config.apiServer.baseUrl}}{{baselink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>PDF</span>
					</a>
					<a href="{{config.apiServer.baseUrl}}{{explink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>save</mat-icon>
						<span>Excel</span>
					</a>
					<a href="{{config.apiServer.baseUrl}}{{reclink}}{{tran.orderno}}" mat-menu-item>
						<mat-icon>print</mat-icon>
						<span>Rcv Doc</span>
					</a>
					<mat-divider></mat-divider>
					<a [routerLink]="'/purchasing/receive/'+tran.orderno" mat-menu-item>
						<mat-icon>call_received</mat-icon>
						<span>Receive</span>
					</a>
					<mat-divider></mat-divider>
					<button mat-menu-item (click)="loadOrder(tran.orderno)">
						<mat-icon>edit</mat-icon>
						<span>Edit PO</span>
					</button>
				</mat-menu>
			</td>
		</ng-container>
		<ng-container matColumnDef="status_id">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Status.</th>
			<td mat-cell *matCellDef="let tran">
				<ng-container *ngIf="!tran.editing">
					<span class="status-{{ tran.status_id }}">{{ tran.statusname }}</span>
					<button mat-icon-button aria-label="Change Status" (click)="toggleStatusChange(tran)">
						<mat-icon class="smaller">edit</mat-icon>
					</button>
				</ng-container>
				<mat-form-field appearance="outline" *ngIf="tran.editing">
					<mat-label>Status</mat-label>
					<span matPrefix *ngIf="tran.editing">
						<mat-icon (click)="toggleStatusChange(tran)" class="smaller">clear</mat-icon>
					</span>
					<mat-select [(value)]="tran.status_id" (selectionChange)="updateStatus($event, tran)">
						<mat-option *ngFor="let status of statuses" [(value)]="status.status_id">{{ status.name }}</mat-option>
					</mat-select>

				</mat-form-field>
			</td>
		</ng-container>
		<ng-container matColumnDef="orderno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>PO#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.orderno }}</td>
		</ng-container>
		<ng-container matColumnDef="orddate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header> Date Ordered</th>
			<td mat-cell *matCellDef="let tran">{{ tran.orddate }}</td>
		</ng-container>
		<ng-container matColumnDef="suppname">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Vendor</th>
			<td mat-cell *matCellDef="let tran">
				{{ tran.suppname }}
			</td>
		</ng-container>
		<ng-container matColumnDef="requisitionno">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Req#</th>
			<td mat-cell *matCellDef="let tran">{{ tran.requisitionno }}</td>
		</ng-container>

		<ng-container matColumnDef="ordervalue">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Net $</th>
			<td mat-cell *matCellDef="let tran">{{ tran.ordervalue | currency }}</td>
		</ng-container>
		<ng-container matColumnDef="orderpendingvalue">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Pending $</th>
			<td mat-cell *matCellDef="let tran">{{ tran.orderpendingvalue | currency }}</td>
		</ng-container>
		<ng-container matColumnDef="ordereditems">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>ORD/REC/PEND</th>
			<td mat-cell *matCellDef="let tran">{{ tran.ordered }}/{{ tran.rec }}/<b>{{ tran.pendingitems }}</b></td>
		</ng-container>
		<ng-container matColumnDef="deliverydate">
			<th mat-header-cell *matHeaderCellDef mat-sort-header arrowPosition="before" class="text-right">Date Wanted</th>
			<td mat-cell *matCellDef="let tran" class="text-right">{{ tran.deliverydate }}</td>
		</ng-container>
		<ng-container matColumnDef="initiator">
			<th mat-header-cell *matHeaderCellDef mat-sort-header class="text-right" arrowPosition="before"> Initiator</th>

			<td mat-cell *matCellDef="let tran" class="text-right">
				{{ tran.initiator }}
			</td>
		</ng-container>
		<tr mat-header-row *matHeaderRowDef="headercolumns;sticky: true;"></tr>
		<tr mat-row *matRowDef="let row; columns: headercolumns"></tr>
	</table>

	<mat-paginator [pageSizeOptions]="[50, 100, 500]" showFirstLastButtons aria-label="select page">
	</mat-paginator>
</div>