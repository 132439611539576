import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';
import { Location } from '@angular/common'
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import * as FileSaver from 'file-saver';
import { GlobalsService } from '../../services/globals.service';
import { SystemService } from '../../services/system.service';
import { merge, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, tap, delay } from 'rxjs/operators';

import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-storefront-settings',
	templateUrl: './storefront-settings.component.html',
	styleUrls: ['./storefront-settings.component.scss']
})
export class StorefrontSettingsComponent implements OnInit {
	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	color = 'blue';
	awaitingApprovalCount: number = 0;
	constructor(private globalSearchService: GlobalSearchService, private systemService: SystemService, private location: Location, public cdr: ChangeDetectorRef, private globalsService: GlobalsService, private fb: UntypedFormBuilder) {

		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {

	}

	updateApprovalCount(customers: any): void {
		this.awaitingApprovalCount = (customers && customers.awaiting && customers.awaiting.length) ? customers.awaiting.length : 0;
	}

	back() {
		this.location.back();
	}

}