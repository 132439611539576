import { Component, OnDestroy, ElementRef, OnInit, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef, Injectable, ChangeDetectionStrategy } from '@angular/core';
import { CurrencyPipe, ViewportScroller } from '@angular/common';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { TableModule } from 'primeng/table';
import { DailySales } from '../../classes/reports';
import { DomSanitizer } from '@angular/platform-browser';
import { map, timeout } from 'rxjs/operators';

import { Message } from '../../classes/message';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { UsersService } from '../../services/users.service';

import { DashboardService } from '../../services/dashboard.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import * as Chartist from 'chartist';
import { Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';

import { Chart, registerables, Colors, TooltipPositionerFunction, ChartType, Tooltip } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import autocolors from 'chartjs-plugin-autocolors';
import { event } from 'jquery';
import { Context } from 'chartjs-plugin-datalabels';
import { listeners } from 'process';
import { E } from '@angular/cdk/keycodes';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableModule } from '@angular/material/table';
import { Overlay } from '@angular/cdk/overlay';


declare module 'chart.js' {
	interface TooltipPositionerMap {
		top: TooltipPositionerFunction<ChartType>;
	}
}

Chart.register(autocolors);


@Component({
	selector: 'app-counter-pos-dashboard',
	templateUrl: './counter-pos-dashboard.component.html',
	styleUrls: ['./counter-pos-dashboard.component.scss'],
	animations: [
		trigger('flipState', [
			state('active', style({
				// transform: 'rotateY(179deg)',
				//safari does not like rotate Yputting the d
				transform: 'rotate3d(0, 1, 0, 179deg)',
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			state('orderlookup', style({
				transform: 'rotate3d(0, 1, 0, 179deg)',
			})),
			transition('active => inactive', animate('400ms ease-out')),
			transition('inactive => active', animate('400ms ease-in')),
			transition('inactive => orderlookup', animate('400ms ease-out')),
			transition('orderlookup => inactive', animate('400ms ease-in')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, overflow: 'hidden' })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})
export class CounterPosDashboardComponent implements OnInit {
	@ViewChild('monthView') monthViewRef: ElementRef;
	datepicked: any = false;
	public info = [];
	item: any = [];
	public ordersbyday: any = [];
	public max: any = [];
	public percentup: any = [];
	activeReport: any = 'Report 1';
	openorders: any = 'notready';
	needlocation: any = 'notready';
	negativestock: any = 'notready';
	newMessage: string;
	messageList: string[] = [];
	negativelink: any;
	needloclink: any;
	sender: any = [];
	flip = 'inactive';
	viewing: any = '';
	flip_text = '';
	color = 'blue'
	SENDER: any = [];
	messages = [];
	message: any = [];
	CHAT_ROOM = 'DashBoard';
	messageForm: any = [];
	carddate: any = [];
	posbyday: any = [];
	orderpweek: any = [];
	orderweek: any = [];
	config: any = false;
	popweek: any = [];
	poweek: any = [];
	popercentup: any = [];
	taxorders: any = [];
	thisoverlast: any = false;
	telegram_reports: string[] = ['Report 1', 'Report 2', 'Report 3', 'Report 4', 'Report 5'];
	datasource: any = false;
	lineover: any = false;
	catchart: any = false;
	linechart: any = false;
	linechartreverse: any = false;
	viewingdata: any = false;
	public dataDailySalesChart: any = {
		labels: [],
		series: [],
	};
	catandlines: any = false;
	userData;
	hidedash: any = true;
	user: any;
	telegramAllowed = true;
	category_table_data: any = [];
	line_table_data: any = [];

	dayInvoices: any = false;
	displaydetails: any = false;
	overLastStatus: { loading: boolean; day: number; monthname: string; monthnum: number; fromyear: string; toyear: string; viewing: string; source: number; message: string; temp: { year: any; month: any; }; };
	viewingInvoice: any = false;
	fetching_day: any = false;
	fetching_month: any = false;

	constructor(private router: Router, private sanitizer: DomSanitizer, public dashboardService: DashboardService, private cp: CurrencyPipe, public formBuilder: UntypedFormBuilder, private globalsService: GlobalsService, private globalSearchService: GlobalSearchService, private modalService: NgbModal, private usersService: UsersService) {
		this.color = this.globalsService.getColor();
		this.userData = this.usersService.getLocalUser();

		Tooltip.positioners.top = function (items) {
			const chart = this.chart;
			return {
				x: chart.chartArea.right,
				y: chart.chartArea.top,
				xAlign: 'center',
				yAlign: 'bottom'
			};
		};

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			if (this.userData.user.issalesman || this.config.env.package == 'tires') {
				this.telegramAllowed = false;
			}

			this.loadData();
		});
	}

	ngOnInit() {
		this.defaultThisOverLast();

		this.globalSearchService.mesagesallowed.next(false);
		this.messageForm = this.formBuilder.group({
			message: '',
		});
	}

	viewHistory(month: any, year: any) {
		if (parseInt(month) < 10) {
			month = '0' + month;
		}

		const date = new Date(year + '-' + month + '-15')

		this.datepicked = date;

		this.modalService.open(this.monthViewRef, { ariaLabelledBy: 'modal-title', size: 'xl', animation: false }).result.then((result) => {

		}, (reason) => {

		});
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./orders/entry/' + event.header.debtorno + '/' + event.header.branchcode]);
	}

	getMonthYearQty(month: any, year: any) {

		const obj = year.qty.filter((monthyear: any) => {
			return monthyear.label == month;
		})[0];

		switch (obj.monthgrowth) {
		case -1:
			var color = '';
			break;
		default:
			var color = (obj.monthgrowth > 0) ? 'text-success' : 'text-danger'
			break;
		}

		switch (obj.monthpreviousgrowth) {
		case 0:
		case -1:
			var mcolor = '';
			break;
		default:
			var mcolor = (obj.monthpreviousgrowth > 0) ? 'text-success' : 'text-danger'
			break;
		}

		const returnValue = {
			percent_month: obj.monthpreviousgrowth,
			percent_month_color: mcolor,
			percent_month_icon: (obj.monthpreviousgrowth > 0) ? 'fa-long-arrow-up' : 'fa-long-arrow-down',
			percent: (obj.monthgrowth == -1) ? 0 : obj.monthgrowth,
			class: color,
			icon: (obj.monthgrowth > 0) ? 'fa-long-arrow-up' : 'fa-long-arrow-down',
		};

		return returnValue;
	}

	formatData(data = [], title: any, expanded: any = false) {
		const _labels = [];
		const _series = [];
		const datapoints = [];

		if (!expanded) {
			data = data.slice(0, 10);
		}

		data.forEach((r, index) => {
			const value = {
				value: r.value,
				meta: r.label,
				name: r.label,
				label: r.label,
				data: r.value
			}
			_labels.push(r.label)

			if (this.config.env.package == 'tires') {
				_series.push(r.qty)
			} else {
				_series.push(r.value)
			}

			datapoints.push(value)
		});

		return {
			labels: _labels,
			datasets: [{
				fill: false,
				label: title,
				data: _series,
			}]
		};
	}

	getCostTotalYear(year: any) {
		const value = year.cost.reduce(function (accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	yearQtyGrowth(yearin: any) {

		// 		let value  = year.qty.reduce(function(accumulator: number, items: any) {
		// 			let addon = parseFloat(items.value);
		// 			return accumulator + addon;
		// 		}, 0);
		//
		// 		return value;
	}

	getSaleTotalYear(year: any) {
		let value = 0
		if (year) {
			value = year.sales.reduce(function (accumulator: number, items: any) {
				const addon = parseFloat(items.value);
				return accumulator + addon;
			}, 0);
		} else {
			value = 0
		}

		return value;
	}

	getQtyTotalYear(year: any) {
		const value = year.qty.reduce(function (accumulator: number, items: any) {
			const addon = parseFloat(items.value);
			return accumulator + addon;
		}, 0);

		return value;
	}

	subscritionsactive: any = [];

	loadData() {

		Chart.register(...registerables);
		Chart.register(ChartDataLabels);

		this.subscritionsactive.thislast = this.dashboardService.getThisYearOverLast().subscribe((results: any) => {
			this.overLastStatus.temp.year = results;
			this.overLastStatus.loading = false;

			this.thisoverlast = results;
			if (this.lineover && typeof this.catchart.lineover === 'function') {
				this.lineover.destroy();
			}

			const monthlables = results.label_sets[0];
			const datasets = results.data_sets

			this.createYearOverLast(monthlables, datasets);

		});



		this.subscritionsactive.catline = this.dashboardService.getCatAndLines().subscribe((results: any) => {
			this.catandlines = results;

			const catdata = this.formatData(results.category_sales, 'Category Sales', false);

			if (this.catchart && typeof this.catchart.destroy === 'function') {
				this.catchart.destroy();
			}
			if (this.telegramAllowed) {
				// this.catchart = new Chart('catChartRef', {
				// 	type: 'bar',
				// 	data: catdata,
				// 	options: {
				// 		responsive: true,
				// 		indexAxis: 'y',
				// 		plugins: {
				// 			legend: {
				// 				position: 'bottom',
				// 			},
				// 			title: {
				// 				display: false,
				// 				text: 'Category Sales'
				// 			},
				// 			autocolors: {
				// 				enabled: true,
				// 				mode: 'data'
				// 			}
				// 		}
				// 	}
				// });
			}

			if (this.linechart && typeof this.linechart.destroy === 'function') {
				this.linechart.destroy();
			}



			const linedata = this.formatData(results.line_sales.slice(0, 10), 'Product Line Sales', false);
			this.linechart = new Chart('plineChart', {
				type: 'bar',
				data: linedata,

				options: {
					indexAxis: 'y',
					responsive: true,
					plugins: {
						legend: {
							position: 'bottom',
						},
						title: {
							display: false,
							text: 'Product Line Sales'
						},
						autocolors: {
							enabled: true,
							mode: 'data'
						}
					}
				}
			});
			const linedatar = this.formatData(results.line_sales_reversed.slice(0, 10), 'Product Line Sales', false);
			this.linechartreverse = new Chart('plineChartR', {
				type: 'bar',
				data: linedatar,
				options: {
					indexAxis: 'y',
					responsive: true,
					plugins: {
						legend: {
							position: 'bottom',
						},
						title: {
							display: false,
							text: 'Product Line Sales'
						},
						autocolors: {
							enabled: true,
							offset: 3,
							mode: 'data'
						}
					}
				}
			});
		});
	}




	viewingData(data: any) {
		this.viewingdata = (data) ? true : false;
	}

	randomString(length, chars) {
		let result = '';
		for (let i = length; i > 0; --i) {result += chars[Math.floor(Math.random() * chars.length)];}
		return result;
	}
	toggleFlip(viewing: any) {
		this.viewing = viewing
		this.flip = (this.flip == 'active') ? 'inactive' : 'active';
	}

	nextChar(c) {
		return String.fromCharCode(c.charCodeAt(0) + 1);
	}

	getDataChartPoint(value: any, label: any, letter: any) {
		return {
			value: value,
			//name: label,
			className: 'ct-series-' + letter,
		}
	}

	getDayByDay(chart: any) {

		if (this.fetching_month) {
			this.fetching_month.unsubscribe();
		}

		const data = {
			monthnum: chart['tooltip']['dataPoints'][0].dataIndex + 1,
			fromyear: chart['legend']['legendItems'][0]['text'][0],
			toyear: chart['legend']['legendItems'][chart.legend.legendItems.length - 1]['text'][0]
		}

		this.overLastStatus.monthname = (new Date(0, data.monthnum, 0)).toLocaleString('default', { month: 'long' })
		this.overLastStatus.monthnum = data.monthnum;
		this.overLastStatus.message = `Loading ${this.overLastStatus.monthname}...`;
		this.overLastStatus.loading = true;

		this.fetching_month = this.dashboardService.getThisMonthOverLast(data).subscribe((results: any) => {
			this.overLastStatus.temp.month[0] = results;
			this.overLastStatus.loading = false;
			this.overLastStatus.viewing = 'month';

			this.thisoverlast = results;
			if (this.lineover) {
				this.lineover.destroy();
			}

			const monthlables = results.label_sets[0];
			const datasets = results.data_sets

			this.createMonthOverLast(monthlables, datasets);
		});
	}

	getDaySales(event, elements, chart: any) {
		if (this.fetching_day) {
			this.fetching_day.unsubscribe();
			this.overLastStatus.loading = false;
			this.overLastStatus.message = '';
		}
		//save a snapshot of overlaststatus to go back to
		this.overLastStatus.temp.month[1] = JSON.parse(JSON.stringify(this.overLastStatus));

		if (chart !== '') {
			//input came from canvas
			this.overLastStatus.fromyear = chart['legend']['legendItems'][0]['text'][0];
			this.overLastStatus.toyear = chart['legend']['legendItems'][chart.legend.legendItems.length - 1]['text'][0];
			this.overLastStatus.monthnum = new Date(Date.parse(`${this.overLastStatus.monthname} 1,2013`)).getMonth() + 1;
			this.overLastStatus.day = elements[0].index + 1;
		} else {
			//input came from year/year table button
			this.overLastStatus.fromyear = elements;
			this.overLastStatus.toyear = elements;
			this.overLastStatus.monthnum = new Date(Date.parse(`${this.overLastStatus.monthname} 1,2013`)).getMonth() + 1;
			this.overLastStatus.day = event;
		}

		//ternary is to not show matchiing from / to years
		this.overLastStatus.message = `Loading ${this.overLastStatus.monthname} ${this.overLastStatus.day}, ${this.overLastStatus.fromyear == this.overLastStatus.toyear ? this.overLastStatus.fromyear : `${this.overLastStatus.fromyear} - ${this.overLastStatus.toyear}`}`;
		this.overLastStatus.loading = true;

		this.fetching_day = this.dashboardService.getThisDayOverLast(this.overLastStatus).subscribe((results) => {
			this.openDocument(results[0].orderno, results[0].type, results[0].id, 'details', 'pdf');
			this.dayInvoices = results;
			this.overLastStatus.loading = false;
			this.overLastStatus.viewing = 'day';
		})
	}


	overLastBack() {
		const viewing = this.overLastStatus.viewing;

		switch (viewing) {
		case 'month':
			//back to year
			this.defaultThisOverLast();
			this.thisoverlast = this.overLastStatus.temp.year; //holds intial chart load data
			break;
		case 'day':
			//back to month
			this.thisoverlast = this.overLastStatus.temp.month[0]; //0 holds chart data for last viewing month
			this.overLastStatus = this.overLastStatus.temp.month[1]; //1 holds overlaststatus copy for last viewing month data
			this.dayInvoices = false;
			this.viewingInvoice = false;

			break;
		}

		const monthlables = this.thisoverlast.label_sets[0];
		const datasets = this.thisoverlast.data_sets

		if (this.lineover) {
			this.lineover.destroy();
		}

		switch (viewing) {
		case 'month':
			//back to year
			this.createYearOverLast(monthlables, datasets);
			break;
		case 'day':
			//back to month
			this.createMonthOverLast(monthlables, datasets);
			break;
		}
	}


	defaultThisOverLast() {
		if (this.overLastStatus) {
			var prev_data = this.overLastStatus.temp;
		}
		const temp = prev_data ? prev_data : { year: '', month: [] }

		this.overLastStatus = {
			loading: false,
			day: 0,
			monthname: '',
			monthnum: 0,
			fromyear: '',
			toyear: '',
			viewing: 'year',
			source: 2,
			message: '',
			temp: temp
		}

	}

	openDocument(orderno: any, type: any, id: any, content: any, display: string) {
		switch (type) {
		case '11':
			this.globalsService.getCreditNote(id, display).subscribe((result: any) => {
				this.displaydetails = result;
			})
			break;
		case '10':
			this.globalsService.getInvoice(id, display).subscribe((result: any) => {
				this.displaydetails = result;
			})
			break;
		}
		this.viewingInvoice = { id: id, orderno: orderno }
	}

	createYearOverLast(monthlables, datasets) {
		this.lineover = new Chart('thisYoy', {
			type: 'bar',
			data: {
				labels: monthlables,
				datasets: datasets
			},
			options: {
				responsive: true,
				onClick: (e) => {
					this.getDayByDay(e['chart']);
				},
				interaction: {
					mode: 'index'
				},
				plugins: {
					legend: {
						position: 'bottom',
					},
					title: {
						display: false,
						text: 'YoY Sales'
					},
					autocolors: {
						enabled: false,
						offset: 5,
						repeat: 2,
					}
				}
			}
		});
	}

	createMonthOverLast(monthlables, datasets) {
		this.lineover = new Chart('thisYoy', {
			type: 'bar',
			data: {
				labels: monthlables,
				datasets: datasets
			},
			options: {
				responsive: true,
				scales: {
					x: {
						stacked: true,
					},
					y: {
						stacked: true
					}
				},
				onClick: (e, context, chart) => {
					this.getDaySales(e, context, chart);
				},
				interaction: {
					mode: 'index'
				},
				plugins: {
					datalabels: {
						listeners: {
							enter: function (context, event) {
								return true;
							},
							leave: function (context, event) {
								return false;
							},
							click: function (context, event) {

							}
						},
						display: function (context) {
							if (context.active && context.dataset['data'][context.dataIndex] != 0) {
								return true;
							} else {
								return false;
							}

						},
						formatter: function (value, context) {
							return `${context.dataset.label} : $${value}`;
						},
						rotation: function (context) {
							return context.active ? 0 : 0;
						},
						align: 'center',
						borderWidth: 1,
						borderRadius: 5,
						borderColor: 'black',
						backgroundColor: function (context) {
							if (context.active) {
								return context.dataset.backgroundColor?.valueOf().toString().replace('.5', '1');
							}
						},
						color: 'black',
						opacity: 90,
					},
					legend: {
						position: 'bottom',
					},
					title: {
						display: false,
						text: 'YoY Sales'
					},
					autocolors: {
						enabled: false,
						offset: 5,
						repeat: 2,
					},
					tooltip: {
						position: 'top'
					}
				}
			}
		});
	}

	ngOnDestroy() {

		if (this.subscritionsactive.openorder) {
			this.subscritionsactive.openorder.unsubscribe();
		}

		if (this.subscritionsactive.neg) {
			this.subscritionsactive.neg.unsubscribe();
		}

		if (this.subscritionsactive.thislast) {
			this.subscritionsactive.thislast.unsubscribe();
		}

		if (this.subscritionsactive.catline) {
			this.subscritionsactive.catline.unsubscribe();
		}

		if (this.linechart) {
			this.linechart.destroy()
		}

		if (this.linechartreverse) {
			this.linechartreverse.destroy()
		}
	}
}
