import { ChangeDetectorRef, Component, OnInit, ElementRef, Input, Output, EventEmitter, HostListener, ViewChild, TemplateRef } from '@angular/core';
import { ROUTES } from '../../classes/routes';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { UsersService } from '../../services/users.service';
import { Message } from '../../classes/message';

import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { interval, Subscription, Observable } from 'rxjs';
import { OrdersService } from '../../services/orders.service';
import { OmsService } from '../../services/oms.service';
import * as moment from 'moment';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { getPrinters } from 'pdf-to-printer';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DispatchService } from 'app/services/dispatch.service';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

declare const google: any;

@Component({
	selector: 'app-navbar',
	templateUrl: './navbar.component.html',
	styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

	@Output() rightSidebarEvent = new EventEmitter < boolean > ();
	@ViewChild('checkedOrders') checkedOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('pickingOrders') pickingOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('pickedOrders') pickedOrdersTemplate = {} as TemplateRef < any > ;
	@ViewChild('sweeper') sweeperRef = {} as TemplateRef < any > ;
	@ViewChild('newOrders') newOrdersTemplate = {} as TemplateRef < any > ;

	private sort: MatSort;
	returningTruck: any;
	mapLoading: boolean = false;
	subbedToAlerts: boolean = false;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {

		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;

	navSubscription: Subscription
	idleState = 'NOT_STARTED';
	countdown ? : number = null;
	lastPing ? : Date = null;
	neworders: any = [];

	opened = false
	ordersopened = false

	listTitles: any[];
	location: Location;
	right_sidebar = true;
	mobile_menu_visible: any = 0;
	toggleButton: any;
	sidebarVisible: boolean;
	searchTerm = '';
	userData;
	orderData;
	searchResults;
	SENDER;
	messages = [];
	message;
	CHAT_ROOM = 'DashBoard';
	messageForm;
	messagecount = 0;
	public itcount = 0;
	sub;
	notificationcount: number;
	public collapseMini = false;
	color: any = 'blue';
	config: any = false;
	original_url: any = false;
	user: any = false;
	timer: any = false;

	pendingorders: any = [];
	allpendingorders: any = [];
	pendingopen: any = [];
	pickedorders: any = [];
	invoiceorders: any = [];
	pickingorders: any = [];
	pickopen: any = false;
	invoiceopen: any = false;
	checkopen: any = false;

	items_per_page = [10, 25, 50, 100, 500];
	pagesizedefault = 10;

	searchPending = new UntypedFormControl('');

	//truck alert & map
	@ViewChild('mapDiv') mapDivEle: ElementRef;
	@ViewChild('truckalert') truckalertRef: ElementRef;
	

	map: any = false;
	updated_map: any = false;
	markers: any = [false];
	pathlines: any = false;
	isMerging: boolean = false;
	map_animation: any = google.maps.Animation.DROP;


	durationInSeconds = 5;
	fetching: any = false;
	showsweeper: boolean = false;
	private ctrlPressed = false;
	private aPressed = false;

	@HostListener('document:keydown', ['$event'])
	handleKeyDown(event: KeyboardEvent): void {
		if (event.key === 'Control') {
			this.ctrlPressed = true;
		}
		if (event.key.toLowerCase() === 'h' && this.ctrlPressed) {
			this.aPressed = true;
		}
		if (event.key.toLowerCase() === 'j' && this.ctrlPressed && this.aPressed) {
			event.preventDefault(); // Optional: prevent browser shortcuts like 'Ctrl + A'
			if(!this.showsweeper) {
				this.showsweeper = true;
				this.openMinesweeper();
				console.log('Ctrl + h + j pressed. Sweeper activated!');
			} else {
				this.showsweeper = false;
			}
		}
	}

	@HostListener('document:keyup', ['$event'])
	handleKeyUp(event: KeyboardEvent): void {
		if (event.key === 'Control') {
			this.ctrlPressed = false;
		}
		if (event.key.toLowerCase() === 'h') {
			this.aPressed = false;
		}
	}
	constructor(private _snackBar: MatSnackBar, private dispatchService: DispatchService, private bottomSheet: MatBottomSheet, private omsService: OmsService, private modalService: NgbModal, private ordersService: OrdersService, location: Location, private element: ElementRef, private router: Router, private globalSearchService: GlobalSearchService, private usersService: UsersService, public formBuilder: UntypedFormBuilder, private idle: Idle, keepalive: Keepalive, public cd: ChangeDetectorRef) {

		this.color = this.globalSearchService.getColor();
		this.location = location;



		this.globalSearchService.configsubscription.subscribe(conf => {
			this.config = conf;
		});

		this.globalSearchService.user.subscribe(result => {
			this.user = result;
			if (this.config.package === 'beauty') {
				const data = [{ user: this.user.user.userid }];

				this.omsService.subscribeToOrderUpdates((err, data) => {
					this.loadCheckedOrders();
				});
			}

			//groups && users - phil is the user logged in on the order dashbaord tv in the office @ cornwall
			const allowed = ['11', 'PHIL', '22']
			if (this.config.env.package === 'tires' && !this.subbedToAlerts && (allowed.includes(this.user.user.user_group) || allowed.includes(this.user.user.userid))) {
				this.subbedToAlerts = true;
				this.omsService.subscribeToTruckClearAlerts(result => {
					this.showTruckReturnAlert(result.truck)
				})
			}
			//everyone else besides drivers
			if (this.config.env.package === 'tires' && (this.user.user.user_group !== '11') || this.user.user.userid === 'PHIL') {
				this.subbedToAlerts = true;
				this.omsService.subscribeToTruckClearAlerts(result => {
					const dateTime = new Date().toLocaleString();
					this.openSnackBar(`${result?.truck?.truckname} Returning | ${dateTime}`, 'Close');
				})
			}
		});

		idle.setIdle(600); // how long can they be inactive before considered idle, in seconds
		idle.setTimeout(600); // how long can they be idle before considered timed out, in seconds
		idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

		// do something when the user becomes idle
		idle.onIdleStart.subscribe(() => {
			this.idleState = 'IDLE';
			this.original_url = this.router.url
			this.autonav();
		});
		// do something when the user is no longer idle
		idle.onIdleEnd.subscribe(() => {
			this.idleState = 'NOT_IDLE';
			this.countdown = null;
			cd.detectChanges(); // how do i avoid this kludge?
			this.reset();
			this.router.navigate([this.original_url]);
		});

		// do something when the user has timed out
		idle.onTimeout.subscribe(() => this.idleState = 'TIMED_OUT');
		// do something as the timeout countdown does its thing
		idle.onTimeoutWarning.subscribe(seconds => this.countdown = seconds);
		
	}

	openSnackBar(message: string, action: string) {
		this._snackBar.open(message, action, {
			duration: 15000
		});
	}

	orderInNewOrder(data: any) {
		const thisorderexits = this.neworders.filter((a: any) => {
			return a.orderno == data.orderno;
		});
		return thisorderexits[0];
	}

	togglePickedOrders() {
		if (!this.pickopen) {
			this.pickopen = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.pickedOrdersTemplate, config);
		} else {
			this.pickopen = false;
			this.bottomSheet.dismiss(this.pickedOrdersTemplate);
		}
	}

	togglePickingOrders() {
		if (!this.pickopen) {
			this.pickopen = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.pickingOrdersTemplate, config);
		} else {
			this.pickopen = false;
			this.bottomSheet.dismiss(this.pickingOrdersTemplate);
		}
	}

	toggleCheckedOrders() {
		if (!this.checkopen) {
			this.checkopen = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.checkedOrdersTemplate, config);
		} else {
			this.checkopen = false;
			this.bottomSheet.dismiss(this.checkedOrdersTemplate);
		}
	}

	toggleNewOrders() {
		if (!this.ordersopened) {
			this.ordersopened = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.newOrdersTemplate, config);
		} else {
			this.ordersopened = false;
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		}
	}

	toggleBottomSheet() {
		if (!this.opened) {
			this.opened = true;
			const config = {
				hasBackdrop: true
			}
			this.bottomSheet.open(this.checkedOrdersTemplate, config);
		} else {
			this.opened = false;
			this.bottomSheet.dismiss(this.checkedOrdersTemplate);
		}
	}

	vibrate() {
		if (window.navigator && window.navigator.vibrate) {
			navigator.vibrate(2000);
		} else {
			// Not supported
		}
	}

	getColor() {
		return this.color;
	}

	resetNewOrders() {
		this.neworders = [];
		this.bottomSheet.dismiss(this.newOrdersTemplate);
	}

	closeBottomSheet() {
		this.bottomSheet.dismiss(this.checkedOrdersTemplate);
	}

	autonav() {

		if (this.idleState == 'IDLE') {
			if (this.user && this.user.user.user_group == '21') {
				this.navSubscription = interval(15000).subscribe((x => {
					this.rotateNav();
				}));
			} else {
				const d = new Date();
				const hour = d.getHours();
				if (hour > 20) {
					this.reset();
					//this.router.navigate(['auth/logout']);
					location.reload()
				}
			}
		}
	}

	rotateNav() {

		const navoptions = [
			'warehouse/warehouse-reports',
			'dashboard',
		]

		if (this.itcount == navoptions.length - 1) {
			this.itcount = 0;
		}

		const newroute = navoptions[this.itcount];
		if (this.user) {
			this.router.navigate([newroute]);
			this.itcount = this.itcount + 1
		}

		if (
			this.router.url != '/orders/entry' &&
			this.router.url != '/inventory/lookup' &&
			this.router.url != '/dispatches/route' &&
			this.router.url != '/orders/pick'

		) {

			if (this.sidebarVisible) {
				//	this.sidebarToggle();
				//	this.collapseMini = false;
				//	this.miniSideBar()
			}

			//this.router.navigate([newroute]);
		} else {
			//not allowed here.
		}

	}

	keyPress(event: KeyboardEvent): void {
		this.reset();
	}

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e) {
		this.reset();
	}

	reset() {
		// we'll call this method when we want to start/reset the idle process
		// reset any component state and be sure to call idle.watch()
		this.idle.watch();
		this.idleState = 'NOT_IDLE';
		this.countdown = null;
		this.lastPing = null;
		if (this.navSubscription) {
			this.navSubscription.unsubscribe();
		}



	}

	ngOnInit() {

		this.reset();
		this.setPagination([]);

		this.userData = this.usersService.getLocalUser();

		this.globalSearchService.messages.subscribe((results: any) => {
			this.messagecount = results;
		});

		this.globalSearchService.searchTerm.subscribe((newValue: any) => {
			this.searchTerm = newValue;
		});

		this.globalSearchService.userData.subscribe((newValue: any) => {
			this.userData = newValue;
			this.user = newValue;
			//this.loadCheckedOrders();
		});

		this.globalSearchService.searchResults.subscribe((newValue: any) => {
			this.searchResults = newValue;
		});

		this.listTitles = ROUTES.filter(listTitle => listTitle);

		this.listTitles.forEach(a => {
			if (a.children) {
				a.children.forEach(b => {
					if (this.listTitles.indexOf(b) < 1) {
						this.listTitles.push(b);
					}
				})
			}
		});

		const navbar: HTMLElement = this.element.nativeElement;
		this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
		this.router.events.subscribe((event) => {
			this.sidebarClose();
			const $layer: any = document.getElementsByClassName('close-layer')[0];
			if ($layer) {
				$layer.remove();
				this.mobile_menu_visible = 0;
			}
		});

		const navstate = this.usersService.getNavState();
		if (navstate) {
			this.collapseMini = false;
			this.miniSideBar();
		}


		this.loadCheckedOrders();

	}

	audioPlayNewOrder() {

		//var audio = new Audio("/assets/out-of-nowhere-message-tone.mp3");
		//let play = audio.play();
		// if (play !== undefined) {
		// 	play.catch(error => {
		// 		// Auto-play was prevented
		// 		// Show a UI element to let the user manually start playback
		// 	}).then(() => {
		// 		// Auto-play started
		// 	});
		// }
	}



	loadCheckedOrders() {
		// Setup search parameters with direct default location assignment
		const search = {
			loccode: this.user?.user.defaultlocation.loccode || '',
			debtorno: false,
			newonly: true,
		};

		// Cancel any active subscription
		if (this.fetching) {
			this.fetching?.unsubscribe();
		}

		// Process orders in a single-pass reduce function
		this.fetching = this.ordersService.getCustomerOpenOrdersFiltered(search).subscribe((results: any) => {
			if(results && results.length) {
				const categorizedOrders = results.reduce(
					(acc, order) => {
							const orderStatusId = parseInt(order.orderstatusid, 10);
		
							// Only process orders with placedfrom values of '3' or '4'
							if (order.placedfrom === '3' || order.placedfrom === '4') {
								switch (true) {
									case orderStatusId === 1:
										acc.pendingorders.push(order);
										break;
									case orderStatusId >= 5 && orderStatusId < 21:
										acc.pickingorders.push(order);
										break;
									case orderStatusId === 21:
										acc.pickedorders.push(order);
										break;
									case orderStatusId === 24:
										acc.invoiceorders.push(order);
										break;
								}
							}
							return acc;
						}, { pendingorders: [], pickingorders: [], pickedorders: [], invoiceorders: [] }
				);
				
				this.pendingorders = categorizedOrders.pendingorders;
				this.allpendingorders = categorizedOrders.pendingorders;
				this.setPagination(this.pendingorders);
				
				this.pickingorders = categorizedOrders.pickingorders;
				this.pickedorders = categorizedOrders.pickedorders;
				this.invoiceorders = categorizedOrders.invoiceorders;
			}

			
		});
	}



	loadOrder(orderno: any) {
		this.globalSearchService.reloadingOrder(true);
		this.ordersService.loadSalesOrder(orderno).subscribe((result: any) => {
			this.router.navigate(['./orders/entry/' + result.header.debtorno + '/' + result.header.branchcode]);
			this.globalSearchService.reloadingOrder(false);
			this.spliceandCloseNewOrder(orderno);
		});
	}

	spliceandCloseNewOrder(orderno: any) {
		//REMOVED New orders - made more sense for pending
		if (!this.neworders.length) {
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		} else {
			//not sure if this is wanted. place holder
			this.bottomSheet.dismiss(this.newOrdersTemplate);
		}
	}

	selectItemLookup(event: any) {
		this.router.navigate(['./customers/view/' + event.debtorno]);
	}

	pickOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['./orders/pick/' + event]);
	}

	checkOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['./orders/check/' + event]);
	}

	invoiceOrder(event: any) {
		this.bottomSheet.dismiss()
		this.router.navigate(['orders/invoice/' + event + '/dispatch']);
	}

	sidebarOpen() {
		const toggleButton = this.toggleButton;
		const body = document.getElementsByTagName('body')[0];
		setTimeout(function() {
			toggleButton.classList.add('toggled');
		}, 500);

		body.classList.add('nav-open');

		this.sidebarVisible = true;
	};

	sidebarClose() {
		const body = document.getElementsByTagName('body')[0];
		this.toggleButton.classList.remove('toggled');
		this.sidebarVisible = false;
		body.classList.remove('nav-open');
	};

	sidebarToggle() {
		// const toggleButton = this.toggleButton;
		// const body = document.getElementsByTagName('body')[0];
		const $toggle = document.getElementsByClassName('navbar-toggler')[0];
		//this.usersService.setNavState(this.sidebarVisible)

		if (this.sidebarVisible === false) {
			this.sidebarOpen();
		} else {
			this.sidebarClose();
		}
		const body = document.getElementsByTagName('body')[0];

		if (this.mobile_menu_visible == 1) {
			// $('html').removeClass('nav-open');
			body.classList.remove('nav-open');
			if ($layer) {
				$layer.remove();
			}
			setTimeout(function() {
				$toggle.classList.remove('toggled');
			}, 400);

			this.mobile_menu_visible = 0;
		} else {
			setTimeout(function() {
				$toggle.classList.add('toggled');
			}, 430);

			var $layer = document.createElement('div');
			$layer.setAttribute('class', 'close-layer');

			if (body.querySelectorAll('.main-panel')) {
				document.getElementsByClassName('main-panel')[0].appendChild($layer);
			} else if (body.classList.contains('off-canvas-sidebar')) {
				document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
			}

			setTimeout(function() {
				$layer.classList.add('visible');
			}, 100);

			$layer.onclick = function() { //asign a function
				body.classList.remove('nav-open');
				this.mobile_menu_visible = 0;
				$layer.classList.remove('visible');
				setTimeout(function() {
					$layer.remove();
					$toggle.classList.remove('toggled');
				}, 400);
			}.bind(this);

			body.classList.add('nav-open');
			this.mobile_menu_visible = 1;
		}
	};

	getTitle() {

		let titlee = this.location.prepareExternalUrl(this.location.path());
		if (titlee.charAt(0) === '#') {
			titlee = titlee.slice(1);
		}
		if (this.listTitles) {
			for (let item = 0; item < this.listTitles.length; item++) {
				if (this.listTitles[item].path === this.router.url) {
					return this.listTitles[item].title;
				}
			}
		}

		const subpath = titlee.split('/', 3);

		return this.capitalize(subpath)

		return 'Dashboard';
	}

	public capitalize(s) {

		let fullstring = '';
		s.forEach(function(value) {
			fullstring += value && value[0].toUpperCase() + value.slice(1) + ' ';
		});

		return fullstring;
	}

	public miniSideBar() {
		const body = document.getElementsByTagName('body')[0];

		if (this.collapseMini) {
			this.collapseMini = false;
			body.classList.remove('sidebar-mini');
		} else {
			this.collapseMini = true;
			body.classList.add('sidebar-mini');
		}

		this.usersService.setNavState(this.collapseMini);
	}

	public onInput(event: any) {
		// this pushes the input value into the service's Observable.
		this.globalSearchService.searchTerm.next(event.target.value);
	}

	right_side_bar() {
		this.right_sidebar = !this.right_sidebar
		this.rightSidebarEvent.emit(this.right_sidebar)
	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this.cd.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}

	searchOpenOrders(event: any) {
		if (event != '') {
			this.pendingorders = this.globalSearchService.filterItem(this.allpendingorders, event, 'orderno,deliverto,debtorno,deladdress1,deladdress3');
			this.setPagination(this.pendingorders);
		} else {
			this.pendingorders = this.allpendingorders;
			this.setPagination(this.pendingorders);
		}
	}

	resetCount() {
		this.messagecount = 0;
	}
	
	closeMineSweeper(): void {
		this.bottomSheet.dismiss(this.sweeperRef);
	}
	
	openMinesweeper(): void {
		const config = {
			hasBackdrop: true
		}
		
		this.bottomSheet.open(this.sweeperRef, config);
	}

	showTruckReturnAlert(data) {
		this.returningTruck = data;
		this.dispatchService.getActiveDispatches().subscribe((res: any) => {
			// if(res.in this.user.user.userid)

			if (!res['drivers'].includes(this.user.user.userid)) {
				this.modalService.open(this.truckalertRef, { ariaLabelledBy: 'modal-title', size: 'xl', backdropClass: 'interrupt', backdrop: 'static', keyboard: false, centered: true, windowClass: 'interrupt' }).result.then((result) => {}, (reason) => {});
				this.mapLoading = true;

				setTimeout(() => {
					this.drawMap(data);
				}, 2500)
			}
		})

	}

	drawMap(truck) {

		this.globalSearchService.hideSideBar();
		const coordinates = [];
		const lat = truck.lat;
		const lng = truck.lng;
		const truckname = truck.truckname;
		//bfdsc
		const home = { lat: 42.928455044944165, lng: -78.82142723259965 }
		const truckpos = { lat: Number(lat), lng: Number(lng) }
		const trucklat = new google.maps.LatLng(truckpos);
		const lines = [trucklat];
		lines.push(home)

		const m = new google.maps.Marker({
			position: home,
			title: 'BFDSC',
			optimized: true,
			animation: google.maps.Animation.DROP,
			label: {
				text: 'BFDSC',
				color: '#000',
				fontSize: '14px',
				fontWeight: 'bold'
			}
		}, );

		coordinates.push(m);

		const c = new google.maps.Marker({
			position: trucklat,
			title: truckname,
			optimized: true,
			animation: google.maps.Animation.DROP,
			label: {
				text: truckname,
				color: '#000',
				fontSize: '14px',
				fontWeight: 'bold'
			}
		});

		coordinates.push(c);

		const mapOptions = {
			zoom: 11,
			center: truckpos,
			scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
			styles: [{
				'featureType': 'water',
				'stylers': [{
					'saturation': 43
				}, {
					'lightness': -11
				}, {
					'hue': '#0088ff'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'geometry.fill',
				'stylers': [{
					'hue': '#ff0000'
				}, {
					'saturation': -100
				}, {
					'lightness': 99
				}]
			}, {
				'featureType': 'road',
				'elementType': 'geometry.stroke',
				'stylers': [{
					'color': '#808080'
				}, {
					'lightness': 54
				}]
			}, {
				'featureType': 'landscape.man_made',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ece2d9'
				}]
			}, {
				'featureType': 'poi.park',
				'elementType': 'geometry.fill',
				'stylers': [{
					'color': '#ccdca1'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'labels.text.fill',
				'stylers': [{
					'color': '#767676'
				}]
			}, {
				'featureType': 'road',
				'elementType': 'labels.text.stroke',
				'stylers': [{
					'color': '#ffffff'
				}]
			}, {
				'featureType': 'poi',
				'stylers': [{
					'visibility': 'off'
				}]
			}, {
				'featureType': 'landscape.natural',
				'elementType': 'geometry.fill',
				'stylers': [{
					'visibility': 'on'
				}, {
					'color': '#b8cb93'
				}]
			}, {
				'featureType': 'poi.park',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.sports_complex',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.medical',
				'stylers': [{
					'visibility': 'on'
				}]
			}, {
				'featureType': 'poi.business',
				'stylers': [{
					'visibility': 'simplified'
				}]
			}]

		};

		this.map = new google.maps.Map(document.getElementById('map'), mapOptions);

		coordinates.forEach((item: any) => {
			item.setMap(this.map);
		});

		const lineSymbol = {
			path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		};

		const pathTo = new google.maps.Polyline({
			path: lines,
			geodesic: true,
			strokeColor: '#F1575A',
			strokeOpacity: 1.0,
			strokeWeight: 2,
			icons: [{
				icon: lineSymbol,
				offset: '100%'
			}],
		});

		pathTo.setMap(this.map);
		this.mapLoading = false;
	}

	ngOnDestroy() {

	}
}