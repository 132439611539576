import { Component, OnDestroy, SimpleChanges, OnChanges, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

import * as FileSaver from 'file-saver';

import { CustomerService } from '../../../services/customer.service';
import { CreditService } from '../../services/credit.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PurchasingService } from '../../services/purchasing.service';
import { PaymentsService } from '../../services/payments.service';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { saveAs } from 'file-saver';


@Component({
	selector: 'app-credit-invoice',
	templateUrl: '../dispatch-invoice-shared.html',
	styleUrls: ['../dispatch-invoice-shared.scss']
})
export class CreditInvoiceComponent implements OnInit {

	@Input() orderno: any = false;
	@Output() invoiced = new EventEmitter < any > ();
	@Output() reloadCustomer = new EventEmitter < any > ();

	documentDetails: FormGroup;
	document_text = 'Credit';
	doc_return_type = 'Credit Type';
	document_type = '11';
	creditsApplied = false;


	invoice_result: any = false;
	sending = false;
	order: any = {
		header: [],
		details: [],
		//porequired: '',
		//freight_tax: [],
		tax_data: {},
	};


	debtorno: any = '';
	branchcode: any = '';
	//not being sent in the header. on time
	reprintlink: any = '';
	dispatchlink: any = '';
	dispatching = false;
	dispatch_details: any = [];
	//container for specifics about taxes.
	taxes: any = [];

	freight_tax_total = 0;
	freight_charge = 0.00;
	payment_total = 0.00
	freight_taxes: any = []
	display_total: any = '';

	payments_added: any = [];

	headercolumns = [
		'stockid',
		'quantity',
		'units'
	];

	totals = {
		subtotal: 0.00,
		freight: 0.00,
		tax: 0.00,
		discount: 0.00,
		total: 0.00,
	};

	taxdatatotal = 0;

	prefered_vendor: any = [];
	payments: any = [];
	color = 'blue'
	salespeople: any = false;
	terms: any = false;
	shipvias: any = false;
	baseUrl = '';
	invoiceLink = '';

	document_types = [
		{ label: 'Return To Stock', value: 'return' },
		{ label: 'Goods Written Off', value: 'writeoff' },
		{ label: 'Reverse Overcharge', value: 'reverse' },
	];

	constructor(private fb: FormBuilder, private creditService: CreditService, private route: ActivatedRoute, private customerService: CustomerService, private location: Location, public router: Router, private globalSearchService: GlobalSearchService, private purchasingService: PurchasingService, private paymentsService: PaymentsService) {
		this.color = this.globalSearchService.getColor();
		this.baseUrl = this.globalSearchService.config.apiServer.baseUrl;
		this.invoiceLink = this.globalSearchService.config.apiServer.creditLink;
	}

	ngOnInit(): void {

		this.globalSearchService.salespeople.subscribe(async (results: any) => {
			this.salespeople = results;
		});

		this.globalSearchService.terms.subscribe(async (results: any) => {
			this.terms = results;
		});

		this.globalSearchService.shipvia.subscribe((results: any) => {
			this.shipvias = results;
		});

		this.loadOrder();
	}

	setForm() {
		const credit_type = (this.order.header.options) ? this.order.header.options.credit_type : this.document_types[0].value;
		this.documentDetails = this.fb.group({
			invoiceDate: [new Date().toISOString(), Validators.required],
			packages: [1, Validators.required],
			invoiceText: [''],
			email: [this.order.invoiceemailaddress],
			customerref: [this.order.header.customerref],
			terms: [this.order.header.termsindicator, Validators.required],
			salesperson: [this.order.header.salesmancode, Validators.required],
			shipvia: [this.order.header.shipvia, Validators.required],
			document_type: [credit_type, Validators.required],
			pickedby : [],
			packedby : [],
		});

		this.documentDetails.get('document_type').disable();
	}

	getTotalDispatching() {
		let value = 0;
		if (this.order.details) {
			value = this.order.details.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.dispatch);
			}, 0);
		}

		return value;
	}


	getBalance() {
		const value = this.payment_total - this.totals.total;
		//if(value < 0) {
		//	value = 0.00
		//}
		return value;
	}

	loadOrder() {

		this.creditService.getOrder(this.orderno).subscribe((details) => {
			this.order = details

			this.freight_charge = parseFloat(this.order.header.freightcost)
			this.debtorno = this.order.header.debtorno
			this.branchcode = this.order.header.branchcode
			this.orderno = this.order.header.orderno
			//display on loaded
			this.order.tax_data.authorities.forEach(r => {
				r.taxrate = parseFloat(r.taxrate) * 100
			})

			const data = {
				orderno: this.order.header.orderno,
				debtorno: this.order.header.debtorno,
				branchcode: this.order.header.branchcode,
			}

			this.setForm();

			this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
				this.payments = results;
			})

			this.updateTotals();
		})
	}



	createInvoice() {
		if (this.order && this.documentDetails.valid) {
			const data = {
				order: this.order,
				totals: this.totals,
				payments: this.payments_added,
				taxes: this.taxes,
				document_form: this.documentDetails.value
			}
			this.sending = true;
			this.creditService.createInvoice(data).subscribe((results: any) => {
				this.sending = false;

				if (results.invoice.success) {
					this.invoiced.emit(results);
					this.reloadCustomer.emit(true)
					this.invoice_result = results;

					this.invoice_result = false;
					this.reloadCustomer.emit(true);
					this.clearCart();
					this.router.navigate(['/credits/success/' + results.invoice.id]);

				} else {
					alert('Error');
				}
			});
		} else {
			if (!this.documentDetails.valid) {
				this.globalSearchService.getFormValidationErrors(this.documentDetails);
			}
		}
	}

	clearCart() {
		this.creditService.clearOrder(this.order.header.debtorno, this.order.header.branchcode).subscribe((results: any) => {});
	}

	updateDispatchQty(event: any, item: any) {

		if (parseFloat(item.ordered) >= parseFloat(event.target.value)) {
			item.dispatch = parseFloat(event.target.value);
		} else {

			event.target.value = parseFloat(item.ordered);
			item.dispatch = parseFloat(item.ordered)
		}

		this.updateTotals()
	}

	setQty(event: any, item: any) {
		if (parseFloat(item.ordered) < parseFloat(event.target.value)) {
			item.dispatch = this.santizeNumberValue(event.target.value);
		} else {
			item.dispatch = item.ordered;
		}
		this.updateTotals()
		//this.getTotal();
	}

	santizeNumberValue(input: any) {
		return (Number.isNaN(input) || input == '') ? 0 : this.financial(input);
	}

	updatePayments(event: any) {

		if (event) {
			this.payments_added = event;
			this.payment_total = this.payments_added.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0);
		}

		const data = {
			orderno: this.order.header.orderno,
			debtorno: this.order.header.debtorno,
			branchcode: this.order.header.branchcode,
		}

		this.paymentsService.getOrderPayments(data).subscribe((results: any) => {
			this.payments = results;
			this.updateTotals()

			this.payment_total = this.financial(results.reduce(function(accumulator, item) {
				return parseFloat(accumulator) + parseFloat(item.amount);
			}, 0));

		});

	}

	termRequiresPayment() {
		const term = this.terms.filter(r => {
			return r.termsindicator == this.documentDetails.get('terms').value;
		})[0];

		if (term) {
			return term.collect_payment == '1';
		}

		return false;
	}

	setFreightCharge(event: any) {

		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			if (!(event.target.value.trim() === '.')) {}
		} else {

			this.order.header.freightcost = parseFloat(event.target.value)
			this.freight_charge = this.order.header.freightcost;
			this.updateTotals();
		}
	}

	setFreightTax(event: any, tax: any) {
		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			// If it's NaN or less than 0, set it to 0 unless the last character is a period
			if (!(event.target.value.trim() === '.')) {
				event.target.value = 0;
			}
		}

		tax.taxrate = parseFloat(event.target.value);
		this.updateTotals();
	}

	setTax(event: any, item: any, taxindex: any) {
		// Parse event.target.value as a number
		const value = parseFloat(event.target.value);

		// Check if the parsed value is NaN or less than 0
		if (isNaN(value) || value < 0) {
			// If it's NaN or less than 0, set it to 0 unless the last character is a period
			if (!(event.target.value.trim() === '.')) {
				event.target.value = 0;
			}
		}

		// Update tax rate with the sanitized value
		item.taxes[taxindex].taxrate = parseFloat(event.target.value);

		// Update totals
		this.updateTotals();
	}


	getFreightTax() {

		const total = 0
		this.freight_tax_total = 0;
		const freight = this.freight_charge

		let freighttax = 0;
		if (this.order) {
			const taxauths = [];
			this.freight_taxes = this.order.freight_tax;

			//by auth
			this.order.freight_tax.forEach((tax, index) => {

				const taxrate = this.roundToDecimalPlaces(tax.taxrate / 100, 6);
				const taxvalue = this.roundToDecimalPlaces(taxrate * freight, 2);
				taxauths.push({ taxauthid: tax.taxauthid, value: taxvalue })

			});

			this.freight_taxes = taxauths;
			//total freight tax
			freighttax = this.order.freight_tax.reduce(function(accumulator, tax) {
				return parseFloat(accumulator) + ((tax.taxrate / 100) * freight);
			}, 0);
		}

		this.freight_tax_total = freighttax;
		return freighttax;
	}

	roundToDecimalPlaces(num: number, decimals: number): number {
		const multiplier = Math.pow(10, decimals);
		return Math.round((num + Number.EPSILON) * multiplier) / multiplier;
	}

	updateTotals() {

		//todo clean this up. requires two containers..
		//parse items subtotals / taxes first.
		this.totals = {
			subtotal: 0.00,
			freight: 0.00,
			tax: 0.00,
			discount: 0.00,
			total: 0.00,
		};
		this.totals.total = 0;
		let totaltax = 0;
		//zero out tax containers
		const parent = this;

		this.order.tax_data.authorities.forEach((item, index) => {
			this.order.tax_data.authorities[index].total = 0;
		});

		const freighttax = this.getFreightTax()
		//add freight tax to tax total container

		this.freight_taxes.forEach((fghtax) => {
			this.order.tax_data.authorities.forEach((tax, index) => {
				if (tax.taxauthid == fghtax.taxauthid) {
					const value = parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
					if (value) {
						this.order.tax_data.authorities[index].total += parseFloat(Number.parseFloat(fghtax.value).toFixed(2));
					}
				}
			})
		})

		if (this.order?.details?.length) {
			// Iterate over order details
			this.order.details.forEach((item, index) => {
				// Calculate discount and subtotal per item
				const discountprice = item.unitprice - parent.financial(item.discountpercent * item.unitprice);
				const subtotal = parent.financial(discountprice * item.dispatch);

				// Set the line subtotal
				this.order.details[index].linesubtotal = subtotal;

				// Calculate taxes for each item
				if (this.order.tax_data.authorities.length > 0) {
					item.taxes.forEach((tax, subindex) => {
						const thistax = this.order.tax_data.authorities.find((all) => all.taxauthid === tax.taxauthid);
						if (thistax) {
							const tindex = this.order.tax_data.authorities.indexOf(thistax);
							const authtax = parent.financial((tax.taxrate / 100) * subtotal);

							// Update tax totals
							this.order.tax_data.authorities[tindex].total += authtax || 0.00;
							this.order.details[index].taxes[subindex].total = authtax || 0.00;
						}
					});
				}

				// Calculate total line tax and line total
				const total_linetax = item.taxes.reduce((accumulator, line) => {
					return accumulator + parent.financial((line.taxrate / 100) * subtotal);
				}, 0);

				this.order.details[index].taxtotal = total_linetax;
				this.order.details[index].linetotal = subtotal + total_linetax;

				totaltax += total_linetax;
			});

			// Calculate subtotal, tax, and total amounts
			this.totals.subtotal = this.order.details.reduce((accumulator, line) => {
				const discountpriced = parent.financial(line.discountpercent * line.unitprice);
				const discountprice = line.unitprice - discountpriced;
				const subtotal = parent.financial(discountprice * line.dispatch);
				return accumulator + subtotal;
			}, 0);

			this.taxdatatotal = this.order.tax_data.authorities.reduce((accumulator, line) => {
				return accumulator + line.total;
			}, 0);

			// Add freight tax to the total tax
			this.totals.tax = this.financial(totaltax) + this.financial(freighttax);
			this.totals.freight = this.freight_charge;

			// Final total calculation
			this.totals.total = this.totals.subtotal + this.totals.freight + this.totals.tax + this.totals.discount;

			// Pre-authorization check
			this.checkPreAuth();
		}
	}

	financial(num: number): number {
		const rounded = Math.round((num + Number.EPSILON) * 100) / 100; // Rounding to 2 decimal places again
		return parseFloat(rounded.toFixed(2));
	}

	back(): void {
		this.location.back()
	}

	addPoItems(supplier: any) {

		const item = [{
			stockid: '',
			description: '',
			line_notes: '',
			qty: '',
			price: '',
		}];

		this.purchasingService.addToOrder(item, supplier).subscribe((result) => {

		})
	}

	ngAfterViewInit(): void {

	}

	checkAuthStatus(authData: any): boolean {
		const existingAuthsExist = authData.existing_auths && authData.existing_auths.length > 0;
		const newAuthStatus = authData.new_auth === false;

		return existingAuthsExist && newAuthStatus;
	}

	checkPreAuth() {
		const { preauthdetails } = this.order;
		if (!preauthdetails) return;

		const authTotal = Number(preauthdetails.authtotal);
		const total = Number(this.totals.total);

		if (isNaN(authTotal) || isNaN(total)) {
			return;
		}

		const preAuthMessage = `Pre Authorization for $${authTotal.toFixed(2)} will be completed`;
		const mismatchMessage = `INVOICE AMOUNT DOES NOT MATCH AUTHORIZATION AMOUNT NEW AUTHORIZATION AMOUNT $${total.toFixed(2)}`;

		preauthdetails.message = authTotal !== total ? mismatchMessage : preAuthMessage;
	}

	ngOnChanges(changes: any) {
		if (changes.orderno) {
			this.invoice_result = false;
			this.loadOrder()
		}
	}

	setFreightChargeZero() {

		const value = 0;

		this.order.header.freightcost = 0
		this.freight_charge = this.order.header.freightcost;
		this.updateTotals();

	}


}
