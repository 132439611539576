import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common'
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup } from '@angular/forms';

import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { PurchasingService } from '../../services/purchasing.service';
import { InventoryService } from '../../services/inventory.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-purchasing-brand-priority',
	templateUrl: './purchasing-brand-priority.component.html',
	styleUrls: ['./purchasing-brand-priority.component.scss']
})
export class PurchasingBrandPriorityComponent implements OnInit {
	color = 'blue';
	brands: any = [];
	filteredBrands = [...this.brands];
	vendors: any = [];
	priorities: any = [];
	searchText: string = '';
	selectedVendor: { [key: string]: string } = {};
	vendor = new UntypedFormControl('');
	constructor(private inventoryService: InventoryService, private purchasingService: PurchasingService, private globalSearchService: GlobalSearchService, private location: Location, private route: ActivatedRoute, private globalsService: GlobalsService, private fb: UntypedFormBuilder, private modalService: NgbModal) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.inventoryService.getBrands().subscribe(results => {
			this.brands = results;
			this.filteredBrands = [...this.brands];
		})

		this.purchasingService.getSuppliers('').subscribe((results: any) => {
			this.vendors = results;
		});
	}

	loadPriorities(id: any) {
		this.inventoryService.getBrandPriorities(id).subscribe(results => {
			this.priorities = results;
		})
	}

	filterBrands(): void {
		const search = this.searchText.trim().toLowerCase();
		this.filteredBrands = this.brands.filter((brand) =>
			brand.name.toLowerCase().includes(search)
		);
	}

	// Clear the search box
	clearSearch(): void {
		this.searchText = '';
		this.filterBrands();
	}

	removeVendor(vendor: any, brand: any) {

		const index = brand.priorities.indexOf(vendor)
		brand.priorities.splice(index, 1)
		if (!brand.priorities.length) {
			brand.priorities = false
		}

		const data = { brand: brand, remove: vendor }
		this.inventoryService.removeBrandPriorities(data).subscribe(result => {

		});

	}

	addVendor(brand: any) {
		let proceed = true;
		const selectedVendorName = this.selectedVendor[brand.name]; // Get the selected vendor name from ngModel
	
		if (selectedVendorName) {
			const selectedVendor = this.vendors.find((v: any) => v.suppname === selectedVendorName);
	
			if (!brand.priorities) {
				brand.priorities = [];
			} else {
				// Check if the vendor already exists in the priorities
				const existingVendor = brand.priorities.find(
					(priority: any) => priority.supplierid === selectedVendor.supplierid
				);
	
				if (existingVendor) {
					proceed = false; // Prevent duplicates
				}
			}
	
			if (proceed) {
				// Add the selected vendor to priorities
				brand.priorities.push(selectedVendor);
	
				// Prepare data to send to the backend
				const data = { brand: brand, priorities: brand.priorities };
	
				// Call API to update priorities
				this.inventoryService.updateBrandPriorities(data).subscribe(() => {
					console.log('Vendor added successfully:', selectedVendor);
				});
	
				// Clear the selected vendor for this brand
				this.selectedVendor[brand.name] = '';
			}
		}
	}
	
	exportXls() {
		const alldata = [];
	
		// Define the mapping for brand-level fields
		const fieldMapping = {
			name: 'Brand Name',
			mfg_code: 'MFG Code',
			sort_order: 'Sort Order',
			isactive: 'Is Active',
			active: 'Active Status',
			atd_account: 'ATD Account',
			priority: 'Priority',
			warehouses: 'Warehouses',
			a1: 'A1',
			a2: 'A2',
			a3: 'A3',
		};
	
		// Iterate through brands and dynamically handle priorities
		this.brands.forEach((brand: any) => {
			// Format brand-level data
			const formattedBrand = {};
			Object.keys(fieldMapping).forEach((key) => {
				if (brand[key] !== undefined) {
					formattedBrand[fieldMapping[key]] = brand[key];
				}
			});
	
			// Check for priorities and dynamically include their keys
			brand.priorities.forEach((priority: any) => {
				const formattedPriority = { ...formattedBrand }; // Clone brand-level data
				Object.keys(priority).forEach((key) => {
					if (priority[key] !== undefined) {
						const columnName = key
							.replace(/_/g, ' ') // Convert underscores to spaces
							.replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize words
						formattedPriority[columnName] = priority[key];
					}
				});
	
				// Push the combined data to the export array
				alldata.push(formattedPriority);
			});
		});
	
		// Export the formatted data
		this.globalSearchService.exportJsonToExcel(alldata, 'Brand_Priorities');
	}
	
	drop(event: CdkDragDrop < string[] > , brand: any) {

		moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
		const data = { brand: brand, priorities: event.container.data }
		this.inventoryService.updateBrandPriorities(data).subscribe(result => {

		});

	}

	back(): void {
		this.location.back()
	}

}