import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';

import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';

@Component({
	selector: 'app-profit-loss',
	templateUrl: './profit-loss.component.html',
	styleUrls: ['./profit-loss.component.scss']
})
export class ProfitLossComponent implements OnInit {
	periods: any = [];
	default_period: any = [];
	default_toperiod: any = '';
	default_fromperiod: any = '';
	formGroup: UntypedFormGroup;
	loading = false;
	profit_loss: any = false;
	title = '';
	from_period_options: any = [];
	to_period_options: any = [];
	profit_loss_totals: any = [];
	pdflink: any = '';
	excellink: any = '';
	color: any = '';
	detailOrSummaryOptions = [
	  { value: 'detail', label: 'Detail' },
	  { value: 'summary', label: 'Summary' }
	];


	constructor(private location: Location, private generalLedgerService: GeneralLedgerService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder) {}

	ngOnInit(): void {

		this.color = this.globalSearchService.getColor();
		this.generalLedgerService.getPeriods().subscribe(async (results: any) => {
			this.periods = results.periods
			this.from_period_options = results.periods
			this.to_period_options = results.periods

			this.formGroup = this.fb.group({
				showAllAccounts: [false],
				detailOrSummary: ['detail', Validators.required],
				from_period: [results.default_fromperiod, [Validators.required]],
				to_period: [results.default_toperiod, [Validators.required]],
			});
		});

	}

	updateToOptions(value: any) {

		this.to_period_options = this.periods.filter((period) => {
			return parseInt(period.periodno) >= parseInt(value)
		});
	}

	calculateSectionTotal(
	  index: number,
	  field: 'monthactual' | 'periodactual'
	): number {
	  const currentSection = this.profit_loss[index].sectioninaccounts;

	  // Find all groups in the current section
	  const sectionGroups = this.profit_loss.filter(
		group => group.sectioninaccounts === currentSection
	  );

	  // Sum the totals for the specified field
	  return sectionGroups.reduce((sum, group) => sum + group.totals[field], 0);
	}

	calculateGrossProfit(
	  incomeIndex: number,
	  costOfSalesIndex: number,
	  field: 'monthactual' | 'periodactual'
	): number {
	  const totalIncome = this.calculateSectionTotal(incomeIndex, field);
	  const totalCostOfSales = this.calculateSectionTotal(costOfSalesIndex, field);

	  return totalIncome - totalCostOfSales;
	}

	calculateGrossProfitPercent(
	  incomeIndex: number,
	  costOfSalesIndex: number,
	  field: 'monthactual' | 'periodactual'
	): number {
	  const totalIncome = this.calculateSectionTotal(incomeIndex, field);
	  if (totalIncome === 0) return 0;

	  const grossProfit = this.calculateGrossProfit(incomeIndex, costOfSalesIndex, field);
	  return (grossProfit / totalIncome) ;
	}


	isSectionEnd(index: number): boolean {
		// Check if the current group is the last one in its section
		const currentSection = this.profit_loss[index].sectioninaccounts;
		const nextSection = this.profit_loss[index + 1]?.sectioninaccounts;
		return currentSection !== nextSection;
	}

	updateFromOptions(value: any) {
		this.from_period_options = this.periods.filter((period) => {
			return parseInt(period.periodno) <= parseInt(value)
		});
	}

	back() {
		this.location.back();
	}

	getTrialBalance() {

		if (this.formGroup.valid) {
			this.loading = true;
			this.generalLedgerService.getProfitAndLoss(this.formGroup.value).subscribe(async (results: any) => {
				this.loading = false;
				this.title = results.title
				this.profit_loss = results.data;
				this.profit_loss_totals = results.total;
				this.pdflink = results.pdflink;
				this.excellink = results.excellink;

			});
		}

	}

}