import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { InventoryService } from 'app/services/inventory.service';
import { PrintService } from 'app/services/print.service';

@Component({
	selector: 'app-bins-verified',
	templateUrl: './bins-verified.component.html',
	styleUrls: ['./bins-verified.component.scss']
})
export class BinsVerifiedComponent implements OnInit {
	verifiedbins: any = false;
	config: any;
	displayedColumns: string[] = ['bin', 'loccode', 'action']
	columnHeaders: string[] = ['Bin', 'Loccode', 'Action'];
	dataSource: MatTableDataSource<any>;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('addbinref') binref: ElementRef;

	loading: boolean = false;
	user: any = false;
	binid = new UntypedFormControl('');
	loc = new UntypedFormControl('');
	userLocs: any = false;
	locations: any;
	alreadyExists: boolean = false;


	constructor(private printService:PrintService, private inventoryService: InventoryService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) { }



	ngOnInit(): void {
		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
				this.loc.setValue( this.user.defaultlocation.loccode);
			}
		});

		this.globalSearchService.locations.subscribe((r:any) => {
			this.locations = r;
		});

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.loadData();
	}


	loadData() {
		this.loading = true;
		this.inventoryService.getVerifiedBinListing().subscribe((res: any) => {
			this.verifiedbins = res;
			this.updateDataSource(res);
			this.loading = false;
			this.binid.valueChanges.subscribe((newV)=>{
				this.alreadyExists = true;
				const match = this.verifiedbins.filter((b)=>{
					const loc = this.loc.value;
					return b.bin.toUpperCase() == newV.toUpperCase() && b.loccode == loc;
				})
				this.alreadyExists = match.length ? true : false;
			})
		})
	}

	updateDataSource(data) {
		this.dataSource = new MatTableDataSource(data)
		this.dataSource.paginator = this.paginator;
	}


	filter(event: any) {
		this.updateDataSource(this.verifiedbins.filter((bin) => {
			return bin.bin.toUpperCase().includes(event.target.value.toUpperCase());
		}));
	}

	addNewBin() {
		this.alreadyExists = false;
		this.binid.setValue('');
		this.modalService.open(this.binref, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {
			if(reason == 'action'){
				const bin = this.binid.value.toUpperCase();
				const loccode = this.loc.value;
				if(!this.alreadyExists){
					this.inventoryService.addVerifiedBin(bin, loccode).subscribe((res: any)=>{
						this.globalSearchService.showNotification(res.msg, 'success', 'bottom', 'left');
						this.loadData();
					})
				}else{
					this.globalSearchService.showNotification(`Verified bin "${bin}" already exists for location ${loccode}`, 'danger', 'bottom', 'left')
				}
			}
		});
	}

	dismissModal(){
		this.modalService.dismissAll('action');
	}

	removeBin(input) {
		const bin = input.bin;
		const loccode = input.loccode;

		this.inventoryService.removeFromVerifiedBins(bin, loccode).subscribe((res: any)=>{
			this.globalSearchService.showNotification(res.msg, 'success', 'bottom', 'left')
			this.loadData();
		})
	}

	getHtmlTable(){
		var html = '<table><thead><tr>';
		Object.keys(this.verifiedbins[0]).forEach((k)=>{
			html += "<th>" + k.charAt(0).toUpperCase() + k.slice(1) + "</th>";
		})
		html += "</tr></thead><tbody>";
		Object.values(this.verifiedbins).forEach((u)=>{
			html += '<tr>';
			html += '<td>' + u['loccode'] + '</td>'
			html += '<td>' + u['bin'] + '</td>'
			html += '</tr>';
		})
		html += '</tbody></table>';
		return html;

	}

	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.getHtmlTable());
		const data = {
			content: encoded,
			filename: `VerifiedBins`,
			title: `Verified Bin Listing`,
			subtitle: ``
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	exportPdf() {
		const encoded: string = this.globalSearchService.base64encode(this.getHtmlTable());
		const data = {
			content: encoded,
			filename: `VerifiedBins`,
			title: `Verified Bin Listing`,
			subtitle: ``
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}


}
