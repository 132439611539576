<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<!-- Card Wrapper -->
				<div class="card tp-box">
					<!-- Dynamic Card Header -->
					<div class="card-header card-header-{{ color }}-6">
						<h4 class="card-title">
							<i class="material-icons me-2 cursor-pointer" (click)="back()">arrow_left</i>
							Brand Priorities
						</h4>
						<p class="card-category mb-0">Manage and organize your brand priorities effectively</p>
					</div>

					<!-- Search Box -->
					<div class="card-body pb-0">
						<div class="row mb-4">
							<div class="col-md-2">
								<button mat-raised-button color="accent" (click)="exportXls()" *ngIf="filteredBrands" class="mt-2">
								<i class="fa fa-file-excel-o"></i>&nbsp;&nbsp;XLS</button>
							</div>
							<div class="col-md-6 offset-md-4">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Search Brands</mat-label>
									<input matInput type="text" placeholder="Type to search..." [(ngModel)]="searchText" (input)="filterBrands()" />
									<button mat-icon-button matSuffix aria-label="Clear" *ngIf="searchText" (click)="clearSearch()">
										<mat-icon>close</mat-icon>
									</button>
								</mat-form-field>
							</div>
						</div>
					</div>

					<!-- Card Content -->
					<div class="card-body">
					  <div class="row" *ngFor="let brand of filteredBrands">
						<div class="col-12">
						  <!-- Brand Section -->
						  <div class="brand-section border rounded p-3 mb-4 shadow-sm">
							<!-- Brand Header -->
							<div class="d-flex justify-content-between align-items-center">
							  <h5 class="brand-title">{{ brand.name }}</h5>
							  <div class="d-flex align-items-center">
								<!-- Custom Select -->
								<div class="custom-select-wrapper me-3">
								  <select class="custom-select" [(ngModel)]="selectedVendor[brand.name]">
									<option value="" disabled selected>Select Vendor</option>
									<option *ngFor="let v of vendors" [value]="v.suppname">
									  {{ v.suppname }}
									</option>
								  </select>
								</div>
					
								<!-- Add Vendor Button -->
								<button class="btn btn-primary btn-sm" (click)="addVendor(brand)">
								  Add Vendor
								</button>
							  </div>
							</div>
					
							<!-- Priorities List -->
							<div *ngIf="brand.priorities && brand.priorities.length > 0" class="priority-list mt-3">
							  <div
								cdkDropList
								class="vendor-list"
								[cdkDropListData]="brand.priorities"
								(cdkDropListDropped)="drop($event, brand)"
							  >
								<div
								  class="vendor-box"
								  *ngFor="let p of brand.priorities; let i = index;"
								  cdkDrag
								>
								  <span class="vendor-item">
									<span class="priority-badge">{{ i + 1 }}</span>
									{{ p.suppname }}
								  </span>
								  <button
									mat-icon-button
									color="warn"
									(click)="removeVendor(p, brand)"
									matTooltip="Remove Vendor"
								  >
									<mat-icon>remove</mat-icon>
								  </button>
								</div>
							  </div>
							</div>
							<!-- <div *ngIf="brand.priorities" class="priority-list mt-3">
							  <h6 class="priority-header">Priority List</h6>
							  <div class="vendor-list p-2">
								<div
								  class="vendor-box mb-2"
								  *ngFor="let p of brand.priorities; let i = index;"
								  cdkDrag
								>
								
								  <span class="vendor-item">
									  
									<span class="priority-badge">{{ i + 1 }}</span>
									{{ p.suppname }}
								  </span>
								  <button class="btn btn-outline-danger btn-sm" (click)="removeVendor(p, brand)">
									Remove
								  </button>
								</div>
							  </div>
							</div> -->
						  </div>
						</div>
					  </div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>