import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FixedAssetsService } from '../../services/fixed-assets.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Location } from '@angular/common';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-fixed-assets-item',
	templateUrl: './fixed-assets-item.component.html',
	styleUrls: ['./fixed-assets-item.component.scss'],
	animations: [
		trigger('expandCollapse', [
			state('void', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
			state('*', style({ height: '*', opacity: 1 })),
			transition('void <=> *', [animate('300ms ease-in-out')]),
		]),
	],
})
export class FixedAssetsItemComponent implements OnInit {
	fixedAssetForm: FormGroup;
	fixedAssets: any[] = [];
	assetCategories: any[] = [];
	assetLocations: any[] = [];
	editingAsset: any = null;
	isLoading = false;
	color = 'blue';
	isFormVisible = false;
	depreciationTypes: any[] = [
		{ id: '0', name: 'Straight Line' },
		{ id: '1', name: 'Declining Balance' },
	  ];

	constructor(
		private fb: FormBuilder,
		private fixedAssetsService: FixedAssetsService,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private location: Location,
		private globalSearchService: GlobalSearchService
	) {
		this.fixedAssetForm = this.fb.group({
			assetid : [null],
			description: ['', Validators.required],
			longdescription: ['', Validators.required],
			assetcategoryid: ['', Validators.required],
			assetlocation: ['', Validators.required],
			depntype: ['', Validators.required],
			depnrate: ['', Validators.required],
			barcode: [''],
			serialno: [''],
		});
	}

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.loadFixedAssets();
		this.loadAssetCategories();
		this.loadAssetLocations();
	}

	back(): void {
		this.location.back();
	}

	// Load Fixed Assets
	loadFixedAssets(): void {
		this.isLoading = true;
		this.fixedAssetsService.fetchAllAssets().subscribe(
			(response) => {
				this.fixedAssets = response.data || [];
				this.isLoading = false;
			},
			() => {
				this.snackBar.open('Error loading Fixed Assets.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	// Load Asset Categories
	loadAssetCategories(): void {
		this.fixedAssetsService.fetchAssetCategories().subscribe(
			(response) => {
				this.assetCategories = response.data || [];
			},
			() => {
				this.snackBar.open('Error loading Asset Categories.', 'Close', { duration: 3000 });
			}
		);
	}

	// Load Asset Locations
	loadAssetLocations(): void {
		this.fixedAssetsService.fetchAssetLocations().subscribe(
			(response) => {
				this.assetLocations = response.data || [];
			},
			() => {
				this.snackBar.open('Error loading Asset Locations.', 'Close', { duration: 3000 });
			}
		);
	}

	editAsset(asset) {
		this.editingAsset = asset;
		this.fixedAssetForm.patchValue(asset);
		this.isFormVisible = true;
	}
	// Save Fixed Asset
	saveFixedAsset(): void {
		if (this.fixedAssetForm.valid) {
			const data = this.fixedAssetForm.value;
			if (this.editingAsset) {
				data.assetId = this.editingAsset.assetId;
				this.fixedAssetsService.updateAsset(data).subscribe(() => {
					this.snackBar.open('Asset updated successfully.', 'Close', { duration: 3000 });
					this.loadFixedAssets();
					this.isFormVisible = false;
				});
			} else {
				this.fixedAssetsService.addAsset(data).subscribe(() => {
					this.snackBar.open('Asset added successfully.', 'Close', { duration: 3000 });
					this.loadFixedAssets();
					this.isFormVisible = false;
				});
			}
			this.resetForm();
		}
	}

	// Delete Fixed Asset
	deleteFixedAsset(assetId: string): void {
		if (confirm('Are you sure you want to delete this asset?')) {
			this.fixedAssetsService.deleteAsset(assetId).subscribe(() => {
				this.snackBar.open('Asset deleted successfully.', 'Close', { duration: 3000 });
				this.loadFixedAssets();
			});
		}
	}

	resetForm(): void {
		this.fixedAssetForm.reset();
		this.editingAsset = null;
	}
}