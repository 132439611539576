<div class="row mb-4">
	<div class="col-md-6">
		<button mat-raised-button color="accent" (click)="addContact()">Add New Contact</button>
	</div>
	<div class="col-md-6 text-right">
		<button mat-raised-button color="primary" (click)="onSubmit()">Save</button>
	</div>
</div>

<div class="row" *ngFor="let contact of vendor.contacts; let i = index">
	<div class="col-12 mb-4">
		<mat-card class="contact-card">
			<mat-card-header>
				<mat-card-title>Contact {{ i + 1 }}</mat-card-title>
				<button mat-icon-button color="warn" (click)="removeContact(contact.contid)">
					<mat-icon>delete</mat-icon>
				</button>
			</mat-card-header>
			<mat-card-content>
				<div class="row">
					<div class="col-md-12">
						<mat-form-field class="full-width" appearance="outline">
							<mat-label>Name</mat-label>
							<input matInput value="{{ contact.contactname }}" required placeholder="Contact Name" (input)="updateContactName($event, contact)" />
						</mat-form-field>
					</div>
					<div class="col-md-12">
						<mat-form-field class="full-width" appearance="outline">
							<mat-label>Phone</mat-label>
							<input matInput value="{{ contact.phoneno }}" placeholder="Contact Phone" (input)="updateContactPhone($event, contact)" />
						</mat-form-field>
					</div>
					<div class="col-md-12">
						<mat-form-field class="full-width" appearance="outline">
							<mat-label>Email</mat-label>
							<input matInput value="{{ contact.email }}" placeholder="Contact Email" (input)="updateContactEmail($event, contact)" />
						</mat-form-field>
					</div>
					<div class="col-md-12">
						<mat-form-field class="full-width" appearance="outline">
							<mat-label>Role</mat-label>
							<input matInput required value="{{ contact.role }}" placeholder="Contact Role" (input)="updateContactRole($event, contact)" />
						</mat-form-field>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>