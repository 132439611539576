import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { PaymentsService } from '../../services/payments.service';
import { PrintService } from '../../services/print.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-check-printing',
	templateUrl: './check-printing.component.html',
	styleUrls: ['./check-printing.component.scss']
})
export class CheckPrintingComponent implements OnInit {
	currencies: any[] = [];
	bankAccounts: any[] = [];
	paymentMethods: any[] = [];
	vendorTypes: any[] = [];
	dayPayments: any[] = [];

	selectedCurrency: string = 'USD';
	currency: string = 'USD';
	bankAccount: string = '10400';
	selectedBankAccount: string = '10400';
	checkNumber: string = '0';
	postingDate: string = new Date().toISOString().split('T')[0];
	vendorStart: string = '';
	vendorEnd: string = '';
	vendorType: string = '';
	includeDiscount: string = '1';
	dueDate: string = new Date(new Date().setDate(new Date().getDate() + 15)).toISOString().split('T')[0];

	color: string = 'blue';

	paymentForm: FormGroup;
	checkData: any = [];
	checklist: any = [];
	vendorTotals: any = [];
	data: any = [];
	postDates: any = [];
	totalDue: any = 0;
	totalDiscount: number = 0;
	selectedTransactions: number = 0;
	vendorChecks: {
		[key: string]: boolean } = {};
	vendorDates: {
		[key: string]: string } = {};
	sending: any = false;
	checks: any = [];
	responsePayload: any = [];
	constructor(
		private globalSearchService: GlobalSearchService,
		private paymentsService: PaymentsService,
		private http: HttpClient,
		private location: Location,
		private formBuilder: FormBuilder,
		private printService: PrintService,
	) {
		this.color = this.globalSearchService.getColor();

		this.paymentForm = this.formBuilder.group({
			bankaccount: [''],
			currency: [''],
			payment_type: [''],
			checkNumber: [''],
			postingDate: [new Date()]
		});
	}

	ngOnInit(): void {
		const payload = [];
		this.paymentsService.getCheckSetup(payload).subscribe(r => {
			this.vendorStart = r.vendorstart
			this.vendorEnd = r.vendorend
			this.vendorTypes = r.vendclass
			this.vendorTypes = r.vendclass
			this.paymentForm.get('checkNumber').setValue(r.CheckNum)
		})
		this.initializeBankAccounts();
		this.initializeCurrencies();
		this.initializePaymentMethods();
		this.fetchTodayPayments();
	}

	initializeBankAccounts() {
		this.globalSearchService.bankaccounts.subscribe((accounts: any) => {
			this.bankAccounts = accounts;
			if (accounts && accounts.length) {
				this.paymentForm.get('bankaccount')?.setValue(accounts[0].accountcode);
			}
		});
	}

	initializeCurrencies() {
		this.globalSearchService.currencies.subscribe((currencies: any) => {
			this.currencies = currencies;
			if (currencies && currencies.length) {
				this.globalSearchService.company.subscribe((company: any) => {
					if (company) {
						this.paymentForm.get('currency')?.setValue(company.currencydefault);
					}
				});
			}
		});
	}

	initializePaymentMethods() {
		this.globalSearchService.payment_methods.subscribe((paymentMethods: any) => {
			this.paymentMethods = paymentMethods;
			if (paymentMethods && paymentMethods.length) {
				this.paymentForm.get('payment_type')?.setValue(paymentMethods[0].paymentid);
			}
		});
	}

	fetchTodayPayments() {
		const today = new Date().toISOString().split('T')[0];
		this.paymentsService.getPayments(today).subscribe((results: any) => {
			this.dayPayments = results;

		});
	}

	back(): void {
		this.location.back();
	}

	searching: boolean = false;

	searchChecks() {
		const searchParams = {
			vendorStart: this.vendorStart,
			vendorEnd: this.vendorEnd,
			vendorType: this.vendorType,
			currency: this.paymentForm.get('currency')?.value,
			includeDiscount: this.includeDiscount,
			dueDate: this.dueDate,
			postingDate: this.postingDate
		};
		this.searching = true;
		this.paymentsService.searchChecks(searchParams).subscribe((results: any) => {
			this.searching = false;
			this.checkData = results;
			this.checklist = results.checklist;
			this.data = results;
			this.vendorTotals = results.vendortotals
			//this.totalDue = results.totaldue
			Object.keys(this.data.checklist).forEach(vendorKey => {
				this.vendorDates[vendorKey] = this.data.defCheckPostDate;
			});

		});
	}

	// Variables to hold totals
	// Function to update totals when a checkbox or input value changes
	updateTotals(vendorKey: string, transactions: any[]): void {
		this.totalDue = 0;
		this.totalDiscount = 0;
		this.selectedTransactions = 0;

		// Iterate through transactions to calculate totals
		transactions.forEach(transaction => {
			if (transaction.selected) {
				// Parse numbers to ensure correct calculations
				const due = parseFloat(transaction.bal || 0);
				const discount = parseFloat(transaction.disc || 0);

				this.totalDue += due;
				this.totalDiscount += discount;
				this.selectedTransactions++;
			}
		});
	}

	// Call this method whenever a checkbox or input changes
	updateVendorTotal(vendorKey: string): void {
		const vendorTransactions = this.checklist[vendorKey] || [];
		this.updateTotals(vendorKey, vendorTransactions);
	}


	refreshVendor(vendor: string): void {
		// Logic to refresh vendor-specific data
	}
	removeVendor(vendor: string): void {
		delete this.checklist[vendor];
		delete this.vendorTotals[vendor];
	}
	toggleGroup(vendor: string): void {
		const isChecked = (document.getElementById(`groupcheck-${vendor}`) as HTMLInputElement).checked;
		this.checklist[vendor].forEach((transaction: any) => {
			const checkbox = document.getElementById(`check-${transaction.id}`) as HTMLInputElement;
			checkbox.checked = isChecked;
		});
		this.updateVendorTotal(vendor);
	}

	isDueSoon(dueDate: string): boolean {
		const today = new Date();
		const due = new Date(dueDate);
		const timeDifference = due.getTime() - today.getTime();
		const daysDifference = timeDifference / (1000 * 3600 * 24); // Convert milliseconds to days

		return daysDifference <= 14 && daysDifference >= 0; // Highlight if due within 7 days
	}


	exportToPDF() {

	}
	applyFilters() {

	}

	refreshVendorData(vendorKey: string): void {
		console.log(`Refreshing data for vendor: ${vendorKey}`);
		// Implement API call or logic to refresh vendor data
	}

	updateGroupCheck(vendorKey: string): void {
	  const isChecked = this.vendorChecks[vendorKey];

	  // Update all transactions for the vendor based on the group checkbox state
	  const transactions = this.data.checklist[vendorKey] || [];
	  transactions.forEach((transaction: any) => {
		transaction.selected = isChecked;
	  });

	  // Recalculate totals
	  this.updateVendorTotal(vendorKey);
	}

	updateCheckDate(vendorKey: string): void {
		console.log(
			`Check date updated for vendor ${vendorKey}: ${this.vendorDates[vendorKey]}`
		);
		// Handle check date change logic
	}


	successMessage: string | null = null;

	finalizePostings(): void {
	  this.paymentsService.postChecksFinalize(this.responsePayload).subscribe(
		(results: any) => {
		  if (results.success) {
			this.responsePayload = [];
			this.checks = [];
			this.successMessage = 'Postings finalized successfully!';

			// Reset the success message after 5 seconds
			setTimeout(() => {
			  this.successMessage = null;
			}, 5000);
		  }
		},
		(error: any) => {
		  console.error('Error processing payments:', error);
		  alert('There was an error processing the payments. Please try again.');
		}
	  );
	}


	createPrint(): void {
		const payload = {
			reprint: "false",
			journal: this.paymentForm.get('checkNumber')?.value || '', // Get journal number from the form
			checkNum: this.paymentForm.get('checkNumber')?.value || '1001', // Default to '1001' if not set
			date: this.generateVendorDates(), // Generate the date map dynamically
			payamt: this.generatePayAmounts(), // Generate pay amounts dynamically
			discamt: this.generateDiscountAmounts(), // Generate discount amounts dynamically
			bankacct: this.paymentForm.get('bankaccount')?.value || '', // Bank account selected
			currency: this.paymentForm.get('currency')?.value || 'USD', // Default to USD
			duedate: this.dueDate, // Due date from the component
			include: this.generateIncludeMap() // Map of transactions to include
		};

		this.paymentsService.postChecks(payload).subscribe(
			(results: any) => {
				// Handle success
				this.responsePayload = results;
				this.formatChecks();
				this.checkData = [];
				this.checklist = [];
				this.data = [];
				this.vendorTotals = [];
				this.totalDue = 0;


			},
			(error: any) => {
				// Handle errors gracefully
				console.error('Error processing payments:', error);
				alert('There was an error processing the payments. Please try again.');
			}
		);
	}

	printCheck() {
		this.globalSearchService.downloadPdf(this.responsePayload?.base64content, this.responsePayload?.checkNum + '-CheckRun.pdf');
		this.sending = false;

	}
	formatChecks(): void {
		const checks = this.responsePayload?.checks;
		if (checks) {
			this.checks = Object.keys(checks).map((checkNumber) => {
				const checkData = checks[checkNumber];
				return {
					checkNumber,
					postings: checkData.postings,
					check: checkData.check,
					stub: checkData.stub
				};
			});
		}
	}

	// Helper to generate date mapping
	private generateVendorDates(): {
		[key: string]: string } {
		const dates: {
			[key: string]: string } = {};
		Object.keys(this.checklist).forEach(vendorKey => {
			dates[vendorKey] = this.postDates[vendorKey] || this.postingDate;
		});
		return dates;
	}

	// Helper to generate pay amounts mapping
	private generatePayAmounts(): {
		[key: string]: string } {
		const payAmounts: {
			[key: string]: string } = {};
		Object.keys(this.checklist).forEach(vendorKey => {
			this.checklist[vendorKey].forEach((transaction: any) => {
				if (transaction.selected) {
					payAmounts[transaction.id] = transaction.bal || '0.00';
				}
			});
		});
		return payAmounts;
	}

	// Helper to generate discount amounts mapping
	private generateDiscountAmounts(): {
		[key: string]: string } {
		const discountAmounts: {
			[key: string]: string } = {};
		Object.keys(this.checklist).forEach(vendorKey => {
			this.checklist[vendorKey].forEach((transaction: any) => {
				if (transaction.selected) {
					discountAmounts[transaction.id] = transaction.disc || '0.00';
				}
			});
		});
		return discountAmounts;
	}

	// Helper to generate include map
	private generateIncludeMap(): {
		[key: string]: boolean } {
		const includeMap: {
			[key: string]: boolean } = {};
		Object.keys(this.checklist).forEach(vendorKey => {
			this.checklist[vendorKey].forEach((transaction: any) => {
				includeMap[transaction.id] = transaction.selected || false;
			});
		});
		return includeMap;
	}



}