import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input , Pipe, PipeTransform, Output, EventEmitter , ViewEncapsulation,AfterViewInit,SimpleChanges, OnChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup,UntypedFormControl } from '@angular/forms';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../services/vendor.service';
import { GlobalsService } from '../../services/globals.service';
import { trigger, state, style, transition, animate , query, group, stagger } from '@angular/animations';
import { Location } from '@angular/common'

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { pdfDefaultOptions } from 'ngx-extended-pdf-viewer';

@Component({
	selector: 'app-vendor-transactions',
	templateUrl: './vendor-transactions.component.html',
	styleUrls: ['./vendor-transactions.component.scss'],
	animations: [
	  trigger('flipState', [
		  state('active', style({
			  transform: 'rotateY(-179deg)'
		  })),
		  state('inactive', style({
			  transform: 'rotateY(0)'
		  })),
		  transition('inactive => active', animate('250ms ease-in')),
		  transition('active => inactive', animate('250ms ease-out')),
	  ]),
	  trigger('openClose', [
		  state(':enter', style({ height: '*' })),
		  state(':leave', style({ height: '0px' })),
		  transition('false <=> true', animate(500))
	  ]),
		trigger('grow', [ // Note the trigger name
		  transition(':enter', [
			// :enter is alias to 'void => *'
				style({ height: '0',width: '0', overflow: 'hidden' }),
				animate(200, style({ height: '*' , width: '*' })),
				animate('200ms', style({ opacity: 1 })),
		  ]),
		  transition(':leave', [
			// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0 , overflow: 'hidden' })),
				animate('100ms', style({ opacity: 0 }))
		  ])
		]),
	]
})
export class VendorTransactionsComponent implements OnInit {

	headercolumns: string[] = [
		'transno',
		'typename',
		'trandate',
		'order_',
		'totalamount',
		'allocated',
		'balance',
		'settled',
		//'suppname',
		//'address1',
		//'telephone'
	];

	@Input() vendor: any = false;
	@Output() editing = new EventEmitter<boolean>();
	@Output() newvendordata = new EventEmitter<boolean>();
	@ViewChild('details') detailsRef: ElementRef;
	@ViewChild('allocations') allocationsRef: ElementRef;


	@ViewChild(MatSort, { static: false }) sort: MatSort;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	transactions: any = [];
	alltransactions: any = [];
	displaydetails: any = false;
	sending = false;

	keywords = new UntypedFormControl('')

	fromdateCtrl = new UntypedFormControl('')
	todateCtrl = new UntypedFormControl('')
	todate = new Date();
	fromdate = new Date();
	opentrans = new UntypedFormControl(true);


	type_filters = [
		{ value: 20, name: 'Invoice' },
		{ value: 21, name: 'Credit' },
		{ value: 22, name: 'Payment' },
	];

	flip = 'inactive';
	type_selected: any = '';
	transidtype: any =false;
	transid: any =false;

	constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,private vendorService: VendorService, public router: Router, private location: Location, private globalsService: GlobalsService,private modalService: NgbModal) { }

	ngOnInit(): void {


		const today = new Date();
		const priorDate = new Date(new Date().setDate(today.getDate() - 365));
		this.fromdate = priorDate;
		this.type_selected = [20,21,22]

		this.fromdateCtrl.setValue(this.fromdate)
		this.todateCtrl.setValue(this.todate)

		this.route.params.subscribe(params => {
			const supplierid = params['id'];
			this.updateTransactions();
			this.opentrans.valueChanges.subscribe(newValue => {

				if (newValue) {
					if (!this.alltransactions) {
						this.alltransactions = this.transactions;
					}
					this.transactions = this.alltransactions.filter((r: any) => {
						return r.settled == 'No';
					});
				} else {
					this.updateTransactions();
					this.transactions = this.alltransactions;
				}
			});
		});

	}

	loadAllocationTo(transid: any, type: any) {
		this.flip = 'active';
		this.transid = transid;
		this.transidtype = type;
	}

	loadAllocations(transid: any, type: any) {
		this.flip = 'active';
		this.transid = transid;
		this.transidtype = type;
	}

	getTransactionTotals() {
		let total = 0
		if (this.transactions) {
			total = this.transactions.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.totalamount);
			}, 0);
		}
		return total;
	}

	getAllocTotals() {
		let total = 0
		if (this.transactions) {
			total = this.transactions.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.allocated);
			}, 0);
		}
		return total;
	}

	getBalanceTotals() {
		let total = 0
		if (this.transactions) {
			total = this.transactions.reduce(function(accumulator, c) {
				return accumulator + (parseFloat(c.totalamount) - parseFloat(c.allocated));
			}, 0);
		}
		return total;
	}

	hassubscription: any = false;

	updateTransactions() {
		const search = {
			fromdate : this.fromdateCtrl.value,
			todate : this.todateCtrl.value,
			supplierid: this.vendor.supplierid,
			opentrans: this.opentrans.value,
			types: this.type_selected,
			keywords: this.keywords.value
		}
		this.sending = true;
		if(this.hassubscription) {
			this.hassubscription.unsubscribe();
		}
		this.hassubscription = this.vendorService.getVendorTransactions(search).subscribe( (results: any) => {
			this.alltransactions = results;
			//this.transactions = new MatTableDataSource(results);
			this.transactions = (results);
			this.transactions.sort = this.sort;
			this.transactions.paginator = this.paginator;
			this.sending = false;
		});

	}

	back(): void {
		this.editing.emit(false);
	}

	openDocument(transaction: any, content: any, display: string) {
		switch (transaction.type) {
		case '21':
			this.globalsService.getCreditNote(transaction.id, display).subscribe((r: any) => {
				this.displaydetails = r;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})
			break;
		case '20':
			this.globalsService.getVendorInvoice(transaction.id, display).subscribe((r: any) => {
				this.displaydetails = r;
				this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});
			})

			break;
		}
	}


	openGl(transaction: any, content: any) {
		this.globalsService.getTransactionGl(transaction.transno, transaction.type).subscribe((r: any) => {

			this.displaydetails = r;
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});

		})
	}

	viewPayments(transaction: any, content: any) {
		this.globalsService.getSupplierHowPaid(transaction.id, transaction.type).subscribe((r: any) => {

			this.displaydetails = r;
			this.modalService.open(content, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});

		})
	}

	getTotal(column: string): number {
	  if (!this.displaydetails?.data) return 0;
	  return this.displaydetails.data.reduce((total, row) => {
		return total + (parseFloat(row[column]) || 0);
	  }, 0);
	}


	viewAllocations(transaction: any, content: any) {
		this.globalsService.getVendoorAllocationsWType(transaction.id, transaction.type).subscribe((r: any) => {

			this.displaydetails = r;
			this.modalService.open(this.detailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {});

		})
	}
	sendemail: any = '';
	emailtrans: any = '';
// 	sendEmail(transaction: any, forcetype ? ) {
// 		this.sendemail = '';
// 		this.emailtrans = transaction;
// 		if (this.sendemail == '') {
// 			for (let x = 0; x < this.vendr.branches.length; x++) {
// 				if (this.customerdata.branches[x].branchcode == transaction.branchcode) {
// 					this.sendemail = this.customerdata.customer.invoiceemailaddress;
// 					if (forcetype) {
// 						this.emailtype = forcetype;
// 					} else {
// 						this.emailtype = false;
// 					}
// 				}
// 			}
// 		}
//
// 		this.modalService.dismissAll();
// 		this.modalService.open(this.emailRef, { ariaLabelledBy: 'modal-title', size: 'md' }).result.then((result) => {}, (reason) => {});
// 	}

	ngOnChanges(changes: any) {

		this.updateTransactions();
	}

	ngAfterViewInit() {
	}

}
