<div class="main-content" *ngIf="glaccounts">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							Journal Entry
						</h4>
						<p class="card-category text-right">GL Journal Entry</p>
					</div>
					<div class="card-body">
						<div class="row">
							<!-- Left Panel: Form -->
							<div class="col-md-4">
								<form [formGroup]="accountForm" class="journal-form" (ngSubmit)="addValue()">
									<mat-form-field appearance="outline" class="full-width">
										<mat-label>Journal Type</mat-label>
										<mat-select formControlName="type">
											<mat-option *ngFor="let type of journal_types" [value]="type">{{ type | uppercase }}</mat-option>
										</mat-select>
									</mat-form-field>

									<mat-form-field appearance="outline" class="full-width">
										<mat-label>Transaction Date</mat-label>
										<input matInput [matDatepicker]="picker" formControlName="trandate">
										<mat-datepicker-toggle matSuffix [for]="picker">
											<mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
										</mat-datepicker-toggle>
										<mat-datepicker #picker></mat-datepicker>
									</mat-form-field>
									<app-gl-account-lookup (glselected)="addGl($event)" [reset]="resetjournal" ></app-gl-account-lookup>

									<mat-form-field appearance="outline" class="full-width">
										<mat-label>Description</mat-label>
										<input matInput formControlName="description" placeholder="Enter description" appSelectOnFocus>
									</mat-form-field>

									<mat-form-field appearance="outline" class="full-width">
										<mat-label>Value</mat-label>
										<input matInput formControlName="debitcredit" type="number" placeholder="Enter value">
									</mat-form-field>

									<div class="text-left">
										<button mat-raised-button color="accent" class="full-width">Add</button>
									</div>
								</form>
							</div>

							<!-- Right Panel: Summary -->
							<div class="col-md-8">
								<ng-container *ngIf="debits_credits.length">
									<div class="summary-header">
										<h4>Journal Summary</h4>
										<div class="action-buttons">
											<button mat-icon-button color="primary" (click)="exportXls()">
												<i class="fa fa-file-excel-o"></i>
											</button>
											<button mat-icon-button color="warn" (click)="exportPdf()">
												<i class="fa fa-file-pdf-o"></i>
											</button>
										</div>
									</div>

									<h5 *ngIf="requiredToBalance() !== 0" class="alert alert-danger text-center">
										Required to Balance: {{ requiredToBalance() | currency }}
									</h5>
									<h5 *ngIf="requiredToBalance() === 0" class="alert alert-success text-center">
										In Balance: {{ requiredToBalance() | currency }}
									</h5>

									<div class="table-responsive">
										<table class="table table-striped">
											<thead>
												<tr>
													<th>Account</th>
													<th>Description</th>
													<th class="text-right">Debit</th>
													<th class="text-right">Credit</th>
													<th></th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let d of debits_credits">
													<td>{{ d.account.accountcode }} - {{ d.account.accountname }}</td>
													<td>{{ d.description }}</td>
													<td class="text-right">
														<span *ngIf="d.debitcredit >= 0">{{ d.debitcredit | currency }}</span>
													</td>
													<td class="text-right">
														<span *ngIf="d.debitcredit < 0">{{ d.debitcredit | currency }}</span>
													</td>
													<td>
														<button mat-icon-button color="warn" (click)="removeDC(d)">
															<mat-icon>delete</mat-icon>
														</button>
													</td>
												</tr>
												<tr>
													<td><b>Total</b></td>
													<td></td>
													<td class="text-right"><b>{{ getDebitTotal() | currency }}</b></td>
													<td class="text-right"><b>{{ getCreditTotal() | currency }}</b></td>
													<td></td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="text-right mt-3">
										<button mat-raised-button color="primary" [disabled]="requiredToBalance() !== 0" (click)="postJournal()">
											Post Journal
										</button>
									</div>
								</ng-container>

								<ng-container *ngIf="!debits_credits.length">
									<div class="empty-state">
										<mat-icon>note_add</mat-icon>
										<h5>Start Adding Entries</h5>
										<p>Values will display here as you enter them.</p>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>