import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, ControlContainer, FormGroupDirective, UntypedFormControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { GlobalsService } from '../../services/globals.service';
import { OrdersService } from '../../services/orders.service';
import { ReportsService } from '../../services/reports.service';
import { PrintService } from '../../services/print.service'
import * as FileSaver from 'file-saver';
import { CustomerService } from 'app/services/customer.service';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { interval, Observable } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-customer-export',
  templateUrl: './customer-export.component.html',
  styleUrls: ['./customer-export.component.scss']
})
export class CustomerExportComponent implements OnInit {

	@ViewChild('print_table') printtable: ElementRef;
	color: any = 'blue';
	config: any = [];
	public searchForm: UntypedFormGroup;
	cities: any = [];
	states: any = [];
	paymentterms: any = [];
	clubs: any = [];
	sending: any = false;
	orderResults: any;
	salespeople: any = [];
	fromdate: any = '';
	todate: any = '';
	filename: any = 'Customer_export_';
	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		if(this.paginator) {
			this.dataSource.paginator = this.paginator;
		}
	}
	
	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;

	ngOnInit(): void {}
	constructor(private _changeDetectorRef: ChangeDetectorRef, private customerService: CustomerService, private globalSearchService: GlobalSearchService, private fb: UntypedFormBuilder, private printService: PrintService, private globalsService: GlobalsService, private reportsService: ReportsService) {

		this.color = this.globalSearchService.getColor();
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		});

		this.reportsService.getSalesPeople().subscribe((results: any) => {
			this.salespeople = results;
		});
		
		this.customerService.getClubs().subscribe(details => {
			this.clubs = details;
		});
		this.customerService.getCities().subscribe(details => {
			this.cities = details;
		});
		this.customerService.getStates().subscribe(details => {
			this.states = details;
		});
		this.globalsService.getTerms().subscribe(details => {
			this.paymentterms = details;
		});
		const today = new Date();
		const priorDate = new Date(today.getFullYear(), today.getMonth(), 1);
		this.fromdate = priorDate;
		this.todate = today;
		
		this.searchForm = this.fb.group({
			fromDateCtrl: [this.fromdate],
			toDateCtrl: [this.todate],
			keywords: [''],
			city: [''],
			states: [''],
			salesperson: [''],
			payterms: [''],
			clubs: [''],
			clientsince: [''],
			balancedue: ['0'],
			salesfloor: [''],
			emailflag: ['0'],			
		});
		//this.setPagination([]);
	}
		
	back(){
	
	}
	
	getCustExport(){
	
		this.sending = true;


		this.customerService.getCustomerExport(this.searchForm.value).subscribe(r => {
			this.sending = false;
			this.orderResults = r;
			this.setPagination(this.orderResults.values);
		});
	}
	
	exportPdf() {

		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const today = new Date();
		const data = {
			content: encoded,
			filename: this.filename + today.getMonth() + today.getDate()+today.getFullYear(),
			title: ' Customer Export ',
			subtitle: ' created '+today.toLocaleString()
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls() {
		const today = new Date();
		const encoded: string = this.globalSearchService.base64encode(this.printtable.nativeElement.innerHTML);
		const data = {
			content: encoded,
			filename: this.filename+ today.getMonth() + today.getDate()+today.getFullYear(),
			title: ' Customer Export ',
			subtitle: ' created '+today.toLocaleString()
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}
	
	setPagination(tableData) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this._changeDetectorRef.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();

	}
}
