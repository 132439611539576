

<div class="main-content">
  <div class="container-fluid">
	<div class="row">
	  <div class="col-md-12">
		<div class="card">
		  <div class="card-header card-header-{{ color }}">
			<h4 class="card-title" id="headof">
			  <i class="material-icons" (click)="back()">arrow_left</i> Fixed Assets Management
			</h4>
		  </div>
		  <div class="card-body">
			<mat-tab-group>
			  <!-- Fixed Assets Tab -->
			  <mat-tab label="Items">
				<app-fixed-assets-item></app-fixed-assets-item>
			  </mat-tab>

			  <!-- Asset Categories Tab -->
			  <mat-tab label="Categories">
				<div class="tab-content">
				  <app-fixed-assets-categories></app-fixed-assets-categories>
				</div>
			  </mat-tab>

			  <!-- Asset Locations Tab -->
			  <mat-tab label="Locations">
				<div class="tab-content">
					<app-fixed-assets-locations></app-fixed-assets-locations>
				</div>
			  </mat-tab>
			  <!-- Asset Locations Tab -->
				<mat-tab label="Register">
					<ng-template matTabContent>
					<div class="tab-content">
						<app-fixed-assets-register></app-fixed-assets-register>
					</div>
					</ng-template>
				</mat-tab>

			</mat-tab-group>
		  </div>
		</div>
	  </div>
	</div>
  </div>
</div>
