import { Component, OnInit } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
	selector: 'app-minesweeper',
	templateUrl: './minesweeper.component.html',
	styleUrls: ['./minesweeper.component.scss']
})
export class MinesweeperComponent implements OnInit {
	grid: Cell[][];
	rows = 10;
	cols = 10;
	mines = 15;
	gameOver = false;
	gameWon = false;
	timer = 0;
	timerSubscription: Subscription;
	flagsRemaining: number;

	ngOnInit(): void {
		this.startGame();
	}

	startGame(): void {
		this.gameOver = false;
		this.gameWon = false;
		this.timer = 0;
		this.flagsRemaining = this.mines;
		this.grid = this.createGrid(this.rows, this.cols, this.mines);

		// Start the timer
		if (this.timerSubscription) {
			this.timerSubscription.unsubscribe();
		}
		this.timerSubscription = interval(1000).subscribe(() => {
			if (!this.gameOver && !this.gameWon) {
				this.timer++;
			}
		});
	}

	createGrid(rows: number, cols: number, mines: number): Cell[][] {
		const grid: Cell[][] = Array.from({ length: rows }, () =>
			Array.from({ length: cols }, () => ({
				revealed: false,
				mine: false,
				flagged: false,
				adjacentMines: 0
			}))
		);

		// Place mines
		for (let i = 0; i < mines; i++) {
			let row, col;
			do {
				row = Math.floor(Math.random() * rows);
				col = Math.floor(Math.random() * cols);
			} while (grid[row][col].mine);
			grid[row][col].mine = true;

			this.updateAdjacentCounts(grid, row, col);
		}

		return grid;
	}

	updateAdjacentCounts(grid: Cell[][], row: number, col: number): void {
		for (let r = row - 1; r <= row + 1; r++) {
			for (let c = col - 1; c <= col + 1; c++) {
				if (this.isValidCell(r, c) && !grid[r][c].mine) {
					grid[r][c].adjacentMines++;
				}
			}
		}
	}

	isValidCell(row: number, col: number): boolean {
		return row >= 0 && col >= 0 && row < this.rows && col < this.cols;
	}

	revealCell(row: number, col: number): void {
		const cell = this.grid[row][col];

		if (this.gameOver || cell.revealed || cell.flagged) return;

		cell.revealed = true;

		if (cell.mine) {
			this.gameOver = true;
			this.timerSubscription.unsubscribe();
			alert('Game Over! You hit a mine.');
			return;
		}

		if (cell.adjacentMines === 0) {
			this.revealAdjacentCells(row, col);
		}

		this.checkWinCondition();
	}

	revealAdjacentCells(row: number, col: number): void {
		for (let r = row - 1; r <= row + 1; r++) {
			for (let c = col - 1; c <= col + 1; c++) {
				if (this.isValidCell(r, c) && !this.grid[r][c].revealed) {
					this.revealCell(r, c);
				}
			}
		}
	}

	toggleFlag(row: number, col: number, event: MouseEvent): void {
		event.preventDefault();
		const cell = this.grid[row][col];

		if (cell.revealed) return;

		cell.flagged = !cell.flagged;
		this.flagsRemaining += cell.flagged ? -1 : 1;
		this.checkWinCondition();
	}

	checkWinCondition(): void {
		const allCellsCorrect = this.grid.flat().every((cell) =>
			cell.mine ?
			cell.flagged :
			cell.revealed
		);

		if (allCellsCorrect) {
			this.gameWon = true;
			this.timerSubscription.unsubscribe();
			alert('Congratulations! You won the game.');
		}
	}
}

interface Cell {
	revealed: boolean;
	mine: boolean;
	flagged: boolean;
	adjacentMines: number;
}