import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GeneralLedgerService } from '../../services/general-ledger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-gl-tags',
	templateUrl: './gl-tags.component.html',
	styleUrls: ['./gl-tags.component.scss'],
})
export class GLTagsComponent implements OnInit {
	glTagForm: FormGroup;
	tags: any[] = [];
	editingTag: any = null; // Tag currently being edited
	isLoading = false;
	color = 'blue';
	constructor(
		private fb: FormBuilder,
		private glTagsService: GeneralLedgerService,
		private snackBar: MatSnackBar,
		private globalSearchService: GlobalSearchService
	) {
		this.color = this.globalSearchService.getColor();
		this.glTagForm = this.fb.group({
			description: [''],
		});
	}

	ngOnInit(): void {
		this.loadTags();
	}

	// Fetch all tags
	loadTags(): void {
		this.isLoading = true;
		this.glTagsService.fetchTags({}).subscribe(
			(response) => {
				this.tags = response.tags || [];
				this.isLoading = false;
			},
			(error) => {
				this.snackBar.open('Error fetching tags.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	// Add or update tag
	saveTag(): void {
		const description = this.glTagForm.get('description')?.value;

		if (!description) {
			this.snackBar.open('Tag description is required.', 'Close', { duration: 3000 });
			return;
		}

		if (this.editingTag) {
			// Update existing tag
			this.glTagsService.updateTag({ tagId: this.editingTag.tagId, description }).subscribe(
				() => {
					this.snackBar.open('Tag updated successfully.', 'Close', { duration: 3000 });
					this.loadTags();
					this.resetForm();
				},
				(error) => {
					this.snackBar.open('Error updating tag.', 'Close', { duration: 3000 });
				}
			);
		} else {
			// Add new tag
			this.glTagsService.addTag({ description }).subscribe(
				() => {
					this.snackBar.open('Tag added successfully.', 'Close', { duration: 3000 });
					this.loadTags();
					this.resetForm();
				},
				(error) => {
					this.snackBar.open('Error adding tag.', 'Close', { duration: 3000 });
				}
			);
		}
	}

	// Delete a tag
	deleteTag(tagId: string): void {
		if (!confirm('Are you sure you want to delete this tag?')) {
			return;
		}

		this.glTagsService.deleteTag({ tagId }).subscribe(
			() => {
				this.snackBar.open('Tag deleted successfully.', 'Close', { duration: 3000 });
				this.loadTags();
			},
			(error) => {
				this.snackBar.open('Error deleting tag.', 'Close', { duration: 3000 });
			}
		);
	}

	// Edit a tag
	editTag(tag: any): void {
		this.editingTag = tag;
		this.glTagForm.patchValue({ description: tag.description });
	}

	// Reset the form
	resetForm(): void {
		this.editingTag = null;
		this.glTagForm.reset();
	}

	// Go back to the previous screen
	back(): void {
		window.history.back();
	}

}