<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<!-- Card Header -->
					<div class="card-header card-header-{{ color }}-1">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							GL Tags Management
						</h4>
						<p class="card-category text-right">View and manage GL Tags</p>
					</div>

					<!-- Card Body -->
					<div class="card-body">
						<!-- Tag Form -->
						<form [formGroup]="glTagForm" (ngSubmit)="saveTag()" class="p-4 border rounded shadow-sm bg-light">
							<div class="row g-3 align-items-end">
								<div class="col-md-8">
									<mat-form-field appearance="outline" class="w-100 mt-0 pt-0">
										<mat-label>{{ editingTag ? 'Edit Tag Description' : 'New Tag Description' }}</mat-label>
										<input matInput formControlName="description" />
									</mat-form-field>
								</div>
								<div class="col-md-4 text-end">
									<button mat-raised-button color="primary" type="submit" class="w-100">
										{{ editingTag ? 'Update Tag' : 'Add Tag' }}
									</button>
									<button mat-raised-button color="accent" type="button" class="w-100 mt-2" *ngIf="editingTag" (click)="resetForm()">
										Cancel
									</button>
								</div>
							</div>
						</form>

						<!-- Spinner -->
						<div class="col-md-12 text-center mt-4" *ngIf="isLoading">
							<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
								<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
							</svg>
						</div>

						<!-- Tags Table -->
						<div class="row mt-4">
							<div class="col-md-12">
								<div class="custom-table-wrapper">
									<table class="custom-table">
										<thead class="custom-table-header">
											<tr>
												<th class="custom-table-cell text-center">ID</th>
												<th class="custom-table-cell text-center">Description</th>
												<th class="custom-table-cell text-center">Actions</th>
											</tr>
										</thead>
										<tbody>
											<tr *ngFor="let tag of tags" class="custom-table-row">
												<td class="custom-table-cell text-center font-weight-bold">{{ tag.tagId }}</td>
												<td class="custom-table-cell text-left">{{ tag.description }}</td>
												<td class="custom-table-cell text-center">
													<div class="custom-actions-group">
														<button mat-icon-button color="primary" (click)="editTag(tag)" matTooltip="Edit Tag">
															<mat-icon>edit</mat-icon>
														</button>
														<button mat-icon-button color="warn" (click)="deleteTag(tag.tagId)" matTooltip="Delete Tag">
															<mat-icon>delete</mat-icon>
														</button>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
</div>