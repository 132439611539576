<ng-template #detailsModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{ orderdetails.header.name }} ( {{ orderdetails.header.debtorno
			}} ) </h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="row m-0 p-0 pb-1">
				<div class="col-6 m-0 p-0">
					<h4>INVOICE: <b>{{ orderdetails.header.orderno }}</b></h4>
				</div>
				<div class="col-6 m-0 p-0 d-flex justify-content-end">
					<button class="justify-content-end" mat-raised-button color="primary" (click)="openTrackingAssign(orderdetails.header.orderno)">
						<mat-icon>edit</mat-icon>Tracking
					</button>
				</div>

		</div>

		<div class="table table-responsive">
			<table class="table table-sm">
				<thead>
					<tr>
						<th class="text-left">ITEM</th>
						<th class="text-left">DESC</th>
						<th class="text-center">QTY</th>
						<th class="text-center">PRICE</th>
						<th class="text-right">EXT.</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of orderdetails.details">
						<td class="text-left"><b>{{ item.stkcode }}</b></td>
						<td>{{ item.description }}</td>
						<td class="text-center">{{ item.ordered }} </td>
						<td class="text-center">{{ item.ordered | currency}} </td>
						<td class="text-right">{{ item.unitprice * item.ordered | currency }} </td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>

<ng-template #trackingModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Assign Tracking Number</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')" id="trackingexit"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<app-order-tracking [forced_orderno]="this.trackingorder"  (updated)="dismissTracking()"></app-order-tracking>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>


<div class="main-content bg-white p-0">
	<div class="card-body no-overflow p-0">
		<div class="table table-responsive text-center searchlist" *ngIf="searching">
			<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
				<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
				</circle>
			</svg>
		</div>
		<ng-container *ngIf="itemlist && itemlist.length">
			<div class="table table-responsive searchlist mt-3" #searhlist>
				<table class="table table-sm table-hover">
					<thead class="sticky-thead">
						<tr>
							<th></th>
							<th class="text-left">ITEM</th>
							<th class="text-left">DESC</th>
						</tr>
					</thead>
					<tbody>
						<ng-container *ngFor="let item of itemlist ">
							<tr (click)="loadItem(item)">
								<td> <a mat-raised-button class="width: 20px">Select</a></td>
								<td class="text-left f12">
									{{ item.stockid }}
								</td>
								<td class="text-left ">{{ item.description }}</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</ng-container>

		<!-- item details -->
		<div class="table mt-3 shift-20" *ngIf="itemio && itemio.item && !itemlist">
			<ng-container *ngIf="itemio.item">
				<div class="title-section">
					<h2 class="item-title">{{ itemio.item.description }}</h2>
					<p class="item-stockid">( {{ itemio.item.stockid }} )</p>
				</div>

				<div class="card">
					<div class="card-header card-header-tabs card-header-{{color}}-2 p-0">
						<div class="nav-tabs-navigation">
							<div class="nav-tabs-wrapper text-nowrap">
								<ul class="nav nav-tabs col-12" data-tabs="tabs">
									<li class="nav-item col-3 mt-0 mb-0 text-center">
										<a mat-button class="nav-link active" href="#info" data-toggle="tab">
											<i class="material-icons">code</i>
											<div class="ripple-container"></div>
										</a>
									</li>
									<li class="nav-item col-3 mt-0 mb-0 text-center" *ngIf="config.env.package == 'tires'">
										<a mat-button class="nav-link" href="#picking" data-toggle="tab">
											<i class="material-icons">rule</i>
											<div class="ripple-container"></div>
										</a>
									</li>
									<li class="nav-item col-3 mt-0 mb-0 text-center" *ngIf="config.env.package == 'tires'">
										<a mat-button class="nav-link" href="#scantobin" data-toggle="tab">
											<i class="material-icons">archive</i>
											<div class="ripple-container"></div>
										</a>
									</li>
									<li class="nav-item col-3 mt-0 mb-0 text-center">
										<a mat-button class="nav-link" href="#history" data-toggle="tab">
											<i class="material-icons">cloud</i>
											<div class="ripple-container"></div>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div class="card-body p-0 m-02">
						<!-- <span (click)="loadItem(itemio.item)" class="reload">&nbsp;&nbsp;<mat-icon @flipState >refresh</mat-icon>&nbsp;&nbsp;</span> -->
						<div class="tab-content">
							<div class="tab-pane active" id="info">
								<div class="row m-0 p-0">
									<div class="col-12">
										<div class="spec-section">
											<!-- On Hand -->
											<div class="spec-row">
												<div class="spec-item">
													<div class="spec-label">On Hand</div>
													<div class="spec-value">
														{{ itemio.total_qoh }}
														<span *ngIf="already_scanned" class="spec-addition">(+{{ already_scanned }})</span>
													</div>
												</div>
												<div class="spec-item">
													<div class="spec-label">Committed</div>
													<div class="spec-value">{{ itemio.reserved }}</div>
												</div>
												<div class="spec-item">
													<div class="spec-label">Available</div>
													<div class="spec-value" [ngClass]="{
												  'available-high': itemio.total_qoh - itemio.reserved > itemio.item.minmax.minimum,
												  'available-low': itemio.total_qoh - itemio.reserved <= itemio.item.minmax.minimum
												}">
														{{ itemio.total_qoh - itemio.reserved }}
													</div>
												</div>
											</div>
											<div class="spec-row">
												<div class="spec-item">
													<div class="spec-label">On Order</div>
													<div class="spec-value">{{ itemio.openpos }}</div>
												</div>
												<div class="spec-item">
													<div class="spec-label">Min / Max</div>
													<div class="spec-value">
														<ng-container *ngIf="itemio.item">
															{{ itemio.item.minmax.minimum }} / {{ itemio.item.minmax.maximum }}
														</ng-container>
													</div>
												</div>
											</div>
										</div>
										<div class="bin-section" *ngIf="itemio.bins">
											<div class="bin-header">Bin Details</div>
											<div class="bin-list">
												<ng-container *ngFor="let loc of binDisplay">
													<div class="bin-item" [ngClass]="{ 'empty-bin': loc.quantity == '0' }" *ngIf="loc.defaultbin === '1' ">
														<div class="bin-label">
															<span *ngIf="loc.bin === ''"><i>N/A</i></span>
															<span *ngIf="loc.bin !== ''">{{ loc.bin }}</span>
														</div>
														<div class="bin-quantity">
															<span>{{ loc.quantity }}</span>
														</div>
													</div>
												</ng-container>
												<ng-container *ngFor="let loc of binDisplay">
													<div class="bin-item" [ngClass]="{ 'empty-bin': loc.quantity == '0' }" *ngIf="loc.defaultbin != '1' && loc.quantity > 0">
														<div class="bin-label">
															<span *ngIf="loc.bin === ''"><i>N/A</i></span>
															<span *ngIf="loc.bin !== ''">{{ loc.bin }}</span>
														</div>
														<div class="bin-quantity">
															<span>{{ loc.quantity }}</span>
														</div>
													</div>
												</ng-container>
											</div>
										</div>
										<div class="orders-section mb-2" *ngIf="itemio">
											<!-- Section Header -->
											<div class="section-header">
												<h2>Orders</h2>
											</div>

											<!-- No Orders -->
											<div class="no-orders" *ngIf="!itemio.reserved_orders">
												<p>No reserved orders.</p>
											</div>

											<!-- Orders List -->

											<div class="orders-list" *ngIf="itemio.salesordersdetails">

												<div *ngFor="let orders of itemio.salesordersdetails" class="order-card">
													<!-- Order Header -->
													<div class="order-header">
														<span class="order-number" (click)="loadOrder(orders.orderno)">
															<strong>Order #: {{ orders.orderno }}</strong>
														</span>
														<span *ngIf="orders.tracking.length" (click)="openTrackingAssign(orders.orderno)">
															Tracking #:
															<ng-container *ngFor="let track of orders.tracking">
																<span class="font-weight-light">({{track.shippername}})</span>{{track.trackingnumber}} &nbsp;
															</ng-container>
														</span>
														<span *ngIf="itemio.dispatches && itemio.dispatches[orders.orderno]" class="order-dispatch">
															Dispatch: <b>{{ itemio.dispatches[orders.orderno] }}</b>
														</span>
													</div>

													<!-- Order Items -->
													<div class="order-items">
														<div *ngFor="let order of itemio.reserved_orders_items[orders.orderno]; let index = index" class="order-row">
															<mat-icon class="status-icon" [ngClass]="{'zero-status': order.picked === 0,'partial-status': order.picked > 0 && order.picked < order.pick,'completed-status': order.picked === order.pick}">
																circle
															</mat-icon>
															<span class="bin-label">{{ order.bin }}</span>
															<span class="pick-status">{{ order.picked }}/<b>{{ order.pick }}</b></span>
															<span class="separator" *ngIf="index < itemio.reserved_orders_items[orders.orderno].length - 1">
																|
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane p-0" id="history">
								<mat-tab-group>
									<mat-tab label="Movements">
										<div class="col-12 p-0 m-0">
											<svg *ngIf="movementsLoading" class="spinner m-auto" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
											<div class="alert alert-warning" *ngIf="!itemMovements && !movementsLoading">No Movements found</div>
											<table class="table table-sm thead-dark table-striped table-hover table-bordered" *ngIf="itemMovements && !movementsLoading">
												<thead class="sticky-top">
													<tr>
														<th class="text-left w-20">Trandate</th>
														<th class="text-left w-20">Order</th>
														<th class="text-left w-20">Narrative</th>
														<th class="text-center w-20">QTY</th>
														<th class="text-center w-20">QOH</th>
														<th class="text-left w-20">User</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let h of dataObs | async">
														<td class="text-left">
															{{ h.trandate | date: 'MM/dd/yy' }}<br>
															{{ h.typename }}
														</td>
														<td class="text-left">{{ h.reference }}</td>
														<td class="text-left narrative-cell">{{ h.narrative }}</td>
														<td class="text-center">{{ h.qty }}</td>
														<td class="text-center">{{ h.newqoh }}</td>
														<td class="text-left">{{ h.userid }}</td>
													</tr>
												</tbody>
											</table>
											<mat-paginator [hidePageSize]="true" [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault" *ngIf="itemMovements">
											</mat-paginator>

											<mat-paginator [hidePageSize]="true" [pageSizeOptions]="items_per_page" [pageSize]="pagesizedefault" *ngIf="itemMovements"></mat-paginator>
										</div>
									</mat-tab>
									<mat-tab label="Picking History" *ngIf="item.item">
										<app-item-pick-history [item]="item.item" [source]="'warehouse'" (reload_lookup)="reloadData()"></app-item-pick-history>
									</mat-tab>
									<mat-tab label="Purchase History">
										<div class="table text-center" *ngIf="historyloading">
											<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
												<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
											</svg>
										</div>
										<div class="col-12" *ngIf="!history && !historyloading">
											<div class="alert alert-warning">No Purchase History</div>
										</div>
										<table class="table table-sm thead-dark table-striped table-hover table-bordered" *ngIf="history">
											<thead class="sticky-top">
												<tr>
													<th class="text-left w-20">Date</th>
													<th class="text-left w-20">Order #</th>
													<th class="text-left w-20">Note</th>
													<th class="text-center w-20">Qty</th>
													<th class="text-center w-20">QOH</th>
													<th class="text-left w-20">User</th>
												</tr>
											</thead>
											<tbody>
												<tr *ngFor="let h of dataObs | async">
													<td class="text-left">
														{{ h.trandate | date: 'MM/dd/yy' }}<br>
														{{ h.typename }}
													</td>
													<td class="text-left">{{ h.transno }}</td>
													<td class="text-left narrative-cell">{{ h.narrative }}</td>
													<td class="text-center">{{ h.qty }}</td>
													<td class="text-center">{{ h.newqoh }}</td>
													<td class="text-left">{{ h.userid }}</td>
												</tr>
											</tbody>
										</table>
									</mat-tab>
								</mat-tab-group>

							</div>
							<div class="tab-pane p-0" id="scantobin">
								<div class="col-12" *ngIf="bins.length && item.item.stockid">
									<app-bin-item-scan [bins]="bins" [forced_stockid]="item.item.stockid" (reload_lookup)="reloadData()"></app-bin-item-scan>
								</div>
							</div>
							<div class="tab-pane p-0" id="picking">
								<div class="col-12">
									<app-order-pick [orders_filter]="itemOrders" [picksonly]="true" (reload_lookup)="reloadData()"></app-order-pick>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-container>
		</div>

	</div>
	<div class="warehouse-nav">
		<div class="input-container">
			<input type="text" #searchRef [formControl]="lookup" id="searchRef" autocomplete="off" placeholder="Scan or Search..." (keyup.enter)="$event.stopPropagation(); $event.preventDefault(); lookupVersionEight(); $event.target.blur();" />
			<button class="search-button" type="button" (click)="$event.stopPropagation(); $event.preventDefault(); lookupVersionEight();">
				<mat-icon>search</mat-icon>
			</button>
		</div>
	</div>
</div>