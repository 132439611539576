import { Component, OnInit } from '@angular/core';
import { RouteInfo, ROUTES } from '../../classes/routes';
import { UsersService } from '../../services/users.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { ReportsService } from '../../services/reports.service';
import { GlobalsService } from '../../services/globals.service';
import { RuntimeConfigLoaderModule } from 'runtime-config-loader';
import { Router } from '@angular/router';
import { Subject, forkJoin, combineLatest, of } from 'rxjs';
import { switchMap, takeUntil, map } from 'rxjs/operators';
//import * as $ from "jquery";
declare const $: any;

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.css']
})

export class SidebarComponent implements OnInit {
	menuItems: any[];
	searchTerm: any;
	userData: any;
	searchResults: any;
	user: any;
	messagecount: any;
	navstate: boolean;
	reports: any = [];
	anchors: any = [];
	access: any = ['/customers'];
	always_allowed = ['/logout', '/auth/permission'];
	config: any = false;
	datasets: any = false;
	favorites: any = [];
	memoizedPathAllowed: any = [];
	constructor(public reportsService: ReportsService, private globalsService: GlobalsService, private userService: UsersService, private globalSearchService: GlobalSearchService, public router: Router) {
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})
	}

	ngOnInit() {
		this.memoizedPathAllowed = this.memoize(this.pathAllowed.bind(this));

		// Handle user and related subscriptions
		this.globalSearchService.user
			.pipe(
				switchMap((user: any) => {
					this.user = user;

					// Fetch reports and datasets conditionally
					const reports$ = this.reportsService.getReports();
					const datasets$ =
						user.user.department === '1'
							? this.globalSearchService.datasets
							: of(null);

					return forkJoin({ reports: reports$, datasets: datasets$ });
				})
			)
			.subscribe(({ reports, datasets }) => {
				this.reports = reports;
				if (datasets) {
					this.datasets = datasets;
				}
				this.initializeMenuItems();
			});

		// Subscribe to other services for state updates
		this.globalSearchService.messages.subscribe((results: any) => {
			this.messagecount = results;
		});

		this.globalSearchService.searchTerm.subscribe((newValue: string) => {
			this.searchTerm = newValue;
		});

		this.globalSearchService.userData.subscribe((newValue: string) => {
			this.userData = newValue;
		});

		this.globalSearchService.searchResults.subscribe((newValue: string) => {
			this.searchResults = newValue;
		});

		this.globalsService.getFavorites().subscribe((faves: any) => {
			this.favorites = faves;
		});
	}



	private initializeMenuItems(): void {
		this.menuItems = ROUTES.filter((menuItem) => {
			if (this.always_allowed.includes(menuItem.path)) {
				return true;
			}
			if (this.user?.user?.fullaccess < 8 && this.user?.user?.access_permissions) {
				return this.user.user.access_permissions.includes(menuItem.path);
			}
			return true;
		});
	}


	isTitleInReports(title: string): boolean {
		return this.reports.some(report => report.title === title);
	}


	setDataSet(event: any) {
		const newDataSet = event;
		localStorage.removeItem('app');
		this.globalSearchService.setPackage('app', event.config.env.package)
		const newpackaege = this.globalSearchService.getSessionItem('app');
		this.globalSearchService.configsubscription.next(event.config);
		this.router.navigate(['/']);
		this.config = event.config;
		RuntimeConfigLoaderModule.forRoot({ configUrl: './assets/config/config.' + event.config + '.json' })
	}

	anchorReports(anchor: any) {
		if (!this.reports) {
			return [];
		}

		return this.reports.filter((list: any) => list.group === anchor);
	}

	pathAllowed(path: string): boolean {
		const { fullaccess, access_permissions } = this.user.user;

		if (fullaccess < 8) {
			return access_permissions ? access_permissions.includes(path) : false;
		}

		return true;
	}


	memoize(fn: Function) {
		const cache = new Map();
		return function(...args: any[]) {
			const key = JSON.stringify(args);
			if (cache.has(key)) {
				return cache.get(key);
			}
			const result = fn.apply(this, args);
			cache.set(key, result);
			return result;
		};
	}


	navTo(link) {
		if (link != '') {
			this.router.navigate([link, { skipLocationChange: true }]);
		}
	}
	getNavState() {
		return this.userService.getNavState();
	}

	isMobileMenu() {
		if ($(window).width() > 991) {
			return false;
		}
		return true;
	};

	collapse(event: any) {
		const children = document.querySelector('.children');
		children.classList.remove('show');
	}

	resetCount() {
		this.messagecount = 0;
	}

	onInput(event: any) {
		// this pushes the input value into the service's Observable.
		this.globalSearchService.searchTerm.next(event.target.value);
	}

	selectParentMarker(element: string, secondref: string) {
		const el = $(element);

		// Remove classes from all menu items
		$('.children').removeClass('show');
		$('.nav .nav-item').removeClass('active');

		if (el.length) {
			const sidebar = $('.sidebar-wrapper');
			const sidebarOffset = sidebar.offset()?.top ?? 0;
			const elementOffset = el.offset()?.top ?? 0;

			// Calculate the scrollTop value to align the element at the top of the sidebar
			const targetScroll = sidebar.scrollTop() + elementOffset - sidebarOffset;

			// Animate scrolling to align the menu item at the top of the sidebar
			sidebar.animate({ scrollTop: targetScroll }, 100);

			// Add active and show classes to the relevant items
			$(`${secondref} .children`).addClass('show');
			el.addClass('active');
		} else {
			// Handle the case where the element doesn't exist
			$(`${secondref} .children`).addClass('show');
		}
	}




	selectMarker(element: string, secondref: string) {
		const el = $(element);
		if (el) {
			const sidebar = $('.sidebar-wrapper');
			if (el.offset()) {
				let count = el.offset().top - sidebar.offset().top - sidebar.scrollTop();
				if (count < 0) {
					count = el.offset().top - sidebar.offset().top + sidebar.scrollTop();
				}
				$('.children').removeClass('show');
				$(secondref).addClass('show');
				sidebar.animate({
					scrollTop: count
				}, 100);
			} else {
				$('.children').removeClass('show');
				$(secondref).addClass('show');

			}
		}

	}

	addFavorites(){
	let title = '';
	let icon = '';
	const path = this.router.url;

		for(var x = 0; x < this.menuItems.length; x++){

			if(this.menuItems[x].path == path){
			title = this.menuItems[x].title;
			icon = this.menuItems[x].icon;
			break;
			}
			//check children as well
			if(this.menuItems[x].children?.length > 0){

				this.menuItems[x].children.forEach(c => {
					if(c.path == path){
						title = c.title;
						icon = c.icon;
					}
				});
			}
			if(this.menuItems[x].reports?.length > 0){

				this.menuItems[x].reports.forEach(c => {
					if(c.path == path){
						title = c.title;
						icon = c.icon;
					}
				});
			}

		}
		if(title == ''){
		//Could be a report builder report.
			for(var x = 0; x < this.reports.length; x++){
				if(this.reports[x].nglink == path){
				title = this.reports[x].title;
				icon = 'article';
				break;
				}
			}
		}
		const data = {path: path, icon: icon, title: title};
		this.globalsService.addFavorite(data).subscribe((faves: any) => {
			this.favorites = faves;
		});
	}
	removeFavorites(){
	const path = this.router.url;
	let faveid = '';
	for(var x = 0; x < this.favorites.length; x++){


			if(this.favorites[x].path == path){
			faveid = this.favorites[x].faveid;
			break;
			}
		}
	let data = {faveid: faveid};
	this.globalsService.removeFavorite(data).subscribe((faves: any) => {
			this.favorites = faves;
		});
	}

	isFavorite(){

		let fave = false;
		const path = this.router.url;
		for(var x = 0; x < this.favorites.length; x++){


			if(this.favorites[x].path == path){
			fave = true;
			break;
			}
		}
		return fave;
	}

}
