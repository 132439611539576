import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { InventoryService } from '../../services/inventory.service'
import { EducationService } from '../../services/education.service'
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';

@Component({
	selector: 'app-product-groups',
	templateUrl: './product-groups.component.html',
	styleUrls: ['./product-groups.component.scss']
})
export class ProductGroupsComponent implements OnInit {
	color: any = 'blue';
	searchingcgroups: any = false;
	@ViewChild('groupEdit') groupEditRef: ElementRef;

	filteredcgroups: any = [];
	filteredcustomers: any = [];
	cgroups: any = [];
	customers: any = [];
	editinggroup: any = {};
	cgroupsearch = new FormControl('');
	custsearch = new FormControl('');
	categoryCtrl:any = new UntypedFormControl('');
	brandCtrl: any = new UntypedFormControl('');
	descCtrl: any = new UntypedFormControl('');
	prodCtrl:any = new UntypedFormControl('');
	categories: any = [];
	brands: any = [];
	productlines: any = [];
	picklist: any = [];
	files: File[] = [];
	uploadresults: any = [];
	viewingglc: any = false;
	constructor(private fb: UntypedFormBuilder, private inventoryService: InventoryService, private educationService: EducationService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) { }

	ngOnInit(): void {
		this.color = this.globalSearchService.getColor();
		this.cgroupsearch.valueChanges.subscribe(newValue => {
			this.filterGroup();
		});
		this.custsearch.valueChanges.subscribe(newValue => {

			this.filterCustomers();
		});
		this.inventoryService.getItems().subscribe(results => {
			this.customers = [];
			this.filteredcustomers = [];
		});
		this.inventoryService.getItemProductlines().subscribe((a) => {
		this.productlines = a;
		});
		this.inventoryService.getItemCategories().subscribe((a) => {
		this.categories = a;
		});
		this.inventoryService.getBrands().subscribe((a) => {
		this.brands = a;
		});
		this.loadGroups();
	}

	filterGroup() {

		if (this.cgroupsearch.value == '') {
			this.searchingcgroups = this.cgroups;
		} else {
			this.searchingcgroups = this.globalSearchService.filterItem(this.cgroups, this.cgroupsearch.value, 'name,description')
		}

	}
	filterCustomers() {

		if (this.custsearch.value == '') {
			this.filteredcustomers = this.customers;
		} else {
			this.inventoryService.itemSearch({ 'stockid': this.custsearch.value }).subscribe(results => {
				this.filteredcustomers = results;
			});
		}

	}

	loadGroups() {
		this.inventoryService.getGroups().subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
		});
	}

	addCustomerGroup() {

		this.editinggroup = { group_id: '', name: '', description: '', findmy: '', grouptype: 1, members: [], image: '', thumb: '' };
		this.modalService.open(this.groupEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});

	}

	editGroup(group: any) {
		this.editinggroup = group;

		this.modalService.open(this.groupEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}

	getProducts(){
		const data = {cats: this.categoryCtrl.value, prods: this.prodCtrl.value, brands: this.brandCtrl.value, desc: this.descCtrl.value};

		this.educationService.getProducts(data).subscribe((a)=>{
		this.picklist = a;
		});
	}

	addProducts(){
		this.picklist.forEach((i)=>{
		const p = {group_id: this.editinggroup.group_id, stockid: i.stockid, description: i.description};
		this.editinggroup.members.push(p);
		});
		this.picklist = [];
	}

	excludeProduct(item: any){
	const index = this.picklist.indexOf(item);
		if (index >= 0) {
			this.picklist.splice(index, 1);
		}
	}
	//So much for two-way binding
	updateGroup(fieldname: any, event: any) {

		if(fieldname == 'name'){
			this.editinggroup.name = event;
		} else if (fieldname == 'desc'){
			this.editinggroup.description = event;
		} else if (fieldname == 'findmy'){
			this.editinggroup.findmy = event;
		} else if (fieldname == 'grouptype'){
			this.editinggroup.grouptype = event.value;
		}
	}

	removeMember(cust: any) {
		const index = this.editinggroup.members.indexOf(cust);
		this.editinggroup.members.splice(index, 1);
	}
	setPrimary(cust: any, event: any) {

		const index = this.editinggroup.members.indexOf(cust);
		this.editinggroup.members[index].is_primary = event;
		this.editinggroup.members.forEach((member: any, index2) => {

			if (index2 != index) {
				member.is_primary = false;
			}
		});

	}
	saveGroup() {

		this.inventoryService.saveGroup(this.editinggroup).subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
			this.editinggroup = {};
			this.modalService.dismissAll();
		});

	}
	removeGroup(group) {
		this.inventoryService.removeGroup(group.group_id).subscribe(results => {
			this.cgroups = results;
			this.filteredcgroups = results;
			this.searchingcgroups = results;
			this.editinggroup = {};
		});
	}
	displayFn(cust: any): string {
		return cust ? cust.stockid + ': ' + cust.description : '';
	}
	back() {

	}

	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}

	onSelect(cat: any, event: any) {
		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff

				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.inventoryService.uploadItemGroupFile(upload, cat).subscribe((results: any) => {
					this.editinggroup.thumb = results.thumb;
					this.editinggroup.image = results.file;
				});

			});
		}
	}

	private async readFile(file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

}
