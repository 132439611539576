<div class="main-content">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<div class="card">
						<div class="card-header card-header-{{color}}">
							<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> Storefront Settings</h4>
						</div>
						<div class="card-body">
							<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
								<mat-tab label="Banners">
									<ng-template matTabContent>
										<app-banners></app-banners>
									</ng-template>
								</mat-tab>
								<mat-tab label="Basic Settings">
									<!--<app-banners></app-sales-people>-->
								</mat-tab>
								<mat-tab label="Categories">
									<ng-template matTabContent>
										<app-categories></app-categories>
									</ng-template>
								</mat-tab>
								<mat-tab label="Customers Awaiting Approval ({{ awaitingApprovalCount || 0 }})">
								  <app-customer-approval (customersawaiting)="updateApprovalCount($event)"></app-customer-approval>
								</mat-tab>

								<mat-tab label="Information">
									<ng-template matTabContent>
										<app-information></app-information>
									</ng-template>
								</mat-tab>
								<mat-tab label="QR Codes">
									<ng-template matTabContent>
										<app-qr-codes></app-qr-codes>
									</ng-template>
								</mat-tab>
								<mat-tab label="Best Sellers">
									<ng-template matTabContent>
										<app-bestsellers></app-bestsellers>
									</ng-template>
								</mat-tab>
							</mat-tab-group>
						</div>
					</div>
				</div>
			</div>
		</div>
</div>
