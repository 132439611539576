<div class="minesweeper">
	<h2>Minesweeper</h2>
	<div class="controls">
		<button (click)="startGame()">Restart</button>
		<div class="info">
			<span>Time: {{ timer }}s</span>
			<span>Flags: {{ flagsRemaining }}</span>
		</div>
	</div>
	<div class="grid">
		<div *ngFor="let row of grid; let rowIndex = index" class="row">
			<div *ngFor="let cell of row; let colIndex = index" class="cell" [class.revealed]="cell.revealed" [class.mine]="cell.revealed && cell.mine" [class.flagged]="cell.flagged" (click)="revealCell(rowIndex, colIndex)" (contextmenu)="toggleFlag(rowIndex, colIndex, $event)">
				<span *ngIf="cell.revealed && cell.adjacentMines > 0 && !cell.mine">
					{{ cell.adjacentMines }}
				</span>
			</div>
		</div>
	</div>
	<p *ngIf="gameOver">Game Over! Click "Restart" to try again.</p>
	<p *ngIf="gameWon">You Won! 🎉</p>
</div>