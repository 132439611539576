<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{ color }}">
						<h4 class="card-title" id="headof"><i class="material-icons" (click)="back()">arrow_left</i> Profit & Loss</h4>

					</div>
					<div class="card-body">
						<form [formGroup]="formGroup" *ngIf="formGroup">
							<div class="row">
								<!-- Checkbox for showing all accounts -->
								<div class="col-md-12 mb-3">
									<mat-checkbox formControlName="showAllAccounts">
										Show all Accounts including zero balances
									</mat-checkbox>
								</div>

								<!-- Dropdown for Detail or Summary -->
								<div class="col-md-12 mb-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Detail Or Summary</mat-label>
										<mat-select formControlName="detailOrSummary">
											<mat-option *ngFor="let option of detailOrSummaryOptions" [value]="option.value">
												{{ option.label }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<!-- From Period Selection -->
								<div class="col-md-6">
									<mat-form-field appearance="outline" class="ml-2 mr-2 w-100">
										<mat-label>From Period</mat-label>
										<mat-select formControlName="from_period" (selectionChange)="updateToOptions($event.value)">
											<mat-option *ngFor="let period of from_period_options" [value]="period.periodno">
												{{ period.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<!-- To Period Selection -->
								<div class="col-md-6">
									<mat-form-field appearance="outline" class="ml-2 mr-2 w-100">
										<mat-label>To Period</mat-label>
										<mat-select formControlName="to_period">
											<mat-option *ngFor="let period of to_period_options" [value]="period.periodno">
												{{ period.lastdate_in_period }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<!-- Action Buttons -->
								<div class="col-md-12 mt-3">
									<button mat-raised-button color="accent" (click)="getTrialBalance()">Run</button>
									&nbsp;
									<ng-container *ngIf="profit_loss && profit_loss.length">
										&nbsp;&nbsp;
										<a [href]="pdflink" class="btn btn-danger" mat-raised-button>
											<i class="fa fa-file-pdf-o"></i> PDF
										</a>
										&nbsp;&nbsp;
										<a [href]="excellink" class="btn btn-success" mat-raised-button>
											<i class="fa fa-file-excel-o"></i> Excel
										</a>
									</ng-container>
								</div>
							</div>

						</form>
						<div class="row">
							<div class="col-md-12 text-center" *ngIf="loading">
								<svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
									<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30">
									</circle>
								</svg>
							</div>
						</div>
						<ng-container *ngIf="profit_loss">
							<div class="profit-loss-container">
								<h3 class="title">{{ title }}</h3>

								<!-- Groups Section -->
								<div class="group" *ngFor="let group of profit_loss; let i = index; last as isLast">
									<h4 class="group-title" *ngIf="formGroup.get('detailOrSummary').value !='summary'">{{ group.title }}</h4>
									<div class="table-container">
										<table class="table table-striped table-bordered">
											<thead *ngIf="formGroup.get('detailOrSummary').value !='summary'">
												<tr>
													<th width="20%">Account</th>
													<th width="30%">Account Name</th>
													<th class="text-right">Period Actual</th>
													<th class="text-right">Period Budget</th>
													<th class="text-right">Last Yr</th>
												</tr>
											</thead>
											<tbody *ngIf="group && group.accounts">

												<ng-container *ngFor="let account of group.accounts">
													<ng-container *ngIf="formGroup.get('detailOrSummary').value !='summary'">
														<ng-container *ngIf="formGroup.get('showAllAccounts').value">
															<tr>
																<td>{{ account.accountcode }}</td>
																<td>{{ account.accountname }}</td>
																<td class="text-right">{{ account.periodactual | currency }}</td>
																<td class="text-right">{{ account.periodbudget | currency }}</td>
																<td class="text-right">{{ account.accountperiodly | currency }}</td>
															</tr>
														</ng-container>
														<ng-container *ngIf="!formGroup.get('showAllAccounts').value">
															<tr *ngIf="(account.periodactual !== 0 || account.accountperiodly !== 0)">
																<td>{{ account.accountcode }}</td>
																<td>{{ account.accountname }}</td>
																<td class="text-right">{{ account.periodactual | currency }}</td>
																<td class="text-right">{{ account.periodbudget | currency }}</td>
																<td class="text-right">{{ account.accountperiodly | currency }}</td>
															</tr>
														</ng-container>

													</ng-container>
												</ng-container>

												<tr>
													<td colspan="2">
														<h5 class="bold mt-3">
															{{ group.title }}
														</h5>
													</td>
													<td class="text-right">{{ group.totals.periodactual | currency }}</td>
													<td class="text-right">{{ group.totals.periodbudget | currency }}</td>
													<td class="text-right">{{ group.totals.monthactual | currency }}</td>
												</tr>
												<ng-container *ngIf="isSectionEnd(i)">
												  <tr>
													<td colspan="5">
													  <div class="custom-section-total-container">
														<div class="custom-section-header">
														  <h4>{{ group.glgroup }} Totals</h4>
														</div>

														<div class="custom-section-content">
														  <!-- Total calculations -->
														  <ng-container *ngIf="profit_loss[i].sectioninaccounts !== 2">
															<p class="custom-total-item">
															  <span class="custom-total-label">Total:</span>
															  <span class="custom-total-value">{{ calculateSectionTotal(i, 'periodactual') | currency }}</span>
															</p>
														  </ng-container>

														  <!-- Gross Profit calculations (conditionally rendered) -->
														  <ng-container *ngIf="profit_loss[i].sectioninaccounts === 2"> <!-- Cost of Sales -->
															<p class="custom-total-item">
															  <span class="custom-total-label">Total:</span>
															  <span class="custom-total-value">{{ calculateSectionTotal(i, 'periodactual') | currency }}</span>
															</p>
															<p class="custom-total-item custom-gross-profit">
															  <span class="custom-total-label">Gross Profit:</span>
															  <span class="custom-total-value">{{ calculateGrossProfit(0, i, 'periodactual') | currency }}</span>
															</p>
															<p class="custom-total-item custom-gross-profit-percent">
															  <span class="custom-total-label">Gross Profit Percent:</span>
															  <span class="custom-total-value">{{ calculateGrossProfitPercent(0, i, 'periodactual') | percent:'1.2-2' }}</span>
															</p>
														  </ng-container>
														</div>
													  </div>
													</td>
												  </tr>
												</ng-container>

											</tbody>
										</table>
									</div>
								</div>

								<!-- Totals Section -->
								<div class="totals-container" *ngIf="profit_loss_totals">
									<!-- Header Row -->
									<div class="total-item header">
										<span class="label"></span>
										<span class="value">This Year</span>
										<span class="value">Last Year</span>
									</div>

									<!-- Total Revenue -->
									<div class="total-item">
										<span class="label">Total Revenue:</span>
										<span class="value">{{ profit_loss_totals.revenue | currency }}</span>
										<span class="value">{{ profit_loss_totals.revenuely | currency }}</span>
									</div>

									<!-- Total Cost of Goods -->
									<div class="total-item">
										<span class="label">Total Cost of Goods:</span>
										<span class="value">{{ profit_loss_totals.cogs | currency }}</span>
										<span class="value">{{ profit_loss_totals.cogsly | currency }}</span>
									</div>

									<!-- Gross Profit -->
									<div class="total-item">
										<span class="label">Gross Profit:</span>
										<span class="value">{{ profit_loss_totals.revenue - profit_loss_totals.cogs | currency }}</span>
										<span class="value">{{ profit_loss_totals.revenuely - profit_loss_totals.cogsly | currency }}</span>
									</div>

									<!-- Gross Profit Percent -->
									<div class="total-item">
										<span class="label">Gross Profit Percent:</span>
										<span class="value">
											{{ ((profit_loss_totals.revenue - profit_loss_totals.cogs) / profit_loss_totals.revenue) * 100 | number: '1.2-2' }}%
										</span>
										<span class="value">
											{{ ((profit_loss_totals.revenuely - profit_loss_totals.cogsly) / profit_loss_totals.revenuely) * 100 | number: '1.2-2' }}%
										</span>
									</div>

									<!-- Total Expenses -->
									<div class="total-item">
										<span class="label">Total Expenses:</span>
										<span class="value">{{ profit_loss_totals.expenses | currency }}</span>
										<span class="value">{{ profit_loss_totals.expensesly | currency }}</span>
									</div>

									<!-- Total Profit / Loss -->
									<div class="total-item">
										<span class="label">Total Profit / Loss:</span>
										<span class="value">
											{{ profit_loss_totals.revenue - profit_loss_totals.cogs - profit_loss_totals.expenses | currency }}
										</span>
										<span class="value">
											{{ profit_loss_totals.revenuely - profit_loss_totals.cogsly - profit_loss_totals.expensesly | currency }}
										</span>
									</div>

									<!-- Total Profit / Loss Percent -->
									<div class="total-item">
										<span class="label">Total Profit / Loss Percent:</span>
										<span class="value">
											{{ ((profit_loss_totals.revenue - profit_loss_totals.cogs - profit_loss_totals.expenses) / profit_loss_totals.revenue) * 100 | number: '1.2-2' }}%
										</span>
										<span class="value">
											{{ ((profit_loss_totals.revenuely - profit_loss_totals.cogsly - profit_loss_totals.expensesly) / profit_loss_totals.revenuely) * 100 | number: '1.2-2' }}%
										</span>
									</div>
								</div>

							</div>
						</ng-container>
						<ng-container *ngIf="!profit_loss">

						</ng-container>


					</div>
				</div>
			</div>
		</div>
	</div>
</div>