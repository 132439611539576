import { Component, OnDestroy, OnInit, SimpleChanges, OnChanges, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { DropzoneComponent, DropzoneDirective, DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerService } from '../../services/customer.service';
import { OrdersService } from '../../services/orders.service';
import { CreditService } from '../../services/credit.service';
import { UsersService } from '../../services/users.service';
import { PaymentsService } from '../../services/payments.service';
import { DispatchService } from '../../services/dispatch.service';
import { Location } from '@angular/common'
import { Subject, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { DataTableDirective } from 'angular-datatables';
import { TableModule } from 'primeng/table';
import * as FileSaver from 'file-saver';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { Product } from '../../classes/orders';
import { Directive, HostListener, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate, query, group, stagger } from '@angular/animations';
import { forkJoin } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { OmsService } from 'app/services/oms.service';

@Component({
	selector: 'app-view',
	templateUrl: './view.component.html',
	styleUrls: ['./view.component.scss'],
	encapsulation: ViewEncapsulation.Emulated,
	animations: [
		trigger('flipState', [
			state('active', style({
				transform: 'rotateY(-179deg)'
			})),
			state('inactive', style({
				transform: 'rotateY(0)'
			})),
			transition('inactive => active', animate('250ms ease-in')),
			transition('active => inactive', animate('250ms ease-out')),
		]),
		trigger('openClose', [
			state(':enter', style({ height: '*' })),
			state(':leave', style({ height: '0px' })),
			transition('false <=> true', animate(500))
		]),
		trigger('filterAnimation', [
			transition(':enter, * => 0, * => -1', []),
			transition(':increment', [
				query(':enter', [
					style({ opacity: 0, width: 0 }),
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 1, width: '*' })),
					]),
				], { optional: true })
			]),
			transition(':decrement', [
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({ opacity: 0, width: 0 })),
					]),
				])
			]),
		]),
		trigger('grow', [ // Note the trigger name
			transition(':enter', [
				// :enter is alias to 'void => *'
				style({ height: '0', width: '0' }),
				animate(200, style({ height: '*', width: '*' })),
				animate('200ms', style({ opacity: 1 })),
			]),
			transition(':leave', [
				// :leave is alias to '* => void'
				animate(100, style({ height: 0, width: 0, })),
				animate('100ms', style({ opacity: 0 }))
			])
		]),
	]
})



export class ViewComponent implements OnInit {

	@ViewChild('documentModal') documentModalRef: ElementRef;
	@ViewChild('inactiveModal') inactiveModalRef: ElementRef;
	@ViewChild('monthDetails') monthDetailsRef: ElementRef;


	selectedbranch: any = [];
	closeResult: any = [];
	text_add_button = 'Add New Ship-To';
	files: File[] = [];
	uploadresults: any = [];
	customernumber: any;
	customerdata: any = [];
	customertrans: any = [];
	flip_button_txt = 'Transactions';
	viewingtrans: any = 'transactions';
	flip = 'inactive';
	//force on for edits
	ctl = false;
	transactions: any = [];
	customer_files: any = [];
	view_index = 0;

	viewingdoc: any = false;
	orderdata: any = [];
	quotes: any;
	payment = false;
	editbilling = false;
	newcustomertoggle = false;
	edittoggle = false;
	editing_settings = false;
	editing_shipping = false;
	editing_notes = false;
	editing_contacts = false;
	editing_ar = false;
	attach_file = false;
	invoicing = false;
	customerpoline = false;
	emailstatement = false;
	invoiceemailaddress: any = '';
	ediinvoices = false;
	statementemailaddress: any = '';
	debtorno: any;
	color = 'blue';
	pricelists: any = [];
	reloadData = true;
	activeIndex = 0;
	primaryActiveIndex = 0;

	loadedQuote = false;

	resetTrans: Subject < boolean > = new Subject < boolean > ();
	config: any = [];
	credit_data: any = [];
	user: any = [];
	routes: any = [];
	isItemLookup = false;
	viewingcards = false;
	profile_cards: any = false;
	loadingcards: any = false;
	taxauths: any = false;

	editsallowed: any = {
		discounts: true,
		editar: true,
		editbilling: true,
		editshipping: true,
		editcontact: true,
		editprofile: true,
		editnotes: true,
		takepayment: true,
	}

	sending: any = false;
	brand_details: any = false;
	isactive: any = true;
	refusedemail: any = false;
	activecolor = 'green';


	private sort: MatSort;
	@ViewChild(MatSort) set matSort(ms: MatSort) {
		this.sort = ms;
		this.setDataSourceAttributes();
	}

	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	dataSource: MatTableDataSource < any > ;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataObs: Observable < any > ;
	items_per_page = [25, 50, 100, 200, 500];
	pageSize = 25;
	pagesizedefault = 25;
	isPosUser: any = false;

	constructor(private omsService: OmsService, private creditService: CreditService, private usersService: UsersService, private paymentsService: PaymentsService, private dispatchService: DispatchService, private route: ActivatedRoute, private customerService: CustomerService, public orderService: OrdersService, private location: Location, public router: Router, public cdr: ChangeDetectorRef, private globalSearchService: GlobalSearchService, private modalService: NgbModal) {

		this.omsService.setPrintedOrder();

		this.dispatchService.getRoutes().subscribe(r => {
			this.routes = r;
		});

		this.globalSearchService.taxgroups.subscribe(r => {
			this.taxauths = r;
		});

		this.globalSearchService.salestypes.subscribe(r => {
			this.pricelists = r;
		});

//
// 		forkJoin({
//
// 			pricelists: this.globalSearchService.salestypes
// 		}).subscribe((results) => {
// 			this.routes = results.routes;
// 			this.taxauths = results.taxgroups;
// 			this.pricelists = results.pricelists;
// 		});
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
				if (u.user.issalesman) {
					this.editsallowed = r.salesmanAllowed
				}
			})
		});

		this.usersService.isPosUser().subscribe((isPOS) => {
			if (isPOS) {
				this.isPosUser = true;
			}
		});



	}

	updatePageSize(event: any) {
		this.pageSize = event.value;
	}

	getRouteName(id: any) {
		if (id) {
			const route = this.routes.filter(r => {
				return r.route_Id == id;
			})[0]

			if (route) {
				return route.route_description;
			}
			return '';
		} else {
			return '';
		}
	}

	toggleCreditCards() {
		this.viewingcards = (this.viewingcards) ? false : true;
	}



	ngOnInit(): void {

		this.setPagination([]);
		this.color = this.globalSearchService.getColor();
		this.globalSearchService.mesagesallowed.next(true);
		this.route.params.subscribe(params => {
			const customernumber = params['id'];
			this.debtorno = customernumber;
			this.viewingcards = false;
			this.customerService.getDebtor(customernumber).subscribe(async (results: any) => {
				this.editbilling = false;
				this.newcustomertoggle = false;
				this.edittoggle = false;
				this.editing_settings = false;
				this.editing_shipping = false;
				this.editing_notes = false;
				this.editing_contacts = false;
				this.editing_ar = false;
				this.customerdata = results;
				this.globalSearchService.lastCustomer.next(results.customer_id);
				this.activeIndex = 0;
				this.refusedemail = this.customerdata.customer.refusedemail
				//this.customerService.getAccountInq(customernumber).subscribe(async (results: any) => {
				//	this.transactions = results;
				//	this.globalSearchService.lastCustomerTrans.next(results);
				//});
			});
		});


	}

	removeCustomer() {
		if (this.customerdata) {

			if (this.user.user.customeradmin == '1') {
				this.customerService.removeDebtor(this.customerdata).subscribe(async (results: any) => {
					if (results.success) {
						this.router.navigate(['./customers']);
					} else {
						alert('Can Not Remove Customer: \n' + results.reasons);
					}
				});
			}
		}
	}

	getTaxAuth(taxgroupid: any) {
		if (this.taxauths) {
			const auth = this.taxauths.filter(r => {
				return r.taxgroupid == taxgroupid
			})[0];
			if (auth && auth.taxgroupdescription) {

				return auth.taxgroupdescription + ' ' + auth.rate;
			} else {
				return 'N/A';
			}

		} else {
			return 'N/A';
		}
	}

	loadCustomerBranch(event: any) {

		if (event === true) {
			this.customerService.getDebtor(this.debtorno).subscribe(async (results: any) => {
				this.customerdata = results;
				this.globalSearchService.lastCustomer.next(results.customer_id);
			});
		} else {
			this.customerService.getDebtorWithBranch(event.debtorno, event.branchcode).subscribe(async (results: any) => {
				this.customerdata = results;
				this.globalSearchService.lastCustomer.next(results.customer_id);
			});
		}
	}

	loadCustomer(event: any) {

		this.customerService.getDebtor(this.debtorno).subscribe(async (results: any) => {
			this.customerdata = results;
			this.globalSearchService.lastCustomer.next(results.customer_id);
			this.viewingcards = false;
			this.invoicing = false;
			this.isactive = this.customerdata.customer.isactive
			this.refusedemail = this.customerdata.customer.refusedemail

			//this.customerService.getAccountInq(this.debtorno).subscribe(async (results: any) => {
			//	this.transactions = results;
			//	this.globalSearchService.lastCustomerTrans.next(results);
			//});
		});
	}

	selectCustomer(row: any) {
		this.router.navigate(['./customers/view/' + row.debtorno]);
	}

	checkTab(event: any) {
		switch (event.index) {
			case 1:
				this.reloadData = true;
				break;
			default:
				break;
		}
	}

	toggleTransactions(intype: any) {
		this.ctl = false;
		if (!this.ctl) {
			this.globalSearchService.mesagesallowed.next(true);
		}
		this.viewingtrans = intype;
		switch (this.flip) {
			case 'inactive':
				this.flip = 'active';
				this.flip_button_txt = 'Customer Info'

				break;
			default:
				//flip back to customer info (transactions is flipped back and fourth)
				if (this.viewingtrans == 'transactions') {
					this.flip = 'inactive';
					this.flip_button_txt = 'Transactions'
				}
				break;
		}
	}

	getPriceListName(code: any): string {
		const pricelist = this.pricelists.find(pricelist => pricelist.typeabbrev === code);
		return pricelist?.sales_type || 'N/A';
	}

	changeBranch(branchcode: any) {
		this.customerService.getDebtorWithBranch(this.customerdata.debtorno, branchcode).subscribe(async (results: any) => {
			this.customerdata = results;
		});
	}

	newShipTo() {
		this.text_add_button = (this.text_add_button == 'Add New Ship-To') ? 'Cancel Add New Ship To' : 'Add New Ship-To';
		this.newcustomertoggle = (this.newcustomertoggle) ? false : true;
		this.edittoggle = false;
	}

	updateRefused(event: any) {

		this.refusedemail = event.target.checked;

		const data = {
			debtorno: this.customerdata.debtorno,
			branchcode: this.customerdata.branchcode,
			refusedemail: event.target.checked
		}

		this.customerService.updateDebtorRefused(data).subscribe((results) => {
			this.customerdata = results;
		});
	}

	updateActive(event: any) {
		//event.preventDefault();
		if (this.customerdata.customer.isactive) {
			const confirmed = confirm('Mark Customer as Inactive?');
			if (confirmed) {
				const data = {
					debtorno: this.customerdata.debtorno,
					branchcode: this.customerdata.branchcode,
					isactive: false
				}
				this.customerService.updateDebtorActive(data).subscribe((results) => {
					this.customerdata = results;
				});
			} else {
				return false;
			}
		} else {
			this.modalService.open(this.inactiveModalRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
				this.closeResult = `Closed with: ${result}`;
				this.editing_shipping = false;
			}, (reason) => {
				this.updateShipToEdit('any');
				this.editing_shipping = false;
				this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
			});

			this.reactivateCustomer()
		}
	}

	reactivateCustomer() {
		const data = {
			debtorno: this.customerdata.debtorno,
			branchcode: this.customerdata.branchcode,
			isactive: true
		}
		this.customerService.updateDebtorActive(data).subscribe((results) => {
			this.customerdata = results;
		});
	}

	isActiveText() {
		if (this.customerdata) {
			return (this.customerdata.customer.isactive) ? 'ACTIVE' : 'INACTIVE';
		}
		return '';
	}
	updateActiveIndex(event: any) {

		this.activeIndex = event;
		switch (this.activeIndex) {
			//force update customer on reselect index
			case 2:
				this.isItemLookup = true;
				break;
			case 0:
				this.customerService.getDebtor(this.debtorno).subscribe(async (results: any) => {
					this.customerdata = results;
				});
				this.isItemLookup = false;
				break;
			default:
				this.isItemLookup = false;
				break;
		}
	}


	addCreditItems(items: any) {

		const payload = items.map(item => ({
			orderno: item.orderno,
			stockid: item.stockid,
			description: item.description,
			discount: parseFloat(item.discountpercent) * 100,
			quantity: item.qty,
			price: parseFloat(item.price),
			debtorno: item.debtorno,
			branchcode: item.branchcode,
		}));



		this.creditService.addMultipleToOrder(payload, this.customerdata.debtorno, this.customerdata.branchcode).subscribe((results: any) => {

			if (results.success) {

				items.forEach((item, index) => {
					//reset qtys and show notification
					if (parseFloat(item.quantity) !== 0) {
						const color = (item.isnegative) ? 'warn' : 'success';
						this.globalSearchService.showNotification(item.description + ' x ' + item.qty + ' Added', color, 'bottom', 'left');
					}
				});

			} else {
				this.globalSearchService.showNotification(results.message, 'danger', 'bottom', 'left');
			}
		});

		this.router.navigate(['receivables/credit-note/' + this.customerdata.debtorno +'/'+ this.customerdata.branchcode]);

	}

	updateCreditData(event: any) {
		this.credit_data = event;
		this.customerdata.openrmas = event;
	}


	loadQuote(quote: any) {

		this.orderService.loadQuote(quote.orderno).subscribe((r: any) => {

			this.changeBranch(r.header.branchcode);
			this.activeIndex = 2;
			this.loadedQuote = true;

		})
		/*
		//let service = this.orderService;
		this.orderService.clearOrder(this.customerdata.debtorno, this.customerdata.branchcode).subscribe( (cleared: any) => {
			quote.details.forEach( (item: any)  => {
				let item_add = {
					stockid: item.stkcode,
					quantity: item.quantity,
					price: item.unitprice,
					discount: item.discountpercent,
				}
				service.addToOrder(item_add, this.customerdata.debtorno, this.customerdata.branchcode).subscribe(async (results: any) => {
				});
			});
			//active index item lookup

		});
		*/
	}

	selectTransactions() {
		this.activeIndex = 1;

	}

	invoiceOrder(event: any) {
		this.invoicing = event;
		this.selectItemLookup(event);
	}

	loadPreOrder(event: any) {

		if (event.header) {
			this.customerService.getDebtorWithBranch(event.header.debtorno, event.header.branchcode).subscribe(async (results: any) => {
				this.customerdata = results;
			});
		}
		this.activeIndex = 2;
	}

	selectItemLookup(event: any) {

		if (event.header) {
			this.customerService.getDebtorWithBranch(event.header.debtorno, event.header.branchcode).subscribe(async (results: any) => {
				this.customerdata = results;
			});
		}
		this.activeIndex = 2;
	}

	setInvoice() {
		this.invoicing = true;
	}

	ngAfterViewInit() {

	}

	onUploadError(event: any) {

	}

	onUploadSuccess(event: any) {

	}

	onSelect(event: any) {

		this.files.push(...event.addedFiles);
		for (let i = 0; i < this.files.length; i++) {
			this.readFile(this.files[i]).then(fileContents => {
				//stuff

				const upload = {
					content: fileContents,
					name: event.addedFiles[0].name,
					mime: event.addedFiles[0].type,
				}

				this.customerService.uploadFile(upload, this.customerdata.debtorno).subscribe((results: any) => {
					this.customerdata.customer_files = results.customer_files;
					this.files = [];
				});

			});
		}
	}

	removeFile(link: any) {
		const data = { link: link, debtorno: this.customerdata.debtorno }
		this.customerService.removeFile(data).subscribe((result: any) => {
			this.customerdata.customer_files = result.customer_files;
		})

	}
	onRemove(event: any) {
		this.files.splice(this.files.indexOf(event), 1);
	}


	salemanName(input: any) {
		const name = this.customerdata.salespeople.filter((p) => {
			return p.code == input
		})[0];
		if (name) {
			return name.name
		}
		return 'N/A';
	}

	paymenttoggle() {
		this.payment = (this.payment) ? false : true;
		if (!this.payment) {
			//	this.globalSearchService.mesagesallowed.next(true);
		}
	}
	ctlonoff() {
		this.ctl = (this.ctl) ? false : true;
		if (!this.ctl) {
			//	this.globalSearchService.mesagesallowed.next(true);
		}
	}

	toggleAttach() {
		this.attach_file = (this.attach_file) ? false : true;
	}

	back(): void {
		this.location.back()
	}

	//prevents cart collapse on activity
	stopProp(event: any): void {
		event.stopPropagation();
	}


	updateSettingEdit(input: any) {
		this.editing_settings = (this.editing_settings) ? false : true;
	}

	updateArEdit(input: any) {
		if (this.editsallowed.editar) {
			this.editing_ar = (this.editing_ar) ? false : true;
		}
	}

	updateContactEdit(input: any) {
		this.editing_contacts = (this.editing_contacts) ? false : true;
	}
	updateNoteEdit(input: any) {
		this.editing_notes = (this.editing_notes) ? false : true;
	}

	updateShipToEdit(input: any) {
		this.editing_shipping = (this.editing_shipping) ? false : true;
		//this.modalService.close('#shiptodetails')
	}
	//switch isnt working moviing to indivdual function
	updateEditing(input: any) {
		//this.modalService.dismiss('');

		switch (input) {
			case 'shipping':
				this.editing_shipping = (this.editing_shipping) ? false : true;
				break;
			case 'notes':
				this.editing_notes = (this.editing_notes) ? false : true;
				break;
			case 'settings':
				this.editing_settings = (this.editing_settings) ? false : true;
				break;
			default:
				this.editbilling = (this.editbilling) ? false : true;
				break;
		}
	}

	edit(content: any) {
		switch (content) {
			case 'editing_contacts':
				if (this.editsallowed.editcontact) {
					this.editing_contacts = (this.editing_contacts) ? false : true;
				}
				break;
			case 'notes':
				if (this.editsallowed.editnotes) {
					this.editing_notes = (this.editing_notes) ? false : true;
				}
				break;
			case 'shipping':
				if (this.editsallowed.editshipping) {
					this.editing_shipping = (this.editing_shipping) ? false : true;
				}
				break;
			case 'settings':
				if (this.editsallowed.editprofile) {
					this.editing_settings = (this.editing_settings) ? false : true;
				}
				break;
			default:
				if (this.editsallowed.editbilling) {
					this.editbilling = (this.editbilling) ? false : true;
				}
				break;
		}
	}

	openShippingModal(content: any) {
		if (this.editsallowed.editshipping) {
			this.openModal(content)
		}
	}

	getYesNo(input: boolean) {
		return (input) ? 'Y' : 'N';
	}

	updateBranch(input: any) {

		this.customerService.getDebtor(this.debtorno).subscribe((results: any) => {
			this.customerdata = results;
			this.modalService.dismissAll('close');
			this.updateShipToEdit('close');
			this.newcustomertoggle = false;
		});

	}

	updateCustomer(input: any) {
		this.customerdata = input;
	}

	openDocument(doc) {

		this.viewingdoc = doc

		this.modalService.open(this.documentModalRef, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;

		}, (reason) => {

		});
	}
	openModal(content) {
		this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			this.editing_shipping = false;
		}, (reason) => {
			this.updateShipToEdit('any');
			this.editing_shipping = false;
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}

	loadPayments() {
		//this.router.navigate(['/receivables/payment-entry/311/00']);
	}

	private async readFile (file: File): Promise < string | ArrayBuffer > {
		return new Promise < string | ArrayBuffer > ((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = e => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = e => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				return reject(null);
			}
			reader.readAsDataURL(file);
		});

		this.files = [];
	}

	getBrandSales(debtorno: any, brand: any) {

		const today = new Date();
		//var priorDate = new Date(new Date().setDate(today.getDate() - this.daysmax));

		const data = {
			debtorno: debtorno,
			brands: [brand],
			from: 'all',
			//to: today,
		}

		this.customerService.searchCustomerHistory(data).subscribe(async (results: any) => {
			this.sending = false;
			this.brand_details = results;
			this.setPagination(results.items)
			this.modalService.open(this.monthDetailsRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {}, (reason) => {
				this.setPagination([])
			});
		});
	}

	getItemTotals() {
		let total = 0
		if (this.brand_details) {
			total = this.brand_details.items.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.qty);
			}, 0);
		}
		return total;
	}

	getDollarTotals() {
		let total = 0
		if (this.brand_details) {
			total = this.brand_details.items.reduce(function(accumulator, c) {
				return accumulator + parseFloat(c.discountedlinetotal);
			}, 0);
		}
		return total;
	}

	isImage(file: any): boolean {
		return file.link && /\.(jpg|jpeg|png|gif)$/i.test(file.link);
	}


	ngOnChanges(changes: any) {

	}

	setPagination(tableData) {
		this.dataSource = new MatTableDataSource < any > (tableData);
		this.cdr.detectChanges();
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
	}


}