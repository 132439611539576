<div class="main-content">
	<div class="container-fluid">
		<div class="col-md-12 ">
			<div class="card ">
				<div class="card-header card-header-{{color}}-6">
					<h4 class="card-title">
						<i class="material-icons" (click)="back()">arrow_left</i>
						<!-- &nbsp;<button mat-icon-button [cdkCopyToClipboard]="vendor_data.supplierid">
					<small><mat-icon>content_copy</mat-icon></small> </button> -->
						<span class="bold"></span>
					</h4>
				</div>
				<div class="card-body">
					<div class="row mt-2">
						<div class="col-12">
							<h3 class="mt-2">Check Printing</h3>
						</div>
					</div>
					<div class="row mt-2">
						<div class="col-12">
							<h4 class="text-left border-bottom">Posting Details</h4>
						</div>
					</div>
					<form [formGroup]="paymentForm">
						<div class="row">
							<div class="col-2">
								<label>Currency</label>
								<select formControlName="currency" class="form-control">
									<option *ngFor="let curr of currencies" [value]="curr.currabrev">
										{{ curr.currabrev }}
									</option>
								</select>
							</div>
							<div class="col-3">
								<label>Bank Account</label>
								<select formControlName="bankaccount" class="form-control">
									<option *ngFor="let bank of bankAccounts" [value]="bank.accountcode">
										{{ bank.bankaccountname }}
									</option>
								</select>
							</div>
							<div class="col-4">
								<label>Check Number</label>
								<input type="text" formControlName="checkNumber" class="form-control" />
							</div>
							<div class="col-3">
								<label>Posting Date</label>
								<input type="date" formControlName="postingDate" class="form-control" />
							</div>
						</div>
					</form>
					<h4 class="text-left border-bottom mt-3">Search Details</h4>
					<div class="row">
						<div class="col-2">
							<label>Starting Vendor</label>
							<input type="text" [(ngModel)]="vendorStart" class="form-control" />
						</div>
						<div class="col-2">
							<label>Ending Vendor</label>
							<input type="text" [(ngModel)]="vendorEnd" class="form-control" />
						</div>
						<div class="col-2">
							<label>Vendor Type</label>
							<select [(ngModel)]="vendorType" class="form-control">
								<option value="">All</option>
								<option *ngFor="let type of vendorTypes" [value]="type.code">
									{{ type.description }}
								</option>
							</select>
						</div>
						<div class="col-3">
							<label>Include Checks Eligible for Discount</label>
							<select [(ngModel)]="includeDiscount" class="form-control">
								<option value="0">No</option>
								<option value="1">Yes</option>
							</select>
						</div>
						<div class="col-3">
							<label>Payment Due Date</label>
							<input type="date" [(ngModel)]="dueDate" class="form-control" />
						</div>
					</div>
					<div class="row mt-3">
						<div class="col-12 text-right">
							<button class="btn btn-primary" (click)="searchChecks()">
								<i class="fa fa-search"></i> Search

								<span matPrefix *ngIf="searching" class="ml-auto mr-auto">
									<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</button>
						</div>
					</div>
				</div>



				<ng-container *ngIf="successMessage">
				  <div class="success-message">
					<i class="fa fa-check-circle"></i>
					{{ successMessage }}
				  </div>
				</ng-container>


				<ng-container *ngIf="checks && checks.length">
					<!-- Actions Section -->
					<div class="ml-auto mr-auto">
						<button (click)="printCheck()" class="btn btn-danger">
							<i class="fa fa-file-pdf-o"></i> PDF
						</button>
						<button (click)="finalizePostings()" class="btn btn-success ml-2">
							<i class="fa fa-check-circle"></i> Finalize Postings
						</button>
					</div>

					<!-- Check Cards -->
					<div class="container">
						<div *ngFor="let check of checks | keyvalue" class="ap-clerk-check-card">
							<div class="ap-clerk-check-card__header">
								<h4>{{ check.value.check.payorder }}</h4>
							</div>

							<div class="ap-clerk-check-card__details">
								<div class="ap-clerk-check-detail">
									<label>Pay Order:</label>
									<span class="ap-clerk-check-value">{{ check.value.check.payorder }}</span>
								</div>
								<div class="ap-clerk-check-detail">
									<label>Amount:</label>
									<span class="ap-clerk-check-value">${{ check.value.check.amount | number: '1.2-2' }}</span>
								</div>
								<div class="ap-clerk-check-detail">
									<label>Date:</label>
									<span class="ap-clerk-check-value">{{ check.value.check.date }}</span>
								</div>
								<div class="ap-clerk-check-detail">
									<label>Pay Word:</label>
									<span class="ap-clerk-check-value">{{ check.value.check.payword }}</span>
								</div>
								<div class="ap-clerk-check-detail">
									<label>Address:</label>
									<span class="ap-clerk-check-value">{{ check.value.check.addr }}</span>
								</div>
							</div>

							<div class="ap-clerk-check-card__footer">
								<div class="ap-clerk-check-summary">
									Total Amount Paid: <span>${{ check.value.postings.AccumBalance | number: '1.2-2' }}</span>
								</div>
								<div class="ap-clerk-check-summary">
									Currency: <span>{{ check.value.postings.Currency }}</span>
								</div>
							</div>
						</div>
					</div>
				</ng-container>

				<ng-container>
					<div *ngIf="data.success" class="vendor-container">
						<div class="action-bar">

							<!-- <button class="btn btn-secondary" (click)="applyFilters()">Filter</button> -->

						</div>

						<div class="transaction-header">
							<div class="transaction-checkbox"></div>
							<div class="transaction-details mt-2">Transaction Details</div>
							<div class="transaction-dates mt-2">Dates</div>
							<div class="transaction-amounts">Amounts</div>
							<div class="transaction-discount">Discount</div>
							<div class="transaction-amount-due">Amount Due</div>
						</div>


						<div *ngFor="let vendor of data.checklist | keyvalue" class="vendor-group card-header mt-0 mb-0">
							<div class="row mt-0 mb-0">
								<!-- Vendor Name and Refresh Button -->
								<div class="col-6 text-left">
									<h4>
										<!-- <button class="btn btn-sm refeshsup" (click)="refreshVendorData(vendor.key)">
											<i class="fa fa-refresh"></i>
										</button> -->
										<b>{{ vendor.key }} {{ vendor.value[0]?.suppname }}</b>
									</h4>


									<input type="checkbox" class="form-control groupcheck" [attr.data-supplier]="vendor.key" [(ngModel)]="vendorChecks[vendor.key]" (change)="updateGroupCheck(vendor.key)" />


								</div>

								<!-- Check Date -->
								<div class="col-5 text-right">
									Check Date:
									<input type="date" class="form-control date dated text-center" [(ngModel)]="vendorDates[vendor.key]" (change)="updateCheckDate(vendor.key)" />
								</div>

								<!-- Remove Vendor Button -->
								<div class="col-1 text-right">
									<small>
										<button class="btn btn-danger btn-sm removecheck" (click)="removeVendor(vendor.key)">
											<i class="fa fa-minus"></i>
										</button>
									</small>
								</div>
							</div>



							<div *ngFor="let transaction of vendor.value" class="transaction-row" [ngClass]="{
							'due-soon': isDueSoon(transaction.duedate),
							'discount-available': transaction.discountavail > 0,
							'completed': transaction.bal <= 0
							}">
								<div class="transaction-checkbox">
									<label class="custom-checkbox">
										<input type="checkbox" [id]="'check-' + transaction.id" [(ngModel)]="transaction.selected" (change)="updateVendorTotal(vendor.key)" />
										<span class="checkmark"></span>
									</label>
								</div>

								<div class="transaction-details">
									<label class="transaction-type">
										{{ transaction.typename }}
										<small>({{ transaction.transno }})</small>
									</label>
									<br />
									<b class="transaction-reference">{{ transaction.suppreference }}</b>
								</div>
								<div class="transaction-dates">
									<label class="transaction-dates-label">Inv Date | Due Date</label>
									<br />
									<span class="transaction-dates-values">
										{{ transaction.trandate }} | {{ transaction.duedate }}
									</span>
								</div>
								<div class="transaction-amounts">
									<div class="amount-item" [ngClass]="{'positive-amount': transaction.ovamount + transaction.ovgst > 0, 'negative-amount': transaction.ovamount + transaction.ovgst < 0}">
										<label class="amount-label">Net:</label>
										<span class="amount-value">${{ transaction.ovamount + transaction.ovgst | number: '1.2-2' }}</span>
									</div>
									<div class="amount-item" [ngClass]="{'positive-amount': transaction.alloc > 0, 'negative-amount': transaction.alloc < 0}">
										<label class="amount-label">Applied:</label>
										<span class="amount-value">${{ transaction.alloc | number: '1.2-2' }}</span>
									</div>
								</div>


								<div class="transaction-discount">
									<label class="transaction-discount-label" [ngClass]="{
									'due-soon': isDueSoon(transaction.discdate),
									'discount-available': transaction.discountavail > 0 }">
										Discount:
										<small class="transaction-discount-date">{{ transaction.discdate }}</small>
									</label>
									<div class="discount-wrapper">
										<input class="transaction-discount-input" type="text" [(ngModel)]="transaction.disc" (ngModelChange)="updateVendorTotal(vendor.key)" />
									</div>
								</div>

								<div class="transaction-amount-due">
									<label class="transaction-label">Amount Due:</label>
									<div class="amount-due-wrapper">
										<span class="currency-indicator">$</span>
										<input class="transaction-due-input" type="text" [(ngModel)]="transaction.bal" (ngModelChange)="updateVendorTotal(vendor.key)" />
									</div>
								</div>


							</div>
							<div class="vendor-total">
								<h5>Total for {{ vendor.key }}: ${{ vendorTotals[vendor.key] | number: '1.2-2' }}</h5>
							</div>
						</div>

						<div id="floater-container">
							<div class="ml-auto mr-0 summary-card">
								<h4>Total Selected Due: ${{ totalDue | number: '1.2-2' }}</h4>
								<p>Discounts Applied: ${{ totalDiscount | number: '1.2-2' }}</p>
								<p>Transactions Selected: {{ selectedTransactions }}</p>
								<div class="button-group ml-auto mr-auto ">
									<button class="ml-auto mr-auto btn btn-primary" (click)="createPrint()">Print Checks </button>
								</div>
							</div>
						</div>

					</div>
					<ng-template #noResults>
						<div class="alert alert-danger text-center">No Results</div>
					</ng-template>
				</ng-container>
			</div>
		</div>
	</div>
</div>