
<mat-form-field class="full-width" appearance="outline">
	<mat-label>General Ledger Account</mat-label>
	<input type="text" #glcharge matInput [formControl]="glsearch" [matAutocomplete]="gls" appSelectOnFocus>
	<mat-autocomplete #gls="matAutocomplete" (optionSelected)="addGl($event)" [displayWith]="displayFn">
		<mat-option *ngFor="let account of glaccounts" [value]="account" >
			<b>{{ account. group }}</b>: {{ account.accountcode }} {{ account.accountname }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>

