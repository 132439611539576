import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FixedAssetsService } from '../../services/fixed-assets.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-fixed-assets-register',
  templateUrl: './fixed-assets-register.component.html',
  styleUrls: ['./fixed-assets-register.component.scss'],
  animations: [
	trigger('expandCollapse', [
	  state('void', style({ height: '0px', opacity: 0 })),
	  state('*', style({ height: '*', opacity: 1 })),
	  transition('void <=> *', [animate('300ms ease-in-out')]),
	]),
  ],
})
export class FixedAssetsRegisterComponent implements OnInit {
  registerFiltersForm: FormGroup;
  fixedAssets: any[] = [];
  categories: any[] = [];
  locations: any[] = [];
  isFiltersVisible: boolean = false;
  isLoading: boolean = false;
  depreciationTypes: any[] = [
	  { id: '0', name: 'Straight Line' },
	  { id: '1', name: 'Declining Balance' },
	];

	isCalculating = false;
	  isCommitting = false;
	  depreciationData: any[] = [];
  constructor(
	private fb: FormBuilder,
	private fixedAssetsService: FixedAssetsService,
	private snackBar: MatSnackBar
  ) {
	this.registerFiltersForm = this.fb.group({
	  fromDate: [new Date(), Validators.required],
	  toDate: [new Date(), Validators.required],
	  assetCategory: ['%'],
	  assetLocation: ['%'],
	  assetId: ['%'],
	});
  }

  ngOnInit(): void {
	this.loadFilters();
	this.loadFixedAssets();
  }

  loadFilters(): void {
	this.fixedAssetsService.fetchFilters().subscribe(
	  (response) => {
		this.categories = response.categories || [];
		this.locations = response.locations || [];
	  },
	  () => {
		this.snackBar.open('Error loading filters.', 'Close', { duration: 3000 });
	  }
	);
  }

  loadFixedAssets(): void {
	  console.log('loading-1')
	if (this.registerFiltersForm.valid) {
	  const filters = this.registerFiltersForm.value;
	  this.isLoading = true;
	  console.log('loading')
	  this.fixedAssetsService.fetchFixedAssetsRegister(filters).subscribe(
		(response) => {
			console.log(response);
		  this.fixedAssets = response.data || [];
		  this.isLoading = false;
		},
		() => {
		  this.snackBar.open('Error loading Fixed Assets Register.', 'Close', { duration: 3000 });
		  this.isLoading = false;
		}
	  );
	}
  }

  exportCsv(): void {
	const filters = this.registerFiltersForm.value;
	this.fixedAssetsService.exportFixedAssetsCsv(filters).subscribe(
	  () => {
		this.snackBar.open('CSV exported successfully.', 'Close', { duration: 3000 });
	  },
	  () => {
		this.snackBar.open('Error exporting CSV.', 'Close', { duration: 3000 });
	  }
	);
  }

  exportPdf(): void {
	const filters = this.registerFiltersForm.value;
	// this.fixedAssetsService.exportFixedAssetsPdf(filters).subscribe(
	//   () => {
	// 	this.snackBar.open('PDF exported successfully.', 'Close', { duration: 3000 });
	//   },
	//   () => {
	// 	this.snackBar.open('Error exporting PDF.', 'Close', { duration: 3000 });
	//   }
	// );
  }

  editAsset(asset: any): void {
	// Logic for editing an asset
  }

  deleteFixedAsset(assetId: string): void {
	// Logic for deleting an asset
  }

  getTotalCostBfwd(): number {
	return this.fixedAssets.reduce((total, asset) => total + (asset.costBfwd || 0), 0);
  }

  getTotalDepnBfwd(): number {
	return this.fixedAssets.reduce((total, asset) => total + (asset.depnBfwd || 0), 0);
  }

  getTotalNbv(): number {
	return this.fixedAssets.reduce((total, asset) => total + (asset.nbv || 0), 0);
  }

 calculateDepreciation(): void {
   this.isCalculating = true;

   // Define the payload with the process date
   const payload = {
	 processDate: this.registerFiltersForm.value.toDate || new Date().toISOString().split('T')[0] // Use the selected date or today's date
   };

   this.fixedAssetsService.calculateDepreciation(payload).subscribe(
	 (response) => {
	   this.depreciationData = response.data || [];
	   this.isCalculating = false;
	   this.snackBar.open('Depreciation calculated successfully.', 'Close', { duration: 3000 });
	 },
	 () => {
	   this.isCalculating = false;
	   this.snackBar.open('Error calculating depreciation.', 'Close', { duration: 3000 });
	 }
   );
 }


	commitDepreciation(): void {
	  if (this.depreciationData.length === 0) {
		this.snackBar.open('No depreciation data to commit.', 'Close', { duration: 3000 });
		return;
	  }

	  this.isCommitting = true;
	  this.fixedAssetsService.commitDepreciation(this.depreciationData).subscribe(
		() => {
		  this.isCommitting = false;
		  this.snackBar.open('Depreciation committed successfully.', 'Close', { duration: 3000 });
		  this.depreciationData = [];
		},
		() => {
		  this.isCommitting = false;
		  this.snackBar.open('Error committing depreciation.', 'Close', { duration: 3000 });
		}
	  );
	}

	excludeAsset(assetId: string): void {
	  this.depreciationData = this.depreciationData.filter((asset) => asset.assetId !== assetId);
	  this.snackBar.open(`Asset ${assetId} excluded from depreciation.`, 'Close', { duration: 3000 });
	}

}
