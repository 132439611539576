<ng-template #editzipref let-modal>
	<div class="modal-header">
		<h4 class="modal-title">
			Editing Zipcode <b>{{editing.zipcode}}</b>
		</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body row m-0 p-0 col-12">
        <div class="col-6 m-0 p-0 h-600">
            <table class="table thead-dark table-striped">
                <thead>
                    <tr>
                        <th>Debtorno</th>
                        <th>Name</th>
                        <th>Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let cust of editing.debtors" (click)="updateViewing(cust)" [ngClass]="rowDebtors[debtorIndex].debtorno == cust.debtorno && rowDebtors[debtorIndex].branchcode == cust.branchcode ? 'veiwing' : ''">
                        <td>{{cust.debtorno}}.{{cust.branchcode}}</td>
                        <td>{{cust.brname}}</td>
                        <td>{{cust.braddress1}} {{cust.braddress3}} {{cust.braddress4}},{{cust.braddress5}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-6 m-0 p-0 h-600">
            <h4 *ngIf="viewingCust" class="text-center p-2 zipselection mb-0 sticky-top mat-elevation-z24">
                <button mat-icon-button class="left-abs" (click)="cycle('last')"><mat-icon>arrow_left</mat-icon></button>
                {{viewingCust.debtorno}}.{{viewingCust.branchcode}} {{viewingCust.brname}}
                <br style="height: 1px;"> 
                {{viewingCust.braddress1}} {{viewingCust.braddress3}}, {{viewingCust.braddress4}}, {{viewingCust.braddress5}}
                <button mat-icon-button class="right-abs" (click)="cycle('next')"><mat-icon>arrow_right</mat-icon></button>
            </h4>
            <div #mapDiv class="w-100 mt-0">
                <div class="text-center m-auto">
                    <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" *ngIf="mapLoading">
                        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>    
                </div>
                <div class="w-100" id="map"></div>
            </div>
        </div>
	</div>
	<div class="modal-footer">
        <div class="col-2 m-0 p-0">
            <mat-form-field appearance="outline" class="m-0 p-0">
                <mat-label>Route</mat-label>
                <mat-select  [formControl]="routeRef">
                  <mat-option *ngFor="let r of routes" [value]="r.route_Id">
                    {{r.route_description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            <button mat-raised-button color="primary" (click)="updateRoute(editing)" class="m-0 p-0 w-100 mt-1"><mat-icon>save</mat-icon>Save</button>
        </div>
	</div>
</ng-template>

<div class="main-content">
    <div class="row col-10 m-auto p-2">
        <div class="col-12 m-0 p-0 row justify-content-between">
            
            <mat-form-field class="col-7 m-0 p-0">
                <mat-label>Zipcode Search</mat-label>
                <input matInput placeholder="Search for a bin" [formControl]="searchRef" (keyup)="filter()">
            </mat-form-field>

            <mat-form-field appearance="outline" class="ml-0 p-0 col-2">
                <mat-label>Route</mat-label>
                <mat-select  [formControl]="routeFilterRef" (selectionChange)="filter()">
                  <mat-option [value]=""></mat-option>
                  <mat-option *ngFor="let r of routes" [value]="r.route_Id">
                    {{r.route_description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <div class="col-1 m-0 p-0" *ngIf="verifiedzipcodes.length">
                <button mat-flat-button (click)="exportXls()" color="primary"><i class="fa fa-file-excel-o"></i> XLS</button>
                <button mat-flat-button (click)="exportPdf()" color="accent" class="mt-1"><i class="fa fa-file-pdf-o"></i> PDF</button>
            </div>

        </div>
    </div>

    <div class="col-10 p-2 m-auto">
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg" *ngIf="loading">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z1" *ngIf="!loading">
            <ng-container *ngFor='let col of displayedColumns; let i = index'>
                <ng-container matColumnDef="{{col}}">

                    <th mat-header-cell *matHeaderCellDef [ngClass]="[
                        col == 'zipcode' ? 'text-left' : '',
                        col == 'route' || col == 'count' ? 'text-center' : '',
                        col == 'action' ? 'text-right' : ''
                    ]">{{columnHeaders[i]}}</th>

                    <td mat-cell *matCellDef="let element" [ngClass]="[
                        col == 'zipcode' ? 'text-left':'',
                        col == 'route' || col == 'count'  ? 'text-center' : '',
                        col == 'action' ? 'text-right' : ''
                    ]">
                        <ng-container *ngIf="col != 'action'"> {{element[col]}} </ng-container>
                        <ng-container *ngIf="col == 'action'">
                            <button mat-icon-button (click)="editZipcode(element)" color="primary"><mat-icon>edit</mat-icon></button>
                        </ng-container>
                    </td>

                </ng-container>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[50, 100, 250]" showFirstLastButtons>
        </mat-paginator>
    </div>
</div>