import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralLedgerService } from '../../services/general-ledger.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { GlobalSearchService } from '../../services/globalsearchservice.service';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
	selector: 'app-inventory-gl-postings',
	templateUrl: './inventory-gl-postings.component.html',
	styleUrls: ['./inventory-gl-postings.component.scss'],
	animations: [
		trigger('expandCollapse', [
			state('void', style({ height: '0px', opacity: 0, overflow: 'hidden' })),
			state('*', style({ height: '*', opacity: 1 })),
			transition('void <=> *', [animate('300ms ease-in-out')]),
		]),
	],
})
export class InventoryGlPostingsComponent implements OnInit {
	inventoryGLForm: FormGroup;
	inventoryGLPostings: any[] = [];
	areas: any[] = [];
	stockCategories: any[] = [];
	salesTypes: any[] = [];
	glCodes: any[] = [];
	editingPost: any = null;
	isLoading = false;
	color = 'blue';
	isFormVisible = false;

	@ViewChild('inventoryGLModal') inventoryGLModal: any;

	constructor(
		private fb: FormBuilder,
		private glService: GeneralLedgerService,
		private snackBar: MatSnackBar,
		private location: Location,
		private globalSearchService: GlobalSearchService,
		private dialog: MatDialog
	) {
		this.color = this.globalSearchService.getColor();
		this.inventoryGLForm = this.fb.group({
			area: ['', Validators.required],
			stkcat: ['', Validators.required],
			salestype: ['', Validators.required],
			glcode: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.loadInventoryGLPostings();
		this.loadDependencies();
	}

	toggleForm(): void {
		this.isFormVisible = !this.isFormVisible;
	}

	resetAndOpenForm(): void {
		this.resetForm();
		this.isFormVisible = true;
	}

	openInventoryGLModal(post?: any): void {
		if (post) {
			this.editingPost = post;
			this.inventoryGLForm.patchValue(post);
		} else {
			this.resetForm();
		}
		this.dialog.open(this.inventoryGLModal);
	}

	saveInventoryGLPosting(): void {
		if (this.inventoryGLForm.valid) {
			if (this.editingPost) {
				const data = { ...this.inventoryGLForm.value, id: this.editingPost.id };
				this.glService.updateInventoryGLPosting(data).subscribe(() => {
					this.isFormVisible = false;
					this.snackBar.open('Inventory GL Posting updated successfully.', 'Close', { duration: 3000 });
					this.loadInventoryGLPostings();
					this.dialog.closeAll();
				});
			} else {
				this.glService.addInventoryGLPosting(this.inventoryGLForm.value).subscribe((r: any) => {
					if (r.error) {
						this.snackBar.open(r.error, 'Close', { duration: 3000 });
					} else {
						this.snackBar.open('Inventory GL Posting added successfully.', 'Close', { duration: 3000 });
					}
					this.loadInventoryGLPostings();
					this.dialog.closeAll();
				});
			}
		}
	}

	resetForm(): void {
		this.editingPost = null;
		this.inventoryGLForm.reset();
	}

	loadInventoryGLPostings(): void {
		this.isLoading = true;
		this.glService.fetchInventoryGLPostings({}).subscribe(
			(response) => {
				this.inventoryGLPostings = response.data || [];
				this.isLoading = false;
			},
			(error) => {
				this.snackBar.open('Error loading Inventory GL Postings.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	loadDependencies(): void {
		this.glService.fetchAreas().subscribe(
			(res) => {
				this.areas = res.areas.map((area: any) => ({ name: area.name, code: area.code }));
			},
			(error) => {}
		);

		this.glService.fetchStockCategories().subscribe(
			(res) => {
				this.stockCategories = res.stockCategories.map((category: any) => ({ name: category.name, code: category.code }));
			},
			(error) => {}
		);

		this.glService.fetchSalesTypes().subscribe(
			(res) => {
				this.salesTypes = res.salesTypes.map((type: any) => ({ name: type.name, code: type.code }));
			},
			(error) => {}
		);

		this.glService.fetchGLCodes().subscribe(
			(res) => {
				this.glCodes = res.glCodes.map((code: any) => ({ name: code.name, code: code.code }));
			},
			(error) => {}
		);
	}

	deletePosting(id: string): void {
		if (!confirm('Are you sure you want to delete this posting?')) return;

		this.glService.deleteInventoryGLPosting({ id }).subscribe(
			() => {
				this.snackBar.open('Inventory GL Posting deleted successfully.', 'Close', { duration: 3000 });
				this.loadInventoryGLPostings();
			},
			() => this.snackBar.open('Error deleting Inventory GL Posting.', 'Close', { duration: 3000 })
		);
	}

	editPosting(post: any): void {
		this.editingPost = post;
		this.inventoryGLForm.patchValue(post);
		this.isFormVisible = true;
	}

	back(): void {
		this.location.back();
	}
}
