import { Component, Input, OnInit } from '@angular/core';
import { InventoryService } from 'app/services/inventory.service';
import { OnDestroy, ViewChild, ChangeDetectorRef, ElementRef, Pipe, PipeTransform, Output, EventEmitter, ViewEncapsulation, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-item-pick-history',
	templateUrl: './item-pick-history.component.html',
	styleUrls: ['./item-pick-history.component.scss']
})
export class ItemPickHistoryComponent implements OnInit, OnChanges {
	
	@ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
		this.paginator = mp;
		this.setDataSourceAttributes();
	}
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	dataSource = new MatTableDataSource < any > ;
	dataObs: Observable < any > ;
	items_per_page = [5, 10, 20 , 100, 500, 1000];
	pagesizedefault = 20;
	
	@Input() source: any = '';
	@Input() item;
	content = false;
	itemForm: UntypedFormGroup;
	afterdate = new Date();
	todate = new Date(new Date().setDate(this.afterdate.getDate() - 365));
	locations: any = [];
	results: any = false;
	slidefilteroption: any;
	option: any = true;
	errorHighlight = false;
	sending = false;

	constructor(private _changeDetectorRef: ChangeDetectorRef, private inventoryService: InventoryService, private fb: UntypedFormBuilder, private globalSearchService: GlobalSearchService) {
		this.content = true;
		this.globalSearchService.locations.subscribe(async (results: any) => {
			this.locations = results;
		});
	}

	setDataSourceAttributes() {
		this.dataSource.paginator = this.paginator;
	}

	ngOnInit(): void {

	}

	ngOnChanges(changes:any): void {
		this.setForm();
	}

	setForm() {
		this.itemForm = this.fb.group({
			stockid: [this.item.stockid, Validators.required],
			loccode: [this.locations[0]?.loccode, Validators.required],
			fromdate: [this.todate, Validators.required],
			todate: [this.afterdate, Validators.required],
			option: [this.option]
		});

		this.getPickHistory()
	}


	getPickHistory() {
		const data = this.itemForm.value;
		this.sending = true;
		this.inventoryService.getPickHistory(data).subscribe((result) => {
			this.setPagination(result);
			this.sending = false;
		});
	}
	

	setPagination(tableData: any) {
		this.dataSource = new MatTableDataSource <any> (tableData);
		this.dataSource.paginator = this.paginator;
		this.dataObs = this.dataSource.connect();
		this.results = tableData;
		this._changeDetectorRef.detectChanges();
	}

	toggleErrorHighlight() { //clicking the document table header cell higlights rows where pick != picked
		this.errorHighlight = this.errorHighlight == true ? false : true;
	}

}
