import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalSearchService } from 'app/services/globalsearchservice.service';
import { CustomerService } from 'app/services/customer.service';
import { DispatchService } from 'app/services/dispatch.service';
import tt from '@tomtom-international/web-sdk-maps';
import { PrintService } from 'app/services/print.service';


@Component({
  selector: 'app-dispatches-route-maintance',
  templateUrl: './dispatches-route-maintance.component.html',
  styleUrls: ['./dispatches-route-maintance.component.scss']
})
export class DispatchesRouteMaintanceComponent  implements OnInit {
	verifiedbins: any;
	config: any;
	displayedColumns: string[] = ['zipcode', 'route', 'count', 'action']
	columnHeaders: string[] = ['Zipcode', 'Route', 'Debtor Count', 'Action'];
	dataSource: MatTableDataSource<any>;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild('addbinref') binref: ElementRef;
	@ViewChild('editzipref') zipref: ElementRef;
	@ViewChild('mapDiv') mapDivEle: ElementRef;

	loading: boolean = false;
	user: any = false;
	loc = new UntypedFormControl('');
	routeRef = new UntypedFormControl('');
	searchRef = new UntypedFormControl('');
	routeFilterRef = new UntypedFormControl('');
	userLocs: any = false;
	locations: any;
	alreadyExists: boolean = false;
  	zipcodes: any;
	verifiedzipcodes: any = false;
	editing: any;
	map: any = false;
	mapLoading: boolean;
	viewingCust: any = false;
	routes: any = false;
	debtorIndex: any;
	debtorsLength: any;
	rowDebtors: any;
	loadingMap: boolean;
	markers: any[];
	marker_color = {
		home: '#ff0000',
		viewing: '#0000ff'
	}

	
	constructor(private printService:PrintService, private dispatchService: DispatchService, private customerService: CustomerService, private globalSearchService: GlobalSearchService, private modalService: NgbModal) { }


	ngOnInit(): void {
		this.globalSearchService.user.subscribe((results: any) => {
			if (results) {
				this.user = results.user;
				this.loc.setValue( this.user.defaultlocation.loccode);
			}
		});
		this.globalSearchService.locations.subscribe((r:any) => {
			this.locations = r;
		});

		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
		})

		this.loadData();
	}


	loadData() {
		this.loading = true;
    	this.customerService.getDebtorZipCodes().subscribe((res: any)=> {
			this.zipcodes = res;
			this.verifiedzipcodes = res;
			this.updateDataSource(res);
			this.loading = false;
    	})

		this.dispatchService.getRoutes().subscribe((res)=>{
			this.routes = res;
		})

		this.filter();
	}

	updateDataSource(data) {
		this.dataSource?.data ? this.dataSource.data = data : this.dataSource = new MatTableDataSource(data)
		this.dataSource.paginator = this.paginator;
	}


	filter() {
		const route = this.routeFilterRef.value ? this.routeFilterRef.value : false ;
		const search = this.searchRef.value ? this.searchRef.value : false;
		if(route || search){
			this.updateDataSource(this.verifiedzipcodes.filter((zip) => {
				if(route && search){
					return zip.zipcode.toUpperCase().includes(search) && zip.routeid == route;
				}else if(route){
					return zip.routeid == route;
				}else if(search){
					return zip.zipcode.toUpperCase().includes(search);
				}
			}));
		}else{
			this.updateDataSource(this.verifiedzipcodes);
		}
	}

	editZipcode(row){
		this.editing = row;
		this.rowDebtors = row['debtors'];
		this.debtorIndex = 0;
		this.routeRef.setValue(row.routeid);
		this.modalService.open(this.zipref, { ariaLabelledBy: 'modal-title', size: 'zip' }).result.then((result) => {}, (reason) => {
			this.viewingCust = false;
		});

		this.viewingCust = this.rowDebtors[this.debtorIndex];

		setTimeout(()=>{
			this.loadMap();
		},2000);

	}

	loadMap(){
		const key = this.config.apiCredentials.tomtom;
		if(key){
			this.map = tt.map({
				key: key,
				container: "map"
			})
	
			//main wh coords
			const home: [number, number]  = [Number(-78.82142723259965), Number(42.928455044944165)];
	
			//debtor markers
			this.markers = [];
			this.rowDebtors.forEach(loc => {
				if(loc !== this.viewingCust){
					var coord: [number, number] = [Number(loc['lng']), Number(loc['lat'])];	
					this.markers.push({
						debtor: loc.debtorno + '' + loc.branchcode,
						coords: coord,
						marker:  new tt.Marker().setLngLat(coord)
					})
				}
			});
			
			//add initial debtor viewing last so it is above other markers
			const viewing: [number, number] = [Number(this.viewingCust.lng), Number(this.viewingCust.lat)];
			this.markers.push({
				debtor: this.viewingCust.debtorno + '' + this.viewingCust.branchcode,
				coords: viewing,
				marker: new tt.Marker({color: this.marker_color['viewing']}).setLngLat(viewing)
			})
	
			//home marker
			this.markers.push({
				debtor: 'home',
				coords: home,
				marker:  new tt.Marker({color: this.marker_color['home']}).setLngLat(home)
			});
	
			this.map.addControl(new tt.NavigationControl())
	
			this.map.on('load', ()=>{
				//draw markers
				this.markers.map((mark)=> mark['marker'].addTo(this.map));
	
				//zoom in on home
				const bounds: any = [home,viewing];
				this.map.fitBounds(bounds, {
					maxZoom: 9
				});
			})
		}
	}

	cycle(dir){
		//remove color from current index
		this.updateMarkerColor('inactive');

		if(dir == 'next'){
			this.debtorIndex < this.rowDebtors.length - 1  ? this.debtorIndex++ : this.debtorIndex = 0 
		}else{
			this.debtorIndex > 0 ? this.debtorIndex-- : this.debtorIndex = this.rowDebtors.length -1;
		}

		//add color to new index
		this.updateMarkerColor('viewing');		
		//update viewing cust
		this.viewingCust = this.rowDebtors[this.debtorIndex];
		
	}

	updateMarkerColor(type){
		var id;
		var match;
		switch(type){
			case 'inactive':
				id = this.viewingCust.debtorno + '' + this.viewingCust.branchcode;
				match = this.markers.find((o)=> id == o.debtor);
				match.marker.remove();
				match.marker = new tt.Marker({}).setLngLat(match.coords).addTo(this.map);
				break;
			case 'viewing':
				id = this.rowDebtors[this.debtorIndex].debtorno + "" + this.rowDebtors[this.debtorIndex].branchcode;
				match = this.markers.find((o)=> id == o.debtor );
				match.marker.remove();
				match.marker = new tt.Marker({color: this.marker_color['viewing']}).setLngLat(match.coords).addTo(this.map)
				break;
		}
	}


	updateViewing(row){
		this.updateMarkerColor('inactive');
		this.debtorIndex = this.rowDebtors.indexOf(this.rowDebtors.find((o)=> o.debtorno == row.debtorno && o.branchcode == row.branchcode));
		this.updateMarkerColor('viewing');
		this.viewingCust = this.rowDebtors[this.debtorIndex];
	}


	updateRoute(row){
		const debtors = (row.debtors).map((cust)=>{return {'debtorno' : cust.debtorno, 'branchcode': cust.branchcode}})
		const data = {
			zipcode: row.zipcode,
			debtors: debtors,
			route: this.routeRef.value
		}

		this.dispatchService.updateRouteByZipcode(data).subscribe((res)=>{
			if(res.success){
				this.globalSearchService.showNotification(`Zipcode '${row.zipcode}' updated`, 'success', 'bottom','left');
				this.loadData()
			}
		})
	}

	dismissModal(){
		this.modalService.dismissAll('action');
	}

	exportXls() {
		const encoded: string = this.globalSearchService.base64encode(this.getHtmlTable());
		const data = {
			content: encoded,
			filename: `zipcodeToRoutes`,
			title: `Zipcode to Route`,
			subtitle: `Routes assigned to zipcodes`
		}

		this.printService.xls(data).subscribe((result: any) => {
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	exportPdf() {
		const encoded: string = this.globalSearchService.base64encode(this.getHtmlTable());
		const data = {
			content: encoded,
			filename: `zipcodeToRoutes`,
			title: `Zipcode to Route`,
			subtitle: `Routes assigned to zipcodes`
		}

		this.printService.pdf(data).subscribe((result: any) => {
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	getHtmlTable(){
		//header
		var html = '<table><thead><tr>';
		(Object.keys(this.verifiedzipcodes[0])).forEach((k)=>{
			if(k != 'debtors'){
				html += ("<th>" + k + "</th>");
			}
		})
		html += '</tr></thead><tbody>'

		//body
		this.verifiedzipcodes.forEach(row => {
			html += "<tr>";
			var vals = Object.values(row);
			vals.forEach(v => {
				if(!Array.isArray(v)){
					v = v == (undefined || null) ? '' : v;
					html += "<td>" + v + "</td>";
				}
			});
			html += "</tr>";
		});
		html += "</tbody></table>"
		return html;


	}

}	
