<div class="main-content">
	<div class="container-fluid">
		<div class="row tp-wrapper m-0 p-0">
			<div class="col-md-12 ">
				<div class="card ">
					<div class="card-header card-header-{{color}}-6">
						<h4 class="card-title" (click)="back()">
							<i class="material-icons">arrow_left</i>
							{{ title }}
						</h4>
					</div>
					<div class="card-body">

						<div class="row">
							<!-- AR/AP Mode Toggle -->
							<div class="col-md-1">
								<mat-slide-toggle (change)="toggleMode()" class="mt-3">
									{{ isARMode ? 'AR' : 'AP' }}
								</mat-slide-toggle>
							</div>

							<!-- Hide/Show Cleared Toggle -->
							<div class="col-md-2">
								<mat-slide-toggle [formControl]="showCleared" (change)="toggleClearView()" class="mt-3">
									{{ !showCleared ? 'Hide Cleared' : 'Show Cleared' }}
								</mat-slide-toggle>
							</div>

							<!-- Search Field -->
							<div class="col-md-3">
								<mat-form-field appearance="outline" class="full-width">
									<mat-label>Search</mat-label>
									<input matInput type="text" placeholder="Search transactions" [formControl]="searchFilter" />
								</mat-form-field>
							</div>

							<!-- Warehouse Dropdown -->
							<div class="col-md-2">
								<mat-form-field appearance="outline">
									<mat-label>Warehouse</mat-label>
									<mat-select [formControl]="defaultlocation" multiple appSelectAll>
										<mat-option *ngFor="let loc of locations" [value]="loc.loccode">
											{{ loc.locationname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<!-- Payment Type Dropdown -->
							<div class="col-md-2">
								<mat-form-field appearance="outline">
									<mat-label>Type</mat-label>
									<mat-select [formControl]="paymenttypefilter" multiple appSelectAll>
										<mat-option *ngFor="let cur of payment_methods" [value]="cur">
											{{ cur.paymentname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>

							<!-- Date Range Picker -->
							<div [ngClass]="config.env.package == 'beauty' ? 'col-md-3' : 'col-md-3'">
								<mat-form-field appearance="outline" class="full-width">
									<mat-label>Date Range</mat-label>
									<mat-date-range-input [rangePicker]="dateRangePicker">
										<input matStartDate [formControl]="datefrom" placeholder="From">
										<input matEndDate [formControl]="dateto" placeholder="To">
									</mat-date-range-input>
									<mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
									<mat-date-range-picker #dateRangePicker></mat-date-range-picker>
								</mat-form-field>
							</div>

							<!-- Load Button -->
							<div class="col-md-2">
							  <button
								mat-raised-button
								color="accent"
								class="mt-3"
								(click)="loadTransactions()"
								[disabled]="running"
							  >
								<span matPrefix *ngIf="running" class="ml-auto mr-auto">
									<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
								<span *ngIf="!running">Load</span>
							  </button>
							</div>

						</div>

						<div class="row">
							<!-- Summary Section -->
							<div class="col-12">
								<div class="summary-section mt-3">
									<div>Total Payments: {{ total_pay | currency }}</div>
									<div>Credit Card Total: {{ cctotal | currency }}</div>
									<!-- <div>Total Sub: {{ total_sub | currency }}</div> -->
									<div>Total Discount: {{ total_discount | currency }}</div>
								</div>
							</div>

							<!-- Transactions Section -->
							<div class="col-12">
								<div class="transactions-container">
									<!-- Header -->
									<div class="transaction-header">
										<span>Transaction #</span>
										<span>Date</span>
										<span>Debtor #</span>
										<span>Reference</span>
										<span>Amount</span>
										<span>Discount</span>
										<span>Currency</span>
										<span>Allocation</span>
										<span>Check #</span>
										<span>Bank Type</span>
										<span>Name</span>
										<span>Cleared</span>
										<span></span>

									</div>
									<!-- Rows -->
									<div *ngFor="let transaction of filteredTransactions;trackBy: trackByTransaction" class="transaction-row" [class.partial]="transaction.amountCleared && transaction.amountCleared != transaction.ovamount && !transaction.isCleared ">
										<!-- Action Column -->
										<span [class.cleared]="transaction.isCleared">{{ transaction.transno }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.trandate }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.debtorno }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.reference }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.ovamount | currency:transaction.currcode }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.ovdiscount | currency:transaction.currcode }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.currcode }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.alloc | currency:transaction.currcode }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.checknum }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.banktranstype }}</span>
										<span [class.cleared]="transaction.isCleared">{{ transaction.name }}</span>
										<span [class.cleared]="transaction.isCleared" [ngClass]="transaction.isSelected ? 'editable-amount' : ''">
											<span *ngIf="!transaction.isSelected" class="editable-amount" (click)="toggleEdit(transaction)">
												{{ transaction.amountCleared | currency }}
												<i class="fa fa-edit"></i>
											</span>

											<ng-container *ngIf="transaction.isSelected">
												<div class="editable-container">
													<div class="editable-row">
														<input id="amount-input-{{ transaction.transno }}" type="number" [formControl]="temporaryAmount" class="editable-input" placeholder="Enter amount" />
													</div>
													<div class="editable-actions">
														<button class="editable-save-button" (click)="savePartial(transaction)">
															<i class="fa fa-save"></i>
														</button>
														<button class="editable-cancel-button" (click)="toggleEdit(transaction)">
															<i class="fa fa-close"></i>
														</button>
													</div>
												</div>
											</ng-container>

										</span>

										<span (click)="toggleClear(transaction)">
											<div class="custom-checkbox-wrapper">
												<label for="cleared-{{ transaction.id }}" class="custom-checkbox-label">
													<div class="amount-display">

													</div>
												</label>

												<input type="checkbox" id="cleared-{{ transaction.id }}" [(ngModel)]="transaction.isCleared" class="custom-checkbox" />
											</div>
										</span>


									</div>

								</div>
							</div>
						</div>


					</div>
				</div>
			</div>
		</div>
	</div>
</div>