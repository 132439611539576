<div class="text-end">
  <button mat-raised-button class="mt-2 mb-2" color="accent" (click)="isFormVisible = !isFormVisible; resetForm()">
	{{ isFormVisible ? 'Hide Form' : 'Add New Location' }}
  </button>
</div>

<!-- Form for Adding/Editing Locations -->
<div *ngIf="isFormVisible" [@expandCollapse] class="form-container p-4 border rounded shadow-sm bg-light">
  <form [formGroup]="assetLocationForm" (ngSubmit)="saveLocation()">
	<div class="row g-4">
	  <!-- Section Title -->
	  <div class="col-12">
		<h4 class="text-primary mb-3">{{ editingLocation ? 'Edit Location' : 'Add New Location' }}</h4>
	  </div>

	  <!-- Location ID -->
	  <div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
		  <mat-label>Location ID</mat-label>
		  <input matInput formControlName="locationid" placeholder="Enter location ID" />
		  <mat-error *ngIf="assetLocationForm.get('locationid')?.invalid">
			Location ID is required.
		  </mat-error>
		</mat-form-field>
	  </div>

	  <!-- Location Description -->
	  <div class="col-md-6">
		<mat-form-field appearance="outline" class="w-100">
		  <mat-label>Location Description</mat-label>
		  <input matInput formControlName="locationdescription" placeholder="Enter location description" />
		  <mat-error *ngIf="assetLocationForm.get('locationdescription')?.invalid">
			Location description is required.
		  </mat-error>
		</mat-form-field>
	  </div>

	  <!-- Submit Button -->
	  <div class="col-12 text-end mt-3">
		<button mat-raised-button color="accent" type="submit" [disabled]="assetLocationForm.invalid" class="w-25">
		  {{ editingLocation ? 'Update Location' : 'Add Location' }}
		</button>
	  </div>
	</div>
  </form>
</div>

<!-- Asset Locations Table -->
<div class="table-responsive mt-4">
  <table class="table table-striped table-hover table-bordered">
	<thead class="thead-dark">
	  <tr>
		<th class="text-center">Location ID</th>
		<th class="text-center">Description</th>
		<th class="text-center">Actions</th>
	  </tr>
	</thead>
	<tbody>
	  <tr *ngFor="let location of assetLocations" [class.selected]="editingLocation?.id === location.id">
		<td class="text-center">{{ location.locationid }}</td>
		<td class="text-center">{{ location.locationdescription }}</td>
		<td class="text-center">
		  <button mat-icon-button color="primary" (click)="editLocation(location)" matTooltip="Edit">
			<mat-icon>edit</mat-icon>
		  </button>
		  <button mat-icon-button color="warn" (click)="deleteLocation(location.locationid)" matTooltip="Delete">
			<mat-icon>delete</mat-icon>
		  </button>
		</td>
	  </tr>
	</tbody>
  </table>
</div>
