<div class="game-container">
  <!-- Maze -->
  <div *ngFor="let row of maze; let rowIndex = index" class="maze-row">
	<div
	  *ngFor="let cell of row; let colIndex = index"
	  class="maze-cell"
	  [ngClass]="{ wall: cell === 1, path: cell === 0 }"
	  [ngStyle]="{
		'top.px': rowIndex * gridSize,
		'left.px': colIndex * gridSize
	  }"
	></div>
  </div>

  <!-- Pac-Man -->
  <div
	class="pacman"
	[ngStyle]="{
	  'top.px': pacmanPosition.top,
	  'left.px': pacmanPosition.left,
	  'transform': pacmanDirection
	}"
  ></div>

  <!-- Pellets -->
  <div
	*ngFor="let pellet of pellets"
	class="pellet"
	[ngStyle]="{'top.px': pellet.top, 'left.px': pellet.left}"
  ></div>

  <!-- Ghosts -->
  <div
	*ngFor="let ghost of ghosts"
	class="ghost"
	[ngStyle]="{'top.px': ghost.top, 'left.px': ghost.left}"
  ></div>
</div>

<!-- Status Display -->
<div class="status">
  <h3>Score: {{ score }}</h3>
  <h3>Lives: {{ lives }}</h3>
  <h3>Level: {{ level }}</h3>
  <h3>Time: {{ timer | date: 'mm:ss' }}</h3>
  <h3 *ngIf="isGameOver">Game Over!</h3>
  <h3 *ngIf="isWin">You Win! Proceeding to Next Level...</h3>
</div>

<!-- Controls -->
<div class="controls">
  <button (click)="togglePause()">{{ isPaused ? 'Resume' : 'Pause' }}</button>
  <button (click)="restartGame()">Restart</button>
</div>