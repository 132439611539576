import { Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ReportsComponent } from '../reports/reports.component';
import { ReportViewComponent } from '../reports/report-view/report-view.component';

import { AuthGuardService as AuthGuard } from '../auth/auth-guard.service'

//vustomers
import { CustomersComponent } from '../customers/customers.component';
import { CustomersGroupsComponent } from '../customers/customers-groups/customers-groups.component'
import { CustomerDashboardComponent } from '../customers/customer-dashboard/customer-dashboard.component';
import { CrptComponent } from '../customers/crpt/crpt.component';
import { CsrptComponent } from '../customers/csrpt/csrpt.component';
import { CustomerItemHistoryComponent } from '../customers/customer-item-history/customer-item-history.component';
import { CustomerMonthlyHistoryComponent } from '../customers/customer-monthly-history/customer-monthly-history.component';
import { CustomerCdetComponent } from '../customers/customer-cdet/customer-cdet.component';
import { CustomerReportsComponent } from '../customers/customer-reports/customer-reports.component';

import { CpslsComponent } from '../customers/cpsls/cpsls.component';

import { CvslsComponent } from '../customers/cvsls/cvsls.component';
import { CustomerCpdetComponent } from '../customers/customer-cpdet/customer-cpdet.component';
import { ViewComponent } from '../customers/view/view.component';
import { CustomerOrdersComponent } from '../customers/customer-orders/customer-orders.component';
import { CustomerEditComponent } from '../customers/edit/customer-edit/customer-edit.component';
import { CustomerExportComponent } from '../customers/customer-export/customer-export.component';
import { CustomerVehiclesComponent } from '../customers/customer-vehicles/customer-vehicles.component';
import { ConsignmentEditComponent } from '../customers/consignment-edit/consignment-edit.component';
//oe


import { LogoutComponent } from '../auth/logout/logout.component';
//import { OrdersComponent } from '../orders/orders.component';
import { OrderLookupComponent } from '../orders/order-lookup/order-lookup.component';
import { OrderSuccessComponent } from '../orders/order-entry/order-success/order-success.component';
import { OrdersDashboardComponent } from '../orders/orders-dashboard/orders-dashboard.component';
import { OrderConfirmationComponent } from '../orders/order-confirmation/order-confirmation.component';
import { OrderInquiryComponent } from '../orders/order-inquiry/order-inquiry.component';
import { OrderItemInquiryComponent } from '../orders/order-item-inquiry/order-item-inquiry.component';
// import { OrderTrackingComponent } from 'app/orders/order-tracking/order-tracking.component';
import { OrderTrackingComponent } from 'app/shared/order-tracking/order-tracking.component';

import { OrdersOpenQuotesComponent } from '../orders/orders-open-quotes/orders-open-quotes.component';
import { ProductlineReportComponent } from '../orders/productline-report/productline-report.component';


//import { OrderManagementComponent } from '../orders/order-management/order-management.component';
import { VendorsComponent } from '../vendors/vendors.component';
import { VendorItemsComponent } from '../vendors/vendor-items/vendor-items.component';
import { VendorEditComponent } from '../vendors/edit/vendor-edit/vendor-edit.component';
import { VendorCreateComponent } from '../vendors/vendor-create/vendor-create.component';
import { VendorViewComponent } from '../vendors/vendor-view/vendor-view.component';
import { VendorInvoiceComponent } from '../vendors/vendor-invoice/vendor-invoice.component';
import { VendorsDashboardComponent } from '../vendors/vendors-dashboard/vendors-dashboard.component';
import { VendorAllocationsComponent } from '../vendors/vendor-allocations/vendor-allocations.component'
import { VrptComponent } from '../vendors/vrpt/vrpt.component';
import { CheckPrintingComponent } from '../vendors/check-printing/check-printing.component';

import { VendorInvoiceCompleteComponent } from '../vendors/vendor-invoice/vendor-invoice-complete/vendor-invoice-complete.component';

import { RouteBuilderComponent } from '../orders/route-builder/route-builder.component';

import { DispatchesRouteBuilderComponent } from '../dispatches/dispatches-route-builder/dispatches-route-builder.component';
import { DispatchesDashboardComponent } from '../dispatches/dispatches-dashboard/dispatches-dashboard.component';

import { DispatchesLoadPriorityComponent } from '../dispatches/dispatches-load-priority/dispatches-load-priority.component';


import { DispatchesActiveDispatchComponent } from '../dispatches/dispatches-active-dispatch/dispatches-active-dispatch.component';

import { DispatchesLoadSheetComponent } from '../dispatches/dispatches-load-sheet/dispatches-load-sheet.component';

import { OrderPickComponent } from '../orders/order-pick/order-pick.component';

import { OrderCheckComponent } from '../orders/order-check/order-check.component';

import { AdrsComponent } from '../orders/adrs/adrs.component';
import { OrderOpenlistComponent } from '../orders/order-openlist/order-openlist.component';
import { OrderLoadComponent } from '../orders/order-load/order-load.component';
//import { OrderInvoiceComponent } from '../orders/order-entry/order-invoice/order-invoice.component';
import { OrderDispatchComponent } from '../orders/order-entry/order-dispatch/order-dispatch.component';

import { OrderReprintPickComponent } from '../orders/order-reprint-pick/order-reprint-pick.component'

import { TimelineComponent } from '../orders/timeline/timeline.component';
import { CustomerSearchComponent } from '../orders/customer-search/customer-search.component';
import { OrderEntryComponent } from '../orders/order-entry/order-entry.component'


//import { PickComponent } from '../orders/pick/pick.component';
import { DispatchesComponent } from '../dispatches/dispatches.component';
import { DispatchesDriverRunComponent } from '../dispatches/dispatches-driver-run/dispatches-driver-run.component';
import { DispatchesDeliveredNotinvoicedComponent } from '../dispatches/dispatches-delivered-notinvoiced/dispatches-delivered-notinvoiced.component';
import { DispatchesMaintanceComponent } from '../dispatches/dispatches-maintance/dispatches-maintance.component';
import { DispatchesRouteMaintanceComponent } from '../dispatches/dispatches-route-maintance/dispatches-route-maintance.component';



import { InventoryComponent } from '../inventory/inventory.component';
import { InventoryDashboardComponent } from '../inventory/inventory-dashboard/inventory-dashboard.component';
import { InventoryListComponent } from '../inventory/inventory-list/inventory-list.component';
import { SpecialPricingComponent } from '../inventory/special-pricing/special-pricing.component';
import { InventoryTransfersComponent } from '../inventory/inventory-transfers/inventory-transfers.component';
import { InventoryPromosComponent } from '../inventory/inventory-promos/inventory-promos.component';
import { InventoryGlclassComponent } from '../inventory/inventory-glclass/inventory-glclass.component';

import { BranchReorderComponent } from '../inventory/branch-reorder/branch-reorder.component';
import { WeeklyReorderComponent } from '../inventory/weekly-reorder/weekly-reorder.component';

import { WarehouseBinsComponent } from '../warehouse/warehouse-bins/warehouse-bins.component'
import { WarehouseDashboardComponent } from '../warehouse/warehouse-dashboard/warehouse-dashboard.component'
import { WarehouseItemReceivingComponent } from '../warehouse/warehouse-item-receiving/warehouse-item-receiving.component'
import { WarehouseItemScanComponent } from '../warehouse/warehouse-item-scan/warehouse-item-scan.component'
import { WarehouseItemScanTwoComponent } from '../warehouse/warehouse-item-scan-two/warehouse-item-scan-two.component'
import { WarehouseItemLookupComponent } from '../warehouse/warehouse-item-lookup/warehouse-item-lookup.component'

import { WarehouseStockCountsComponent } from '../warehouse/warehouse-stock-counts/warehouse-stock-counts.component'


import { DeliveryBoardComponent } from '../warehouse/delivery-board/delivery-board.component'

import { QantelItemSearchComponent } from '../qantel/qantel-item-search/qantel-item-search.component'
import { QantelOrderDashComponent } from '../qantel/qantel-order-dash/qantel-order-dash.component';
import { OdsComponent } from '../ods/ods.component';

//purchasing
import { PurchaseCreateComponent } from '../purchasing/purchase-create/purchase-create.component';
import { PurchaseOrderEntryComponent } from '../purchasing/purchase-order-entry/purchase-order-entry.component';
import { PurchasingSuccessComponent } from '../purchasing/purchasing-success/purchasing-success.component';
import { PurchasingDashboardComponent } from '../purchasing/purchasing-dashboard/purchasing-dashboard.component';
import { PurchaseOrderReceiveComponent } from '../purchasing/purchase-order-receive/purchase-order-receive.component'

import { PurchaseOrderInquiryComponent } from '../purchasing/purchase-order-inquiry/purchase-order-inquiry.component'


import { PurchasingReceiptjournalComponent } from '../purchasing/purchasing-receiptjournal/purchasing-receiptjournal.component';

import { PurchaseOrderViewComponent } from '../purchasing/purchase-order-view/purchase-order-view.component';

import { PurchasingReceiptjournaluploadComponent } from '../purchasing/purchasing-receiptjournalupload/purchasing-receiptjournalupload.component';
import { PurchasingReorderingRestockComponent } from '../purchasing/purchasing-reordering/purchasing-reordering-restock/purchasing-reordering-restock.component';
import { PurchasingReorderingAvgsalesComponent } from '../purchasing/purchasing-reordering/purchasing-reordering-avgsales/purchasing-reordering-avgsales.component';
import { PurchasingReorderingMinmaxComponent } from '../purchasing/purchasing-reordering/purchasing-reordering-minmax/purchasing-reordering-minmax.component';
import { PurchasingBrandPriorityComponent } from '../purchasing/purchasing-brand-priority/purchasing-brand-priority.component';
import { PurchasingReorderingComponent } from '../purchasing/purchasing-reordering/purchasing-reordering.component';


import { CustomerCreateComponent } from '../customers/customer-create/customer-create.component';

import { InventoryViewComponent } from '../inventory/inventory-view/inventory-view.component';

import { InventoryCreateComponent } from '../inventory/inventory-create/inventory-create.component';
import { InventoryValueComponent } from '../inventory/inventory-value/inventory-value.component';


import { CompanySetupComponent } from '../system/company-setup/company-setup.component';
import { InventoryLocationsComponent } from '../system/inventory-locations/inventory-locations.component';
import { InventorySettingsComponent } from '../system/inventory-settings/inventory-settings.component';
import { StorefrontSettingsComponent } from '../system/storefront-settings/storefront-settings.component';


import { InventoryLocationsCreateComponent } from '../system/inventory-locations/inventory-locations-create/inventory-locations-create.component';
import { GlAccountsComponent } from '../system/gl-accounts/gl-accounts.component'
import { CustomerGroupsComponent } from '../system/customer-groups/customer-groups.component'
import { ProductGroupsComponent } from '../system/product-groups/product-groups.component'
import { AccountSectionsComponent } from '../system/gl-accounts/account-sections/account-sections.component';
import { AccountGroupsComponent } from '../system/gl-accounts/account-groups/account-groups.component';

import { CouponSetupComponent } from '../system/coupon-setup/coupon-setup.component';


import { MailComponent } from '../system/mail/mail.component';

import { PaymentEntryComponent } from '../receivables/payment-entry/payment-entry.component'
import { ReceivablesByshipviaComponent } from '../receivables/receivables-byshipvia/receivables-byshipvia.component'

import { DailyTransactionsComponent } from '../receivables/daily-transactions/daily-transactions.component'
import { DriComponent } from '../receivables/dri/dri.component'

import { JournalReportComponent } from '../receivables/journal-report/journal-report.component'


import { ReceivablesAllocationsComponent } from '../receivables/receivables-allocations/receivables-allocations.component'
import { ReceivablesDashboardComponent } from '../receivables/receivables-dashboard/receivables-dashboard.component'
import { SrptComponent } from '../receivables/srpt/srpt.component';



import { StatementsComponent } from '../receivables/statements/statements.component'
import { DayendCheckoutComponent } from '../receivables/dayend-checkout/dayend-checkout.component'
import { RsiComponent } from '../receivables/rsi/rsi.component'
import { DregComponent } from '../receivables/dreg/dreg.component'

import { PaymentReportComponent } from '../receivables/payment-report/payment-report.component'
import { PosSalesReportComponent } from '../receivables/pos-sales-report/pos-sales-report.component'

import { UsersComponent } from '../users/users.component'
import { UserEditComponent } from '../users/user-edit/user-edit.component'
import { UserProfileComponent } from '../users/user-profile/user-profile.component'
import { UserSettingsComponent } from '../users/user-settings/user-settings.component'
import { UserCreateComponent } from '../users/user-create/user-create.component'

import { UserGroupsComponent } from '../users/user-groups/user-groups.component'
import { UserGroupsEditComponent } from '../users/user-groups-edit/user-groups-edit.component'

import { PermissionComponent } from '../auth/permission/permission.component';

import { ApiSettingsComponent } from '../system/api-settings/api-settings.component';
import { SystemTypesViewComponent } from '../system/system-types-view/system-types-view.component';

import { MergeCustomersComponent } from '../system/merge-customers/merge-customers.component';
import { MoveTransactionComponent } from '../system/move-transaction/move-transaction.component';
import { ReportBuilderComponent } from '../system/report-builder/report-builder.component';
import { SaleBuilderComponent } from '../system/sale-builder/sale-builder.component';
import { TaxesComponent } from '../system/taxes/taxes.component';
import { BankAccountsComponent } from '../system/bank-accounts/bank-accounts.component';

import { CreditFreestandingComponent } from '../credits/credit-freestanding/credit-freestanding.component'

import { CreditSuccessComponent } from '../credits/credit-success/credit-success.component'
import { SuccessComponent } from '../credits/success/success.component'
import { CreditReceiveComponent } from '../credits/credit-receive/credit-receive.component'
import { CreditLoadComponent } from '../credits/credit-load/credit-load.component'
import { OpenCreditListComponent } from '../credits/open-credit-list/open-credit-list.component'


import { CreditDispatchComponent } from '../credits/credit-dispatch/credit-dispatch.component'
import { FinalizeComponent } from '../credits/finalize/finalize.component'


import { FixedAssetsManagementComponent } from '../fixed-assets/fixed-assets-management/fixed-assets-management.component'

import { TrialBalanceComponent } from '../general-ledger/trial-balance/trial-balance.component'
import { JournalInquiryComponent } from '../general-ledger/journal-inquiry/journal-inquiry.component'
import { GlBudgetsComponent } from '../general-ledger/gl-budgets/gl-budgets.component'
import { GLTagsComponent } from '../general-ledger/gl-tags/gl-tags.component'
import { SalesGLPostingsComponent } from '../general-ledger/sales-gl-postings/sales-gl-postings.component'
import { CogsGlPostingsComponent } from '../general-ledger/cogs-gl-postings/cogs-gl-postings.component'
import { InventoryGlPostingsComponent } from '../general-ledger/inventory-gl-postings/inventory-gl-postings.component'

//import { SalesTaxComponent } from '../general-ledger/sales-tax/sales-tax.component'

import { SalesTaxCompressedComponent } from '../general-ledger/sales-tax-compressed/sales-tax-compressed.component'


import { AccountInquiryComponent } from '../general-ledger/account-inquiry/account-inquiry.component'

import { ProfitLossComponent } from '../general-ledger/profit-loss/profit-loss.component'
import { BalanceSheetComponent } from '../general-ledger/balance-sheet/balance-sheet.component'
import { JournalEntryComponent } from '../general-ledger/journal-entry/journal-entry.component'
import { BankRecComponent } from '../general-ledger/bank-rec/bank-rec.component'

//import { UsergroupsComponent} from '../usergroups/usergroups.component'

import { PurchasingOpenOrdersComponent } from '../purchasing/purchasing-open-orders/purchasing-open-orders.component';
import { CounterPosComponent } from '../counter-pos/counter-pos/counter-pos.component'
import { CounterPosInvoiceComponent } from '../counter-pos/counter-pos-invoice/counter-pos-invoice.component'
import { CounterPosDashboardComponent } from '../counter-pos/counter-pos-dashboard/counter-pos-dashboard.component'
import { ItemQueryComponent } from '../counter-pos/item-query/item-query.component'
import { PosReceiptComponent } from '../counter-pos/pos-receipt/pos-receipt.component'


import { WarehouseFinalizerecComponent } from '../warehouse/warehouse-finalizerec/warehouse-finalizerec.component';

import { ArchiveListComponent } from '../archive/archive-list/archive-list.component';
import { WarehouseReportsComponent } from '../warehouse/warehouse-reports/warehouse-reports.component';

import { WarehouseCheckingComponent } from '../warehouse/warehouse-checking/warehouse-checking.component';
import { IdetReportComponent } from '../shared/idet-report/idet-report.component';
import { TopHundredComponent } from '../shared/top-hundred/top-hundred.component';
import { SpslsComponent } from '../orders/spsls/spsls.component';
import { ShippingMethodsComponent } from '../system/shipping-methods/shipping-methods.component';

import { InventoryUploadComponent } from '../inventory/inventory-upload/inventory-upload.component';
import { CidReportComponent } from '../shared/cid-report/cid-report.component';

import { EducationEventsComponent } from '../education/education-events/education-events.component';
import { EducationLocationsComponent } from '../education/education-locations/education-locations.component';
import { JpmsReportComponent } from '../shared/jpms-report/jpms-report.component';
import { ReportsPmmComponent } from '../shared/reports-pmm/reports-pmm.component';

import { PriceLevelPricingComponent } from '../customers/price-level-pricing/price-level-pricing.component';
import { BarcodeAssignerComponent } from '../shared/barcode-assigner/barcode-assigner.component';
import { FormBuilderComponent } from '../shared/form-builder/form-builder.component';
import { WarehouseCheckingFedexComponent } from 'app/warehouse/warehouse-checking-fedex/warehouse-checking-fedex.component';
import { DispatchDriverUnloadComponent } from '../dispatches/dispatch-driver-unload/dispatch-driver-unload.component';
import { WarehouseCheckingWifiComponent } from '../warehouse/warehouse-checking-wifi/warehouse-checking-wifi.component';

//import { ReportsService } from '../services/reports.service';


export interface RouteInfo {
	path: string;
	title: string;
	icon: string;
	class: string;
	children ? : any[];
	reports ? : any[];
	anchor ? : string;
	package ? : string;

}
// SIDE BAR DISPLAY

export const VERSION: any = { version: '1.1.2' }


export const ROUTES: RouteInfo[] = [

		{ path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: '' },
		//{ path: '/orders/entry/new', title: 'New Order', icon: 'launch', class: '' },
		{
			path: '/customers',
			title: 'Customers',
			icon: 'groups',
			class: '',
			anchor: 'customers',
			children: [{
					path: '/customers/dashboard',
					title: 'Customer Dashboard',
					icon: 'CD',
					class: '',
				},
				{
					path: '/customers',
					title: 'Customer Lookup',
					icon: 'CL',
					class: '',
				},
				//{
				//	path: '/customer/groups',
				//	title: 'Customer Groups',
				//	icon: 'CL',
				//	class: '',
				//},
			],
			reports: [{
					path: '/customers/crpt',
					title: 'Customer Sales By Branch',
					icon: 'CRPT',
					class: '',
				},
				{
					path: '/customers/csrpt',
					title: 'Customer Salesman Ranking',
					icon: 'CSRPT',
					class: '',
				},
				{
					path: '/customers/itemHistory',
					title: 'Customer Item History',
					icon: 'CIH',
					class: '',
				},
				{
					path: '/customers/monthlyHistory',
					title: 'Customer Monthly History',
					icon: 'CMH',
					class: '',
				},
				{
					path: '/customers/cdet',
					title: 'Customer Detail Report',
					icon: 'CDET',
					class: '',
				},
				{
					path: '/customers/cpsls',
					title: 'Customer PL Sales Report',
					longtitle: 'Customer Product Line Sales Report',
					icon: 'CPSLS',
					class: '',
				},
				{
					path: '/customers/customer-cpdet',
					title: 'Single Customer PL Sales Report',
					longtitle: 'Single Customer Product Line Sales Report',
					icon: 'CPDET',
					class: '',
				},
				{
					path: '/customers/customer-export',
					title: 'Customer Export',
					longtitle: 'Customer Export',
					icon: 'CEXP',
					class: '',
				},

			]
		},
		{
			path: '/counter-pos',
			title: 'Counter POS',
			icon: 'point_of_sale',
			class: '',
			anchor: 'counter-pos',
			package: 'beauty',
			children: [
				// Dashboard and Core Operations
				{
					path: '/counter-pos-dashboard',
					title: 'POS Dashboard',
					icon: 'PD',
					class: '',
					anchor: 'counter-pos-dashboard'
				},
				{
					path: '/counter-pos',
					title: 'New Order',
					icon: 'OE',
					class: '',
					anchor: 'counter-daily-orderentry'
				},
				{
					path: '/counter-pos/openorders',
					title: 'Open Orders',
					icon: 'OO',
					class: '',
					anchor: 'orders-open-pos'
				},
				{
					path: '/counter-pos/create-credit-note',
					title: 'Create Credit',
					icon: 'CC',
					class: '',
					anchor: 'orders-create-credit-note-pos'
				},

				// Reports
				{
					path: '/counter-pos/daily-transactions',
					title: 'Daily Transactions',
					icon: 'DT',
					class: '',
					anchor: 'counter-daily-transactions'
				},
				{
					path: '/counter-pos/cid-report',
					title: 'Cust Item Sales Report',
					icon: 'CID',
					class: '',
					anchor: 'counter-cid-report'
				},
				{
					path: '/counter-pos/payment-report',
					title: 'Payment Report',
					icon: 'PR',
					class: '',
					anchor: 'counter-payments'
				},

				// Inventory and Reordering
				{
					path: '/inventory/weekly-reorder',
					title: 'Weekly Re-Ordering',
					icon: 'BR',
					class: '',
					package: ''
				},

				// Queries and Inquiries
				{
					path: '/counter-pos/iq',
					title: 'Item Query',
					icon: 'IQ',
					class: '',
					anchor: 'counter-pos-item-query'
				},
				{
					path: '/counter-pos/dri',
					title: 'Daily Receivables Inquiry',
					icon: 'DRI',
					class: '',
					anchor: 'dri'
				},
				{
					path: '/counter-pos/dreg',
					title: 'DREG',
					icon: 'DR',
					class: '',
					anchor: 'counter-dreg'
				}
			]
		},

		{
			path: '/orders',
			title: 'Orders',
			icon: 'shopping_cart',
			class: '',
			anchor: 'sales',
			children: [
				/*{
					path: '/orders/qantel-dashboard',
					title: 'Q Order DashBoard',
					icon: 'QOD',
					class: '',
					anchor: 'orders-qantel-dashboard'
				},
				*/
				{
					path: '/orders/dashboard',
					title: 'Order DashBoard',
					icon: 'OD',
					class: '',
					anchor: 'orders-dashboard'
				},
				{
					path: '/orders/entry',
					title: 'Order Entry',
					icon: 'OE',
					class: '',
					anchor: 'orders-entry'
				},
				{
					path: '/orders/openorders',
					title: 'Open Orders',
					icon: 'OO',
					class: '',
					anchor: 'orders-open'
				},
				{
					path: '/orders/openquotes',
					title: 'Open Quotes',
					icon: 'OQ',
					class: '',
					anchor: 'orders-open-quotes'
				},
				{
					path: '/orders/create-credit-note',
					title: 'Create RMA',
					icon: 'CC',
					class: '',
					anchor: 'orders-create-credit-note'
				},
				{
					path: '/credits/open-credits',
					title: 'Open RMAs',
					icon: 'OC',
					class: '',
					anchor: 'open-credit-list'
				},
				{
					path: '/orders/order-inquiry',
					title: 'Order Inquiry',
					icon: 'OI',
					class: '',
					anchor: 'order-inquiry'
				},
				{
					path: '/orders/order-item-inquiry',
					title: 'Order Item Inquiry',
					icon: 'OII',
					class: '',
					anchor: 'order-item-inquiry'
				},
				{
					path: '/orders/order-tracking',
					title: 'Order tracking',
					icon: 'OT',
					class: '',
					anchor: 'order-tracking'
				},
				/*{
					path: '/orders/order-confirmation',
					title: 'Order Confirmation',
					icon: 'OC',
					class: '',
					anchor: 'order-confirmation'
				},

				{
					path: '/orders/reprintpick',
					title: 'Print Picking Lists',
					icon: 'PP',
					class: '',
					anchor: 'orders-reprintpick'
				},
				*/
			],
			reports: [{
				path: '/orders/productline-report',
				title: 'Productline Report',
				icon: 'PLR',
				class: '',
				anchor: 'productline-report'
			},
			{
				path: '/orders/spsls',
				title: 'Salesman Product Line Sales by Branch',
				icon: 'SPSLS',
				class: '',
			},
			{
				path: '/orders/jpms',
				title: 'JPMS',
				icon: 'JPMS',
				class: '',
			},
			{
				path: '/orders/pmm',
				title: 'PMM',
				icon: 'PMM',
				class: '',
			}, ]
		},
		{
			path: '/sales-manager-reports',
			title: 'Salesman Reports',
			icon: 'trending_up',
			class: '',
			anchor: 'sales-manager-reports',
			package: 'beauty',



			children: [{
				path: '/sales-manager-reports/dailytrans',
				title: 'Daily Transactions',
				icon: 'DT',
				class: '',
			},
			{
				path: '/sales-manager-reports/crpt',
				title: 'Customer Sales By Branch',
				icon: 'CRPT',
				class: '',
			},
			{
				path: '/sales-manager-reports/csrpt',
				title: 'Customer Salesman Ranking',
				icon: 'CSRPT',
				class: '',
			},
			{
				path: '/sales-manager-reports/itemHistory',
				title: 'Customer Item History',
				icon: 'CIH',
				class: '',
			},
			{
				path: '/sales-manager-reports/monthlyHistory',
				title: 'Customer Monthly History',
				icon: 'CMH',
				class: '',
			},
			{
				path: '/sales-manager-reports/cdet',
				title: 'Customer Detail Report',
				icon: 'CDET',
				class: '',
			},
			{
				path: '/sales-manager-reports/cpsls',
				title: 'Customer PL Sales Report',
				longtitle: 'Customer Product Line Sales Report',
				icon: 'CPSLS',
				class: '',
			},
			{
				path: '/sales-manager-reports/customer-cpdet',
				title: 'Single Customer PL Sales Report',
				longtitle: 'Single Customer Product Line Sales Report',
				icon: 'CPDET',
				class: '',
			},
			{
				path: '/sales-manager-reports/productline-report',
				title: 'Productline Report',
				icon: 'PLR',
				class: '',
				anchor: 'productline-report'
			},
			{
				path: '/sales-manager-reports/spsls',
				title: 'Salesman Product Line Sales by Branch',
				icon: 'SPSLS',
				class: '',
			},
			{
				path: '/sales-manager-reports/top-hundred',
				title: 'Top 100 Items',
				icon: '100',
				class: '',
			},
			{
				path: '/sales-manager-reports/cid-report',
				title: 'Cust Item Sales Detail Report',
				icon: 'CID',
				class: '',
			},
			{
				path: '/sales-manager-reports/cvsls',
				title: 'Vendor Prod. Customer Sales',
				longtitle: 'CVSLS - Vendor / Product / Customer Sales',
				icon: 'CVSLS',
				class: '',
				anchor: 'cvsls',
				package: 'beauty',
			},
			{
				path: '/sales-manager-reports/vrpt',
				title: 'Vendor Sales by Branch',
				longtitle: 'VRPT - Vendor Sales by Branch',
				icon: 'VRPT',
				class: '',
				anchor: 'vrpt',
				// package: 'beauty',
			}]
		},
		{
			path: '/vendors',
			title: 'Vendors',
			icon: 'contactless',
			class: '',
			anchor: 'payables',
			children: [
			{
				path: '/vendors-dashboard',
				title: 'Dashboard',
				icon: 'VD',
				class: '',
				anchor: 'vendors-dashboard'
			},
			{
				path: '/vendors',
				title: 'Lookup',
				icon: 'VL',
				class: '',
				anchor: 'vendors-lookup'
			},
			{
				path: '/vendors-check-print',
				title: 'Check Printing',
				icon: 'CP',
				class: '',
				anchor: 'vendors-check-print'
			},
			{
				path: '/vendor-allocations',
				title: 'Allocations',
				icon: 'A',
				class: '',
				anchor: 'vendor-allocations'
			}],
			reports: [
			{
				path: '/vendors/reports/vrpt',
				title: 'Vendor Sales by Branch',
				longtitle: 'VRPT - Vendor Sales by Branch',
				icon: 'VRPT',
				class: '',
				anchor: 'vrpt',
				// package: 'beauty',
			},
			{
				path: '/customers/cvsls',
				title: 'Vendor Prod. Customer Sales',
				longtitle: 'CVSLS - Vendor / Product / Customer Sales',
				icon: 'CVSLS',
				class: '',
				anchor: 'cvsls',
				package: 'beauty',
			}]
		},
		{
			path: '/warehouose',
			title: 'Warehouse',
			icon: 'domain',
			class: '',
			anchor: 'warehouse',
			children: [{
					path: '/warehouse/dashboard',
					title: 'Dashboard',
					icon: 'WD',
					class: '',
					anchor: 'warehouose-dashboard',
				},
				{
					path: '/warehouse/delivery-board',
					title: 'Incoming Shipments',
					icon: 'IS',
					class: '',
					anchor: 'delivery-board',
				},
				{
					path: '/warehouse/finalize-rec',
					title: 'Finalize Rec',
					icon: 'IS',
					class: '',
					anchor: 'finalize-rec',
				},
				{
					path: '/warehouse/item-lookup',
					title: 'Item Lookup',
					icon: 'IS',
					class: '',
					anchor: 'item-lookup',
				},
				{
					path: '/warehouse/item-scan-two',
					title: 'Scan Items',
					icon: 'SI',
					class: '',
					anchor: 'warehouse-item-scan-two',
				},
				// {
				// 	path: '/warehouse/item-scan',
				// 	title: 'Item Scan-**',
				// 	icon: 'IS',
				// 	class: '',
				// 	anchor: 'warehouse-item-scan',
				// },
				{
					path: '/orders/pick',
					title: 'Picking',
					icon: 'P',
					class: '',
					anchor: 'warehouse-pick',
				},
				{
					path: '/orders/check',
					title: 'Checking',
					icon: 'C',
					class: '',
					anchor: 'warehouse-check',
					package: 'beauty',

				},
				{
					path: '/warehouse/stock-counts',
					title: 'Stock Counts',
					icon: 'SC',
					class: '',
					anchor: 'warehouse-stock-counts',
				},
				/*
					{
						path: '/credit/picking',
						title: 'Credit Receiveing',
						icon: 'CR',
						class: '',
						anchor: 'credit-picking',
					},

					{
						path: '/warehouse/receive',
						title: 'Receiving',
						icon: 'PR',
						class: '',
						anchor: 'purchasing-receive'
					},
					*/
				{
					path: '/warehouse/bins',
					title: 'Bins',
					icon: 'BIN',
					class: '',
					anchor: 'warehouse-bins',
				},
				{
					path: '/warehouse/warehouse-reports',
					title: 'Warehouse Reports',
					icon: 'WHR',
					class: '',
					anchor: 'warehouse-reports'
				},
				{
					path: '/warehouse/warehouse-loading',
					title: 'Warehouse Loading',
					icon: 'WHR',
					class: '',
					anchor: 'warehouse-loading'
				},
				{
					path: '/warehouse/warehouse-loading-wifi',
					title: 'Warehouse Loading Wi-Fi',
					icon: 'WHR',
					class: '',
					anchor: 'warehouse-loading-wifi'
				},
				{
					path: '/warehouse/warehouse-loading-fedex',
					title: 'Warehouse Loading Fedex',
					icon: 'LDF',
					class: '',
					anchor: 'warehouse-loading-fedex'
				},
				{
					path: '/warehouse/barcode-assigner',
					title: 'Barcode Assigner',
					icon: 'BCA',
					class: '',
					anchor: 'barcode-assigner'
				},


			]
		},
		{
			path: '/dispatches',
			title: 'Dispatch',
			icon: 'local_shipping',
			anchor: 'dispatching',
			class: '',
			children: [{
					path: '/dispatches/dashboard',
					title: 'DashBoard',
					icon: 'DD',
					class: '',
					anchor: 'disaptch-dashboard',
				},
				{
					path: '/dispatches/route-builder',
					title: 'Route-Builder',
					icon: 'RB',
					class: '',
					anchor: 'route-builder',
				},
				{
					path: '/dispatches/load-sheet',
					title: 'Load Sheet',
					icon: 'LS',
					class: '',
					anchor: 'loading-sheet',
				},
				{
					path: '/dispatches/driver-unload',
					title: 'Unload Truck',
					icon: 'UT',
					class: '',
					anchor: 'driver-unload',
				},
				{
					path: '/dispatches/active',
					title: 'Active Dispatches',
					icon: 'AD',
					class: '',
					anchor: 'active-dispatches',
				},
				{
					path: '/dispatches/baypriority',
					title: 'Loading Bay Matienance',
					icon: 'LB',
					class: '',
					anchor: 'dispatch-priority',
				},
				/*{
						path: '/dispatches/drivers',
						title: 'Active Runs',
						icon: 'AR',
						class: '',
						anchor: 'active-runs',
					},
					*/
				{
					path: '/dispatches/deliverednotinvoiced',
					title: 'Order Confirmation',
					icon: 'OC',
					class: '',
					anchor: 'active-deliverednotinvoiced',
				},
				{
					path: '/dispatches/open-credits',
					title: 'RMAS',
					icon: 'RMA',
					class: '',
					anchor: 'credits-dispatches-credits',
				},
				{
					path: '/dispatches/maintance',
					title: 'Maintenance',
					icon: 'DM',
					class: '',
					anchor: 'dispatch-maintance',
				},
				{
					path: '/dispatches/route-maintance',
					title: 'Route Maintenance',
					icon: 'DRM',
					class: '',
					anchor: 'dispatch-route-maintance',
				},
			]
		},
		{
			path: '/receivables',
			title: 'Receivables',
			icon: 'payments',
			class: '',
			anchor: 'receivables',
			children: [{
					path: '/receivables/dashboard',
					title: 'Receivables Dashboard',
					icon: 'RD',
					class: '',
					anchor: 'receivables-dashboard',
				},
				{
					path: '/receivables/payment-entry',
					title: 'Payment Entry',
					icon: 'PE',
					class: '',
					anchor: 'receivables-entry',
				},
				{
					path: '/receivables/payment-entry-shipvia',
					title: 'Payment Entry By Terms Code',
					icon: 'PE',
					class: '',
					anchor: 'receivables-entry-byshipvia',
				},

				{
					path: '/receivables/journal-report',
					title: 'Receiving Journal Reports',
					icon: 'RJ',
					class: '',
					anchor: 'journal-report',
				},
				{
					path: '/receivables/daily-transactions',
					title: 'Daily Transactions',
					icon: 'DT',
					class: '',
					anchor: 'daily-transactions',
				},
				{
					path: '/receivables/dri',
					title: 'Daily Receivables Inquiry',
					icon: 'DRI',
					class: '',
					anchor: 'dri',
				},
				{
					path: '/receivables/payment-report',
					title: 'Payment Report',
					icon: 'PR',
					class: '',
					anchor: 'receivables-payments',
				},
				{
					path: '/receivables/pos-sales-report',
					title: 'SalonCentric Sales Report',
					icon: 'SPSR',
					class: '',
					anchor: 'receivables-pos-sales',
				},
				{
					path: '/receivables/credit-note',
					title: 'Credit Entry',
					icon: 'CE',
					class: '',
					anchor: 'receivables-credit-note',
				},
				{
					path: '/receivables/allocations',
					title: 'Open Payments/Credits',
					icon: 'A',
					class: '',
					anchor: 'receivables-allocations',
				},
				{
					path: '/receivables/dayend-checkout',
					title: 'Day End Checkout',
					icon: 'C',
					class: '',
					anchor: 'dayend-checkout',
				},
				{
					path: '/receivables/rsi',
					title: 'Sales Inquiry',
					icon: 'RSI',
					class: '',
					anchor: 'rsi',
				},
				{
					path: '/receivables/dreg',
					title: 'Daily Register',
					icon: 'DREG',
					class: '',
					anchor: 'dreg',
				},
				{
					path: '/receivables/statements',
					title: 'Statements',
					icon: 'RS',
					class: '',
					anchor: 'receivables-statements',
				},
			],
			reports: [{
					path: '/receivables/srpt',
					title: 'Salesman Sales by Branch',
					icon: 'SRPT',
					class: '',
				},

			]
		},
		{
			path: '/education',
			title: 'Education',
			icon: 'school',
			class: '',
			anchor: 'education',
			children: [{
				path: '/education/education-events',
				title: 'Education Events',
				icon: 'EE',
				class: '',
				anchor: 'education-events',
			},
			{
				path: '/education/education-locations',
				title: 'Education Locations',
				icon: 'EL',
				class: '',
				anchor: 'education-locations',
			}, ],
			reports: []
		},

		/*{ path: '/payables', title: 'Payables',icon:'account_balance', class: '' , anchor: 'payables', children: [
		{
		 path: '/purchasing/purchasing-dash',
		 title: 'Payables Dashboard',
		 icon: 'PD',
		 class: '',
		 anchor: 'payables-dash',
	 },
 	]},
	 */
		//package beauty is temporary. need to move it to multi warehouse config
		{
			path: '/inventory',
			title: 'Inventory',
			icon: 'category',
			class: '',
			anchor: 'inventory',
			children: [{
					path: '/inventory/dashboard',
					title: 'Inventory Dashboard',
					icon: 'ID',
					class: '',
				},
				{
					path: '/inventory/list',
					title: 'Item Maintenance',
					icon: 'IM',
					class: '',
				},

				{
					path: '/inventory/inventory-promos',
					title: 'Promos',
					icon: 'IP',
					class: '',
					package: 'beauty',
				},
				{
					path: '/inventory/inventory-transfers',
					title: 'Inventory Transfers',
					icon: 'IT',
					class: '',
					package: '',
				},
				{
					path: '/inventory/branch-reorder',
					title: 'Store Re-Ordering',
					icon: 'BR',
					class: '',
					package: '',
				},
				{
					path: '/inventory/weekly-reorder-inventory',
					title: 'Weekly Re-Ordering',
					icon: 'BR',
					class: '',
					package: '',
				},
				{
					path: '/inventory/special-pricing',
					title: 'Item Specials',
					icon: 'ISM',
					class: '',
				},
				{
					path: '/inventory/value',
					title: 'Inventory Valuation',
					icon: 'IV',
					class: '',
				},

				{
					path: '/inventory/inventory-glclass',
					title: 'GL Class',
					icon: 'IGC',
					class: '',
					anchor: 'inventory-glclass'
				},
				{
					path: 'inventory/maintaince/price-level-pricing',
					title: 'Discount Pricing',
					icon: 'iu',
					class: '',
					anchor: 'inventory-price-level-pricing'
				},
				{
					path: '/inventory/inventory-upload',
					title: 'Inventory Upload',
					icon: 'iu',
					class: '',
					anchor: 'inventory-upload'
				},
				// {
				// 	path: '/inventory/maintaince',
				// 	title: 'Inventory Maintance',
				// 	icon: 'iu',
				// 	class: '',
				// 	anchor: 'inventory-maintance',
				// 	children: [{
				// 		path: '/inventory/maintaince/price-level-pricing',
				// 		title: 'Price Level Pricing',
				// 		icon: 'ID',
				// 		class: '',
				// 	}]
				// },
			],
			reports: [{
				path: '/inventory/idet-report',
				title: 'Item Detail Report',
				icon: 'IGR',
				class: '',
				anchor: 'productline-report'
			},
			{
				path: '/inventory/top-hundred',
				title: 'Top 100 Items',
				icon: '100',
				class: '',
				anchor: 'top-hundred'
			}, ]

		},
		{
			path: '/purchasing',
			title: 'Purchasing',
			icon: 'shopping_bag',
			class: '',
			anchor: 'purchases',
			children: [{
					path: '/purchasing/dashboard',
					title: 'Purchasing Dashboard',
					icon: 'PD',
					class: '',
					anchor: 'purchasing-dashboard'
				},
				{
					path: '/purchasing',
					title: 'New Purchase Order',
					icon: 'PE',
					class: '',
					anchor: 'purchasing-entry'
				},
				{
					path: '/purchasing/openorders',
					title: 'Open Purchase Orders',
					icon: 'PO',
					class: '',
					anchor: 'purchasing-open'
				},
				{
					path: '/purchasing-order-inquiry',
					title: 'Purchase Order Inquiry',
					icon: 'POI',
					class: '',
					anchor: 'purchasing-order-inquiry'
				},
				{
					path: '/purchasing/reorder',
					title: 'Re-Order',
					icon: 'RO',
					class: '',
					anchor: 'purchasing-reorder',
					/*children: [
						{
							path: '/purchasing/reorder/restock',
							 title: 'Re-Stock',
							 icon: 'RS',
							 class: '',
							 anchor: 'purchasing-restock'
						},
						{
							path: '/purchasing/reorder/minmax',
							 title: 'Min Max',
							 icon: 'MM',
							 class: '',
							 anchor: 'purchasing-minmax'
						},
						{
							path: '/purchasing/reorder/avgsales',
							 title: 'Sales Over Time',
							 icon: 'ST',
							 class: '',
							 anchor: 'purchasing-avgsales'
						}

					  ]*/
				},
				{
					path: '/purchasing/brand-priority',
					title: 'Brand Priority',
					icon: 'BP',
					class: '',
					anchor: 'purchasing-brands'
				},
				/*{
					path: 'purchasing-receipt-upload',
					title: 'Purchasing Receipt Upload',
					icon: 'PLU',
					class: '',
					anchor: 'purchasing-upload'
				},
				*/
			]
		},
		// Fixed Assets
		{
			path: '/fixed-assets',
			title: 'Fixed Assets',
			icon: 'view_list',
			class: '',
			anchor: 'system-fixed-assets',
			children: [
			{
				path: '/fixed-assets/fixed-assets-management',
				title: 'Fixed Asset Management',
				icon: 'FI',
				class: ''
			}]
		},
		{
			path: '/general-ledger',
			title: 'General Ledger',
			icon: 'category',
			class: '',
			anchor: 'generallendger',
			children: [
			{
				path: '/general-ledger/journal-entry',
				title: 'Journal Entry',
				icon: 'JE',
				class: '',
			},
			{
				path: '/general-ledger/journal-inquiry',
				title: 'Journal Inquiry',
				icon: 'JI',
				class: '',
			},
			{
				path: '/general-ledger/account-inquiry',
				title: 'Account Inquiry',
				icon: 'AI',
				class: '',
			},
			{
				path: '/general-ledger/trial-balance',
				title: 'Trial Balance',
				icon: 'TB',
				class: '',
			},
			{
				path: '/general-ledger/profit-and-loss',
				title: 'Profit And Loss',
				icon: 'P&L',
				class: '',
			},
			{
				path: '/general-ledger/balance-sheet',
				title: 'Balance Sheet',
				icon: 'BAL',
				class: '',
			},
			{
				path: '/general-ledger/bank-rec',
				title: 'Bank Rec',
				icon: 'BR',
				class: '',
			},
			{
				path: '/general-ledger/sales-taxes',
				title: 'Sales Taxes',
				icon: 'ST',
				class: '',
			},
			{
				path: '/general-ledger',
				title: 'Maintenance',
				icon: 'm',
				class: '',
				children: [{
						path: '/general-ledger/budgets',
						title: 'GL Budgets',
						icon: 'BDT',
						class: '',
					},
					{
						path: '/general-ledger/tags',
						title: 'GL Tags',
						icon: 'GT',
						class: '',
					},
					{
						path: '/general-ledger/sales-gl-postings',
						title: 'Sales GL',
						icon: 'SGL',
						class: '',
					},
					{
						path: '/general-ledger/cogs-gl-postings',
						title: 'Cogs GL',
						icon: 'CGl',
						class: '',
					},
					{
						path: '/general-ledger/inventory-gl-postings',
						title: 'Inv GL',
						icon: 'IGL',
						class: '',
					},
				]
			},
			],
		},
		{ path: '/reports', title: 'Reports', icon: 'bubble_chart', class: '', anchor: 'reports', package: 'all' },
		{
			path: '/users',
			title: 'Users',
			icon: 'admin_panel_settings',
			class: '',
			anchor: 'users',
			children: [{
				path: '/users-list',
				title: 'Users',
				icon: 'UL',
				anchor: 'user-list',
				class: '',
			},
			{
				path: '/usergroups',
				title: 'User Groups',
				icon: 'UG',
				anchor: 'user-groups',
				class: '',
			}]
		},
		{ path: '/archive', title: 'Archive', icon: 'bubble_chart', class: '', anchor: 'archive-list', package: 'all' },
		{
			path: '/system',
			title: 'System',
			icon: 'dns',
			class: '',
			anchor: 'system',
			children: [
				// Core Setup
				{
					path: '/system/company',
					title: 'Company Setup',
					icon: 'CS',
					class: '',
					anchor: 'company-settings'
				},
				{
					path: '/system/types',
					title: 'Types Setup',
					icon: 'SS',
					class: '',
					anchor: 'company-types'
				},
				{
					path: '/system/taxes',
					title: 'Tax Settings',
					icon: 'T',
					class: '',
					anchor: 'taxes'
				},
				{
					path: '/system/inventory-settings',
					title: 'Inventory Settings',
					icon: 'IS',
					class: '',
					anchor: 'system-inventory-lines'
				},

				// Customer & Product Management
				{
					path: '/system/customer-groups',
					title: 'Customer Groups',
					icon: 'CG',
					class: '',
					anchor: 'customer-groups',
					package: 'beauty'
				},
				{
					path: '/system/product-groups',
					title: 'Product Groups',
					icon: 'PG',
					class: '',
					anchor: 'product-groups',
					package: 'beauty'
				},

				// Sales & Marketing
				{
					path: '/system/coupons',
					title: 'Coupons',
					icon: 'C',
					class: '',
					anchor: 'coupons',
					package: 'beauty'
				},
				{
					path: '/system/sale-builder',
					title: 'Sale Builder',
					icon: 'SB',
					class: '',
					anchor: 'sale-builder'
				},
				{
					path: '/system/storefront-settings',
					title: 'Storefront Settings',
					icon: 'SS',
					class: '',
					anchor: 'storefront-settings',
					package: 'beauty'
				},

				// Shipping & Tools
				{
					path: '/system/shipvia',
					title: 'Shipping Setup',
					icon: 'SS',
					class: '',
					anchor: 'system-shipvia'
				},
				{
					path: '/system/tools',
					title: 'Tools',
					icon: 'T',
					class: '',
					anchor: 'tools',
					package: false,
					children: [
					{
						path: '/system/merge-customers',
						title: 'Merge Customers',
						icon: 'MC',
						class: '',
						anchor: 'merge-customers',
						package: false
					},
					{
						path: '/system/move-transactions',
						title: 'Move Transactions',
						icon: 'MT',
						class: '',
						anchor: 'move-transactions',
						package: false
					},
					{
						path: '/system/form-builder',
						title: 'Form Builder',
						icon: 'FB',
						class: '',
						anchor: 'form-builder',
						package: false
					}]
				},

				// Reporting & APIs
				{
					path: '/system/report-builder',
					title: 'Report Builder',
					icon: 'RB',
					class: '',
					anchor: 'report-builder'
				},
				{
					path: '/system/api-settings',
					title: 'Remote API Settings',
					icon: 'RA',
					class: '',
					anchor: 'remote-api-settings'
				},
				// General Ledger
				{
					path: '/gl',
					title: 'General Ledger',
					icon: 'view_list',
					class: '',
					anchor: 'system-general-ledger',
					children: [
					{
						path: '/system/gl-accounts/account-sections',
						title: 'Account Sections',
						icon: 'AS',
						class: ''
					},
					{
						path: '/system/gl-accounts/account-groups',
						title: 'Account Groups',
						icon: 'AG',
						class: ''
					},
					{
						path: '/system/gl-accounts',
						title: 'Chart of Accounts',
						icon: 'COA',
						class: ''
					},
					{
						path: '/system/bank-accounts',
						title: 'Bank Accounts',
						icon: 'BA',
						class: ''
					}]
				}
			],
}, { path: '/logout', title: 'Logout', icon: 'logout', class: '', package: 'all' },
];
//ACTUAL ROUTES
export const AdminLayoutRoutes: Routes = [
	{ path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
	{ path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
	{ path: 'reports/view/:type/:id', component: ReportViewComponent, canActivate: [AuthGuard] },

	{ path: 'counter-pos', component: CounterPosComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos-dashboard', component: CounterPosDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/openorders', component: OrderOpenlistComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/dri', component: DriComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/iq', component: ItemQueryComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/:cid/:branch', component: CounterPosComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/dreg', component: DregComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/daily-transactions', component: DailyTransactionsComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/cid-report', component: CidReportComponent, canActivate: [AuthGuard] },
	{ path: 'counter-pos/invoice/:id/:dispatch', component: CounterPosInvoiceComponent, canActivate: [AuthGuard] },

	{ path: 'counter-pos/payment-report', component: PaymentReportComponent, canActivate: [AuthGuard] },
	{ path: 'pos/success/:id', component: PosReceiptComponent, canActivate: [AuthGuard] },
	//hold over
	{ path: 'credits/success/:id', component: SuccessComponent, canActivate: [AuthGuard] },

	{ path: 'orders', component: OrdersDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'orders/adrs', component: AdrsComponent, canActivate: [AuthGuard] },
	{ path: 'orders/entry', component: CustomerSearchComponent, canActivate: [AuthGuard] },
	{ path: 'orders/entry/new', component: CustomerSearchComponent, canActivate: [AuthGuard] },
	{ path: 'orders/entry/:cid/:branch', component: OrderEntryComponent, canActivate: [AuthGuard] },
	{ path: 'orders/load/:cid/:branch/:orderno', component: OrderLoadComponent, canActivate: [AuthGuard] },
	//{ path: 'orders/invoice/:id', component: OrderInvoiceComponent, canActivate: [AuthGuard] },
	{ path: 'orders/invoice/:id/:dispatch', component: OrderDispatchComponent, canActivate: [AuthGuard] },
	{ path: 'archive', component: ArchiveListComponent, canActivate: [AuthGuard] },

	{ path: 'orders/order-inquiry', component: OrderInquiryComponent, canActivate: [AuthGuard] },
	{ path: 'orders/order-item-inquiry', component: OrderItemInquiryComponent, canActivate: [AuthGuard] },
	{ path: 'orders/order-tracking', component: OrderTrackingComponent, canActivate: [AuthGuard] },
	{ path: 'orders/productline-report', component: ProductlineReportComponent, canActivate: [AuthGuard] },

	{ path: 'orders/qantel-dashboard', component: QantelOrderDashComponent, canActivate: [AuthGuard] },
	{ path: 'orders/qantel-dashboard/:type', component: QantelOrderDashComponent, canActivate: [AuthGuard] },

	{ path: 'orders/dashboard', component: OrdersDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'orders/dashboard/:type', component: OrdersDashboardComponent, canActivate: [AuthGuard] },

	{ path: 'orders/order-confirmation', component: OrderConfirmationComponent, canActivate: [AuthGuard] },
	{ path: 'orders/order-confirmation/:id', component: OrderConfirmationComponent, canActivate: [AuthGuard] },

	{ path: 'orders/lookup', component: OrderLookupComponent, canActivate: [AuthGuard] },
	{ path: 'orders/pick', component: OrderPickComponent, canActivate: [AuthGuard] },
	{ path: 'orders/pick/:id', component: OrderPickComponent, canActivate: [AuthGuard] },

	{ path: 'orders/check', component: OrderCheckComponent, canActivate: [AuthGuard] },
	{ path: 'orders/check/:id', component: OrderCheckComponent, canActivate: [AuthGuard] },



	{ path: 'orders/lookup/:id', component: OrderLookupComponent, canActivate: [AuthGuard] },
	{ path: 'orders/lookup/:id/:type', component: OrderLookupComponent, canActivate: [AuthGuard] },
	{ path: 'orders/openorders', component: OrderOpenlistComponent, canActivate: [AuthGuard] },
	{ path: 'orders/openquotes', component: OrdersOpenQuotesComponent, canActivate: [AuthGuard] },
	{ path: 'orders/success/:id', component: OrderSuccessComponent, canActivate: [AuthGuard] },
	{ path: 'orders/reprintpick', component: OrderReprintPickComponent, canActivate: [AuthGuard] },
	{ path: 'orders/oms', component: OdsComponent, canActivate: [AuthGuard] },
	{ path: 'orders/time', component: TimelineComponent, canActivate: [AuthGuard] },
	{ path: 'orders/jpms', component: JpmsReportComponent, canActivate: [AuthGuard] },
	{ path: 'orders/pmm', component: ReportsPmmComponent, canActivate: [AuthGuard] },
	// { path: 'orders/pick',	 component: PickComponent },
	{ path: 'dispatches', component: DispatchesComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/dashboard', component: DispatchesDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/route', component: RouteBuilderComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/active', component: DispatchesActiveDispatchComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/route-builder', component: DispatchesRouteBuilderComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/baypriority', component: DispatchesLoadPriorityComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/drivers', component: DispatchesDriverRunComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/drivers/:id', component: DispatchesDriverRunComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/deliverednotinvoiced', component: DispatchesDeliveredNotinvoicedComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/maintance', component: DispatchesMaintanceComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/route-maintance', component: DispatchesRouteMaintanceComponent, canActivate: [AuthGuard] },

	{ path: 'dispatches/load-sheet', component: DispatchesLoadSheetComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/load-sheet/:id', component: DispatchesLoadSheetComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/driver-unload', component: DispatchDriverUnloadComponent, canActivate: [AuthGuard] },



	{ path: 'inventory/dashboard', component: InventoryDashboardComponent, canActivate: [AuthGuard] },


	//{ path: 'inventory/:id',	 component: InventoryComponent },

	{ path: 'inventory/lookup', component: InventoryComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/lookup/:id', component: InventoryComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/view/:id', component: InventoryViewComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/list', component: InventoryListComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/value', component: InventoryValueComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/inventory-transfers', component: InventoryTransfersComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/inventory-promos', component: InventoryPromosComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/branch-reorder', component: BranchReorderComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/weekly-reorder', component: WeeklyReorderComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/weekly-reorder-inventory', component: WeeklyReorderComponent, canActivate: [AuthGuard] },

	{ path: 'inventory/idet-report', component: IdetReportComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/top-hundred', component: TopHundredComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/inventory-glclass', component: InventoryGlclassComponent, canActivate: [AuthGuard] },

	{ path: 'inventory/maintaince/price-level-pricing', component: PriceLevelPricingComponent, canActivate: [AuthGuard] },
	{ path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
	{ path: 'customer/groups', component: CustomersGroupsComponent, canActivate: [AuthGuard] },

	{ path: 'customers/dahbaord', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'customers/view/:id', component: ViewComponent, canActivate: [AuthGuard] },
	{ path: 'customers/edit/:id', component: CustomerEditComponent, canActivate: [AuthGuard] },
	{ path: 'customers/customer-export', component: CustomerExportComponent, canActivate: [AuthGuard] },
	{ path: 'customers/create', component: CustomerCreateComponent, canActivate: [AuthGuard] },
	{ path: 'customers/crpt', component: CrptComponent, canActivate: [AuthGuard] },
	{ path: 'customers/cvsls', component: CvslsComponent, canActivate: [AuthGuard] },
	{ path: 'customers/csrpt', component: CsrptComponent, canActivate: [AuthGuard] },
	{ path: 'customers/itemHistory', component: CustomerItemHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'customers/itemHistory/:id', component: CustomerItemHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'customers/monthlyHistory', component: CustomerMonthlyHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'customers/monthlyHistory/:id', component: CustomerMonthlyHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'customers/cpsls', component: CpslsComponent, canActivate: [AuthGuard] },
	{ path: 'customers/cpsls/:id', component: CpslsComponent, canActivate: [AuthGuard] },
	{ path: 'customers/customer-cpdet', component: CustomerCpdetComponent, canActivate: [AuthGuard] },
	{ path: 'customers/customer-cpdet/:id', component: CustomerCpdetComponent, canActivate: [AuthGuard] },
	{ path: 'customers/cdet', component: CustomerCdetComponent, canActivate: [AuthGuard] },
	{ path: 'customers/cdet/:id', component: CustomerCdetComponent, canActivate: [AuthGuard] },
	// SALES MANAGER HACK

	{ path: 'sales-manager-reports/spsls', component: SpslsComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/vrpt', component: VrptComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/crpt', component: CrptComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/cvsls', component: CvslsComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/csrpt', component: CsrptComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/itemHistory', component: CustomerItemHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/itemHistory/:id', component: CustomerItemHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/monthlyHistory', component: CustomerMonthlyHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/monthlyHistory/:id', component: CustomerMonthlyHistoryComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/cpsls', component: CpslsComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/cpsls/:id', component: CpslsComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/customer-cpdet', component: CustomerCpdetComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/customer-cpdet/:id', component: CustomerCpdetComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/cdet', component: CustomerCdetComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/cdet/:id', component: CustomerCdetComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/productline-report', component: ProductlineReportComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/dailytrans', component: DailyTransactionsComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/top-hundred', component: TopHundredComponent, canActivate: [AuthGuard] },
	{ path: 'sales-manager-reports/cid-report', component: CidReportComponent, canActivate: [AuthGuard] },

	{ path: 'customers/invoices/:id', component: CustomerOrdersComponent },
	{ path: 'customers/customer-vehicles/:cid', component: CustomerVehiclesComponent },
	{ path: 'customers/customer-vehicles/:cid/:id', component: CustomerVehiclesComponent },
	{ path: 'customers/consignment-edit/:id', component: ConsignmentEditComponent },

	{ path: 'vendors', component: VendorsComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/create', component: VendorCreateComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/:id', component: VendorViewComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/edit/:id', component: VendorEditComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/items/:id', component: VendorItemsComponent, canActivate: [AuthGuard] },
	{ path: 'vendors-check-print', component: CheckPrintingComponent, canActivate: [AuthGuard] },

	{ path: 'inventory/lookup', component: InventoryComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/lookup/:id', component: InventoryComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/view/:id', component: InventoryViewComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/list', component: InventoryListComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/create', component: InventoryCreateComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/create/:id', component: InventoryCreateComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/special-pricing', component: SpecialPricingComponent, canActivate: [AuthGuard] },
	{ path: 'inventory/inventory-upload', component: InventoryUploadComponent, canActivate: [AuthGuard] },

	{ path: 'warehouse/bins', component: WarehouseBinsComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/finalize-rec', component: WarehouseFinalizerecComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/item-lookup', component: WarehouseItemLookupComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/dashboard', component: WarehouseDashboardComponent, canActivate: [AuthGuard] },
	//{ path: 'warehouse/item-scan', component: QantelItemSearchComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/item-scan', component: WarehouseItemScanComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/item-scan/:item', component: WarehouseItemScanComponent, canActivate: [AuthGuard] },

	{ path: 'warehouse/item-scan-two', component: WarehouseItemScanTwoComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/item-scan-two/:item', component: WarehouseItemScanTwoComponent, canActivate: [AuthGuard] },

	{ path: 'warehouse/delivery-board', component: DeliveryBoardComponent, canActivate: [AuthGuard] },

	{ path: 'warehouse/receive', component: WarehouseItemReceivingComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/receive/:id', component: WarehouseItemReceivingComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/stock-counts', component: WarehouseStockCountsComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/warehouse-reports', component: WarehouseReportsComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/warehouse-loading', component: WarehouseCheckingComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/warehouse-loading-wifi', component: WarehouseCheckingWifiComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/warehouse-loading-fedex', component: WarehouseCheckingFedexComponent, canActivate: [AuthGuard] },
	{ path: 'warehouse/barcode-assigner', component: BarcodeAssignerComponent, canActivate: [AuthGuard] },
	//{ path: 'credit/picking', component: CreditReceiveComponent, canActivate: [AuthGuard] },

	{ path: 'customers', component: CustomersComponent, canActivate: [AuthGuard] },
	{ path: 'customers/dashboard', component: CustomerDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'customers/view/:id', component: ViewComponent, canActivate: [AuthGuard] },
	{ path: 'customers/edit/:id', component: CustomerEditComponent, canActivate: [AuthGuard] },
	{ path: 'customers/create', component: CustomerCreateComponent, canActivate: [AuthGuard] },

	{ path: 'customers/invoices/:id', component: CustomerOrdersComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/create', component: VendorCreateComponent, canActivate: [AuthGuard] },
	{ path: 'vendors-dashboard', component: VendorsDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'vendors', component: VendorsComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/reports/vrpt', component: VrptComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/:id', component: VendorViewComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/edit/:id', component: VendorEditComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/items/:id', component: VendorItemsComponent, canActivate: [AuthGuard] },
	{ path: 'vendor-allocations', component: VendorAllocationsComponent, canActivate: [AuthGuard] },
	{ path: 'vendor-allocations/:transid', component: VendorAllocationsComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/:id/invoice', component: VendorInvoiceComponent, canActivate: [AuthGuard] },
	{ path: 'vendors/invoice/complete/:id', component: VendorInvoiceCompleteComponent, canActivate: [AuthGuard] },


	//purchasing
	{ path: 'purchasing', component: PurchaseCreateComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/dashboard', component: PurchasingDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/openorders', component: PurchasingOpenOrdersComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/receive', component: PurchaseOrderReceiveComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/receive/:id', component: PurchaseOrderReceiveComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/brand-priority', component: PurchasingBrandPriorityComponent, canActivate: [AuthGuard] },

	{ path: 'purchasing/reorder', component: PurchasingReorderingComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/reorder/restock', component: PurchasingReorderingRestockComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/reorder/minmax', component: PurchasingReorderingMinmaxComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/reorder/avgsales', component: PurchasingReorderingAvgsalesComponent, canActivate: [AuthGuard] },


	{ path: 'purchasing/:id', component: PurchaseCreateComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/:id/:order', component: PurchaseOrderEntryComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing-success/:id/:order', component: PurchasingSuccessComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing-receipt', component: PurchasingReceiptjournalComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing/order/view/:id', component: PurchaseOrderViewComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing-receipt-upload', component: PurchasingReceiptjournaluploadComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing-receipt-upload/:id', component: PurchasingReceiptjournaluploadComponent, canActivate: [AuthGuard] },
	{ path: 'purchasing-order-inquiry', component: PurchaseOrderInquiryComponent, canActivate: [AuthGuard] },

	//AR
	{ path: 'receivables/payment-entry', component: PaymentEntryComponent, canActivate: [AuthGuard] },

	{ path: 'receivables/payment-entry-shipvia', component: ReceivablesByshipviaComponent, canActivate: [AuthGuard] },



	{ path: 'receivables/payment-entry/:id/:branch', component: PaymentEntryComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/journal-report', component: JournalReportComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/daily-transactions', component: DailyTransactionsComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/dri', component: DriComponent, canActivate: [AuthGuard] },

	{ path: 'receivables/payment-report', component: PaymentReportComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/pos-sales-report', component: PosSalesReportComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/allocations', component: ReceivablesAllocationsComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/allocations/:transid', component: ReceivablesAllocationsComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/dayend-checkout', component: DayendCheckoutComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/rsi', component: RsiComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/dreg', component: DregComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/statements', component: StatementsComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/srpt', component: SrptComponent, canActivate: [AuthGuard] },
	{ path: 'orders/spsls', component: SpslsComponent, canActivate: [AuthGuard] },
	//	{ path: 'dispatches/open-credit', component: CreditOpenListComponent, canActivate: [AuthGuard] },



	{ path: 'receivables/credit-invoice/:id', component: CreditFreestandingComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/credit-note', component: CreditLoadComponent, canActivate: [AuthGuard] },
	//{ path: 'receivables/credit-note/:transno', component: CreditLoadComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/credit-finalize/:transno', component: FinalizeComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/credit-note/:debtor/:branch', component: CreditFreestandingComponent, canActivate: [AuthGuard] },
	{ path: 'education/education-events', component: EducationEventsComponent, canActivate: [AuthGuard] },
	{ path: 'education/education-locations', component: EducationLocationsComponent, canActivate: [AuthGuard] },

	{ path: 'orders/create-credit-note', component: CreditLoadComponent, canActivate: [AuthGuard] },

	{ path: 'orders/create-credit-note/:debtor/:branch', component: CreditLoadComponent, canActivate: [AuthGuard] },

	{ path: 'counter-pos/create-credit-note', component: CreditLoadComponent, canActivate: [AuthGuard] },
	{ path: 'credits/open-credits', component: OpenCreditListComponent, canActivate: [AuthGuard] },
	{ path: 'dispatches/open-credits', component: OpenCreditListComponent, canActivate: [AuthGuard] },

	{ path: 'receivables/dispatch', component: CreditDispatchComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/dispatch/:transno', component: CreditDispatchComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/success', component: CreditSuccessComponent, canActivate: [AuthGuard] },
	{ path: 'receivables/success/:transno', component: CreditSuccessComponent, canActivate: [AuthGuard] },

	//fixed assets


	{ path: 'fixed-assets/fixed-assets-management', component: FixedAssetsManagementComponent, canActivate: [AuthGuard] },

	{ path: 'receivables/dashboard', component: ReceivablesDashboardComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/account-inquiry', component: AccountInquiryComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/trial-balance', component: TrialBalanceComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/profit-and-loss', component: ProfitLossComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/balance-sheet', component: BalanceSheetComponent, canActivate: [AuthGuard] },
	//{ path: 'general-ledger/sales-taxes', component: SalesTaxComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/sales-taxes', component: SalesTaxCompressedComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/bank-rec', component: BankRecComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/sales-taxes-compressed', component: SalesTaxCompressedComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/budgets', component: GlBudgetsComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/tags', component: GLTagsComponent, canActivate: [AuthGuard] },

	{ path: 'general-ledger/sales-gl-postings', component: SalesGLPostingsComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/cogs-gl-postings', component: CogsGlPostingsComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/inventory-gl-postings', component: InventoryGlPostingsComponent, canActivate: [AuthGuard] },

	{ path: 'general-ledger/journal-entry', component: JournalEntryComponent, canActivate: [AuthGuard] },
	{ path: 'general-ledger/journal-inquiry', component: JournalInquiryComponent, canActivate: [AuthGuard] },

	{ path: 'users-list', component: UsersComponent, canActivate: [AuthGuard] },
	{ path: 'users/edit/:id', component: UserEditComponent, canActivate: [AuthGuard] },
	{ path: 'users/create', component: UserCreateComponent, canActivate: [AuthGuard] },

	{ path: 'users/user-profile', component: UserProfileComponent, canActivate: [AuthGuard] },
	{ path: 'users/user-settings', component: UserSettingsComponent, canActivate: [AuthGuard] },
	{ path: 'usergroups', component: UserGroupsComponent, canActivate: [AuthGuard] },
	{ path: 'usergroups/edit/:id', component: UserGroupsEditComponent, canActivate: [AuthGuard] },

	/* company */
	{ path: 'system/company', component: CompanySetupComponent, canActivate: [AuthGuard] },
	{ path: 'system/taxes', component: TaxesComponent, canActivate: [AuthGuard] },

	{ path: 'system/coupons', component: CouponSetupComponent, canActivate: [AuthGuard] },


	{ path: 'system/settings', component: CompanySetupComponent, canActivate: [AuthGuard] },
	{ path: 'system/inventory-settings', component: InventorySettingsComponent, canActivate: [AuthGuard] },
	{ path: 'system/customer-groups', component: CustomerGroupsComponent, canActivate: [AuthGuard] },
	{ path: 'system/product-groups', component: ProductGroupsComponent, canActivate: [AuthGuard] },
	{ path: 'system/report-builder', component: ReportBuilderComponent, canActivate: [AuthGuard] },
	{ path: 'system/sale-builder', component: SaleBuilderComponent, canActivate: [AuthGuard] },
	{ path: 'system/move-transactions', component: MoveTransactionComponent, canActivate: [AuthGuard] },
	{ path: 'system/form-builder', component: FormBuilderComponent, canActivate: [AuthGuard] },


	{ path: 'system/merge-customers', component: MergeCustomersComponent, canActivate: [AuthGuard] },
	{ path: 'system/api-settings', component: ApiSettingsComponent, canActivate: [AuthGuard] },
	{ path: 'system/gl-accounts', component: GlAccountsComponent, canActivate: [AuthGuard] },
	{ path: 'system/email', component: MailComponent, canActivate: [AuthGuard] },
	{ path: 'system/gl-accounts/account-sections', component: AccountSectionsComponent, canActivate: [AuthGuard] },
	{ path: 'system/gl-accounts/account-sections/:id', component: AccountSectionsComponent, canActivate: [AuthGuard] },
	{ path: 'system/gl-accounts/account-groups', component: AccountGroupsComponent, canActivate: [AuthGuard] },
	{ path: 'system/gl-accounts/account-groups/:id', component: AccountGroupsComponent, canActivate: [AuthGuard] },
	{ path: 'system/gl-accounts/:id', component: GlAccountsComponent, canActivate: [AuthGuard] },
	{ path: 'system/storefront-settings', component: StorefrontSettingsComponent, canActivate: [AuthGuard] },

	{ path: 'system/shipvia', component: ShippingMethodsComponent, canActivate: [AuthGuard] },

	{ path: 'system/bank-accounts', component: BankAccountsComponent, canActivate: [AuthGuard] },

	{ path: 'system/types', component: SystemTypesViewComponent, canActivate: [AuthGuard] },
	{ path: 'system/inventory-locations', component: InventoryLocationsComponent, canActivate: [AuthGuard] },
	{ path: 'system/inventory-locations/create/:id', component: InventoryLocationsCreateComponent, canActivate: [AuthGuard] },
	// { path: 'fileupload',	 component: FileuploadComponent },
	{ path: 'auth/permission', component: PermissionComponent },
	{ path: 'logout', component: LogoutComponent },

];