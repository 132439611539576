import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FixedAssetsService } from '../../services/fixed-assets.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-fixed-assets-locations',
	templateUrl: './fixed-assets-locations.component.html',
	styleUrls: ['./fixed-assets-locations.component.scss'],
})
export class FixedAssetsLocationsComponent implements OnInit {
	assetLocationForm: FormGroup;

	editingLocation: any = null;
	isFormVisible: boolean = false;
	assetLocations: any[] = [];
	isLoading: boolean = false;

	constructor(
		private fb: FormBuilder,
		private fixedAssetsService: FixedAssetsService,
		private snackBar: MatSnackBar
	) {
		this.assetLocationForm = this.fb.group({
			locationid: ['', Validators.required],
			locationdescription: ['', Validators.required],
		});
	}

	ngOnInit(): void {
		this.loadAssetLocations();
	}

	editLocation(location: any): void {
		this.editingLocation = location;
		this.assetLocationForm.patchValue(location);
		this.isFormVisible = true;
	}

	// Load locations
	loadAssetLocations(): void {
		this.isLoading = true;
		this.fixedAssetsService.fetchAssetLocations().subscribe(
			(response) => {
				this.assetLocations = response.data || [];
				this.isLoading = false;
			},
			() => {
				this.snackBar.open('Error loading Asset Locations.', 'Close', { duration: 3000 });
				this.isLoading = false;
			}
		);
	}

	// Save location
	saveLocation(): void {
		const data = this.assetLocationForm.value;
		if (this.editingLocation) {
			data.id = this.editingLocation.id;
			this.fixedAssetsService.updateLocation(data).subscribe(() => {
				this.snackBar.open('Location updated successfully.', 'Close', { duration: 3000 });
				this.loadAssetLocations();
			});
		} else {
			this.fixedAssetsService.addLocation(data).subscribe((r) => {
				if (r.error) {
					this.snackBar.open(r.error, 'Close', { duration: 3000 });
				} else {
					this.snackBar.open('Location added successfully.', 'Close', { duration: 3000 });
				}
				this.loadAssetLocations();
			});
		}
		this.resetForm();
		this.isFormVisible = false;
	}

	// Delete location
	deleteLocation(locationId: string): void {
		if (confirm('Are you sure you want to delete this location?')) {
			this.fixedAssetsService.deleteLocation(locationId).subscribe((r) => {
				if (r.error) {
					this.snackBar.open(r.error, 'Close', { duration: 3000 });
				} else {
					this.snackBar.open('Location deleted successfully.', 'Close', { duration: 3000 });
					this.loadAssetLocations();
				}
			});
		}
		this.isFormVisible = false;
	}

	resetForm(): void {
		this.assetLocationForm.reset();
		this.editingLocation = null;
	}
}