import { Component, OnInit, QueryList, ElementRef, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { PrintService } from '../../services/print.service';
import { GeneralLedgerService } from '../../services/general-ledger.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service';

@Component({
	selector: 'app-journal-inquiry',
	templateUrl: './journal-inquiry.component.html',
	styleUrls: ['./journal-inquiry.component.scss']
})
export class JournalInquiryComponent implements OnInit {
	@ViewChildren('journalTable') journalTables!: QueryList < ElementRef > ;
	journalForm: FormGroup;
	journalData: any = [];
	maxJournalNumber: any = 0;
	color: string = 'blue';
	exporting: any = false;
	//journalTables: any = [];
	searchQuery: string = '';
	filteredJournals = [];
	isrunning: any = false;
	constructor(
		private fb: FormBuilder,
		private glService: GeneralLedgerService,
		private globalSearchService: GlobalSearchService,
		private location: Location,
		private printService: PrintService
	) {
		this.color = this.globalSearchService.getColor();
	}

	ngOnInit(): void {
		this.initializeForm();
		this.fetchMaxJournalNumber();
	}

	initializeForm(): void {
		const fromDate = new Date();
		fromDate.setDate(fromDate.getDate() - 30);

		this.journalForm = this.fb.group({
			numberFrom: [1],
			numberTo: [this.maxJournalNumber],
			fromDate: [fromDate],
			toDate: [new Date()]
		});

	}

	fetchMaxJournalNumber(): void {
		this.glService.getMaxJournalNumber().subscribe((maxNumber: any) => {
			this.maxJournalNumber = maxNumber.maxjounral;
			this.journalForm.get('numberTo')?.setValue(this.maxJournalNumber);
		});
	}

	searchJournals(): void {

		if (this.journalForm.valid) {
			this.isrunning = true;
			const criteria = this.journalForm.value;
			this.glService.getJournals(criteria).subscribe((data) => {
				this.journalData = data;
				this.isrunning = false;
				this.filteredJournals = [...this.journalData.data];
			});
		}
	}

	resetForm(): void {
		this.journalForm.reset({
			numberFrom: 1,
			numberTo: this.maxJournalNumber,
			fromDate: new Date(),
			toDate: new Date()
		});
	}

	filterJournals(): void {
		const query = this.searchQuery.toLowerCase();
		this.filteredJournals = this.journalData.data.filter((journal) => {
			return (
				journal.journalNumber.toLowerCase().includes(query) ||
				journal.date.toLowerCase().includes(query) ||
				journal.transactions.some((transaction) =>
					transaction.accountCode.toLowerCase().includes(query) ||
					transaction.accountName.toLowerCase().includes(query) ||
					transaction.description.toLowerCase().includes(query) ||
					transaction.tag.toLowerCase().includes(query) || // Include tag
					transaction.amount.toString().includes(query) // Include amount
				)
			);
		});
	}

	exportPdf(journalNumber: string): void {
		const journalTable = this.findTableByJournalNumber(journalNumber);
		if (!journalTable) return;

		const encoded: string = this.globalSearchService.base64encode(journalTable.innerHTML);

		const journal = this.journalData.data.find((j) => j.journalNumber === journalNumber);
		if (!journal) {
			console.error(`Journal data not found for journalNumber: ${journalNumber}`);
			return;
		}

		const title = `Journal Number: ${journal.journalNumber}`;
		const subtitle = `Date: ${new Date(journal.date).toLocaleDateString()} Printed ${new Date().toLocaleString()}`;


		const data = {
			content: encoded,
			filename: `Journal_${journalNumber}`,
			landscape: true,
			title: title,
			subtitle: subtitle
		};

		this.exporting = true;
		this.printService.pdf(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadPdf(result.content, data.filename);
		});
	}

	exportXls(journalNumber: string): void {
		const journalTable = this.findTableByJournalNumber(journalNumber);
		if (!journalTable) return;

		const encoded: string = this.globalSearchService.base64encode(journalTable.innerHTML);

		const journal = this.journalData.data.find((j) => j.journalNumber === journalNumber);
		if (!journal) {
			console.error(`Journal data not found for journalNumber: ${journalNumber}`);
			return;
		}

		const title = `Journal Number: ${journal.journalNumber}`;
		const subtitle = `Date: ${new Date(journal.date).toLocaleDateString()} Printed ${new Date().toLocaleString()}`;


		const data = {
			content: encoded,
			filename: `Journal_${journalNumber}`,
			title: title,
			subtitle: subtitle
		};

		this.exporting = true;
		this.printService.xls(data).subscribe((result: any) => {
			this.exporting = false;
			this.globalSearchService.downloadXls(result.content, data.filename);
		});
	}

	private findTableByJournalNumber(journalNumber: string): HTMLElement | null {
		const tableId = `journalTable${journalNumber}`;

		const tableElement = this.journalTables.find(
			(table) => table.nativeElement.id === tableId
		);

		return tableElement ? tableElement.nativeElement : null;
	}


	back(): void {
		this.location.back()
	}

}