import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
	selector: 'app-vendor-aging',
	templateUrl: './vendor-aging.component.html',
	styleUrls: ['./vendor-aging.component.scss']
})
export class VendorAgingComponent implements OnInit {
	@Input() aged;

	@Output() columnzero = new EventEmitter < boolean > ();
	@Output() columnone = new EventEmitter < boolean > ();
	@Output() columntwo = new EventEmitter < boolean > ();
	@Output() columnthree = new EventEmitter < boolean > ();
	@Output() columnfour = new EventEmitter < boolean > ();

	constructor() {}

	ngOnInit(): void {}


	emitZero() {
		this.columnzero.emit(true);
	}

	emitOne() {
		this.columnone.emit(true);
	}
	emitTwo() {
		this.columntwo.emit(true);
	}
	emitThree() {
		this.columnthree.emit(true);
	}
	emitFour() {
		this.columnfour.emit(true);
	}

	getBalanceClass(value: number): string {
		if (value > 0) {
			return 'bg-danger text-white bolder';
		} else if (value < 0) {
			return 'bg-blue text-dark bolder';
		} else {
			return '';
		}
	}
}
