<div class="main-content">
	<div class="col-md-12">

		<div class="container-fluid tp-box" [@flipState]="flip">
			<div class="backside tp-box__side tp-box__back" [ngClass]="flip != 'inactive' ? '' : 'd-none'">
				<div class="card">
					<div class="card-header card-header-{{color}}">
						<h4 class="card-title " (click)="toggleFlip('','')"> <i class="material-icons">arrow_left</i> <span *ngIf="title == ''">Vendors Dashboard</span> {{ title }}</h4>
						<p class="card-category"></p>
					</div>
					<div class="card-body">

						<ng-container [ngSwitch]="title">
							<app-vendordash-overdue *ngSwitchCase="'Over Due Vendors'" [data]="dataset"></app-vendordash-overdue>
							<app-vendordash-payments *ngSwitchCase="'Payments'" [data]="dataset"></app-vendordash-payments>
							<app-vendordash-invoice *ngSwitchCase="'Invoice'" [data]="dataset"></app-vendordash-invoice>
							<app-open-purchase-orders *ngSwitchCase="'Open Po'" [vendor]="false" (orderloaded)="selectItemLookup($event)" (po_data)="updatePoData($event)"></app-open-purchase-orders>
						</ng-container>
						<div class="input-group no-border">
							<div class="ripple-container"></div>
						</div>
						<div class="results mt-2 mb-2">
							<div class="listitem row border-bottom pl-1 pr-1 ml-1 mr-1">
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row tp-box__side tp-box__front" id="frontside" [ngClass]="flip == 'inactive' ? '' : 'd-none'">
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-info card-header-icon">
							<div class="card-icon">
								<i class="material-icons">wb_cloudy</i>
							</div>
							<p class="card-category">Over Due Vendors</p>
							<h3 class="card-title">
								<span *ngIf="aged">{{ aged.length }}</span>
								<span *ngIf="!aged">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="aged">
								<a (click)="toggleFlip('Over Due Vendors',aged)"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
						</div>
					</div>
				</div>
				<!--
					<div class="col-lg-4 col-md-4 col-sm-6">
						<div class="card card-stats">
							<div class="card-header card-header-rose card-header-icon">
								<div class="card-icon">
									<i class="material-icons">payment</i>
								</div>
								<p class="card-category">Payments</p>
								<h3 class="card-title">
									<span *ngIf="payments">{{ payments.length }}</span>
									<span *ngIf="!payments">
										<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
											<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
										</svg>
									</span>
								</h3>
							</div>
							<div class="card-footer">
								<div class="stats" *ngIf="payments">
									<a (click)="toggleFlip('Payments',payments)"><i class="material-icons">visibility</i>
										View Now</a>
								</div>
							</div>
						</div>
					</div>
				-->
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-success card-header-icon">
							<div class="card-icon">
								<i class="material-icons">receipt_long</i>
							</div>
							<p class="card-category">Ready To Invoice</p>
							<h3 class="card-title">
								<span *ngIf="invoice">{{ invoice.length }}</span>
								<span *ngIf="!invoice">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="invoice">
								<a (click)="toggleFlip('Invoice',invoice)"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-4 col-md-4 col-sm-6">
					<div class="card card-stats">
						<div class="card-header card-header-danger card-header-icon">
							<div class="card-icon">
								<i class="material-icons">shop_two</i>
							</div>
							<p class="card-category">Open PO's</p>
							<h3 class="card-title">
								<span *ngIf="allpurchaseorders.pos">{{ allpurchaseorders.pos.length }}</span>
								<span *ngIf="!allpurchaseorders.pos">
									<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
										<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
									</svg>
								</span>
							</h3>
						</div>
						<div class="card-footer">
							<div class="stats" *ngIf="invoice">
								<a (click)="toggleFlip('Open Po',allpurchaseorders)"><i class="material-icons">visibility</i>
									View Now</a>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</div>
</div>
