import { Component, HostListener, ViewChild, ElementRef, OnInit } from '@angular/core';

@Component({
	selector: 'app-space-invaders',
	templateUrl: './space-invaders.component.html',
	styleUrls: ['./space-invaders.component.scss'],
})
export class SpaceInvadersComponent implements OnInit {
	@ViewChild('gameContainer') gameContainer!: ElementRef < HTMLDivElement > ;

	playerPosition: number = 45;
	bullets: { left: number;top: number } [] = [];
	enemyBullets: { left: number;top: number } [] = [];
	enemies: { left: number;top: number } [] = [];
	gameOver: boolean = false;
	roundCleared: boolean = false;
	score: number = 0;
	round: number = 1;

	private bulletIntervals: NodeJS.Timeout[] = [];
	private enemyBulletIntervals: NodeJS.Timeout[] = [];
	private enemyShootIntervals: Map < number, any > = new Map();

	ngOnInit(): void {
		this.startRound();
	}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.focusGame();
		}, 100);
	}

	focusGame() {
		this.gameContainer.nativeElement.focus();
	}

	@HostListener('document:keydown', ['$event'])
	handleKeyPress(event: KeyboardEvent) {
		if (document.activeElement === this.gameContainer.nativeElement) {
			if (event.key === 'ArrowLeft' && this.playerPosition > 5) {
				this.playerPosition -= 5;
			} else if (event.key === 'ArrowRight' && this.playerPosition < 95) {
				this.playerPosition += 5;
			} else if (event.key === ' ') {
				this.shootBullet();
			} else if (this.roundCleared && event.key === 'Enter') {
				this.startNextRound();
			} else if (this.gameOver && event.key === 'Enter') {
				this.restartGame();
			}
		}
	}

	startRound() {
		this.roundCleared = false;
		this.spawnEnemies();
	}

	startNextRound() {
		this.clearIntervals();
		this.bullets = [];
		this.enemyBullets = [];
		this.round++;
		this.startRound();
	}

	restartGame() {
		this.playerPosition = 45;
		this.bullets = [];
		this.enemyBullets = [];
		this.enemies = [];
		this.score = 0;
		this.round = 1;
		this.gameOver = false;
		this.roundCleared = false;
		this.clearIntervals();
		this.startRound();
		this.focusGame();
	}

	clearIntervals() {
		this.enemyShootIntervals.forEach((interval) => clearInterval(interval));
		this.enemyShootIntervals.clear();
		this.bulletIntervals.forEach((interval) => clearInterval(interval));
		this.enemyBulletIntervals.forEach((interval) => clearInterval(interval));
		this.bulletIntervals = [];
		this.enemyBulletIntervals = [];
	}

	shootBullet() {
		this.bullets.push({ left: this.playerPosition, top: 90 });
		this.updateBullets();
	}

	spawnEnemies() {
		this.enemies = [];
		const enemyCount = this.round * 5;
		for (let i = 0; i < enemyCount; i++) {
			this.enemies.push({ left: (i % 10) * 10 + 5, top: Math.floor(i / 10) * 10 + 10 });
		}
		this.moveEnemies();
		this.startEnemyShooting();
	}

	startEnemyShooting() {
		this.enemies.forEach((enemy, index) => {
			const shootInterval = Math.random() * 5000 + 2000;
			const interval = setInterval(() => {
				if (!this.gameOver && !this.roundCleared && this.enemies[index]) {
					this.enemyBullets.push({ left: enemy.left, top: enemy.top });
					this.updateEnemyBullets();
				}
			}, shootInterval);
			this.enemyShootIntervals.set(index, interval);
		});
	}

	updateBullets() {
		const interval = setInterval(() => {
			this.bullets.forEach((bullet, index) => {
				bullet.top -= 2;
				this.checkCollisionWithEnemies(bullet, index);
				if (bullet.top < 0) this.bullets.splice(index, 1);
			});
			if (this.gameOver || this.roundCleared) clearInterval(interval);
		}, 50);
		this.bulletIntervals.push(interval);
	}

	updateEnemyBullets() {
		const interval = setInterval(() => {
			this.enemyBullets.forEach((bullet, index) => {
				bullet.top += 2;
				this.checkCollisionWithPlayer(bullet, index);
				if (bullet.top > 100) this.enemyBullets.splice(index, 1);
			});
			if (this.gameOver || this.roundCleared) clearInterval(interval);
		}, 50);
		this.enemyBulletIntervals.push(interval);
	}

	moveEnemies() {
		let direction = 1;
		const interval = setInterval(() => {
			this.enemies.forEach((enemy) => {
				enemy.left += direction * 2;
				if (enemy.left >= 95 || enemy.left <= 0) {
					direction *= -1;
					this.enemies.forEach((enemy) => {
						enemy.top += 5;
					});
				}
				if (enemy.top > 90) {
					this.gameOver = true;
				}
			});

			if (this.gameOver || this.enemies.length === 0) {
				this.roundCleared = this.enemies.length === 0;
				clearInterval(interval);
			}
		}, 500);
	}

	checkCollisionWithEnemies(bullet: { left: number;top: number }, bulletIndex: number) {
		this.enemies.forEach((enemy, enemyIndex) => {
			if (this.isCollision(bullet, enemy)) {
				this.bullets.splice(bulletIndex, 1);
				this.enemies.splice(enemyIndex, 1);
				this.score += 10;
				const interval = this.enemyShootIntervals.get(enemyIndex);
				if (interval) {
					clearInterval(interval);
					this.enemyShootIntervals.delete(enemyIndex);
				}
			}
		});
	}

	checkCollisionWithPlayer(bullet: { left: number;top: number }, bulletIndex: number) {
		const player = { left: this.playerPosition, top: 90 };
		if (this.isCollision(bullet, player)) {
			this.enemyBullets.splice(bulletIndex, 1);
			this.gameOver = true;
		}
	}

	isCollision(object1: { left: number;top: number }, object2: { left: number;top: number }): boolean {
		return (
			Math.abs(object1.left - object2.left) < 5 && Math.abs(object1.top - object2.top) < 5
		);
	}
}