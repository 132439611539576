<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-primary">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							General Ledger Accounts
						</h4>
					</div>
					<div class="card-body">
						<!-- Filters and Search -->
						<div class="row mb-3">
							<div class="col-md-6">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Search Accounts</mat-label>
									<input matInput (keyup)="applyFilter($event)" placeholder="Search by Code or Name" />
									<mat-icon matSuffix>search</mat-icon>
								</mat-form-field>
							</div>
							<div class="col-md-6">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Filter by Group</mat-label>
									<mat-select (selectionChange)="applyGroupFilter($event.value)" multiple>
										<mat-option *ngFor="let group of groups" [value]="group.groupname">
											{{ group.groupname }}
										</mat-option>
									</mat-select>
								</mat-form-field>
							</div>
						</div>
						<div class="text-end">
							<button mat-raised-button class="mt-2 mb-2" color="accent" (click)="toggleForm()">
								{{ isFormVisible ? 'Hide Form' : 'Add New Account' }}
							</button>
						</div>

						<!-- Table -->
						<div class="table-responsive mt-4">
							<table class="table table-striped table-hover table-bordered">
								<thead class="thead-dark">
									<tr>
										<th class="text-center">Account Code</th>
										<th class="text-center">Account Name</th>
										<th class="text-center">Account Group</th>
										<th class="text-center"></th>
									</tr>
								</thead>
								<tbody>
									<!-- Add New Account Form Row -->
									<tr *ngIf="!editingAccount && isFormVisible">
										<td colspan="4">
											<form [formGroup]="accountForm" (ngSubmit)="saveGLAccount()" class="p-4 border rounded bg-light">
												<div class="row g-4">
													<div class="col-md-4">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Account Code</mat-label>
															<input matInput formControlName="accountcode" placeholder="Account Code" required />
														</mat-form-field>
													</div>
													<div class="col-md-4">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Account Name</mat-label>
															<input matInput formControlName="accountname" placeholder="Account Name" required />
														</mat-form-field>
													</div>
													<div class="col-md-4">
														<mat-form-field appearance="outline" class="w-100">
															<mat-label>Account Group</mat-label>
															<mat-select formControlName="group" required>
																<mat-option *ngFor="let group of groups" [value]="group.groupname">
																	{{ group.groupname }}
																</mat-option>
															</mat-select>
														</mat-form-field>
													</div>
												</div>
												<div class="text-end mt-3">
													<button mat-raised-button color="accent" class="ml-1 mr-1" type="submit" [disabled]="accountForm.invalid">
														Add Account
													</button>
													<button mat-raised-button color="warn"  class="ml-1 mr-1" type="button" (click)="cancelEdit()">
														Cancel
													</button>
												</div>
											</form>
										</td>
									</tr>

									<!-- Existing Accounts -->
									<ng-container *ngFor="let account of filteredAccounts" [class.selected]="editingAccount?.accountcode === account.accountcode">
										<tr>
											<td class="text-center">{{ account.accountcode }}</td>
											<td class="text-center">{{ account.accountname }}</td>
											<td class="text-center">{{ account.group }}</td>
											<td class="text-center">
												<button mat-icon-button color="primary" (click)="editAccount(account)" matTooltip="Edit">
													<mat-icon>edit</mat-icon>
												</button>
												<button mat-icon-button color="warn" (click)="deleteAccount(account.accountcode)" matTooltip="Delete">
													<mat-icon>delete</mat-icon>
												</button>
											</td>
										</tr>
										<tr *ngIf="editingAccount?.accountcode === account.accountcode">
											<td colspan="4">
												<form [formGroup]="accountForm" (ngSubmit)="saveGLAccount()" class="p-4 border rounded bg-light">
													<div class="row g-4">
														<div class="col-md-4">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Account Code</mat-label>
																<input matInput formControlName="accountcode" placeholder="Account Code" required />
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Account Name</mat-label>
																<input matInput formControlName="accountname" placeholder="Account Name" required />
															</mat-form-field>
														</div>
														<div class="col-md-4">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Account Group</mat-label>
																<mat-select formControlName="group" required>
																	<mat-option *ngFor="let group of groups" [value]="group.groupname">
																		{{ group.groupname }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>
													</div>
													<div class="text-end mt-3">
														<button mat-raised-button color="accent" type="submit" [disabled]="accountForm.invalid">
															Update Account
														</button>
														<button mat-raised-button color="warn" type="button" (click)="cancelEdit()">
															Cancel
														</button>
													</div>
												</form>
											</td>
										</tr>
									</ng-container>

									<tr *ngIf="!filteredAccounts || filteredAccounts.length === 0">
										<td class="text-center" colspan="4">No accounts found.</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
