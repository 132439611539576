import { Component, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, ElementRef, Input, Pipe, PipeTransform, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormBuilder, Validators, ControlContainer, FormGroupDirective, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, distinctUntilChanged } from 'rxjs/operators';

import { GlobalsService } from '../../services/globals.service';
import { GlobalSearchService } from '../../services/globalsearchservice.service'
import { InventoryService } from '../../services/inventory.service';
import { CustomerService } from '../../services/customer.service';
import { Location } from '@angular/common'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

import { Directive, HostListener } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as FileSaver from 'file-saver';

@Component({
	selector: 'app-consignment-build',
	templateUrl: './consignment-build.component.html',
	styleUrls: ['./consignment-build.component.scss']
})
export class ConsignmentBuildComponent {
	@Input() customerid: any = false;
	@Input() customer: any = false;
	@Output() updateconsignment: EventEmitter < any > = new EventEmitter();
	
	@ViewChild('consignEdit') specEditRef: ElementRef;
	@ViewChild('returnEdit') putawayRef: ElementRef;
	@ViewChild('consignHist') histRef: ElementRef;
	
	itembins: any = [];
	headercolumns: string[] = [
		'stockid',
		'brname',
		'description',
		'quantity',
		'date_stocked',
		'actions',
	];
	total_qty = 0;
	max_qty = 0;
	addstockid: any = '';
	putaway: any = [];
	putawayqty = 0;
	putawaybin: any = '';
	carttotals: any;
	dspcost = true;
	filteredOptions: any = [];
	allitems: any = [];
	user: any = [];
	debtorno: any = '';
	consignitems: any = [];
	customerdata: any = [];
	imsearch: any = false;
	config: any = [];
	chist: any = [];
	
	holdingitems: any = [];
	
	
	constructor(private route: ActivatedRoute, public customerService: CustomerService, public inventoryService: InventoryService, public globalSearchService: GlobalSearchService, private modalService: NgbModal, private globalsService: GlobalsService) {
		
		this.globalSearchService.configsubscription.subscribe(r => {
			this.config = r;
			this.globalSearchService.user.subscribe(u => {
				this.user = u
			})
		});
	}
	ngOnInit(): void {
	
		this.route.params.subscribe(params => {
			this.debtorno = params['id'];
			this.customerService.getConsignment({ debtorno: this.debtorno }).subscribe((results: any[]) => {
				this.consignitems = results;
			});
			this.customerService.getDebtor(this.debtorno).subscribe((results: any[]) => {
				this.customerdata = results;

			});
		});
		
	}
	autocompleteFilter() {
		if(this.addstockid !='') {
			if(this.imsearch) {
				this.imsearch.unsubscribe()
			}

			const data = {
				stockid: this.addstockid
			};

			this.imsearch = this.inventoryService.itemSearch(data).subscribe(results => {

				const no_zero = results.filter( r => {
					return parseFloat(r.qty) > 0
				})
				//this.itembins = no_zero;

				this.allitems = no_zero;
				this.filteredOptions = no_zero;
			});
		}
	}

	addConsignmentItem() {
		this.addstockid = '';
		this.itembins = [];
		this.autocompleteFilter();
		this.modalService.open(this.specEditRef, { ariaLabelledBy: 'modal-title', size: 'xl' }).result.then((result) => {

		}, (reason) => {

		});
	}
	
	getItemBins(stockid) {

		this.inventoryService.getItemBins({ stockid: stockid, loccode: this.customer.customer.defaultlocation }).subscribe((results: any) => {
			this.itembins = results;
		});
	}

	filterItem(input: any, term: any, keys: any) {
		const result = Object.assign([], input).filter((item) => keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(term, 'gi').test(item[key])));
		return result
	}
	
	moveConsignmentItem() {
		// /this.user.user.defaultlocation.loccode
		
		const totalmovements = this.itembins.reduce(function(accumulator, c) {

			return accumulator + parseFloat(c.quantityreq);
		}, 0);
		
		const data = { type: 'add', debtorno: this.debtorno, stockid: this.addstockid, branchcode:this.customer.branchcode, qty: totalmovements, bins: this.itembins, loccode: this.customer.customer.defaultlocation };

		

		if(totalmovements != 0) {
			this.holdingitems.push(data);
			this.modalService.dismissAll();
			this.globalSearchService.showNotification('Consignment edit list updated','success','bottom','left')

		} else {
			this.globalSearchService.showNotification('No Qty To Move','danger','bottom','left')
		}
	}
	removeConsignmentItem() {
	
		const totalmovements = this.putaway.reduce(function(accumulator, c) {	
			return accumulator + parseFloat(c.qty);
		}, 0);
		const data = { type: 'remove', debtorno: this.debtorno, branchcode:this.customer.branchcode, qty: totalmovements, stockid: this.addstockid, bins: this.putaway, loccode: this.customer.customer.defaultlocation };
		if( totalmovements != 0){
			this.holdingitems.push(data);
			this.globalSearchService.showNotification('Consignment edit list updated','success','bottom','left')
			this.modalService.dismissAll()
			// 	this.customerService.removeConsignment(data).subscribe((results: any) => {
			// 		this.consignitems = results;
			// 		this.updateconsignment.emit(true);		
			// 	});
				this.putaway = [];
			// 	this.modalService.dismissAll();
		} else {
			// this.globalSearchService.showNotification('No Qty returned','danger','bottom','left')
		}
	}

	removeConsignItem(stockid, max) {
		this.max_qty = max;
		this.total_qty = 0;
		this.addstockid = stockid;
		this.putawaybin = '';
		this.putawayqty = 0;
		
		this.modalService.open(this.putawayRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

		}, (reason) => {

		});
	}

	assignConsignmentItem() {
		if (this.putawayqty > this.max_qty) {
			this.putawayqty = this.max_qty;
		}
		this.max_qty = this.max_qty - this.putawayqty;
		const bindata = { bin: this.putawaybin, qty: this.putawayqty };
		this.putaway.push(bindata);
		this.total_qty = this.total_qty + this.putawayqty;
	}
	
	postConsignment(){
		const data = { items: this.holdingitems, debtorno: this.debtorno };
		this.customerService.updateConsignment(data).subscribe((results: any) => {
			if(results.newdoc){
				this.consignitems = results.items;
				this.updateconsignment.emit(true);		
				this.globalSearchService.showNotification('Consignment Document #'+ results.newdoc +' added','success','bottom','left');
				this.holdingitems = [];
				window.open(this.config.apiServer.baseUrl+'?route=apir/documents/consignment&orderno=' + results.newdoc + '&key=' + this.config.apiCredentials.clientId);
			}
		});
	}
	
	consignHistory(){
		const data = { debtorno: this.debtorno };
		this.customerService.consignmentHist(data).subscribe((results: any) => {
			this.chist = results;
			this.chist.forEach((c) => {
				c.histlink = this.config.apiServer.baseUrl+'?route=apir/documents/consignment&orderno=' + c.transno + '&key=' + this.config.apiCredentials.clientId;
			});
			this.modalService.open(this.histRef, { ariaLabelledBy: 'modal-title', size: 'lg' }).result.then((result) => {

			}, (reason) => {

			});
		});
	}
	undohold(item: any){
		const index = this.holdingitems.indexOf(item);
		this.holdingitems.splice(index, 1)
		this.globalSearchService.showNotification('Removed ' + item.qty + 'x ' + item.stockid, 'warning', 'bottom', 'right');
	}
	getConsignmentDoc(){
	
	}
}
