<div class="game-container" #gameContainer tabindex="0">
  <div class="header">
	<div class="score">Score: {{ score }}</div>
	<div class="round">Round: {{ round }}</div>
  </div>

  <div class="player" [style.left.%]="playerPosition"></div>

  <div
	class="bullet"
	*ngFor="let bullet of bullets"
	[style.left.%]="bullet.left"
	[style.top.%]="bullet.top"
  ></div>

  <div
	class="enemy-bullet"
	*ngFor="let bullet of enemyBullets"
	[style.left.%]="bullet.left"
	[style.top.%]="bullet.top"
  ></div>

  <div
	class="enemy"
	*ngFor="let enemy of enemies"
	[style.left.%]="enemy.left"
	[style.top.%]="enemy.top"
  ></div>

  <div class="game-message" *ngIf="roundCleared">Round Cleared! Press Enter to Start Next Round</div>
  <div class="game-message game-over" *ngIf="gameOver">Game Over! Press Enter to Restart</div>
</div>