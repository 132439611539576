<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<!-- Card Header -->
					<div class="card-header card-header-{{ color }}">
						<h4 class="card-title d-flex align-items-center">
							<mat-icon class="mr-2 cursor-pointer" (click)="back()">arrow_back</mat-icon>
							Account Sections
						</h4>
					</div>

					<!-- Card Body -->
					<div class="card-body">
						<!-- Search and Add New Section -->
						<div class="row mb-3">
							<div class="col-md-12">
								<mat-form-field appearance="outline" class="w-100">
									<mat-label>Search Sections</mat-label>
									<input matInput (keyup)="applySearch($event)" placeholder="Search by Section Name" />
									<mat-icon matSuffix>search</mat-icon>
								</mat-form-field>
							</div>
							<div class="col-md-3 text-end">
								<button mat-raised-button color="accent" (click)="toggleForm()">
									{{ isFormVisible ? 'Hide Form' : 'Add New Section' }}
								</button>
							</div>
						</div>

						<!-- Add New Section Form -->
						<div *ngIf="isFormVisible" class="mb-4">
							<form [formGroup]="sectionForm" (ngSubmit)="saveSection()" class="p-4 border rounded bg-light">
								<div class="row g-4">
									<div class="col-md-6">
										<mat-form-field appearance="outline" class="w-100">
											<mat-label>Section ID</mat-label>
											<input matInput type="number" formControlName="sectionid" placeholder="Enter Section ID" required />
											<mat-error *ngIf="sectionForm.get('sectionid')?.hasError('required')">
												Section ID is required.
											</mat-error>
										</mat-form-field>
									</div>
									<div class="col-md-6">
										<mat-form-field appearance="outline" class="w-100">
											<mat-label>Section Name</mat-label>
											<input matInput formControlName="sectionname" placeholder="Enter Section Name" required />
											<mat-error *ngIf="sectionForm.get('sectionname')?.hasError('required')">
												Section Name is required.
											</mat-error>
										</mat-form-field>
									</div>
								</div>
								<div class="text-end mt-3">
									<button mat-raised-button class="ml-1 mr-1" color="accent" type="submit" [disabled]="sectionForm.invalid">
										Add Section
									</button>
									<button mat-raised-button class="ml-1 mr-1" color="warn" type="button" (click)="toggleForm()">
										Cancel
									</button>
								</div>
							</form>
						</div>

						<!-- Sections Table -->
						<div class="table-responsive mt-4">
							<table class="table table-striped table-hover table-bordered">
								<thead class="thead-dark">
									<tr>
										<th class="text-center">Section ID</th>
										<th class="text-center">Section Name</th>
										<th class="text-center">Actions</th>
									</tr>
								</thead>
								<tbody>
									<!-- Sections Data -->
									<ng-container *ngFor="let section of filteredSections">
										<tr>
											<td class="text-center">{{ section.sectionid }}</td>
											<td class="text-center">{{ section.sectionname }}</td>
											<td class="text-center">
												<button mat-icon-button color="primary" (click)="editSection(section)" matTooltip="Edit">
													<mat-icon>edit</mat-icon>
												</button>
												<button mat-icon-button color="warn" (click)="deleteSection(section.sectionid)" matTooltip="Delete">
													<mat-icon>delete</mat-icon>
												</button>
											</td>
										</tr>

										<!-- Inline Edit Form -->
										<tr *ngIf="editingSection?.sectionid === section.sectionid">
											<td colspan="3">
												<form [formGroup]="sectionForm" (ngSubmit)="saveSection()" class="p-4 border rounded bg-light">
													<div class="row g-4">
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Section ID</mat-label>
																<input matInput type="number" formControlName="sectionid" placeholder="Enter Section ID" required />
																<mat-error *ngIf="sectionForm.get('sectionid')?.hasError('required')">
																	Section ID is required.
																</mat-error>
															</mat-form-field>
														</div>
														<div class="col-md-6">
															<mat-form-field appearance="outline" class="w-100">
																<mat-label>Section Name</mat-label>
																<input matInput formControlName="sectionname" placeholder="Enter Section Name" required />
																<mat-error *ngIf="sectionForm.get('sectionname')?.hasError('required')">
																	Section Name is required.
																</mat-error>
															</mat-form-field>
														</div>
													</div>
													<div class="text-end mt-3">
														<button mat-raised-button class="ml-1 mr-1" color="accent" type="submit" [disabled]="sectionForm.invalid">
															Update Section
														</button>
														<button mat-raised-button class="ml-1 mr-1" color="warn" type="button" (click)="cancelEdit()">
															Cancel
														</button>
													</div>
												</form>
											</td>
										</tr>
									</ng-container>

									<!-- No Data Message -->
									<tr *ngIf="!filteredSections || filteredSections.length === 0">
										<td class="text-center" colspan="3">No sections found.</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
