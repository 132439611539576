<ng-template #details let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body">
		<div class="mb-3" *ngIf="displaydetails">
			<h4></h4>
			<ng-container *ngIf="displaydetails.type == 'html'">
				<div [innerHTML]="displaydetails.display">
				</div>
			</ng-container>
			<ng-container *ngIf="displaydetails.type == 'pdf'">
				<ngx-extended-pdf-viewer [base64Src]="displaydetails.display" useBrowserLocale="true" [handTool]="false" [textLayer]="true" [showOpenFileButton]="false" [showPropertiesButton]="false" [showHandToolButton]="true"></ngx-extended-pdf-viewer>
			</ng-container>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #allocations let-modal>
	<div class="modal-header text-dark">
		<h4 class="modal-title">{{ displaydetails.title }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-close"></i>
		</button>
	</div>
	<div class="modal-body">
		<!-- Check if displaydetails has data -->
		<div *ngIf="displaydetails?.data?.length; else noData">
			<h5 class="mb-3">Transaction Details</h5>
			<div class="table-responsive">
				<table class="table table-striped table-hover table-fixed align-middle">
					<thead>
						<tr>
							<th class="text-center">Period</th>
							<th class="text-center">Date</th>
							<th>Account</th>
							<th>Description</th>
							<th class="text-right">Debit</th>
							<th class="text-right">Credit</th>
							<th class="text-center">Posted</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let row of displaydetails.data">
							<td class="text-center">{{ row.period }}</td>
							<td class="text-center">{{ row.date }}</td>
							<td>{{ row.account }}</td>
							<td>{{ row.description }}</td>
							<td class="text-right">{{ row.debit | currency }}</td>
							<td class="text-right">{{ row.credit | currency }}</td>
							<td class="text-center">
								<span [ngClass]="row.posted === 'Yes' ? 'text-success fw-bold' : 'text-danger fw-bold'">{{ row.posted }}</span>
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colspan="4" class="text-end fw-bold">Totals</td>
							<td class="text-right fw-bold">{{ getTotal('debit') | currency }}</td>
							<td class="text-right fw-bold">{{ getTotal('credit') | currency }}</td>
							<td></td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>

		<!-- No Data Message -->
		<ng-template #noData>
			<div class="alert alert-warning text-center" role="alert">
				<strong>No transaction data available to display.</strong>
			</div>
		</ng-template>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-secondary" (click)="modal.dismiss('Close')">Close</button>
	</div>
</ng-template>



<ng-container *ngIf="transactions">

	<app-vendor-aging [aged]="vendor.aged" class="w-100"></app-vendor-aging>

	<nav class="navbar navbar-expand navbar-light bg-light w-100 no-shadow">
		<div class="collapse navbar-collapse" id="navbarNavDropdown">

			<div class="nav-item">
				<mat-form-field appearance="outline" *ngIf="type_filters">
					<mat-label>Type</mat-label>
					<mat-select [(ngModel)]="type_selected" multiple>
						<mat-option *ngFor="let v of type_filters" [value]="v.value">
							{{v.name}}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<ul class="navbar-nav">
				<li class="nav-item">
					<mat-slide-toggle class="nav-link" [formControl]="opentrans">
						<mat-label>Show open docs only</mat-label>
					</mat-slide-toggle>
				</li>
			</ul>
			<div class="nav-item ml-auto mr-0 nav-link form-inline">
				<mat-form-field appearance="outline">
					<mat-label class="text-right">Search By Items/Order</mat-label>
					<input tabindex="0" matInput value="" [formControl]="keywords" class="text-right" (input)="searchTransaction($event.target.value)">
					<mat-progress-bar mode="indeterminate" color="accent" *ngIf="searching"></mat-progress-bar>
					<span matPrefix *ngIf="searching" class="ml-auto mr-auto">
						<svg class="spinner" width="15px" height="15px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>
					<mat-hint>Search for an Item or Order</mat-hint>
				</mat-form-field>
			</div>
			<div class="nav-item form-inline">
				<form class="form-inline my-2 my-lg-0">
					<mat-form-field appearance="outline">
						<mat-label>Transaction Dates</mat-label>
						<mat-date-range-input [rangePicker]="picker">
							<input matStartDate [formControl]="fromdateCtrl" placeholder="Start date">
							<input matEndDate [formControl]="todateCtrl" placeholder="End date">
						</mat-date-range-input>
						<mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
						<mat-date-range-picker #picker></mat-date-range-picker>
					</mat-form-field>
					<button mat-raised-button color="accent" class="ml-2" (click)="updateTransactions()" *ngIf="!sending">
						<mat-icon>refresh</mat-icon>
					</button>
					<span *ngIf="sending">
						<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
							<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
						</svg>
					</span>

				</form>
			</div>
		</div>
	</nav>

	Results: {{ alltransactions.length }}


	<div class="container-fluid tp-box" [@flipState]="flip">

		<div class="tp-box__side tp-box__back" [ngClass]="flip == 'inactive' ? '' : ''">
			<ng-container *ngIf="transid">
				<div class="text-left mt-3">
					<button mat-raised-button color="primary" (click)="flip = 'inactive';transid=false;updateTransactions()">Back</button>
				</div>
				<app-allocations [transid]="transid" [type]="transidtype"></app-allocations>
			</ng-container>
		</div>

		<div id="frontside" class="row tp-box__side tp-box__front" [ngClass]="flip == 'active' ? '' : ''">

			<span *ngIf="sending">
				<svg class="spinner" width="35px" height="35px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
					<circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
				</svg>
			</span>

			<table #tablecontent class="table table-fixed table-hover">
				<thead class="table-light">
					<tr>
						<th class="text-left">Document#</th>
						<th class="text-left">Type</th>
						<th class="text-left">Date</th>
						<th class="text-left">Reference#</th>
						<th class="text-right">Total</th>
						<th class="text-right">Allocated</th>
						<th class="text-right">Balance</th>
						<th class="text-center">Settled</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let tran of transactions" class="align-middle">
						<!-- Document# Column -->
						<td>
							<div class="d-flex align-items-center">
								<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="More" class="me-2">
									<mat-icon>more_vert</mat-icon>
								</button>
								<mat-menu #menu="matMenu">
									<button mat-menu-item *ngIf="tran.type == '20' || tran.type == '21'" (click)="openDocument(tran, details, 'html')">
										<mat-icon>visibility</mat-icon>
										<span>View HTML</span>
									</button>

										<button mat-menu-item *ngIf="tran.type == '20' || tran.type == '21'" (click)="openDocument(tran, details, 'pdf')">
										<mat-icon>visibility</mat-icon>
										<span>View PDF</span>
									</button>
									<!--
										<button mat-menu-item *ngIf="tran.type == '20' || tran.type == '21'" (click)="sendEmail(tran)">
										<mat-icon>email</mat-icon>
										<span>Email</span>
									</button>
									-->
									<mat-divider></mat-divider>
									<button mat-menu-item (click)="loadAllocations(tran.id, tran.type)" *ngIf="tran.type == '21' || tran.type == '22'">
										<mat-icon>view_list</mat-icon>
										<span>Allocate</span>
									</button>
									<button mat-menu-item (click)="viewPayments(tran, details)" *ngIf="tran.type == '20'">
										<mat-icon>credit_card</mat-icon>
										<span>Payments</span>
									</button>
									<mat-divider></mat-divider>
									<button mat-menu-item (click)="openGl(tran, allocations)">
										<mat-icon>view_list</mat-icon>
										<span>GL's</span>
									</button>
									<button mat-menu-item (click)="viewAllocations(tran, details)" *ngIf="tran.type == '21' || tran.type == '22'">
										<mat-icon>view_list</mat-icon>
										<span>Allocations</span>
									</button>
								</mat-menu>
								<span>{{ tran.transno }}</span>
							</div>
						</td>

						<!-- Type Column -->
						<td [innerHTML]="tran.typename"></td>

						<!-- Date Column -->
						<td>{{ tran.trandate }}</td>

						<!-- Reference# Column -->
						<td>{{ tran.suppreference }}</td>

						<!-- Total Column -->
						<td class="text-right">{{ tran.totalamount | currency }}</td>

						<!-- Allocated Column -->
						<td class="text-right">{{ tran.allocated | currency }}</td>

						<!-- Balance Column -->
						<td class="text-right">{{ tran.totalamount - tran.allocated | currency }}</td>

						<!-- Settled Column -->
						<td class="text-center" [ngClass]="(tran.settled == '1') ? 'text-success fw-bold' : 'text-danger fw-bold'">
							<span *ngIf="tran.settled == '1'">Yes</span>
							<span *ngIf="tran.settled == '0'">No</span>
						</td>
					</tr>

					<!-- Totals Row -->
					<tr class="table-secondary fw-bold">
						<td colspan="4" class="text-end">TOTAL</td>
						<td class="text-right">{{ getTransactionTotals() | currency }}</td>
						<td class="text-right">{{ getAllocTotals() | currency }}</td>
						<td class="text-right">{{ getBalanceTotals() | currency }}</td>
						<td></td>
					</tr>
				</tbody>
			</table>

		</div>
	</div>
</ng-container>