<div class="table-responsive w-100">
  <table class="table table-sm table-bordered text-center smaller-font" *ngIf="aged">
	<thead class="table-header-grey text-dark">
		<tr>
			<th class="text-center w-20">BAL</th>
			<th class="text-center w-20">DUE</th>
			<th class="text-center w-20">30</th>
			<th class="text-center w-20">60</th>
			<th class="text-center w-20">90</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="text-center w-20 pointer" [ngClass]="getBalanceClass(aged.balance)"  (click)="emitZero()">{{ aged.balance | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="getBalanceClass(aged.balance)"  (click)="emitOne()">{{ aged.due | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="getBalanceClass(aged.balance)" (click)="emitTwo()">{{ aged.current | currency}}</td>
			<td class="text-center w-20 pointer" [[ngClass]="getBalanceClass(aged.balance)" (click)="emitThree()">{{ aged.overdue1 | currency}}</td>
			<td class="text-center w-20 pointer" [ngClass]="getBalanceClass(aged.balance)" (click)="emitFour()">{{ aged.overdue2 | currency}}</td>
		</tr>
	</tbody>
	</table>
</div>
