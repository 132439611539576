import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FixedAssetsService } from '../../services/fixed-assets.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
	selector: 'app-fixed-assets-categories',
	templateUrl: './fixed-assets-categories.component.html',
	styleUrls: ['./fixed-assets-categories.component.scss'],
})
export class FixedAssetsCategoriesComponent implements OnInit {
	assetCategoryForm: FormGroup;

	editingCategory: any = null;
	isFormVisible: boolean = false;
	assetCategories: any[] = [];
	bsAccounts: any[] = [];
	plAccounts: any[] = [];
	isLoading: boolean = false;

	constructor(
		private fb: FormBuilder,
		private fixedAssetsService: FixedAssetsService,
		private snackBar: MatSnackBar
	) {
		this.assetCategoryForm = this.fb.group({
			categoryid: ['', Validators.required],
			categorydescription: ['', Validators.required],
			costact: ['', Validators.required],
			depnact: ['', Validators.required],
			disposalact: ['', Validators.required],
			accumdepnact: ['', Validators.required],
		});

	}

	ngOnInit(): void {
		this.loadAssetCategories();
		this.loadAccounts();
	}

	editCategory(category: any) {
		this.editingCategory = category;
		this.assetCategoryForm.patchValue(category);
		this.isFormVisible = true;
	}
	// Load categories
	loadAssetCategories(): void {
		this.fixedAssetsService.fetchAssetCategories().subscribe(
			(response) => {
				this.assetCategories = response.data || [];
			},
			() => {
				this.snackBar.open('Error loading Asset Categories.', 'Close', { duration: 3000 });
			}
		);
	}

	loadAccounts(): void {
		this.fixedAssetsService.fetchAccounts('bs').subscribe((response) => {
			this.bsAccounts = response.data || [];
		});

		this.fixedAssetsService.fetchAccounts('pl').subscribe((response) => {
			this.plAccounts = response.data || [];
		});
	}

	// Save category
	saveCategory(): void {
		const data = this.assetCategoryForm.value;
		if (this.editingCategory) {
			data.id = this.editingCategory.id;
			this.fixedAssetsService.updateCategory(data).subscribe(() => {
				this.snackBar.open('Category updated successfully.', 'Close', { duration: 3000 });
				this.loadAssetCategories();
			});
		} else {
			this.fixedAssetsService.addCategory(data).subscribe((r) => {
				if (r.error) {
					this.snackBar.open(r.error, 'Close', { duration: 3000 });
				} else {
					this.snackBar.open('Category added successfully.', 'Close', { duration: 3000 });
				}

				this.loadAssetCategories();
			});
		}
		this.resetForm();
		this.isFormVisible = false;
	}

	// Delete category
	deleteCategory(categoryId: string): void {
		if (confirm('Are you sure you want to delete this category?')) {
			this.fixedAssetsService.deleteCategory(categoryId).subscribe((r) => {
				if (r.error) {
					this.snackBar.open(r.error, 'Close', { duration: 3000 });
				} else {
					this.snackBar.open('Category deleted successfully.', 'Close', { duration: 3000 });
					this.loadAssetCategories();
				}
			});
		}
		this.isFormVisible = false;
	}

	resetForm(): void {
		this.assetCategoryForm.reset();
		this.editingCategory = null;

	}
}