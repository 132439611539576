<ng-template #addCustomer let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">

			<div class="col-12">
				<form [formGroup]="customerTypeForm">
					<mat-form-field appearance="outline">
						<mat-label>Customer Type</mat-label>
						<input type="text" class="text-let m-0 p-0" matInput formControlName="typename" />
					</mat-form-field>

					<mat-form-field appearance="outline">
						<mat-label>Address Type</mat-label>
						<mat-select formControlName="address_type">
							<mat-option [value]="'2'">Visible POS</mat-option>
							<mat-option [value]="'1'">Hide from POS</mat-option>
						</mat-select>
					</mat-form-field>

					<button mat-stroked-button color="primary" aria-label="Save" (click)="addCustomerType()" class="mt-2">
						<mat-icon>save</mat-icon> Save
					</button>
				</form>

			</div>
		</div>
	</div>

	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #editExclusions let-modal>
	<div class="modal-header">
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<h4> Exclusion Rules for {{editrule_name}}</h4>
		<div class="row">
			<div class="col-12">
				<table class="table table-striped">
					<tbody>
						<ng-container *ngIf="ruleset.length != 0">
							<tr *ngFor="let rule of ruleset;">
								<td> {{ rule.description }} </td>
								<td><button mat-stroked-button (click)="editRule(rule)"><mat-icon>edit</mat-icon></button><button mat-stroked-button (click)="deleteRule(rule)"><mat-icon>delete</mat-icon></button></td>
							</tr>
						</ng-container>
						<tr *ngIf="ruleset.length == 0">
							<td> No Rules Defined </td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<form [formGroup]="exclusionForm">
			<div class="row">
				<div class="col-3">
					<mat-form-field appearance="outline">
						<mat-label>Rule Type</mat-label>
						<mat-select formControlName="rule_type" (selectionChange)="typechange()">
							<mat-option *ngFor="let k of ruletypes | keyvalue" [value]="k.key">{{k.value}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="col-3">
					<mat-form-field appearance="outline">
						<mat-label>Inclusive</mat-label>
						<mat-select formControlName="rule_inclusive">
							<mat-option value="0">Not Equal To</mat-option>
							<mat-option value="1">Equal To</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-3" *ngIf="editrule_type == 'P'">
					<mat-form-field appearance="outline">
						<mat-label>Product Line</mat-label>
						<mat-select formControlName="rule_value">
							<mat-option *ngFor="let k of prodlines" [value]="k.line_field">{{k.line_description}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-3" *ngIf="editrule_type == 'C'">
					<mat-form-field appearance="outline">
						<mat-label>Category</mat-label>
						<mat-select formControlName="rule_value">
							<mat-option *ngFor="let k of categories" [value]="k.categoryid">{{k.categorydescription}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-3" *ngIf="editrule_type == 'B'">
					<mat-form-field appearance="outline">
						<mat-label>Brand</mat-label>
						<mat-select formControlName="rule_value">
							<mat-option *ngFor="let k of brands" [value]="k.manufacturer_id">{{k.name}}</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="col-3" *ngIf="editrule_type == 'S'">
					<mat-form-field appearance="outline">
						<mat-label>Item Number</mat-label>
						<input matInput formControlName="rule_value" />
					</mat-form-field>
				</div>
				<div class="col-3">
					<button mat-stroked-button color="success" aria-label="" (click)="saveRule()" class="mt-2">
						<mat-icon>save</mat-icon> Save
					</button>
				</div>
			</div>
		</form>
	</div>

	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #addVendor let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">
						<div class="col-12">
							<form [formGroup]="vendorTypeForm">
								<mat-form-field appearance="outline">
									<mat-label>Vendor Type</mat-label>
									<input type="text" class="text-let m-0 p-0" matInput formControlName="typename" />
								</mat-form-field>
								<button mat-stroked-button color="success" aria-label="" (click)="addVendorType()" class="mt-2">
									<mat-icon>save</mat-icon> Save
								</button>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #addSalesTypeEle let-modal>
	<!-- Modal Header -->
	<div class="modal-header">
		<h5 class="modal-title">Add or Update Sales Type</h5>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<i class="fa fa-times"></i>
		</button>
	</div>

	<div class="modal-body text-dark">

		<div class="row">
			<div class="col-12">
				<form [formGroup]="salesTypeForm">
					<div class="sales-type-form-container">
						<div class="form-card w-100">
							<div class="row mb-3">
								<!-- Sales Type -->
								<div class="col-md-4">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Sales Type (Abbreviation)</mat-label>
										<input type="text" matInput formControlName="typeabbrev" placeholder="Enter Sales Type Abbreviation" />
									</mat-form-field>
								</div>

								<!-- Name -->
								<div class="col-md-4">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Sales Type Name</mat-label>
										<input type="text" matInput formControlName="sales_type" placeholder="Enter Sales Type Name" />
									</mat-form-field>
								</div>

								<!-- Cost Multiplier -->
								<div class="col-md-4">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Cost Multiplier</mat-label>
										<input type="number" matInput formControlName="costMultiplier" placeholder="Enter Cost Multiplier (e.g., 1.2)" />
									</mat-form-field>
								</div>
							</div>
							<div class="row mb-3">
								<!-- Loyalty Points -->
								<div class="col-md-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Loyalty Points</mat-label>
										<input type="number" matInput formControlName="loyaltypoints" placeholder="Enter Loyalty Points" />
									</mat-form-field>
								</div>

								<!-- Price Book Toggle -->
								<div class="col-md-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Price Book</mat-label>
										<mat-select formControlName="ispricebook">
											<mat-option [value]="'0'">No</mat-option>
											<mat-option [value]="'1'">Yes</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								<!-- Fall Back Level -->
								<div class="col-md-6">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Fall Back Level</mat-label>
										<mat-select formControlName="fallback">
											<mat-option [value]="type.typeabbrev" *ngFor="let type of salestypes">
												{{ type.typeabbrev }} - {{ type.sales_type }}
											</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
								
							</div>
							<div class="row">
								<!-- Table Name -->
								<div class="col-md-3" [ngClass]="(salesTypeForm.get('ispricebook') && salesTypeForm.get('ispricebook').value == '1') ? '' : 'd-none'">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Table Name</mat-label>
										<input type="text" matInput formControlName="tablename" placeholder="Enter Table Name" />
									</mat-form-field>
								</div>
							</div>
						</div>
					</div>

					<ng-container *ngIf="salesTypeForm.get('ispricebook') && salesTypeForm.get('ispricebook').value == '1'">
						<!-- Instructions Section -->
						<div class="pricebook-card">
							<div class="pricebook-card-header">
								How to Update the PriceBook
							</div>
							<div class="card-body">
								<ol>
									<li>
										<strong>Turn On "New PriceBook":</strong> Use the <em>New PriceBook</em> switch only if you're creating a new PriceBook table. Leave it off if you're adding to an existing PriceBook.
									</li>
									<li>
										<strong>Get Your File Ready:</strong> Make sure your file is a CSV/XLS/XLSX. Don’t worry—file names and column headers will be cleaned up automatically to remove invalid characters. Use clear column names like <code>brand</code>, <code>price</code>, and <code>partno</code> AND REMOVE FORMULAS!.
									</li>
									<li>
										<strong>Upload the File:</strong> Drag and drop your file into the upload box or click to choose a file. You can only upload one file at a time.
									</li>
									<li>
										<strong>Match Your Data:</strong> After uploading, you’ll need to map your file's columns (e.g., <code>brand</code>, <code>partno</code>, <code>price</code>) to the required fields. This step ensures everything lines up correctly.
									</li>
									<li>
										<strong>Stock ID Matching:</strong> The system will automatically match <code>brand</code> and <code>partno</code> to find the right <code>stockid</code>. If needed, it will clean up the data and try again.
									</li>
									<li>
										<strong>Review and Save:</strong> Once your data is ready, check the preview table to make sure everything looks good. When you’re ready, click "Save" to save your PriceBook and update the Sales Type.
									</li>
								</ol>
							</div>

						</div>

						<!-- Toggle for New PriceBook -->
						<div class="form-group mb-4 mt-3">
							<mat-slide-toggle formControlName="newpricebook" class="d-flex align-items-center">
								<strong>Create New PriceBook</strong>
							</mat-slide-toggle>
						</div>

						<!-- File Upload Section -->
						<ng-container *ngIf="!uploaddata?.header && !isProcessing; else dataPreview">
							<div class="pricebook-card">
								<div class="card-body text-center">
									<ngx-dropzone class="pricebook-dropzone" [multiple]="false" (change)="onSelect($event)" required>
										<ngx-dropzone-label>
											Drop file here or click to upload
										</ngx-dropzone-label>
										<ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
											<ngx-dropzone-label class="text-info">
												<strong>{{ f.name }}</strong> ({{ f.type }})
											</ngx-dropzone-label>
										</ngx-dropzone-preview>
									</ngx-dropzone>
								</div>
							</div>
						</ng-container>



						<!-- Data Preview and Mapping -->
						<ng-template #dataPreview>
							<div class="pricebook-card">
								<div class="pricebook-card-header">
									Data Preview for Table: <spna *ngIf="tablename">{{ tablename }}</spna>
								</div>
								<div class="card-body">
									<div class="text-center mb-4">
										<div *ngIf="!uploaddata || isProcessing">
											<mat-icon>cloud_upload</mat-icon>
											<!-- Spinner -->
											<div *ngIf="isProcessing" class="text-center pricebook-spinner ml-auto mr-auto">
												<mat-spinner></mat-spinner>
												<p class="pricebook-spinner-text">Processing your file, please wait...</p>
											</div>
										</div>
									</div>
									<div class="pricebook-table-responsive" *ngIf="uploaddata?.header">
										<table class="table table-bordered table-fixed">
											<!-- Headers -->
											<thead class="thead-dark sticky-header">
												<tr>
													<th *ngFor="let col of uploaddata.header">
														{{ col }}
													</th>
												</tr>
											</thead>
											<tbody>
												<!-- Mapping -->
												<tr>
													<td *ngFor="let col of uploaddata.header">
														<mat-form-field appearance="fill" class="pricebook-mapping-field">
															<mat-label>Map Field</mat-label>
															<mat-select [(ngModel)]="mapping[col]" required>
																<mat-option *ngFor="let map of mapcols" [value]="map.field">
																	{{ map.label }}
																</mat-option>
															</mat-select>
														</mat-form-field>
													</td>
												</tr>
												<!-- Data Rows -->
												<tr *ngFor="let row of uploaddata.rows">
													<td *ngFor="let col of uploaddata.header">
														{{ row[col] }}
													</td>
												</tr>
											</tbody>
										</table>
									</div>

								</div>
							</div>
						</ng-template>
					</ng-container>
					<div class="row">
						<div class='col-12 text-right'>
							<button mat-stroked-button color="success" aria-label="" (click)="addSalesType()" class="mt-2">
								<mat-icon>save</mat-icon> Save
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div class="modal-footer">
	</div>
</ng-template>

<ng-template #addPaymentTermEle let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Edit</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">

						<form [formGroup]="paymentTermsForm" class="container">
							<div class="row">
								<div class="col-md-6 mb-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Code</mat-label>
										<input type="text" class="text-let m-0 p-0" matInput formControlName="termsindicator" />
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Name</mat-label>
										<input type="text" class="text-let m-0 p-0" matInput formControlName="terms" />
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Days Before Due</mat-label>
										<input type="text" class="text-let m-0 p-0" matInput formControlName="daysbeforedue" />
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Day in Following Month</mat-label>
										<input type="text" class="text-let m-0 p-0" matInput formControlName="dayinfollowingmonth" />
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="standard" class="w-100">
										<mat-label>Process Card</mat-label>
										<mat-select formControlName="cc_process">
											<mat-option [value]="'0'">N</mat-option>
											<mat-option [value]="'1'">Y</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="standard" class="w-100">
										<mat-label>Collect Payment</mat-label>
										<mat-select formControlName="collect_payment">
											<mat-option [value]="'0'">N</mat-option>
											<mat-option [value]="'1'">Y</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="standard" class="w-100">
										<mat-label>Do Not Print</mat-label>
										<mat-select formControlName="donot_print">
											<mat-option [value]="'0'">N</mat-option>
											<mat-option [value]="'1'">Y</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="standard" class="w-100">
										<mat-label>On Account Allowed</mat-label>
										<mat-select formControlName="onaccountallowed">
											<mat-option [value]="'0'">N</mat-option>
											<mat-option [value]="'1'">Y</mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<div class="col-md-6 mb-3">
									<mat-form-field appearance="standard" class="w-100">
										<mat-label>Order Entry</mat-label>
										<mat-select formControlName="order_entry">
											<mat-option [value]="'0'">N</mat-option>
											<mat-option [value]="'1'">Y</mat-option>
										</mat-select>
									</mat-form-field>
								</div>
							</div>

							<div class="row">
								<div class="col-12 text-center">
									<button mat-stroked-button color="success" aria-label="" (click)="addPaymentTerm()" class="mt-2">
										<mat-icon>save</mat-icon> Save
									</button>
								</div>
							</div>
						</form>


					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<ng-template #addClubEle let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-title">Club</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fa-close"></i></button>
	</div>
	<div class="modal-body text-dark">
		<div class="row">
			<div class="card">
				<div class="card-header text-center">
					<h4 class="card-title text-center"></h4>
				</div>
				<div class="card-body text-center">
					<div class="row">

						<form [formGroup]="clubForm" class="container">
							<div class="row">
								<div class="col-md-6 mb-3">
									<mat-form-field appearance="outline" class="w-100">
										<mat-label>Name</mat-label>
										<input type="text" class="text-let m-0 p-0" matInput formControlName="club" />
									</mat-form-field>
								</div>
							</div>

							<div class="row">
								<div class="col-12 text-center">
									<button mat-stroked-button color="success" aria-label="" (click)="addClub()" class="mt-2">
										<mat-icon>save</mat-icon> Save
									</button>
								</div>
							</div>
						</form>


					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
	</div>
</ng-template>
<div class="main-content">
	<div class="container-fluid">
		<div class="row">
			<div class="col-md-12">
				<div class="card">
					<div class="card-header card-header-{{color}}-5">
						<h4 class="card-title "><i class="material-icons" (click)="back()">arrow_left</i> System Types</h4>
						<p class="card-category text-right">System Types</p>
					</div>
					<div class="card-body">
						<mat-tab-group #tabPanel mat-align-tabs="start" class="" color="accent">
							<mat-tab label="Sales Types">
								<button mat-stroked-button color="success" aria-label="" (click)="addSalesTypeDisplay()" class="mt-2">
									<mat-icon>add</mat-icon> Add Sales Type
								</button>

								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>

								<table class="table table-sm mt-3">
									<tr>
										<th></th>
										<th>Abbreviation</th>
										<th>Type</th>
										<th>Cost x</th>
										<th>Loyalty Points</th>
										<th>Price Book</th>
									</tr>
									<tr *ngFor="let type of salestypes">
										<td>
											<button mat-icon-button color="warn" aria-label="delete" class="small-font" (click)="removeSalesType(type)">
												<mat-icon>remove</mat-icon>
											</button>
											<button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editSalesTypeDispaly(type)">
												<mat-icon>edit</mat-icon>
											</button>
										</td>
										<td>{{ type.typeabbrev }}</td>
										<td>{{ type.sales_type }}</td>
										<td>{{ type.costMultiplier }}</td>
										<td>{{ type.loyaltypoints }}</td>
										<td>{{ yesNoValue(type.ispricebook) }}</td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Payment Types">
								<app-payment-types></app-payment-types>
							</mat-tab>
							<mat-tab label="Payment Terms">
								<button mat-stroked-button color="success" aria-label="" (click)="addPaymentTermView()" class="mt-2">
									<mat-icon>add</mat-icon> Add Payment Term
								</button>
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>
								<table class="table table-sm mt-3">
									<thead>
										<tr>
											<th>Actions</th>
											<th>Code</th>
											<th>Name</th>
											<th>Days Before Due</th>
											<th>Day In Following Month</th>
											<th>Discount</th>
											<th>Require CC</th>
											<th>Allow Decline</th>
											<th>Do Not Print</th>
											<th>On Account Allowed</th>
											<th>Order Entry</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let type of payment_terms">
											<td>
												<button mat-icon-button color="warn" aria-label="delete" class="small-font" (click)="removePaymentTerm(type)">
													<mat-icon>remove</mat-icon>
												</button>
												<button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editPaymentTermDispaly(type)">
													<mat-icon>edit</mat-icon>
												</button>
											</td>
											<td>{{ type.termsindicator }}</td>
											<td>{{ type.terms }}</td>
											<td>{{ type.daysbeforedue }}</td>
											<td>{{ type.dayinfollowingmonth }}</td>
											<td>{{ type.terms_discount }}</td>
											<td>{{ yesNoValue(type.cc_process) }}</td>
											<td>{{ yesNoValue(type.decline) }}</td>
											<td>{{ yesNoValue(type.donot_print) }}</td>
											<td>{{ yesNoValue(type.onaccountallowed) }}</td>
											<td>{{ yesNoValue(type.order_entry) }}</td>
										</tr>
									</tbody>
								</table>
							</mat-tab>
							<mat-tab label="Customer Types">
								<button mat-stroked-button color="success" aria-label="" (click)="addCustomerTypeView()" class="mt-2">
									<mat-icon>add</mat-icon> Add Customer Type
								</button>
								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>
								<table class="table table-sm mt-3">
									<tr>
										<th></th>
										<th>ID</th>
										<th>Name</th>
										<th></th>
									</tr>
									<tr *ngFor="let type of customertypes">
										<td>
											<button mat-icon-button color="warn" aria-label="delete" class="small-font" (click)="removeCustomerType(type)">
												<mat-icon>remove</mat-icon>
											</button>
											<button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editCustomerTypeDisplay(type)">
												<mat-icon>edit</mat-icon>
											</button>
										</td>
										<td>{{ type.typeid }}</td>
										<td>{{ type.typename }}</td>
										<td><button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editCustomerTypeExclusions(type)">
												<mat-icon>block</mat-icon> Product Exclusions
											</button></td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Vendor Types">
								<button mat-stroked-button color="success" aria-label="" (click)="addVendorTypeDisplay()" class="mt-2">
									<mat-icon>add</mat-icon> Add Vendor Type
								</button>

								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>

								<table class="table table-sm mt-3">
									<tr>
										<th></th>
										<th>ID</th>
										<th>Name</th>
										<th></th>
									</tr>
									<tr *ngFor="let type of supptypes">
										<td>
											<button mat-icon-button color="warn" aria-label="delete" class="small-font" (click)="removeVendorType(type)">
												<mat-icon>remove</mat-icon>
											</button>
											<button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editVendorTypeDisplay(type)">
												<mat-icon>edit</mat-icon>
											</button>
										</td>
										<td>{{ type.typeid }}</td>
										<td>{{ type.typename }}</td>
										<td></td>
									</tr>
								</table>
							</mat-tab>
							<mat-tab label="Clubs">
								<button mat-stroked-button color="success" aria-label="" (click)="addClubDisplay()" class="mt-2">
									<mat-icon>add</mat-icon> Add Club
								</button>

								<mat-progress-bar mode="indeterminate" color="accent" *ngIf="isLoading"></mat-progress-bar>

								<table class="table table-sm mt-3">
									<tr>
										<th></th>
										<th>ID</th>
										<th>Name</th>
										<th></th>
									</tr>
									<tr *ngFor="let type of clubs">
										<td>
											<button mat-icon-button color="warn" aria-label="delete" class="small-font" (click)="removeClub(type)">
												<mat-icon>remove</mat-icon>
											</button>
											<button mat-icon-button color="accent" aria-label="Edit" class="small-font" (click)="editClubDisplay(type)">
												<mat-icon>edit</mat-icon>
											</button>
										</td>
										<td>{{ type.id }}</td>
										<td>{{ type.club }}</td>
										<td></td>
									</tr>
								</table>
							</mat-tab>
						</mat-tab-group>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>